import React from 'react'
import configdata from '../../services/config.json'

let link = "";
if (process.env.NODE_ENV !== "production") {
    link = configdata.frontEndUrl
} else {
    link = "https://www.professionaleditingtool.com/"
}

export default function FinalPreview() {
    return (
        <div>
            <iframe src={link} style={{height:"100vh", width:"100%"}} title="Iframe Example"></iframe>
        </div>
    )
}
