import http from "./httpService";
// import { apiUrl, herokuUrl } from "./config.json";
import configdata from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/detectors`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/detectors`;
}

export async function getAIReview(text){
  const response = await http.post(`${apiEndpoint}/coAuthorPrompts`, text );
  return response;
}

export async function getSearchApi(text){
  const response = await http.post(`${apiEndpoint}/coAuthorPrompts`, text );
  return response;
}



export default {
    getAIReview,
    getSearchApi
}