import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import Item from "./Item";
import { Button, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";
import Group570 from "../static/Group570.png";
import PaginatioN from "react-js-pagination";
import { paginate } from "../utils/paginate";
import Loader from "../utils/loader";
import { getHomeBlogs, getHomeCMS } from "../services/adminServices";
import noblogs from "../static/emptyblogs.png";
import HeadingB from "./Cards/HeadingB";
import seoServices from "../services/seoServices";
import { getProducts } from '../services/productService';
import { paramCase } from "change-case";
// import {frontEndUrl,imgUrl} from "../services/config.json";
import configdata from '../services/config.json'

export function Blogpage({ category }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = React.useState([]);
  const [navbarProducts, setNavbarProducts] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageItems] = useState(10);
  const [cms, setCms] = useState({});

  useEffect(() => {
    getBlogs();
    getCms();
  }, [currentPage]);

  const getBlogs = async () => {
    setLoading(true);
    let details = await seoServices.getSEOBlogsByCategoryWise(
      pageItems,
      currentPage,
      category
    );

    console.log("getBlogsdetails", details)
    if (details.status == 200) {
      setBlogs(details.data);
      setTotalItems(details.total);
    }
    let products = await getProducts();
    if (products.status == 200) {
      setNavbarProducts(products.data.products);
    }
    setLoading(false);
  };

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    // console.log(cms);
  };

  const handleAboutRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#about`
    } else {
      window.location.href = "https://www.professionaleditingtool.com/#about";
    }
  };

  const handleFeaturesRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#features`
    } else {
      window.location.href =
        "https://www.professionaleditingtool.com/#features";
    }
  };

  const handlePricingRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#pricing`
    } else {
      window.location.href = "https://www.professionaleditingtool.com/#pricing";
    }
  };

  const handleContactRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#contacts`
    } else {
      window.location.href =
        "https://www.professionaleditingtool.com/#contacts";
    }
  };

  const handleProductBlog = async (category) => {
    const product = {
      main_category: "products",
      category: category
    }
    const { data, status } = await seoServices.getProductSEOBlog(product);

    if (status === 200) {
      history.push({
        pathname: `/${paramCase(data.urlValue)}`,
        state: { detail: data }
      });
    } else {
      console.log('no blog found');
    }
  }

  return (
    <div>
      <Loader loading={loading} />

      <Navbar
        expand="lg"
        fixed="top"
        style={{
          backgroundColor: "#1976d2",
          paddingLeft: "7%",
        }}
      >
        <Navbar.Brand style={{ color: "white" }} href="/">
          <img
            className="petLogo"
            alt="Group570"
            style={{ width: "auto", height: "60px" }}
            src={
              cms && cms.Navbar_logo
                ? cms.Navbar_logo.Image
                : `${configdata.imgUrl}/pet-website-static-images/Group+571.png`
            }
          // src={Group570}
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navLinks ml-auto" style={{ marginRight: "20%" }}>
            <Nav.Link
              className="loginLink"
              style={{ color: "white", marginLeft: "10%" }}
            >
              <NavLink className="text-white ft_md" to={{ pathname: "/" }}>
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link className="ft_md text-white">
              <span className="text-white ft_md" onClick={handleAboutRoute}>
                About
              </span>
            </Nav.Link>
            <Nav.Link className="ft_md text-white">
              <span className="text-white ft_md" onClick={handleFeaturesRoute}>
                Features
              </span>
            </Nav.Link>
            <Nav.Link className="ft_md text-white">
              <span className="text-white ft_md" onClick={handlePricingRoute}>
                Pricing
              </span>
            </Nav.Link>
            <Nav.Link className="ft_md text-white">
              <span className="text-white ft_md" onClick={handleContactRoute}>
                Contact
              </span>
            </Nav.Link>
            <Nav.Link className="ft_md text-white">
              <span className="text-white ft_md" onClick={null}>
                Blogs
              </span>
            </Nav.Link>

            <NavDropdown title="Products" id="basic-nav-dropdown">
              {navbarProducts.map(product => (
                <NavDropdown.Item onClick={() => handleProductBlog(product)}>
                  {(product.charAt(0).toUpperCase() + product.slice(1)).replace(/_/g, ' ')}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            <Nav.Link
              className="loginLink"
              style={{ color: "white", marginLeft: "10%" }}
            >
              <NavLink className="text-white ft_md" to={{ pathname: "/login" }}>
                Login
              </NavLink>
            </Nav.Link>
            &nbsp;&nbsp;
            <Link to={{ pathname: "/signup" }}>
              <Button
                className="ft_md"
                style={{
                  textAlign: "center",
                  color: "#4085f6",
                  borderRadius: "20px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  backgroundColor: "white",
                }}
              >
                SignUp
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="container" style={{ marginTop: "7%" }}>
        <div id="mainPreview" className="mt-5">
          <div className="row">
            {blogs.length > 0 ? (
              <>
                {blogs.map((item) => (
                  <div className="col-md-6">
                    <Item item={item} allBlogs={blogs} />
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center">
                <img
                  src={noblogs}
                  style={{
                    height: "60%",
                    width: "60%",
                    marginTop: "5%",
                    marginBottom: "5%",
                  }}
                />
                <HeadingB
                  className="alertHead"
                  text="No Blog Published Yet..!"
                />
              </div>
            )}
          </div>
          {totalItems > pageItems ? (
            <div className="mt-5" style={{ float: "right" }}>
              <PaginatioN
                activePage={currentPage}
                itemsCountPerPage={pageItems}
                totalItemsCount={totalItems}
                pageRangeDisplayed={3}
                onChange={(page) => setCurrentPage(page)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Blogpage;
