import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Button, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import bg from "../static/Home-BG.png";
import Group468 from "../static/Group468.png";
import feature1 from "../static/feature1.png";
import feature2 from "../static/feature2.png";
import feature3 from "../static/feature3.png";
import feature4 from "../static/Group 466.png";
import Group463 from "../static/Group463.png";
import Group464 from "../static/Group464.png";
import Group465 from "../static/Group465.png";
import Group409 from "../static/Group 571.png";
import Group570 from "../static/Group570.png";
import facebook from "../static/facebook.png";
import linkedin from "../static/linkedin.png";
import twitter from "../static/twitter.png";
import BaselineBusiness from "../static/BaselineBusiness.png";
import BaselineEmail from "../static/BaselineEmail.png";
import BaselinePhonelink from "../static/BaselinePhonelink.png";
import HomeSubscription from "../components/HomeSubscription";
import "./Home.css";
import ScrollUp from "./ScrollUp";
import subscribedservices from "../services/subscribeusersservices";
import { toast, ToastContainer } from "react-toastify";
import queryString from "query-string";
import isEmptyObject from "../utils/isEmptyObject";
import ScrollAnimation from "react-animate-on-scroll";
import ReadMoreAndLess from "react-read-more-less";
import blueBG from "../static/blue-bg.png";
import lappy from "../static/lappy.png";
import { getHomeCMS } from "../services/adminServices";
import configdata from "../services/config.json";

class Home extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    cms: {},
  };

  async componentDidMount() {
    let cms = await getHomeCMS();
    this.setState({ cms: cms.data });
    console.log(cms);
    if (localStorage.getItem("sessiontimeout") === "true") {
      toast.info("sessionTimeOut..Please Login");
      localStorage.setItem("sessiontimeout", false);
    }
    if (!isEmptyObject(this.props)) {
      const { referedEmail, accountType } = queryString.parse(
        this.props.location.search
      );
      console.log(referedEmail);
      if (
        !localStorage.getItem("referedEmail") ||
        localStorage.getItem("referedEmail") === "undefined"
      ) {
        localStorage.setItem("referedEmail", referedEmail);
      }
      if (
        !localStorage.getItem("accountType") ||
        localStorage.getItem("accountType") === "undefined"
      ) {
        localStorage.setItem("accountType", accountType);
      }
    }
  }

  subscribe = async () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };
    let response = await subscribedservices.subscribedUsers(data);
    if (response.status !== 200) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }
    this.setState({ name: "", email: "", message: "" }); // clear input field after submission
  };

  onChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(this.state.name);
    // console.log(this.state.email);
    // console.log(this.state.message);
  };

  render() {
    let { cms } = this.state;

    const responsive = {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    };

    const stagePadding = {
      paddingLeft: 100,
      paddingRight: 100
    };

    const headStyle = {
      fontFamily: "Roboto",
      fontSize: "20px",
      lineHeight: "1.8",
      paddingTop: "2%",
    };

    const iconStyle = {
      width: "8%",
      height: "13%",
      objectFit: "contain",
      marginRight: "5%",
      marginTop: "2%",
    };

    const paraStyle = {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "1.47",
      textAlign: "left",
      marginLeft: "15%",
    };

    const ftHeadStyle = {
      fontSize: "17px",
      color: "#ffffff",
      // marginBottom: "15%"
    };

    const ftListStyle = {
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "2.79",
      textAlign: "left",
      color: "#a1a1a1",
      marginBottom: "10%",
    };

    const ftIconStyle = {
      width: "2.5rem",
      height: "2.5rem",
      objectFit: "contain",
      marginRight: "3%",
      // marginTop: "55%"
    };

    const fticon = {
      width: "4%",
      height: "5%",
      objectFit: "contain",
    };

    return (
      <div style={{ overflowY: "hidden" }}>
        {/* Start */}
        <ToastContainer />
        <div>
          {/* Main Background */}
          <div
            className="res-home-bg"
            style={{
              height: "100vh",
              width: "100%",
              // overflow:"auto",
              backgroundImage: `url(${
                cms.Background_left
                  ? cms.Background_left.Image
                  : `${configdata.imgUrl}/pet-website-static-images/blue-bg.png`
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <img 
              id="lappy_image"
              src={cms.Background_right?cms.Background_right.Image :
                `${configdata.imgUrl}/pet-website-static-images/lappy.png`} 
              alt="lappy" 
              className="img-fluid"
              style={{
                position: "absolute",
                zIndex: 1,
                width: `${
                  cms.Background_right ? cms.Background_right.width : "50"
                }vh`,
                height: `${
                  cms.Background_right ? cms.Background_right.height : "50"
                }vh`,
                marginTop: `${
                  cms.Background_right ? cms.Background_right.margin_top : "10"
                }%`,
                marginLeft: `${
                  cms.Background_right ? cms.Background_right.margin_left : "50"
                }%`,
                marginRight: `${
                  cms.Background_right ? cms.Background_right.margin_right : "0"
                }%`,
                marginBottom: `${
                  cms.Background_right
                    ? cms.Background_right.margin_bottom
                    : "0"
                }%`,
              }}
            />
            {/* NavBar Start */}

            <div className="container p-5">
              <Navbar
                expand="lg"
                fixed="top"
                style={{
                  // position: "sticky",
                  // top: "0",
                  backgroundColor: "#1976d2",
                  paddingLeft: "7%",
                  // marginTop: "3%",
                  // backgroundColor:
                  //   window.scrollY > 100 ? "#1976d2" : "transparent"
                }}
                // sticky="top"
              >
                <Navbar.Brand style={{ color: "white" }} href="#">
                  <img
                    className="petLogo"
                    alt="Group570"
                    style={{ width: "auto", height: "60px" }}
                    src={
                      cms.Navbar_logo
                        ? cms.Navbar_logo.Image
                        : `{configdata.imgUrl}/pet-website-static-images/Group+571.png`
                    }
                  ></img>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  // className="navbar-toggler navbar-toggler-right"
                />
                {/* justify-content-end */}
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="navLinks" style={{ marginLeft: "40%" }}>
                    <Nav.Link className="ft_md text-white" href="#about">
                      About
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white" href="#features">
                      Features
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white" href="#pricing">
                      Pricing
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white">
                      <NavLink
                        className="ft_md text-white"
                        to={{ pathname: "/faq" }}
                      >
                        FAQ
                      </NavLink>
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white" href="#contacts">
                      Contact
                    </Nav.Link>
                    <Nav.Link className="ft_md text-white">
                      <NavLink
                        className="ft_md text-white"
                        to={{ pathname: "/publicblog" }}
                      >
                        Blogs
                      </NavLink>
                    </Nav.Link>
                    <Nav.Link
                      className="loginLink"
                      style={{ color: "white", marginLeft: "10%" }}
                      // href="#"
                    >
                      <NavLink
                        className="text-white ft_md"
                        to={{ pathname: "/login" }}
                      >
                        Login
                      </NavLink>
                    </Nav.Link>
                    &nbsp;&nbsp;
                    <Link to={{ pathname: "/signup" }}>
                      <Button
                        className="ft_md"
                        style={{
                          textAlign: "center",
                          color: "#4085f6",
                          borderRadius: "20px",
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          backgroundColor: "white",
                        }}
                      >
                        SignUp
                      </Button>
                    </Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>

            {/* Navbar Ends */}
            {/* lappy ends */}
            <div className="container ">
              <div className="row mt-0 mt-sm-0 mt-md-5">
                <div className="col-md-6 col-8">
                  <div>
                    <p className="res-home-maintitle ft-46 ft-wt-500 text-white mt-3 mb-1 rm">
                      {cms.Project_title}
                      {/* Welcome to Professional Editing Tool */}
                    </p>

                    <div>
                      <input
                        type="checkbox"
                        className="read-more-state"
                        id="post-1"
                      />

                      <p className="read-more-wrap res-home-maintext ft_xl text-white">
                        {cms.Project_subtext}
                        {/* PET is the industry-leading document editing and team
                        collaboration software for Businesses, Educators,
                        Students and Researchers. PET, integration with other
                        apps and services, with the power of AI brings all the
                        state-of-the-art, extensive features which are available
                        in multiple writing tools. &nbsp;
                        <span class="read-more-target">
                          With PET, you can create and edit new projects from
                          any device. Develop a writing education that meets
                          students’ and professionals’ contemporary writing
                          needs.
                        </span> */}
                      </p>

                      <label for="post-1" class="read-more-trigger"></label>
                    </div>

                    <br />

                    {/* <button className="btn btn-white-outline ft_lg px-4 py-2">
                      Know More
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            {/* lappy ends */}
          </div>
          <div id="about"></div>
          <br />
          <br />
          <br />

          {/* Cards Start */}
          <div className="container">
            <div className="text-center">
              <p className="ft-36 rm">{cms.About_title}</p>
              <p className="ft_lg text-b">
                {cms.About_text}
                {/* Professional Editing Tool (PET) is our industry-Leading Document
                Editing and Team Collaboration Solution that was built to help
                improve, integrate and govern document editing and team
                collaboration. Our Mission at PET is to provide industry with
                full featured multifunctional tools and services to meet each
                client's specific document editing needs. At PET, we understand
                the importance effective writing and clarity of ideas. For this
                reason, Your Professional Editing Tool (PET) was developed to
                provide professional editing and team collaboration services to
                all business professionals, students, educators, and writers.
                Simply put, we had the writers in mind! */}
              </p>
              <br />
              <br />
            </div>

            <div className="container">
              <div className="row">
                {cms.About_Cards ? (
                  <>
                    <AliceCarousel
                      duration={400}
                      autoPlay={true}
                      startIndex={0}
                      fadeOutAnimation={true}
                      mouseDragEnabled={true}
                      responsive={responsive}
                      autoPlayInterval={2000}
                      autoPlayDirection="ltr"
                      buttonsDisabled={true}
                      // autoPlayActionDisabled={true}
                      // disableAutoPlayOnAction={true}
                      // stagePadding={stagePadding}
                      infinite={true}
                    >
                      {cms.About_Cards.map((card) => (
                          <div
                            className="card text-center"
                            style={{ width: "85%", height: "65vh" }}
                          >
                            <div className="card-body my-3">
                              <div>
                                <img
                                  className="img-fluid"
                                  src={card.Card_image}
                                  alt=""
                                  style={{ height: "12vh" }}
                                ></img>
                              </div>
                              <br />
                              <p className="ft-22 mb-3 mt-1">{card.Card_title}</p>
                              <p
                                className="card-text ft_md p-2"
                                style={{ height: "35vh", overflowY: "auto" }}
                              >
                                {card.Card_text}
                              </p>
                            </div>
                          </div>
                      ))}
                    </AliceCarousel>
                  </>
                ) : (
                  ""
                )}

                {/* <div className="col-md-4 text-center">
                  <ScrollAnimation animateIn="slideInLeft">
                    <div className="card shadow" style={{ width: "100%" }}>
                      <div className="card-body my-3">
                        <div>
                          <img className="img-fluid" src={feature1} alt=""></img>
                        </div>
                        <br />
                        <p className="ft-22 mb-2">24 / 7 Support</p>
                        <p
                          className="card-text ft_md p-2"
                          style={{ height: "35vh", overflowY: "auto" }}
                        >
                          Since we want each individual person and organization
                          to succeed in their Editing Needs, PET offers 24/7
                          technical support day or night. Our 24/7 support
                          seamlessly transitions between getting support by
                          virtual house calls, phone, chat, and/or DIY guides.
                          Our Technical Support Professionals speak in everyday
                          language and have the experience, know-how, and tools
                          to solve your editing issues as quickly as possible.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>

                <div className="col-md-4 text-center">
                  <ScrollAnimation animateIn="slideInDown">
                    <div className="card shadow" style={{ width: "100%" }}>
                      <div className="card-body my-3">
                        <div>
                          <img className="img-fluid" src={feature2} alt=""></img>
                        </div>
                        <br />
                        <p className="ft-22 mb-2">Data Privacy</p>
                        <p
                          className="card-text ft_md p-2"
                          style={{ height: "35vh", overflowY: "auto" }}
                        >
                          At PET, we act to protect data privacy online and
                          improve personal data protection. We understand that
                          data privacy protection concerns are driving new
                          regulations around the world, and so we go beyond just
                          complying with all the new requirements, but we ensure
                          that we build trust with consumers and users and stand
                          out from their competitors.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-md-4 text-center">
                  <ScrollAnimation animateIn="slideInRight">
                    <div className="card shadow" style={{ width: "100%" }}>
                      <div className="card-body my-3">
                        <div>
                          <img className="img-fluid" src={feature3} alt=""></img>
                        </div>
                        <br />
                        <p className="ft-22 mb-2">Easy Write</p>
                        <p
                          className="card-text ft_md p-2"
                          style={{ height: "35vh", overflowY: "auto" }}
                        >
                          Your PET document editing and team collaboration makes
                          your writing easy. Your PET’s AI Algorithm know the
                          English language inside out and will analyze your
                          written English using over 160 English grammar rules.
                          PET makes your writing easy by not just pointing out
                          mistakes, but by actually fixing it for you, saving
                          you the major headache and confusion of deciphering
                          complex English rules. PET also provide the ability to
                          collaborate live with other writers, which allow
                          others to see what you missed and to ensure you are
                          not alone.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div> */}
              </div>
            </div>
          </div>
          <div id="features"></div>
          <br />
          <br />
          <br />
          {/* Card End */}

          {/* Feature */}
          <div
            id="features"
            className="features text-white"
            style={{ backgroundColor: "#3b81f3" }}
          >
            <br /> <br />
            <div className="text-center text-white mx-4">
              <p className="ft-36 mb-0 ft-wt-500">Features</p>
              <br />
            </div>
            <div
              style={{
                paddingRight: "15%",
                paddingTop: "4%",
                paddingBottom: "6%",
              }}
            >
              <div className="row">
                <div
                  className="col-md-6"
                  style={{
                    height: `${
                      cms.Feature_image ? cms.Feature_image.height : "50"
                    }vh`,
                    width: `${
                      cms.Feature_image ? cms.Feature_image.width : "100"
                    }vw`,
                    backgroundImage: `url(${
                      cms.Feature_image
                        ? cms.Feature_image.image
                        : `${configdata.imgUrl}/pet-website-static-images/Group468.png`
                    })`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div className="col-md-6" id="featureId">
                  {cms.Features ? (
                    <>
                      {cms.Features.map((feature) => (
                        <div className="row">
                          <img
                            alt="Group463"
                            src={feature.image}
                            style={iconStyle}
                          ></img>
                          <h6 style={headStyle}>{feature.title}</h6>
                          <p style={paraStyle}>{feature.text}</p>
                        </div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}

                  {/* <div className="row">
                    <img alt="Group463" src={Group463} style={iconStyle}></img>
                    <h6 style={headStyle}>AI-Powered Products</h6>
                    <p style={paraStyle}>
                      The Professional Editing Tool (PET) is an editing software
                      that incorporates AI to provide a whole new experience in
                      making editing easier and thus improve the workflow. PET
                      utilizes uses advanced machine learning algorithms
                      solutions to analyze documents and grammar in seconds.
                      PET’s Artificial intelligence advises users to rewrite
                      their text whenever a complex phrase will be written.
                      These features will assist writers in every aspect of
                      writing a document.
                    </p>
                  </div>

                  <div className="row">
                    <img alt="Group464" src={Group464} style={iconStyle}></img>
                    <h6 style={headStyle}>Digital Writing</h6>
                    <p style={paraStyle}>
                      The PET application brings all the state-of-the-art,
                      extensive features which are available in multiple writing
                      tools, under one platform to facilitate writers,
                      researchers and students and cut down their cost spending
                      on different platform for multiple features. PET digital
                      writing features make it easy for users to "write" in all
                      sorts of new ways that enable better communications and
                      collaborations for more productivity.
                    </p>
                  </div>

                  <div className="row">
                    <img alt="Group465" src={Group465} style={iconStyle}></img>
                    <h6 style={headStyle}>Sharing with Team</h6>
                    <p style={paraStyle}>
                      PET application helps to align teams with complete editing
                      and writing, while providing Team Collaboration on each
                      document. The PET application is a subscription based
                      multiple users editing tool where users can create,
                      manage, share the documents and write collaboratively with
                      other users in groups or independently.
                    </p>
                  </div>

                  <div className="row">
                    <img alt="Group465" src={feature4} style={iconStyle}></img>
                    <h6 style={headStyle}>Mistake - Free Writing</h6>
                    <p style={paraStyle}>
                      Compose clear, mistake-free writing that makes the right
                      impression with PET’s writing assistant. PET also provide
                      plagiarism Checks to ensure originality and integrity of
                      your writing comparing it with previously published
                      content.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div id="pricing"></div>
          <br />
          <br />

          {/* Features End */}

          {/* Pricing */}
          <div className="container">
            <h2 className="text-center">Pricing</h2>

            <HomeSubscription hide={true} />
          </div>
          <div className="text-center">
            <br />

            <div id="contacts"></div>
          </div>
          <br />
          <br />
          {/* <Subscriptions /> */}
          {/* Pricing Ends */}

          {/* Newsletter */}
          <div
            className="container-fluid text-center py-5"
            style={{ backgroundColor: "#E5E7E9" }}
          >
            <div className="text-g mx-3">
              <p className="ft-36 mb-2">
                {" "}
                Subcribe to know about offers and features!
              </p>
            </div>
            <div>
              <br />
              <br />
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  <input
                    id="newsInput"
                    className="border-0 w-75 p-3"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeData}
                    required
                  ></input>
                  <br />
                  <br />
                  <input
                    id="newsInput"
                    className="border-0 w-75 p-3"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeData}
                    required
                  ></input>
                  <br />
                  <br />
                  <textarea
                    id="newsInput"
                    rows="4"
                    className="border-0 w-75 p-3"
                    placeholder="Your Message"
                    name="message"
                    value={this.state.message}
                    onChange={this.onChangeData}
                    required
                  ></textarea>
                  {/* <input
                    id="newsInput"
                    className="border-0 w-75"
                    placeholder="Your Message"
                    name="message"
                    value={this.state.message}
                    onChange={this.onChangeData}
                    required
                  ></input> */}
                  <br />
                  <br />
                  <button
                    type="button"
                    className="btn btn-outline-primary px-5"
                    id="msgBtn"
                    onClick={this.subscribe}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Newsletter ends */}

          {/* End */}
        </div>

        {/* Footer */}

        <div
          className="footer"
          id="footer"
          style={{
            backgroundColor: "#212121",
            overflowX: "hidden",
          }}
        >
          <div
            className="row ftRow"
            style={{
              paddingBottom: "1%",
              paddingLeft: "6%",
              paddingRight: "4%",
              paddingTop: "2%",
            }}
          >
            <div className="col-md-5">
              <div>
                <img
                  className="ftLogo mb-4"
                  alt="Group409"
                  src={
                    cms.Navbar_logo
                      ? cms.Navbar_logo.Image
                      : `${configdata.imgUrl}/pet-website-static-images/Group+571.png`
                  }
                  style={{
                    width: "35%",
                    height: "15%",
                    objectFit: "contain",
                  }}
                ></img>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselineBusiness"
                    src={BaselineBusiness}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize: "13px" }}
                  >
                    {cms.Footer_Details
                      ? cms.Footer_Details.Address
                      : "22738, Maple Road Suite 120, Lexington Park, Maryland, 20634"}
                    {/* 22738, Maple Road Suite 120, Lexington Park, Maryland, 20634 */}
                  </text>
                </p>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselinePhonelink"
                    src={BaselinePhonelink}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize: "13px" }}
                  >
                    {cms.Footer_Details
                      ? cms.Footer_Details.Phone_no
                      : "301-997-5695 "}
                    {/* 301-997-5695 */}
                  </text>
                </p>

                <p>
                  <img
                    className="ftIcon"
                    alt="BaselineEmail"
                    src={BaselineEmail}
                    style={fticon}
                  ></img>

                  <text
                    className="ml-3"
                    style={{ color: "#9d9d9d", fontSize: "13px" }}
                  >
                    {cms.Footer_Details
                      ? cms.Footer_Details.Email
                      : "admin@professionaleditingtool.com"}
                    {/* admin@professionaleditingtool.com */}
                  </text>
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="vl"
                style={{
                  borderLeft: "1px solid white",
                  height: "155px",
                  float: "left",
                  marginLeft: "-7%",
                }}
              ></div>

              <div className="ftDivTwo ml-5 mt-2">
                <p>
                  <text className="mr-5" style={ftHeadStyle}>
                    Company
                  </text>
                  <text style={ftHeadStyle}>Legal</text>
                </p>

                <p>
                  <a href="#about" className="mr-5" style={ftListStyle}>
                    About Us
                  </a>
                  <Link to="/faq" className="ml-4" style={ftListStyle}>
                    FAQ
                  </Link>
                </p>

                <p>
                  <a href="/privacypolicy" className="mr-5" style={ftListStyle}>
                    Privacy Policy
                  </a>
                  <Link to="/termsofservice" style={ftListStyle}>
                    Terms of Service
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-md-3 text-center">
              <div
                className="vl"
                style={{
                  borderLeft: "1px solid white",
                  height: "155px",
                  float: "left",
                  // marginLeft: "10%"
                }}
              ></div>
              <div className="pt-4">
                <ScrollUp />
                <div
                  className="row ftIcons mt-4"
                  style={{ paddingLeft: "23%" }}
                >
                  <div className="p-2">
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={cms.Footer_Links ? cms.Footer_Links.Facebook : ""}
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="facebook"
                        src={facebook}
                        style={ftIconStyle}
                      ></img>
                    </a>
                  </div>
                  <div className="p-2">
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={cms.Footer_Links ? cms.Footer_Links.Linkedin : ""}
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="linkedin"
                        src={linkedin}
                        style={ftIconStyle}
                      ></img>
                    </a>
                  </div>
                  <div className="p-2">
                    {/* <a href="https://www.facebook.com/" target="_blank"> */}
                    <a
                      href={
                        cms.Footer_Links
                          ? cms.Footer_Links.Twitter
                          : "https://www.facebook.com/"
                      }
                      target="_blank"
                    >
                      <img
                        className="icon "
                        alt="twitter"
                        src={twitter}
                        style={ftIconStyle}
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              opacity: "0.35",
              backgroundColor: "#757575",
              paddingTop: "1%",
              paddingBottom: "1%",
            }}
          >
            <div className="col-md-12 text-center">
              <p
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "300",
                  // lineHeight: "2.99",
                  color: "#ffffff",
                }}
              >
                Copyright © Professional Editing Tool Inc. All Rights Reserved{" "}
                {`${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
