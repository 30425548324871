import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Subscription from "./Subscription";
import { getAllPlan } from "../services/plans";
import { data } from "./PricePlans/data";
import BreadCrumb from "./Cards/BreadCrumbs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Loader from "../utils/loader";
import authService from "../services/authService";
import subscription from "../services/subscription";

function PricingPlan({ hide, currentUser }) {
  const [planList, setplanList] = useState([]);
  const [dashLink] = ["/admindashboard"];
  // const [dashboardLink] = ["/subAdminManageUser"];
  const [dashboardLink] = ["/subAdminDashboard"];
  const [loader, setLoader] = useState(false);
  const user = localStorage.getItem("Role") || "Individual"
  console.log(user, "userdatacheck")
  
  useEffect(() => {
    getSubscriptions();
  }, [user]);
  const getSubscriptions = async () => {
    try {
      // setLoading(true)
      const response = await subscription.getSubscriptions();
      if (response.status === 200) {
        const allSubscriptions = response?.data;
        console.log(allSubscriptions, "allSubscriptions");


        // Separate subscriptions by userType
        const organizationPlans = allSubscriptions.filter(
          (plan) => plan.userType === user
        );
        const plans = organizationPlans;
        console.log("plans===>", plans);

        if (plans.length >= 3) {
          const temp = plans[1];
          plans[1] = plans[2];
          plans[2] = temp;
        }

        let html = plans.map((plan) => {
          const content = unOrderList(plan.billingCycle);
          const planName = plan?.name;
          const nameOfTerms = plan?.billingCycle;
          const planMode = { mode: plan?.planMode };
          const termDetails = convertToObj(plan?.billingCycle);
          const plan_id = plan?._id;
          const defaultPlan = plan?.isDefault;
          return {
            heading: planName,
            ul: content,
            btn_list: nameOfTerms,
            price_detail: termDetails,
            plan_mode: planMode,
            plan_id: plan_id,
            defaultPlan: defaultPlan
          };
        });
        setplanList(html);
        setLoader(false);
      }
    } catch (error) {
      console.log(error, "Error while fetching subscriptions");
    } finally {
      // setLoading(false)
    }
  };

  const initalCall = async () => {
    const data = await getAllPlan();
    if (data.status !== 200) return;

    const plans = data.plan;
    console.log("plans===>", plans);

    if (plans.length >= 3) {
      const temp = plans[1];
      plans[1] = plans[2];
      plans[2] = temp;
    }

    let html = plans.map((plan) => {
      const content = unOrderList(plan.billingCycle);
      const planName = plan.planName;
      const nameOfTerms = plan.billingCycle;
      const planMode = { mode: plan.planMode };
      const termDetails = convertToObj(plan.billingCycle);
      const plan_id = plan._id;
      return {
        heading: planName,
        ul: content,
        btn_list: nameOfTerms,
        price_detail: termDetails,
        plan_mode: planMode,
        plan_id: plan_id,
      };
    });
    setplanList(html);
    setLoader(false);
  };

  const convertToObj = (termDetails) => {
    const termObj = termDetails.reduce((newObj, obj) => {
      const { duration: term, price, totalUsers, userCounts } = obj;

      // Skip entries with missing term (duration)
      if (!term) {
        console.warn("Encountered an item with an undefined term:", obj);
        return newObj;
      }

      // Initialize the term key if it doesn't exist
      if (!newObj[term]) {
        newObj[term] = [];
      }

      // Push the relevant data to the term's array
      newObj[term].push({ price, totalUsers, userCounts });

      return newObj;
    }, {});

    console.log(termObj, "termObj");
    return termObj;
  };


  // const unOrderList = (contentObj) => {
  //   if (contentObj) {
  //     const ul = Object.keys(contentObj).map((key,index) => {
  //       if (key.includes("Subscription Cost")) {
  //         let get_data = contentObj[key];
  //         if (typeof get_data == "object") {
  //           const lh = <lh key={index}>{key}</lh>;
  //           const li = get_data.map((text,index) => <li key={index}>{text}</li>);
  //           return (
  //             <ul key={index}>
  //               {" "}
  //               {lh} {li}{" "}
  //             </ul>
  //           );
  //         }
  //       }
  //     });
  //     return ul;
  //   }
  // };

  const unOrderList = (contentObj) => {
    console.log(contentObj, "contentObj")
    if (contentObj) {
      const ul = contentObj.map((cycle, index) => {
        // Extract necessary properties
        const { duration, price, totalUsers, userCounts } = cycle;

        // Construct the subscription text
        // const subscriptionText = `${duration.charAt(0).toUpperCase() + duration.slice(1)} subscription: $${price}/${duration}, Total Users: ${totalUsers}`;
        const subscriptionText = `${duration.charAt(0).toUpperCase() + duration.slice(1)} subscription: $${price}/${duration}${user === "Organization" ? `, Total Users: ${totalUsers}` : ''}`;

        // Construct userCounts text
        const userCountText = `Users: Admin - ${userCounts?.admin}, Power Users - ${userCounts?.powerUser}, Regular Users - ${userCounts?.regularUser}`;
        console.log(subscriptionText, userCountText, "userCountText")
        return (
          <li key={index}>
            <div>{subscriptionText}</div>
            {user == "Organization" && <div>{userCountText}</div>}
          </li>
        );
      });
      console.log(ul, "ullll")
      return (
        <ul>
          <lh>Subscription Details</lh>
          {ul}
        </ul>
      );
    }

    return null;
  };

  return (
    <div>
      <Loader loading={loader} />
      {!hide && (
        <div className="d-flex justify-content-between align-items-center">
          {/* {currentUser.isAdmin ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Subcriptions"
              dashboardlink={dashLink}
            />
          ) : currentUser.isSubAdmin ? (
            <BreadCrumb
              l1="Dashboard"
              l2="Subcriptions"
              dashboardlink={dashboardLink}
            />
          ) : (
            <>
             
            <BreadCrumb
              l1="Home"
              l2="Subcriptions"
              dashboardlink="dashboard"
            />
            </>
          )} */}

          <div>
            {currentUser && currentUser.isAdmin ? (
              <Link to={"/addsubscription"}>
                <button className="btn btn-primary">
                  <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                  Manage Plans
                </button>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <h1 style={{ textAlign: 'center' }}>Explore for free and unlock additional features later on.</h1>
      {currentUser && currentUser.isAdmin ? (
        <h1 className="text-center" style={{ color: "#3e80f1" }}>Plans</h1>
      ) : (
        <h1 className="text-center" style={{ color: "#3e80f1" }}>Select your plan</h1>
      )}

      <div className="row">
        {planList.map((obj, index) => {
          return (
            <Subscription
              key={index}
              price_detail={obj.price_detail}
              plan_id={obj.plan_id}
              planName={obj.heading}
              planList={obj.ul}
              btn_list={obj.btn_list}
              plan_mode={obj.plan_mode}
              hide={hide}
              defaultPlan={obj?.defaultPlan}
            />
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PricingPlan));
