
export const setSubscriptionPlan = (plan) => {
    console.log("splan",plan)
    return {
        type: "SET_SUBSCRIPTION_PLAN",
        payload: plan,
    };
};


