import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {setCurrentUser } from "../redux/user/user.actions";
import ChatServices from "../services/ChatServices";
const Logout = () => {
  let history = useHistory();
  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    ChatServices.setStatus(userId, 'Offline');
    setCurrentUser({});
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/'
    // history.push('/');
    // history.go(0);
  },[]);
  return null;
};

export default Logout;
