// import { apiUrl, herokuUrl } from "./config.json";
import configdata from "./config.json";

import http from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = configdata.apiUrl + "/payment";
} else {
  apiEndpoint = configdata.herokuUrl + "/payment";
}

export async function addPayments(data) {
  console.log(data);
  let response = await http.post(`${apiEndpoint}/buy`, data);
  console.log(response);
  return response;
}


export async function validatePayment(data) {
  let response = await http.post(`${apiEndpoint}/paypal-transaction-complete`, data);
  console.log(response);
  return response;
}


export default {
  addPayments,
    validatePayment
}