// roleFeatures.reducer.js
const INITIAL_STATE = {
    roleFeatures: []
  };
  
  const roleFeatureReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case 'ROLE_FEATURES':
        return {
          ...state,
          roleFeatures: action.payload  // Store the payload array here
        };
      default:
        return state;
    }
  };
  
  export default roleFeatureReducer;
  