import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, IconButton, Modal, Card, CardContent, CardMedia, Typography, Grid, Menu, MenuItem, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import WindowIcon from '@mui/icons-material/Window';
import DownloadIcon from '@mui/icons-material/Download';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Link } from 'react-router-dom';
import { DocumentGridCard, DocumentListCard } from './MyDocumentsCards';
import documentService from '../services/document';
import { ListItemText } from "@material-ui/core";
import { toast } from 'react-toastify';
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllOrgDocs, getSharedDocsOfOrgUsers } from '../services/subAdminServices';
import { useSelector } from "react-redux";
import Loader from '../utils/loader';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import HeadingB from "./Cards/HeadingB";
import Pagination from '@mui/material/Pagination';
import { addUserLogs } from '../services/userLogServices';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const SubAdminDocuments = () => {
    const userDetails = useSelector((user) => user)
    const userID = userDetails.user.currentUser._id
    const [loading, setLoading] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [listView, setListView] = useState(true)
    const [documents, setDocuments] = useState([])
    const [sortOrder, setSortOrder] = useState('asc');
    const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
    const [myDocs, setMyDocs] = useState([]);
    const [deletedDocs, setDeletedDocs] = useState([]);
    const [sharedDocs, setSharedDocs] = useState([]);
    const [initialMyDocs, setInitialMyDocs] = useState([])
    const [initialDeletedDocs, setInitialDeletedDocs] = useState([])
    const [initialSharedDocs, setInitialSharedDocs] = useState([])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [itemsPerPage, setItemsPerPage] = useState(windowWidth > 991 ? 9 : windowWidth > 767 ? 8 : 3);
    const [myDocsCurrentPage, setMyDocsCurrentPage] = useState(1);
    const [combinedMyDocs, setCombinedMyDocs] = useState([])
    const [selectAllSharedChecked, setSelectAllSharedChecked] = useState(false);
    const [sharedDocsCurrentPage, setSharedDocsCurrentPage] = useState(1);
    // const sharedRef = useRef(null)
    // const scrollToShared = () => {
    //     let element = sharedRef.current;
    //     if (element) {
    //         element.scrollTop = element.scrollHeight;
    //     }
    // }

    // const location = useLocation()
    // const currentPath = window.location.pathname;
    // console.log("currentPath",currentPath)
    // useEffect(() => {
    //     console.log("location",location)
    //     // if (location.hash == '#shared-documents' && myDocs.length > 0 && sharedDocs.length > 0) {
    //     //     const sharedDocumentsDiv = document.getElementById('shared-documents');
    //     //     // if (sharedDocumentsDiv) {
    //     //     //     sharedDocumentsDiv.scrollIntoView({ behavior: 'smooth' });
    //     //     // }
    //     // }
    // }, [myDocs, sharedDocs]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const history = useHistory();
    useEffect(() => {
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            setWindowWidth(newWindowWidth);

            // Update itemsPerPage based on the new window width
            if (newWindowWidth > 991) {
                setItemsPerPage(9);
            } else if (newWindowWidth > 767) {
                setItemsPerPage(8);
            } else {
                setItemsPerPage(3);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Select all cards
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllChecked(isChecked);
        setCombinedMyDocs((prevDocuments) => {
            return prevDocuments.map((doc) => ({
                ...doc,
                isChecked: isChecked
            }));
        });
        // setDeletedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });

        // setSharedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });
    };

    // Download pdf and word doc
    const handleDownloadDropdownClick = (event) => {
        setDownloadAnchorElDropdown(event.currentTarget);
    };

    const handleDownloadDropdownClose = () => {
        setDownloadAnchorElDropdown(null);
    };

    const getMyDocsCurrentPage = () => {
        const startIndex = (myDocsCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return combinedMyDocs.slice(startIndex, endIndex);
    };
    const getSharedDocsCurrentPage = () => {
        const startIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sharedDocs.slice(startIndex, endIndex);
    };

    // const getDeletedDocsCurrentPage = () => {
    //     const startIndex = (deletedDocsCurrentPage - 1) * itemsPerPage;
    //     const endIndex = startIndex + itemsPerPage;
    //     return combinedDeletedDocs.slice(startIndex, endIndex);
    // };


    const handleWordDownload = async() => {
        const selectedDocuments = documents.filter((docs) =>
            docs.isChecked
        );

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }

        const wordDocContent = selectedDocuments
            .map(
                (doc) => {
                    const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
                    let description = parsedJson.blocks[0].text;
                    return `${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/>`
                }
            )
            .join('');


        asBlob(wordDocContent).then(docx => {
            saveAs(docx, `Documents.docx`);
        });

        const data = {
            userId: sessionStorage.getItem("userId"),
            organizationId: sessionStorage.getItem("organizationId"),
            actionType: "downloadDoc",
          };
    
          const addLogs = await addUserLogs(data);
          if (addLogs.status !== 200) {
            toast.error(addLogs.message);
          }
    }


    const handlePDFDownload = async() => {
        const selectedDocuments = documents.filter((docs) => docs.isChecked);

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }

        const pdfContent = selectedDocuments
            .map((doc) => {
                const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = parsedJson.blocks[0].text;

                // Customize the HTML structure as needed
                return `<div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div>`;
            })
            .join('');

        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: 'Documents.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, 'Documents.pdf');
        });
        const data = {
            userId: sessionStorage.getItem("userId"),
            organizationId: sessionStorage.getItem("organizationId"),
            actionType: "downloadDoc",
          };
    
          const addLogs = await addUserLogs(data);
          if (addLogs.status !== 200) {
            toast.error(addLogs.message);
          }
    };




    const handleDropdownSelectforDownload = (selectedOption) => {
        console.log(selectedOption, "selected option");
        switch (selectedOption) {
            case "MS-Word":
                handleWordDownload()
                break;
            case "PDF":
                console.log("PDF")
                handlePDFDownload();
                break;
        }
        handleDownloadDropdownClose();
    };



    // Sorting documents
    const handleSortClick = () => {
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newSortOrder);
        const sortMyDocs = [...myDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortSharedDocs = [...sharedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });

        setMyDocs(sortMyDocs);
        setDeletedDocs(sortDeletedDocs);
        setSharedDocs(sortSharedDocs);

    };


    // Search Documents
    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase();

        const filteredMyDocs = initialMyDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredSharedDocs = initialSharedDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );

        setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
        setDeletedDocs(() => (newSearchTerm ? filteredDeletedDocs : initialDeletedDocs));
        setSharedDocs(() => (newSearchTerm ? filteredSharedDocs : initialSharedDocs));
    };


    // Individual Card Selection
    // const handleMyDocsCardChange = (index) => {
    //     setMyDocs((prevDocuments) => {
    //         const newDocuments = [...prevDocuments];
    //         newDocuments[index].isChecked = !newDocuments[index].isChecked;
    //         return newDocuments;
    //     });
    // };

    const handleMyDocsCardChange = (index) => {
        const pageIndex = (myDocsCurrentPage - 1) * itemsPerPage;
        const docIndex = pageIndex + index; // Calculate the index of the document in the entire list
        setCombinedMyDocs((prevDocuments) => {
            const newDocuments = [...prevDocuments];
            newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

            // Check if all individual cards are checked or not
            const allChecked = newDocuments.every((doc) => doc.isChecked);
            setSelectAllChecked(allChecked);

            return newDocuments;
        });
    };

    const handleMyDocsPageChange = (event, page) => {
        setMyDocsCurrentPage(page);
    };
    const handleSharedDocsPageChange = (event, page) => {
        setSharedDocsCurrentPage(page);
    };

    // const handleDeletedDocsPageChange = (event, page) => {
    //     setDeletedDocsCurrentPage(page);
    // };

    const handleSharedDocsCardChange = (index) => {
        const pageIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
        const docIndex = pageIndex + index;
        setSharedDocs((prevDocuments) => {
            const newDocuments = [...prevDocuments];
            newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

            // Check if all individual cards are checked or not
            const allChecked = newDocuments.every((doc) => doc.isChecked);
            setSelectAllSharedChecked(allChecked);

            return newDocuments;
        });
    };

    // const handleDeletedDocsCardChange = (index) => {
    //     setDeletedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };

    // const handleSharedDocsCardChange = (index) => {
    //     setSharedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };



    // API Calls for getting docs, deleted docs and shared docs
    const getDocsSubAdmin = async () => {
        setLoading(true)
        try {
            const response = await getAllOrgDocs(userID);
            console.log("getDocsSubAdmin", response)
            if (response.status === 200) {
                setMyDocs(response.data);
                setInitialMyDocs(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
        setLoading(false)
    };

    const getDocs = async () => {
        try {
            const response = await documentService.getAllDocuments();
            if (response.status === 200) {
                setMyDocs(response.data);
                setInitialMyDocs(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getDeletedDocs = async () => {
        try {
            const response = await documentService.getDeletedDocuments();
            if (response.status === 200) {
                setDeletedDocs(response.data);
                setInitialDeletedDocs(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getSharedDocs = async () => {
        setLoading(true)
        try {
            const response = await getSharedDocsOfOrgUsers(userID)
            if (response.status === 200) {
                setSharedDocs(response.data);
                setInitialSharedDocs(response.data)
            } else {
                console.error("Error fetching data:", response.message);
                setSharedDocs([])
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
        setLoading(false)
    };

    const getAllDocuments = () => {
        getDocsSubAdmin()
        // getDocs();
        // getDeletedDocs();
        getSharedDocs();
    }

    useEffect(() => {
        getAllDocuments()
    }, []);

    useEffect(() => {
        setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
        setCombinedMyDocs([...myDocs])
    }, [myDocs, deletedDocs, sharedDocs]);


    return (
        <>
            <Loader loading={loading} />
            <div className='container-fluid'>
                {/* <div className='row'>
                    <div className='col-md-6'>
                        <TextField label="Search" variant="outlined" size="small" style={{ width: '100%' }} onChange={handleSearchChange} />
                    </div>
                    <div className='col-md-3'>
                        <Link to="/mynewdocuments">
                            <Button variant="contained" color="primary"
                                startIcon={<AddIcon />}
                                style={{ width: '100%', whiteSpace: 'nowrap' }}
                            >
                                New Document
                            </Button>
                        </Link>
                    </div>
                    <div className='col-md-3'>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<UploadIcon />}
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                        >
                            Upload Document
                        </Button>
                    </div>
                </div> */}
                <div className='row mt-4'>
                    {/* <div className='col-md-4'>
                        <h3>Documents</h3>
                    </div> */}
                    <HeadingB className="alertHead" text="Users Documents" />
                </div>
                <div className='row'>
                    <div className='col-md-7 mt-2'>
                        {/* <FormControl>
                            <FormControlLabel
                                style={{ marginLeft: '10px' }}
                                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                                label={<Typography>Select All</Typography>}
                            />
                        </FormControl> */}
                    </div>
                    <div className='col-md-5 d-flex justify-content-end'>
                        {/* <IconButton>
                            <LightTooltip title="New folder">
                                <CreateNewFolderIcon style={{ color: "gold" }} />
                            </LightTooltip>
                        </IconButton> */}

                        {/* <IconButton>
                            {listView ? <div title='Grid View'><WindowIcon onClick={() => setListView(false)} /></div> : <div title='List View'><TableRowsIcon onClick={() => setListView(true)} /></div>}
                        </IconButton> */}

                        {/* <LightTooltip title={listView &&  "List View"}>
                            <IconButton>
                                {listView ? <WindowIcon style={{ color: "deepskyblue" }} /> : <TableRowsIcon style={{ color: "deepskyblue" }} />}
                            </IconButton>
                        </LightTooltip> */}

                        {/* <IconButton>
                            <div
                                onClick={handleDownloadDropdownClick}
                            >
                                <LightTooltip title="Download"><DownloadIcon style={{ color: "green" }} /></LightTooltip></div>
                        </IconButton> */}

                        <IconButton>
                            <div ><LightTooltip title="Sort"><SortByAlphaIcon style={{ color: "coral" }} onClick={() => handleSortClick()} /></LightTooltip></div>
                        </IconButton>

                        <Menu
                            anchorEl={downloadAnchorElDropdown}
                            open={Boolean(downloadAnchorElDropdown)}
                            onClose={handleDownloadDropdownClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >

                            <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
                                <ListItemText primary="MS-Word" />
                            </MenuItem>

                            <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
                                <ListItemText primary="PDF" />
                            </MenuItem>


                        </Menu>

                    </div>
                </div>

                {(myDocs.length > 0) &&
                    <>

                        <div className='row'>
                            {/* <div className='col-md-12 mt-1 mb-3'><hr /><h3>My Documents</h3></div> */}
                            {getMyDocsCurrentPage().map((doc, index) => {

                                const tempElement = document.createElement('div');
                                tempElement.innerHTML = doc.onlyDocumentContent;

                                // Extract only the text content
                                const textContent = tempElement.textContent;
                                console.log(textContent, "only document content")

                                let description = textContent;
                                let toStatus = doc.tostatus;


                                return (
                                    <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index} >
                                        {listView ? (
                                            <DocumentListCard
                                                title={doc.documentName}
                                                description={description}
                                                cardChecked={doc.isChecked || false}
                                                documentId={doc._id}
                                                onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocumentsSubadmin'}
                                                ownerName={doc.ownerName}
                                                document={doc}
                                                toStatus={toStatus}
                                                SubAdmin={true}
                                                statusHistory={doc.statusHistory}
                                            />
                                        ) : (
                                            <DocumentGridCard
                                                title={doc.documentName}
                                                description={description}
                                                cardChecked={doc.isChecked || false}
                                                documentId={doc._id}
                                                onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocumentsSubadmin'}
                                                ownerName={doc.ownerName}
                                                document={doc}
                                                toStatus={toStatus}
                                                SubAdmin={true}
                                                statusHistory={doc.statusHistory}
                                                setMyDocs={setMyDocs}
                                            />
                                        )}
                                    </div>
                                )

                            })}


                        </div>

                        {combinedMyDocs.length > itemsPerPage && (

                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', }}>
                                <Pagination
                                    count={Math.ceil(combinedMyDocs.length / itemsPerPage)}
                                    page={myDocsCurrentPage}
                                    onChange={handleMyDocsPageChange}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"

                                />
                            </div>

                        )
                        }

                    </>
                }



                {(sharedDocs.length > 0) &&
                    <>
                        <div className='row'
                        // ref={sharedRef} 
                        // id='shared-documents'
                        >
                            <div className='col-md-12 mt-1 mb-3'><hr /><h3>Shared Documents</h3></div>

                            {getSharedDocsCurrentPage().map((doc, index) => {
                                const tempElement = document.createElement('div');
                                tempElement.innerHTML = doc.onlyDocumentContent;

                                // Extract only the text content
                                const textContent = tempElement.textContent;
                                console.log(textContent, "only document content")

                                let description = textContent;
                                let toStatus = doc.tostatus;

                                return (
                                    <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index}>
                                        {listView ? (
                                            <DocumentListCard
                                                title={doc.documentName}
                                                description={description}
                                                cardChecked={doc.isChecked || false}
                                                documentId={doc._id}
                                                onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocumentsSubadmin'}
                                                ownerName={doc.ownerName}
                                                document={doc}
                                                toStatus={toStatus}
                                                SubAdmin={true}
                                                statusHistory={doc.statusHistory}
                                            />
                                        ) : (
                                            <DocumentGridCard
                                                title={doc.documentName}
                                                description={description}
                                                cardChecked={doc.isChecked || false}
                                                documentId={doc._id}
                                                onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocumentsSubadmin'}
                                                ownerName={doc.ownerName}
                                                document={doc}
                                                toStatus={toStatus}
                                                SubAdmin={true}
                                                statusHistory={doc.statusHistory}
                                            />
                                        )}
                                    </div>
                                )

                            })}

                        </div>

                        {sharedDocs.length > itemsPerPage && (

                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Pagination
                                    count={Math.ceil(sharedDocs.length / itemsPerPage)}
                                    page={sharedDocsCurrentPage}
                                    onChange={handleSharedDocsPageChange}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"

                                />
                            </div>

                        )
                        }

                    </>
                }

                {(deletedDocs.length > 0) &&
                    <div className='row'>
                        <div className='col-md-12 mt-1 mb-3'><hr /><h3>Deleted Documents</h3></div>

                        {deletedDocs.map((doc, index) => {
                            const tempElement = document.createElement('div');
                            tempElement.innerHTML = doc.onlyDocumentContent;

                            // Extract only the text content
                            const textContent = tempElement.textContent;
                            console.log(textContent, "only document content")

                            let description = textContent;
                            let toStatus = doc.tostatus;

                            return (
                                <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index}>
                                    {listView ? (
                                        <DocumentListCard
                                            title={doc.documentName}
                                            description={description}
                                            cardChecked={doc.isChecked || false}
                                            documentId={doc._id}
                                            getAllDocuments={getAllDocuments}
                                            type={'DeletedDocuments'}
                                            document={doc}
                                            toStatus={toStatus}
                                        />
                                    ) : (
                                        <DocumentGridCard
                                            title={doc.documentName}
                                            description={description}
                                            cardChecked={doc.isChecked || false}
                                            documentId={doc._id}
                                            getAllDocuments={getAllDocuments}
                                            type={'DeletedDocuments'}
                                            document={doc}
                                            toStatus={toStatus}
                                        />
                                    )}
                                </div>
                            )

                        })}

                    </div>}
            </div>
        </>
    )
}



export default SubAdminDocuments