import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import dateFn from "date-fn";
import Loader from "../utils/loader";
import Avatar from "@material-ui/core/Avatar";
import avatar from "../static/Avatar.png";
import DocumentService from "../services/document";
import StarRatings from "react-star-ratings";
import { ShareOptionModal2, DownloadBlogOptionModal} from "./MyDocumentsCardsModal";
import {
  Editor,
  EditorState,
  ContentState,
  convertFromRaw,
  SelectionState,
  Modifier,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "./Editor/utilize";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SuggestionCard from "./Plagiarism/SuggestionCard";
import "react-toastify/dist/ReactToastify.css";
import { checkPlagiarism } from "../services/plagiarismServices";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  addRating,
  addComments,
  addBlogComments,
} from "../services/feedbackServices";
import { getProfile } from "../services/userService";
import { withRouter } from "react-router-dom";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import ReactHtmlParser from "react-html-parser";
import CommentsCard from "./Cards/CommentsCard";
import BreadCrumb from "./Cards/BreadCrumbs";
import isEmptyObject from "../utils/isEmptyObject";
import "./documentPreview.css";
import { connect, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import BlogServices from "../services/blogServices";
import macroServices from "../services/macroServices";
import swal from "sweetalert";
import adminServices from "../services/adminServices";
import JoditEditorComp from "./ReusableComponents/JoditEditorComp";
import { checkFeatureAccess } from "./featureAccess";


export class DocumentPreview extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    console.log(props);
    this.paraeditor = React.createRef();
    this.state = {
      isShareOptionModal: false,
      isDownloadModalOpen : false,
      editable: true,
      showPlagiarism: false,
      loading: false,
      editorState: EditorState.createEmpty(),
      uniqueProgress: 0,
      plagiarozedProgress: 0,
      sourceCount: 0,
      sentenceCount: 0,
      uniqueWordCount: 0,
      uniqueWordsPercentage: "",
      timeDisplay: "",
      paraCount: "",
      outputArray: [],
      contentEditorState: EditorState.createEmpty(),
      rating: 0,
      commands: "",
      isAdmin: this.props.currentUser.isAdmin,
      isSubAdmin: this.props.currentUser.isSubAdmin,
      _id: props.data._id,
      //  ? props.data._id
      //  : props.data.orginalDocumentID,
      username: "",
      display_picture: "",
      document_Average_Rating: "",
      document_Total_Rating: "",
      new_Average_Rating: "",
      new_Total_Rating: "",
      documentName: "",
      onlyDocumentContent: "",
      date: "",
      tags: [],
      itemsToShow: 5,
      expanded: false,
      readOnly: "",
      htmlContent: null,
      adminViewedUserData: {},
      userDetails: {},
      userFeatures: {},
      RawData: "",
      blockData: {},
      plainWords: "",
      wordFrequency: {},
      wordFrequencyLimit: 5,
      expandWords: false,
      documentType: "",
      dashLink: "/dashboard",
      missingimg: {},
      blogType: "",
      originalImageURL: [],
      sentence_limit: [],
      editor: '',
      isCurrentUserEditorOwner: false,
      editorContent: '',
      accessToEdit : false
    };

    this.showMore = this.showMore.bind(this);
    this.handleContentEditorChange = this.handleContentEditorChange.bind(this);
    this.handleEditorEdit = this.handleEditorEdit.bind(this);
    this.handleEditorDownload = this.handleEditorDownload.bind(this)
    this.openShareModal = this.openShareModal.bind(this);
    this.closeShareModal = this.closeShareModal.bind(this);
  }
  customStyleMap = {
    HIGHLIGHT: {
      backgroundColor: "#FF7F7F",
      color: "white",
    },
  };

  openShareModal() {
    this.setState({ isShareModalOpen: true });
  }

  closeShareModal() {
    this.setState({ isShareModalOpen: false });
  }

  openDownloadOptionModal() {
    this.setState({isDownloadModalOpen: true})
  }

  closeDownloadOptionModal() {
    this.setState({isDownloadModalOpen: false})
  }

  showPlagiarismDiv = () => {
    const { contentEditorState } = this.state;
    if (contentEditorState.getCurrentContent().getPlainText() === "") {
      console.log(
        "texttttttt: ",
        contentEditorState.getCurrentContent().getPlainText()
      );
      toast.warn("Nothing to plagiarise");
    } else {
      this.setState({ showPlagiarism: true });
      this.handleCheckPlagiarism();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentUser, features } = this.props;
    const { htmlContent, editor, RawData } = this.state;

    // Check if the current user is the editor owner
    const isCurrentUserEditorOwner = currentUser._id === editor;

    // Check if htmlContent has changed
    if (RawData !== prevState.RawData) {
      if (RawData.trim() !== "") {
        const contentState = ContentState.createFromText(RawData);
        const newEditorState = EditorState.createWithContent(contentState);
        this.setState({ contentEditorState: newEditorState, editorContent: RawData });
      } else {
        console.log("HtmlContent is empty");
      }
    }

    // Check if the current user is the editor owner and update state accordingly
    if (isCurrentUserEditorOwner !== prevState.isCurrentUserEditorOwner) {
      this.setState({ isCurrentUserEditorOwner });
    }    
  }


  // componentDidUpdate(prevProps, prevState) {
  //   const { currentUser, setCurrentUser, features } = this.props;
  //   console.log(currentUser._id,"=====",this.state.editor)
  //   const isCurrentUserEditorOwner = currentUser._id === this.state.editor;

  //   // If the condition is met, set a state value to true
  //   if (isCurrentUserEditorOwner) {
  //       this.setState({ isCurrentUserEditorOwner: true });
  //   }

  //   if (this.state.htmlContent !== prevState.htmlContent) {
  //       const { htmlContent } = this.state;
  //       const objectdata = htmlContent[0]
  //       const newprops = objectdata.props.children[0]

  //       if (newprops.trim() !== "") {
  //         console.log(newprops,"newprops")
  //           const contentState = ContentState.createFromText(newprops);
  //           const newEditorState = EditorState.createWithContent(contentState);
  //           this.setState({ contentEditorState: newEditorState });
  //       } else {
  //           console.log("HtmlContent is empty");
  //       }
  //   }
  // }

  hidePlagiarismDiv = () => {
    this.setState({ showPlagiarism: false });
  };

  handleCheckPlagiarism = async (outputSentence = "", isTable) => {
    const { contentEditorState } = this.state;
    this.setState({ showPlagiarism: true });

    let sentence = contentEditorState.getCurrentContent().getPlainText();

    this.setState({ loading: true, sourceCount: 0 });
    this.countTextStats(isTable);
    this.uniqueWordsPercentageCalculation();

    let data = {
      text: sentence,
    };
    let userId = sessionStorage.getItem("userId")
    let isCoAuthor = false
    let plagiarismResponse = await checkPlagiarism(data, userId, isCoAuthor);

    if (plagiarismResponse.status === 400) {
      this.setState({ loading: false });
      toast.warn(plagiarismResponse.message);
      return;
    }

    if (plagiarismResponse?.data?.details?.plagPercent !== 0) {
      const uniquePercent = plagiarismResponse.data.uniquePercent;
      const plagPercent = plagiarismResponse.data.plagPercent;
      const plagDetails = plagiarismResponse.data.details;
      const sourceDetails = plagiarismResponse.data.sources;
      let sentenceArray = [];

      const filterPlagDetails = plagDetails.filter(
        (item) => item.unique === "false"
      );

      this.setState({ sourceCount: sourceDetails.length });

      filterPlagDetails.forEach((obj) => {
        let outObject = {
          sentenceDetails: obj.query,
          url: obj?.display?.url,
        };
        sentenceArray.push(outObject);
      });

      this.setState({
        uniqueProgress: uniquePercent,
        plagiarozedProgress: plagPercent,
        outputArray: sentenceArray,
      });

      this.applyHighlight(sentenceArray);
    }

    this.setState({ loading: false });
  };

  applyHighlight = (obj) => {
    const { contentEditorState } = this.state;
    const contentState = contentEditorState.getCurrentContent();
    let newContentState = contentState;

    obj.forEach((detail) => {
      const { sentenceDetails } = detail;
      let start = 0;

      while (start < contentState.getPlainText().length) {
        const startIndex = contentState
          .getPlainText()
          .indexOf(sentenceDetails, start);

        if (startIndex === -1) {
          break;
        }

        const endIndex = startIndex + sentenceDetails.length;

        const selection = SelectionState.createEmpty(
          contentState.getBlockMap().first().getKey()
        )
          .set("anchorOffset", startIndex)
          .set("focusOffset", endIndex);

        newContentState = Modifier.applyInlineStyle(
          newContentState,
          selection,
          "HIGHLIGHT"
        );

        start = endIndex;
      }
    });

    this.setState({
      editorState: EditorState.push(
        contentEditorState,
        newContentState,
        "apply-inline-style"
      ),
    });
  };

  calculateSpeakTime = (countstats, isTable = false) => {
    const { contentEditorState } = this.state;
    const contentState = contentEditorState.getCurrentContent();
    const rawText = convertToRaw(contentState);
    const text = rawText.blocks.map((block) => block.text).join(" ");

    if (text === "") {
      this.setState({ timeDisplay: "0 sec" });
    }

    const wordsPerMinute = 150;
    const wordCount = text.split(/\s+/).length;
    const speakingTimeMinutes = wordCount / wordsPerMinute;
    const minutes = Math.floor(speakingTimeMinutes);
    const seconds = Math.round((speakingTimeMinutes % 1) * 60);

    if (speakingTimeMinutes < 1) {
      this.setState({ timeDisplay: `${seconds} sec` });
    } else {
      this.setState({ timeDisplay: `${minutes} min ${seconds} sec` });
    }

    console.log("countstats_", countstats);
  };

  countTextStats = (isTable) => {
    const { contentEditorState } = this.state;
    let inputText = contentEditorState.getCurrentContent().getPlainText();
    const paragraphs = inputText
      .split("\n")
      .filter((line) => line.trim() !== "").length;
    const sentences = inputText
      .split(/[.!?]/)
      .filter((sentence) => sentence.trim() !== "").length;
    const words = inputText
      .toLowerCase()
      .split(/\W+/)
      .filter((word) => word.trim() !== "");
    const uniqueWords = [...new Set(words)].length;

    this.setState({
      paraCount: paragraphs,
      sentenceCount: sentences,
      uniqueWordCount: uniqueWords,
    });

    let counts = {
      paragraph: paragraphs,
      sentence: sentences,
      uniqueWords: uniqueWords,
    };

    this.calculateSpeakTime(counts, isTable);
  };

  uniqueWordsPercentageCalculation = () => {
    const { contentEditorState } = this.state;
    const currentContent = contentEditorState.getCurrentContent();
    const rawContentState = convertToRaw(currentContent);
    const text = rawContentState.blocks.map((block) => block.text).join(" ");
    const words = text.match(/\b\w+\b/g) || [];
    const uniqueWords = new Set(words);
    const percentage = (uniqueWords.size / words.length) * 100;

    this.setState({ uniqueWordsPercentage: `${percentage.toFixed(0)}%` });
  };

  showMore() {
    this.state.itemsToShow === 5
      ? this.setState({
        itemsToShow: this.state.tags.length,
        expanded: true,
      })
      : this.setState({ itemsToShow: 5, expanded: false });
  }

  configData = async () => {
    let configData = await adminServices.getSentenceLimit();
    let sentenceLimit = configData.sentence_limit;
    sentenceLimit = sentenceLimit?.split(",");
    sentenceLimit = sentenceLimit?.map(Number);
    this.setState({ sentence_limit: sentenceLimit });
  };

  async componentDidMount() {
    const { currentUser, setCurrentUser, features } = this.props;
    this.setState({ userFeatures: features });
    let user;
    if (!currentUser) {
      user = await getProfile();
      user = user.data;
      setCurrentUser(user);
    } else {
      user = { ...user };
    }
    console.log(user);
    if (user.isAdmin) {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(userData);
      if (userData) {
        this.setState(
          {
            adminViewedUserData: userData,
            username: userData.username,
            display_picture: userData.display_picture,
            userDetails: user,
            isAdmin: true,
            dashLink: "/admindashboard",
          },
          () => this.getDocumentData()
        );
      } else {
        this.setState(
          {
            userDetails: user,
            isAdmin: true,
          },
          () => this.getDocumentData()
        );
      }
    } else {
      this.setState(
        {
          userDetails: user,
        },
        () => this.getDocumentData()
      );
    } 
    
      this.setState({ accessToEdit: this.props.hasAccessToEditBlogs });
  }

  // convertToHtml = (data) => {
  //   console.log(data,"data in convert html");
  //   const storedState = JSON.parse(data);
  //   console.log(storedState,"stored state");
  //   const onlyTextData = storedState.blocks
  //     .map((block) => (!block.text.trim() && "\n") || block.text)
  //     .join("\n");
  //   console.log(onlyTextData);
  //   const contentState = convertFromRaw(storedState);
  //   let editorContentHtml = stateToHTML(contentState, option_);
  //   let htmlContent = ReactHtmlParser(editorContentHtml);
  //   console.log(contentState,"contentState");
  //   console.log(htmlContent,"htmlContent");
  //   console.log(editorContentHtml,"editorContentHtml");
  //   this.setState(
  //     { htmlContent, RawData: editorContentHtml, blockData: storedState },
  //     () => {
  //       this._wordFrequency();
  //       this.configData();
  //       this.getImageUrl();
  //     }
  //   );
  // };


  convertToHtml = (data) => {
    console.log(data, "data in convert html");
    //convert only text data plain text
    const cleanedDocumentText = data.replace(/<[^>]+>/g, '');
    const onlyTextData = cleanedDocumentText
    console.log(onlyTextData, "onlyTextData");
    //editor content html = html with p tag
    let editorContentHtml = data
    //thml content  = plain text
    let htmlContent = onlyTextData
    console.log(htmlContent, "htmlContent");
    console.log(editorContentHtml, "editorContentHtml");
    this.setState(
      { htmlContent, RawData: editorContentHtml },
      () => {
        this._wordFrequency();
        this.configData();
        this.getImageUrl();
      }
    );
  };


  getDocumentData = async () => {
    let user;
    const { currentUser, setCurrentUser, history } = this.props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    let data;
    if (this.props.data.documentType === "document") {
      data = await DocumentService.getDocumentbyId(this.state._id);
      this.setState({ documentType: "document" });
    } else {
      data = await BlogServices.getBlogById(this.state._id);
      this.setState({
        documentType: "blog",
        blogType: this.props.location.state.blogType,
      });
      console.log(this.state.blogType);
    }
    console.log(data, "blogggg data");
    if (data.status === 200) {
      const { ratings, totalRatings } = data.data;
      this.setState(
        {
          document_Average_Rating: ratings,
          document_Total_Rating: totalRatings,
          editor: data.data.editor,
          documentName: data.data.documentName,
          onlyDocumentContent: data.data.onlyDocumentContent,
          date: data.data.date,
          tags: data.data.tags,
          readOnly: data.data.readOnly,
          documentType: data.data.documentType,
          loading: false,
          username:
            this.state.username.length > 0
              ? this.state.username
              : user.username,
          display_picture:
            this.state.display_picture.length > 0
              ? this.state.display_picture
              : user.display_picture,
        },
        () => this.convertToHtml(data.data.onlyDocumentContent)
      );
    } else {
      toast.error("document has been deleted");
      this.setState({ loading: false }, () => {
        history.push({ pathname: `/mydocuments` });
      });
    }
  };
  goToDocument = () => {
    try {
      const { userData } = this.props.location.state;
      this.props.history.push({
        pathname: `/mydocuments`,
        state: { userData },
      });
    } catch (e) {
      this.props.history.push({ pathname: `/mydocuments` });
    }
  };

  handleEdit = async (documentId, documentName, onlyDocumentContent, tags) => {
    const { history } = this.props;
    let data;

    if (this.state.documentType === "blog") {
      data = await BlogServices.getBlogById(this.state._id);
      if (data.data.isPublished === true) {
        swal(
          "Wanna Edit The Published Blog ?",
          "To edit the blog please un-publish it and doing so you have to again re-publish the blog for admin approval"
        );
        return;
      }
    } else {
      data = await DocumentService.getDocumentbyId(this.state._id);
    }

    if (data.status === 200) {
      if (data.data.activeDoc === true) {
        let docs = {
          tags: tags,
          documentName: documentName,
          onlyDocumentContent: onlyDocumentContent,
          _id: documentId,
          imageUrl: this.state.originalImageURL,
        };

        history.push({
          pathname: `/texteditor`,
          data: { document: docs, documentType: this.state.documentType },
        });
      } else {
        toast.error("document has been deleted");
        history.push({ pathname: `/mydocuments` });
      }
    } else {
      toast.error("document has been deleted");
      history.push({ pathname: `/mydocuments` });
    }
  };

  addRating = async (rating) => {
    const { history } = this.props;
    const sendData = {
      rating,
      documentId: this.state._id,
    };
    const addRating_res = await addRating(sendData);
    let status = addRating_res.status;
    if (status === 200) {
      toast.success("Rating successfully added");
    } else {
      alert("document has been deleted");
      history.push({ pathname: `/mydocuments` });
    }
  };

  // addRating = async (rating) => {
  //   const sendData = {
  //     rating,
  //     documentId: this.state._id,
  //   };
  //   const addRating_res = await addRating(sendData);
  //   let status = addRating_res.status;
  //   if (status === 200) {
  //     toast.success("Rating successfully added");
  //   } else {
  //     toast.error("Oops something went wrong");
  //   }
  // };

  sendCommand = async (comment, rating) => {
    let documentType = this.state.documentType;
    const sendData = {
      comment,
      documentId: this.state._id,
      rating,
      date: new Date(),
      reply: false,
      commentsID: "",
      total: this.state.new_Total_Rating + 1,
      max: this.state.MaxRatings + rating,
    };
    // console.log(sendData);
    let response = {};
    if (documentType === "document") {
      response = await addComments(sendData);
    } else {
      response = await addBlogComments(sendData);
    }
    if (response.status === 200) toast.success(response.message);
    else toast.error("Oops something went wrong");
  };

  sendPost = async () => {
    if (this.state.userFeatures.Commenting === false) {
      toast("Please upgrade your plan to enable comments feature");
      return;
    }
    this.setState({ loading: true });
    const { rating, commands } = this.state;
    console.log("rating", rating, "commands", commands);
    if (!rating) {
      this.setState({ loading: false });
      toast.error("Please select rating");
      return;
    } else if (!commands) {
      this.setState({ loading: false });
      toast.error("Please enter your comment.");
      return;
    }
    // await this.addRating(rating);
    await this.sendCommand(commands, rating);
    await this.getDocumentData();
    this.child.current.getAllCommentsForDocs();
    this.setState({ commands: "", rating: 0 });
  };

  _plainWordDetector = async (value) => {
    let word = "";
    if (value === 0) {
      word = this.state.plainWords;
    } else if (value === 1) {
      word = "I, me, my, myself, mine, we, us, our, ours";
    } else if (value === 2) {
      word = "you, your, yours, yourself, yourselves";
    } else if (value === 3) {
      word =
        "he, him, his, himself, she, her, hers, herself, it, its, itself, they, them, their, theirs, themselves";
    }

    let nameArr = word.split(",");
    let content = this.state.RawData;
    // console.log(content);
    let convert = content.replace(/\&nbsp;/g, "\u00a0");
    let mainIndex = [];
    let sortIndex = [];
    let weights = [];
    let wordCount = [];

    if (nameArr.length === 1 && nameArr[0].trim().length === 0) {
      // Nothing
    } else {
      for (let j = 0; j < nameArr.length; j++) {
        if (
          nameArr[j].trim() === "" ||
          nameArr[j].trim() === "<" ||
          nameArr[j].trim() === ">" ||
          nameArr[j].trim() === "/" ||
          nameArr[j].trim() === "p"
        ) {
          continue;
        }
        let indices = this.getIndicesOf(nameArr[j].trim(), convert);
        wordCount[j] = 0;
        let wordLength = nameArr[j].trim();
        wordLength = wordLength.length;
        if (indices.length > 0) {
          for (let i = 0; i < indices.length; i++) {
            if (
              convert[indices[i] - 1] === " " ||
              convert[indices[i] - 1] === ">" ||
              convert[indices[i] - 1] === "." ||
              convert[indices[i] - 1] === "," ||
              convert[indices[i] - 1] === "(" ||
              convert[indices[i] - 1] === "[" ||
              convert[indices[i] - 1] === "'" ||
              convert[indices[i] - 1] === '"' ||
              convert[indices[i] - 1] === "\u00a0"
            ) {
              if (
                convert[indices[i] + wordLength] === " " ||
                convert[indices[i] + wordLength] === "<" ||
                convert[indices[i] + wordLength] === "." ||
                convert[indices[i] + wordLength] === "," ||
                convert[indices[i] + wordLength] === ")" ||
                convert[indices[i] + wordLength] === "]" ||
                convert[indices[i] + wordLength] === "'" ||
                convert[indices[i] + wordLength] === '"' ||
                convert[indices[i] + wordLength] === ";" ||
                convert[indices[i] + wordLength] === "\u00a0"
              ) {
                for (let k = indices[i]; k >= 0; k--) {
                  if (convert[k] === "<") {
                    break;
                  }
                  if (convert[k] === ">") {
                    wordCount[j] = wordCount[j] + 1;
                    mainIndex.push(indices[i]);
                    sortIndex.push(indices[i]);
                    weights.push(wordLength);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    if (mainIndex.length > 0) {
      sortIndex = Array.from(new Set(mainIndex));
      sortIndex = sortIndex.sort((a, b) => a - b);
      let newWeights = new Array(sortIndex.length).fill(1);

      for (let x = 0; x < mainIndex.length; x++) {
        let new_index = sortIndex.indexOf(mainIndex[x]);
        newWeights[new_index] = weights[x];
      }
      // console.log(mainIndex);
      // console.log(sortIndex);
      // console.log(weights);
      // console.log(newWeights);

      for (let y = 0; y < sortIndex.length; y++) {
        if (y === 0) {
          convert = [
            convert.slice(0, sortIndex[y]),
            "<mark>",
            convert.slice(sortIndex[y]),
          ].join("");
          convert = [
            convert.slice(0, sortIndex[y] + newWeights[y] + 6),
            "</mark>",
            convert.slice(sortIndex[y] + newWeights[y] + 6),
          ].join("");
        } else {
          let dyno = y * 13;
          convert = [
            convert.slice(0, sortIndex[y] + dyno),
            "<mark>",
            convert.slice(sortIndex[y] + dyno),
          ].join("");
          convert = [
            convert.slice(0, sortIndex[y] + newWeights[y] + dyno + 6),
            "</mark>",
            convert.slice(sortIndex[y] + newWeights[y] + dyno + 6),
          ].join("");
        }
      }
    }

    let htmlContent = ReactHtmlParser(convert);
    this.setState({ htmlContent, wordCount });
  };

  getIndicesOf = (searchStr, str, caseSensitive) => {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  };

  _wordFrequency = async () => {
    let content = this.state.RawData;
    content = content.replace(/(<([^>]+)>)/gi, "");
    content = content.replace(/\&nbsp;/g, "");

    let words = content
      .replace(/[\[\].();,#@$%&*^<>/`~+='"!?:1234567890]/g, "")
      .split(/\s/);
    let freqMap = {};

    words.forEach((w) => {
      // w = w.toLowerCase();
      if (w === "") {
        // Nothing
      } else {
        if (!freqMap[w]) {
          freqMap[w] = 0;
        }
        freqMap[w] += 1;
      }
    });

    let keysSorted = Object.entries(freqMap).sort(function (a, b) {
      return b[1] - a[1];
    });
    let frequency = {};

    keysSorted.forEach((w) => {
      frequency[w[0]] = w[1];
    });

    this.setState({ wordFrequency: frequency });
  };

  _macroMenu = () => {
    let sidebar = document.getElementById("mySidebar");
    let main = document.getElementById("mainPreview");
    let accord = document.getElementById("macroAccordion");

    if (sidebar.style.width === "250px") {
      sidebar.style.width = "0px";
      main.style.marginRight = "0px";
      setTimeout(() => {
        accord.style.display = "none";
      }, 200);
      this._resetMacro();
    } else {
      sidebar.style.width = "250px";
      main.style.marginRight = "250px";
      accord.style.display = "block";
    }
  };

  _resetMacro = () => {
    let RawData = this.state.RawData;
    let htmlContent = ReactHtmlParser(RawData);
    this.setState({ htmlContent, wordFrequencyLimit: 5, expandWords: false });
  };

  _sentenceHighlighter = async (value) => {
    if (value == 0) {
      this._resetMacro();
      return;
    }

    let rawData = this.state.RawData;
    let content = rawData;
    content = content.replace(/\&nbsp;/g, "\u00a0");
    console.log(content);

    let dots = this.getIndicesOf(".", content);
    let quests = this.getIndicesOf("?", content);
    let exclams = this.getIndicesOf("!", content);
    let marks = _.concat(dots, quests, exclams);
    marks = marks.sort((a, b) => a - b);
    console.log(marks);

    let space = 1;
    for (let x = 0; x < marks.length; x++) {
      if (content[marks[x] + space] === "<") {
        content = [
          content.slice(0, marks[x] + space),
          " ",
          content.slice(marks[x] + space),
        ].join("");
        space += 1;
      } else if (
        content[marks[x] + space] === '"' ||
        content[marks[x] + space] === "'"
      ) {
        content = [
          content.slice(0, marks[x] + space + 1),
          " ",
          content.slice(marks[x] + space + 1),
        ].join("");
        space += 1;
      }
    }

    rawData = content;
    console.log(content);
    // let sentences ;
    // sentences = content.split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|\!)\s/);
    // console.log(sentences);
    let { sentence: sentences } = await macroServices.executeRegex({
      sentence: content,
      pattern: "sentenceHighlighterRegex",
    });
    console.log(sentences);
    var x = sentences.length;
    while (x--) {
      let noHTML = sentences[x].replace(/(<([^>]+)>)/gi, "");
      let wordCount = noHTML.split(/[\s,]+/).length;
      if (noHTML[noHTML.length - 1] === "!") {
        if (wordCount <= 2) {
          sentences[x] = sentences[x] + " " + sentences[x + 1];
          sentences.splice(x + 1, 1);
        }
      }
    }
    console.log(sentences);

    for (let i = 0; i < sentences.length; i++) {
      let noHTML = sentences[i].replace(/(<([^>]+)>)/gi, "");
      let og = sentences[i];
      let sentence = sentences[i];
      let sentenceLength = sentence.length;
      let wordCount = noHTML.split(/[\s,]+/).length;

      if (wordCount >= value) {
        console.log(sentence);
        console.log(sentenceLength);
        console.log(wordCount);

        let ps = this.getIndicesOf("<p>", sentence);
        let psotp = 0;
        if (ps.length > 0) {
          for (let j = 0; j < ps.length; j++) {
            if (
              sentence[ps[j] + 3 + 6 * psotp] === "<" &&
              sentence[ps[j] + 4 + 6 * psotp] === "b" &&
              sentence[ps[j] + 5 + 6 * psotp] === "r"
            ) {
              continue;
            }
            if (psotp === 0) {
              sentence = [
                sentence.slice(0, ps[j] + 3),
                "<mark>",
                sentence.slice(ps[j] + 3),
              ].join("");
              psotp += 1;
            } else {
              sentence = [
                sentence.slice(0, ps[j] + 3 + 6 * psotp),
                "<mark>",
                sentence.slice(ps[j] + 3 + 6 * psotp),
              ].join("");
              psotp += 1;
            }
          }
        } else {
          sentence = [sentence.slice(0, 0), "<mark>", sentence.slice(0)].join(
            ""
          );
        }
        console.log(sentence);

        if (sentence[0] === "<") {
          if (sentence[1] !== "p") {
            if (sentence[1] !== "/") {
              if (sentence[1] !== "m") {
                sentence = [
                  sentence.slice(0, 0),
                  "<mark>",
                  sentence.slice(0),
                ].join("");
              }
            }
          }
        } else {
          sentence = [sentence.slice(0, 0), "<mark>", sentence.slice(0)].join(
            ""
          );
        }

        let pe = this.getIndicesOf("</p>", sentence);
        let otp = 0;
        if (pe.length > 0) {
          for (let k = 0; k < pe.length; k++) {
            if (pe[k] === 0) {
              continue;
            }
            if (
              sentence[pe[k] - 1 + 7 * otp] === ">" &&
              sentence[pe[k] - 2 + 7 * otp] === "r" &&
              sentence[pe[k] - 3 + 7 * otp] === "b"
            ) {
              continue;
            }
            if (otp === 0) {
              sentence = [
                sentence.slice(0, pe[k]),
                "</mark>",
                sentence.slice(pe[k]),
              ].join("");
              otp += 1;
            } else {
              sentence = [
                sentence.slice(0, pe[k] + 7 * otp),
                "</mark>",
                sentence.slice(pe[k] + 7 * otp),
              ].join("");
              otp += 1;
            }
          }
        }
        console.log(sentence);

        if (sentence[sentence.length - 1] === ">") {
          // sentence = [sentence.slice(0, sentence.length-4),"</mark>",sentence.slice(sentence.length-4),].join("");
        } else {
          sentence = [
            sentence.slice(0, sentence.length),
            "</mark>",
            sentence.slice(sentence.length),
          ].join("");
        }
        console.log(sentence);

        console.log("================================================");
        content = content.replace(og, sentence);
      }
    }

    console.log(content);
    let htmlContent = ReactHtmlParser(content);
    this.setState({ htmlContent });
  };

  _macroSpace = async () => {
    this._resetMacro();
    let rawData = this.state.RawData;
    let content = rawData;
    let indices = this.getIndicesOf("&nbsp;", content);

    console.log(content);
    console.log(indices);

    var x = indices.length;
    while (x--) {
      if (indices[x] - 6 > 0) {
        if (indices[x] - 6 === indices[x - 1]) {
          indices.splice(x, 1);
        }
      }
    }
    console.log(indices);

    for (let i = 0; i < indices.length; i++) {
      let dyno = i * 26;
      indices[i] += dyno;
      let carry1 = 0;
      let carry2 = 0;
      let skip = 1;

      if (content[indices[i] - 1] === " " || content[indices[i] + 6] === "&") {
        // ******************** FIRST WORD ***************************
        if (content[indices[i] - 1] === ">") {
          content = [
            content.slice(0, indices[i]),
            "<mark>",
            content.slice(indices[i]),
          ].join("");
        }

        if (content[indices[i] - 1] === " ") {
          for (let j = indices[i] - 2; j >= 0; j--) {
            if (content[j] === " ") {
              content = [
                content.slice(0, j + 1),
                "<mark>",
                content.slice(j + 1),
              ].join("");
              break;
            }
            if (content[j] === ">") {
              content = [
                content.slice(0, j + 1),
                "<mark>",
                content.slice(j + 1),
              ].join("");
              break;
            }
          }
        }

        for (let k = indices[i] + 6; k < content.length; k += 6) {
          if (content[k] === "&") {
            // Nothing
          } else {
            content = [content.slice(0, k), "</mark>", content.slice(k)].join(
              ""
            );
            carry1 = k;
            break;
          }
        }
        // ******************** FIRST WORD ***************************

        // ******************** SECOND WORD ***************************
        for (let a = carry1 + 7; a < content.length; a++) {
          if (a === content.length - 4) {
            content = [content.slice(0, a), "<mark>", content.slice(a)].join(
              ""
            );
            carry2 = a;
            break;
          }
          if (content[a] !== "<") {
            content = [content.slice(0, a), "<mark>", content.slice(a)].join(
              ""
            );
            carry2 = a;
            break;
          }
          if (content[a] === "<") {
            for (let b = a + 1; b < content.length; b++) {
              if (content[b] === ">") {
                skip = 0;
                continue;
              }
              if (content[b] === "<") {
                skip = 1;
                continue;
              }
              if (skip === 0) {
                content = [
                  content.slice(0, b),
                  "<mark>",
                  content.slice(b),
                ].join("");
                carry2 = b;
                break;
              }
            }
            break;
          }
        }

        for (let c = carry2 + 6; c < content.length; c++) {
          if (content[c] === " " || content[c] === "<") {
            content = [content.slice(0, c), "</mark>", content.slice(c)].join(
              ""
            );
            break;
          }
        }
        // ******************** SECOND WORD ***************************
      } else {
        // Nothing
      }
    }

    console.log(content);
    let htmlContent = ReactHtmlParser(content);
    this.setState({ htmlContent });
  };

  //*************************************** */
  // Comparing s3 bucket with imageurl array
  getImageUrl = async () => {
    // let RawData = this.state.RawData;
    // let ImageURLS = [];
    // let reg = "";
    let imgdata = {};
    // let regex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

    // while ((reg = regex.exec(RawData))) {
    //   let length = reg[1].length;
    //   ImageURLS.push(reg[1].substring(49, length));
    // }
    // console.log(ImageURLS);

    if (this.state.documentType === "blog") {
      imgdata = await BlogServices.getBlogById(this.state._id);
    } else {
      imgdata = await DocumentService.getDocumentbyId(this.state._id);
    }

    // console.log(imgdata);
    let ogImageUrl = imgdata.data.imageUrl;
    this.setState({ originalImageURL: ogImageUrl });

    // for(let i=0; i<ogImageUrl.length; i++){
    //   if(ImageURLS.includes(ogImageUrl[i])){
    //     // Nothing
    //   }else{
    //     await uploadImageServices.DeleteImageFromS3(ogImageUrl[i]);
    //   }
    // }
  };

  _calculateRatings = async (total, avg, max) => {
    // console.log(total, avg);
    this.setState({
      new_Average_Rating: avg,
      new_Total_Rating: total,
      MaxRatings: max,
    });
  };

  handleContentEditorChange(newState) {
    this.setState({ editorContent: newState });
  }

  // handleEditorEdit(){
  //   this.setState({editable:false});
  // }

  handleEditorEdit() {
    console.log("inside edit++++++")
    // const blogContent = this.state.contentEditorState.getCurrentContent().getPlainText()
    const blogContent = this.state.RawData

    const blogName = this.state.documentName
    const blogId = this.state._id
    const blogTags = this.state.tags
    console.log(blogContent, "blogContent", blogName, "blogName", blogId, "blogId", blogTags, "blogTags")
    console.log(this.state, "this.state......")
    this.props.history.push({
      pathname: '/grammarcheck',
      state: { blogContent, blogName, blogId, blogTags }
    });
  }

  handleEditorDownload() {
    if (!this.state.contentEditorState.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text to download`);
      return;
    }

    const content = this.state.contentEditorState.getCurrentContent().getPlainText();
    const title = this.state.documentName
    console.log("title_", title)
    const blob = new Blob([content], { type: 'text/plain' });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = `${this.state.documentName}.doc`;
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  }

  handleEditorDelete() {
    this.setState({ contentEditorState: EditorState.createEmpty() })
  }



  render() {
    const { _id: documentId } = this.props.data;
    const {
      isShareModalOpen,
      isDownloadModalOpen,
      editable,
      username,
      display_picture,
      commands,
      showPlagiarism,
      loading,
      editorState,
      uniqueProgress,
      plagiarozedProgress,
      sourceCount,
      sentenceCount,
      uniqueWordsPercentage,
      timeDisplay,
      outputArray,
      paraCount,
      document_Average_Rating,
      document_Total_Rating,
      documentName,
      onlyDocumentContent,
      date,
      tags,
      readOnly,
      htmlContent,
      isAdmin,
      isSubAdmin,
      wordFrequency,
      documentType,
      wordFrequencyLimit,
      expandWords,
      blogType,
      sentence_limit,
      plainWords,
      userDetails,
      contentEditorState,
      isCurrentUserEditorOwner,
      accessToEdit
    } = this.state;
    
    return (
      <div className="">
        <Loader loading={this.state.loading} />
        <ToastContainer />
        {documentType === "blog" ? (
          <BreadCrumb
            l1="Home"
            l2="Blogs"
            l3="Blogs Preview"
            dashboardlink={
              isAdmin === true ? "/admindashboard" : (isSubAdmin === true ? "/subAdminDashboard" : "/blogpage")
            }
            documentLink={isAdmin === true ? "/adminBlogs" : (isSubAdmin === true ? "/manageOrganizationBlogs" : "/blogpage")}
          />
        ) : (
          <>
            <BreadCrumb
              l1="Home"
              l2="Documents"
              l3="Document Preview"
              dashboardlink={
                isAdmin === true ? "/admindashboard" : this.state.dashLink
              }
              documentLink={"/mydocuments"}
            />
          </>
        )}

        <div id="mySidebar" className="sidebar">
          <h3 className="p-2">Details</h3>

          <div className="accordion" id="macroAccordion">
            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Word Frequency
              </button>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <div className="row">
                    <table className="table table-hover table-sm table-striped">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Word
                          </th>
                          <th className="text-center" scope="col">
                            Frequency
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(wordFrequency)
                          .slice(0, wordFrequencyLimit)
                          .map((word) => (
                            <tr>
                              <td className="text-center">{word}</td>
                              <td className="text-center">
                                {wordFrequency[word]}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    {!expandWords ? (
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          this.setState({
                            expandWords: true,
                            wordFrequencyLimit:
                              Object.keys(wordFrequency).length,
                          });
                        }}
                      >
                        Show More
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          this.setState({
                            expandWords: false,
                            wordFrequencyLimit: 5,
                          });
                        }}
                      >
                        Show Less
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Highlight Sentences
              </button>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <InputLabel htmlFor="filled-age-native-simple">
                    Word Length in Sentences
                  </InputLabel>
                  <Select
                    native
                    // value={state.age}
                    onChange={(e) => this._sentenceHighlighter(e.target.value)}
                    inputProps={{
                      name: "age",+
                      id: "filled-age-native-simple",
                    }}
                    className="w-100"
                  >
                    { sentence_limit?.map(limit => (
                      <option value={limit}>{limit}</option>
                    )) }
                    {/* <option value={0}>0</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option> 
                  </Select>
                </div>
              </div>
            </div> */}

            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Person Perspective Words
              </button>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(1)}
                  >
                    Detect First Person Words
                  </button>
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(2)}
                  >
                    Detect Second Person Words
                  </button>
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(3)}
                  >
                    Detect Third Person Words
                  </button>
                </div>
              </div>
            </div>

            <div className="card mb-2">
              <button
                className="btn w-100 macro-button collapsed p-2"
                onClick={this._resetMacro}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Plain Word Detection
              </button>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#macroAccordion"
              >
                <div className="card-body">
                  <TextField
                    className="w-100"
                    id="outlined-basic"
                    label="Target Words"
                    variant="outlined"
                    onChange={(e) =>
                      this.setState({ plainWords: e.target.value })
                    }
                  />
                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    onClick={() => this._plainWordDetector(0)}
                  >
                    Detect
                  </button>

                  <div className="text-center mt-3">
                    {plainWords &&
                      plainWords.split(",").map((word, index) => (
                        <ul className="pl-3 pr-3 m-0">
                          {this.state.wordCount && (
                            <li>
                              {word} : {this.state.wordCount[index] || 0}
                            </li>
                          )}
                        </ul>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-2">
              <button
                className="btn w-100 p-2"
                onClick={this._macroSpace}
                style={{ backgroundColor: "#007bff", color: "#fff" }}
              >
                Detect Multiple Spaces
              </button>
            </div>
          </div>
        </div>

        <div id="mainPreview">
          <div className="row " style={{width: "97%", display: "flex",justifyContent: "space-between", alignItems: "center", marginLeft: "5px"}}>
          <div
          className={`card-bg col-sm-12 mb-3 mb-lg-0 ${isCurrentUserEditorOwner ? "col-md-9" : "col-md-10"} `}
          style={{
            padding: "7px",
            borderRadius: "10px",
            boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <div
                  className="row md-8"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    borderRadius: "5px",
                    marginLeft: "6px"
                  }}
                >
                  {" "}
                  <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div>
                      <div className="d-flex align-items-center">
                        <b className="ft_xl zzz "> {documentName} </b>
                      </div>
                    </div>
                    <div className=" text-right rateCol d-flex">
                      <StarRatings
                        rating={this.state.new_Average_Rating || 0}
                        starRatedColor="orange"
                        starDimension="12px"
                        starSpacing="0px"
                        numberOfStars={5}
                        name="rating"
                        style={{ paddingBottom: "2%" }}
                      />


                      <div>
                        <small className=" mt-2 zzz" style={{ opacity: "0.5" }}>
                          &nbsp;&nbsp;&nbsp; {this.state.new_Total_Rating} User Ratings
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center md-4" >
                  <Avatar
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    // alt={username}
                    alt={
                      this.props.data.ownerName
                        ? this.props.data.ownerName
                        : this.props.data.editor?.username
                          ? this.props.data.editor?.username
                          : username
                    }
                    // src={profilePic ? profilePic : avatar}
                    src={
                      this.props.data?.ownerDP
                        ? this.props.data?.ownerDP
                        : this.props.data.editor?.display_picture
                          ? this.props.data.editor?.display_picture
                          : display_picture
                    }
                    id="profilePic"
                    // className={`${classes.avatar} profilePic point`}
                    color="inherit"
                  />
                  {/* <img
                  className="docImg"
                  src={
                    this.props.data.ownerDP
                      ? this.props.data.ownerDP
                      : this.props.data.editor.display_picture
                        ? this.props.data.editor.display_picture
                        : display_picture
                  }
                  alt=""
                /> */}

                  <p className="ml-3 mb-0 zzz">
                    {this.props.data.ownerName
                      ? this.props.data.ownerName
                      : this.props.data.editor?.username
                        ? this.props.data.editor?.username
                        : username}
                    <br />
                    <span className="ft_xs text-e zzz">{dateFn.date(date, 110)}</span>
                  </p>
                </div>

              </div>

            </div>

            <div style={{ display: "flex"}}>
              {/* <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "15px" }}
                onClick={()=>{this.openShareModal()}}
              >
                Share
                <ShortcutIcon />
              </Button> */}
              <ShareOptionModal2
                show={isShareModalOpen}
                onHide={this.closeShareModal}
                id={this.state._id}
              />
              {isCurrentUserEditorOwner &&
                <Button
                  style={{ marginRight: "18px", color: accessToEdit ? "white" : "gray", backgroundColor: accessToEdit ? "dodgerblue" : "lightgray", padding: "9px 13px", fontSize: "15px",  borderRadius: "5px", }}
                  onClick={() => { this.handleEditorEdit() }}
                  disabled={!accessToEdit}
                >
                  Edit
                </Button>

              }
              <Button
                variant="outlined"
                style={{ marginRight: "20px", color: "white", backgroundColor: "dodgerblue", padding: "9px 13px", fontSize: "15px",  borderRadius: "5px", }}
                onClick={() => { this.openDownloadOptionModal() }}
              >
                Download
              </Button>
              <DownloadBlogOptionModal
              show={isDownloadModalOpen}
              onHide={()=>this.closeDownloadOptionModal()}
              id={this.state._id}
              />
              {/* <Button
                variant="outlined"
                color="error"
                style={{ marginRight: "15px" }}
                onClick={()=>{this.handleEditorDelete()}}
              >
                Delete
                <DeleteIcon />
              </Button> */}
            </div>

            {isAdmin === true ? (
              ""
            ) : (
              <div className="col-sm-2 ">
                {documentType == "document" ? (
                  <div style={{ float: "right", marginTop: "4px" }}>
                    <button
                      className="btn btn-primary"
                      onClick={this._macroMenu}
                    >
                      Document Analyzer
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <hr />




          {/* ///////////////////////////////////////////////////////////Commented edit */}
          {/* <div className="row pl-3">
            {tags.length > 0 &&
              tags.slice(0, this.state.itemsToShow).map((tag) => (
                <button
                  key={tag}
                  type="button"
                  className="border ft_xs mr-2 p-1"
                  style={{
                    backgroundColor: "#f1f1f1",
                    borderRadius: "4px",
                    color: "#757575",
                  }}
                >
                  {tag}
                </button>
                // <Chip
                //   key={tag}
                //   label={tag}
                //   clickable
                //   variant="outlined"
                //   id="chip"
                //   // style={{
                //   //   marginRight: "1%",
                //   //   backgroundColor: "#1976d2",
                //   //   color: "white"
                //   // }}
                // >
                //   {tag}
                // </Chip>
              ))}
            {tags.length > 5 && (
              <a
                className="ft_xs"
                onClick={this.showMore}
                style={{
                  cursor: "pointer",
                  color: "#2196f3",
                  textDecoration: "underline",
                  marginTop: "1%",
                }}
              >
                {this.state.expanded ? (
                  <span>Show less</span>
                ) : (
                    <span>Show more</span>
                  )}
              </a>
            )}
          </div>

          <br />
          <div className="d-flex justify-content-between align-items-start">
            <p> {htmlContent} </p>
            {isAdmin && documentType === "blog" && blogType === "my" ? (
              <i
                className="fa fa-edit point text-primary ft_lg"
                data-toggle="tooltip"
                title="Edit"
                onClick={() =>
                  this.handleEdit(
                    documentId,
                    documentName,
                    onlyDocumentContent,
                    tags
                  )
                }
              ></i>
            ) : this.props.data.reviewStatus === true ? (
              ""
            ) : this.props.data.pendingPublish === true ? (
              ""
            ) : readOnly === false && documentType === "document" && !isAdmin ? (
              <i
                className="fa fa-edit point text-primary ft_lg"
                data-toggle="tooltip"
                title="Edit"
                onClick={() =>
                  this.handleEdit(
                    documentId,
                    documentName,
                    onlyDocumentContent,
                    tags
                  )
                }
              ></i>
            ) : (
                      documentType === "blog" &&
                      blogType === "my" &&
                      (
                        <i
                          className="fa fa-edit point text-primary ft_lg"
                          data-toggle="tooltip"
                          title="Edit"
                          onClick={() =>
                            this.handleEdit(
                              documentId,
                              documentName,
                              onlyDocumentContent,
                              tags
                            )
                          }
                        ></i>
                      )
                    )}
          </div>
 */}
          {/* <br />
          <br />
          <br />
          <hr /> */}

          {showPlagiarism && (
            <div
              style={{
                position: "fixed",
                top: "62px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                zIndex: "999", // Ensure the overlay is on top
              }}
            >
              {/* //  <Loader loading={loading} /> */}

              {/* You can add loading spinners or other content here */}
              <div
                style={{
                  height: "83%",
                  width: "85%",
                  padding: "40px",
                  backgroundColor: "white",
                  position: "absolute",
                  top: "20px",
                }}
              >
                <button
                  style={{ position: "absolute", top: "1%", left: "88%" }}
                  onClick={this.hidePlagiarismDiv}
                >
                  <CloseIcon style={{ backgroundColor: "transparent" }} />
                </button>
                <Button
                  variant="contained"
                  aria-label="outlined primary button group"
                  style={{ position: "absolute", top: "1%", left: "76%" }}
                  onClick={this.handleCheckPlagiarism}
                >
                  Check Again
                </Button>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "11px",
                    marginTop: "10px",
                  }}
                >
                  <Card className="col-md-2 col-sm-3">
                    <CardContent className="">
                      {/* <SemiCircleProgressBar percentage={uniqueProgress}>
            <div className="">
            {uniqueProgress}%
            </div>
            </SemiCircleProgressBar> */}
                      <div
                        style={{ width: 100, height: 100, textAlign: "center" }}
                      >
                        <CircularProgressbar
                          value={plagiarozedProgress}
                          // text={`${plagiarozedProgress}%`}
                          counterClockwise={true}
                          circleRatio={0.75}
                          styles={buildStyles({
                            rotation: 0.37,
                            strokeLinecap: "butt",
                            pathColor: "red",
                            trailColor: "#F6B6B6",
                            textColor: "red",
                          })}
                        />
                      </div>
                      <p className="progressText" style={{ color: "red" }}>
                        {plagiarozedProgress}% {this.progressStatus}
                      </p>
                    </CardContent>
                  </Card>
                  <Card className="col-md-2 col-sm-3  mx-1">
                    <CardContent className="">
                      <div
                        style={{ width: 100, height: 100, textAlign: "center" }}
                      >
                        <CircularProgressbar
                          value={uniqueProgress}
                          // text={`${uniqueProgress}%`}
                          // counterClockwise={true}
                          circleRatio={0.75}
                          styles={buildStyles({
                            rotation: 0.63,
                            strokeLinecap: "butt",
                            pathColor: "#32BC7A",
                            trailColor: "#7FF1BA",
                            textColor: "#32BC7A",
                          })}
                        />
                      </div>
                      <p
                        className="progressText ml-3"
                        style={{ color: "#32BC7A" }}
                      >
                        {uniqueProgress}% Unique
                      </p>
                    </CardContent>
                  </Card>
                  <Card className="col-md-5 col-sm-6">
                    <CardContent>
                      <div>
                        <div>
                          <p className="countCardheader">
                            Extra Word Count Statistics
                          </p>
                        </div>
                        <div className="countCardValues d-flex justify-content-between">
                          <div className="d-flex mt-3">
                            <div
                              className="d-flex flex-column"
                              style={{ fontWeight: 600 }}
                            >
                              <p>Sentences</p>
                              <p>Unique Word(s)</p>
                            </div>
                            <div className="d-flex flex-column ml-2">
                              <p>{sentenceCount}</p>
                              {/* <p>{sen}</p> */}
                              {/* <p>{uniqueWordCount + " " + "(" + uniqueWordPercent + ")"}</p> */}
                              <p>{uniqueWordsPercentage}</p>
                            </div>
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              className="d-flex flex-column"
                              style={{ fontWeight: 600 }}
                            >
                              <p>Paragraph(s)</p>
                              <p>Speak Time</p>
                            </div>
                            <div className="d-flex flex-column ml-2">
                              <p>{paraCount}</p>
                              {/* <p>1</p> */}
                              <p>{timeDisplay}</p>
                              {/* <p>{speakTime}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  <div
                    style={{
                      height: "151px",
                      width: "285px",

                      marginLeft: "5px",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div
                      className="P_div1"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="P_div1"
                        style={{
                          flex: 0.25,
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid grey",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "red",
                          }}
                        >
                          {/* Content for the 1st row (25% height) */}
                        </div>
                        <div
                          style={{
                            marginLeft: "8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Identical</div>
                          <span style={{ alignSelf: "flex-end" }}>
                            {plagiarozedProgress}%
                          </span>
                        </div>{" "}
                      </div>
                      <div
                        className="P_div1"
                        style={{
                          flex: 0.25,
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid grey",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "pink",
                          }}
                        >
                          {/* Content for the 2nd row (25% height) */}
                        </div>
                        <div
                          style={{
                            marginLeft: "8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Minor Changes</div>
                          <span className="" style={{ alignSelf: "flex-end" }}>
                            {/*{uniqueWordsPercentage}*/}0%
                          </span>
                        </div>
                      </div>
                      <div
                        className="P_div1"
                        style={{
                          flex: 0.25,
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid grey",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "Orange",
                          }}
                        >
                          {/* Content for the 3rd row (25% height) */}
                        </div>
                        <div
                          style={{
                            marginLeft: "8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Paraphrased</div>
                          <span className="" style={{ alignSelf: "flex-end" }}>
                            0%
                          </span>
                        </div>{" "}
                      </div>
                      <div
                        className="P_div1"
                        style={{
                          flex: 0.25,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "10px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "blue",
                          }}
                        >
                          {/* Content for the 4th row (25% height) */}
                        </div>
                        <div
                          style={{
                            marginLeft: "8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Omitted Words</div>
                          <span className="" style={{ alignSelf: "flex-end" }}>
                            0%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    height: "65%",
                    width: "100%",
                    margin: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
                      height: "100%",
                      width: "70%",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        height: "10%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <span style={{ marginLeft: "20px" }}>SUBMITTED TEXT</span>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <div style={{ marginRight: "15px" }}>
                          <ArrowDownwardIcon />
                        </div>
                        <div style={{ marginRight: "15px" }}>
                          {" "}
                          <ArrowUpwardIcon />
                        </div>
                        <div
                          style={{
                            marginRight: "15px",
                            fontWeight: "bolder",
                            fontSize: "20px",
                          }}
                        >
                          T
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <Editor
                        editorState={editorState}
                        readOnly={editable}
                        customStyleMap={this.customStyleMap}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      height: "100%",
                      width: "29%",
                      marginRight: "20px",
                      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {sourceCount !== 0 ? (
                      <SuggestionCard
                        sentenceArray={outputArray}
                        sourceCount={sourceCount}
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            height: "10%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #000",
                          }}
                        >
                          <span style={{ marginLeft: "20px" }}>RESULT</span>{" "}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%",
                          }}
                        >
                          <h3>Plagiarism Free</h3>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-4">
                        <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />

                    </div> */}
              </div>
            </div>
          )}
          <div
          className="card-bg"
            style={{
              position: "relative",
              marginTop: "10px",
              minWidth: "100%",
              maxWidth: "100%",
              boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
              padding: "5px",
              borderRadius: "10px",
            
              // minHeight: "300px",
              // maxHeight: "300px",
              // overflow: "auto",
            
            }}
          >
            {/* <Editor
              editorState={contentEditorState}
              onChange={(newState) => {
                this.handleContentEditorChange(newState);
              }}
              readOnly={editable}
            /> */}
            <JoditEditorComp
              // editorHeight={isFullSize ? 500 : 350}          
              editorHeight={350}          
              onChange={(newState) => {
            this.handleContentEditorChange(newState);
          }}
          editorContent={this.state.editorContent}
          editor={this.paraeditor}
          isEdit={false}
          isMain={false}
        />
          </div>
          {/* <div style={{ marginTop: "15px" }}>
            <Button
              variant="outlined"
              style={{ marginRight: "15px" }}
              color="primary"
              onClick={this.showPlagiarismDiv}
            >
              Check Plagiarism
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: "15px" }}
              color="primary"
            >
              Paraphrase
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: "15px" }}
              color="primary"
            >
              Summarize
            </Button>
          </div> */}

          {(this.props.data.editor !== this.props.currentUser._id &&
            documentType === "document") ||
            (blogType === "all" &&
              documentType === "blog" &&
              this.props.data.editor._id !== this.props.currentUser._id) ||
            (documentType === "document" &&
              this.props.data.status === "draft") ? (
            <>
              {/* <p
                className="mt-4"
              // style={{ opacity: "0.3" }}
              >
               <b> Comments & Rating</b>
              </p> */}
              <h4 className="mt-4">Comments & Rating</h4>
              <div style={{display: "flex", justifyContent: "space-between",  alignItems: "center"}}>
                <div
                  className="card p-2 card-bg"
                  style={{
                    border: "1px solid #dedcdc",
                    borderRadius: "12px",
                    boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.08)",
                    width: "90%"
                  }}
                >
                  <div style={{display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ width: "10%" }}>
                      <Avatar
                        // aria-controls={menuId}
                        aria-haspopup="true"
                        // alt={username}
                        // alt={username.length > 0
                        //   ? username
                        //   : username}
                        alt={userDetails.username}
                        // src={profilePic ? profilePic : avatar}
                        // src={currentUser.display_picture ? currentUser.display_picture : avatar}
                        id="profilePic"
                        // className={`${classes.avatar} profilePic point`}
                        color="inherit"
                      />

                      {/* <img
                      src={display_picture}
                      className="mx-2 mt-2"
                      alt=""
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                      }}
                    /> */}
                    </div>

                    <div className=" rm-focus" style={{ width: "70%" }}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        rows={3}
                        value={commands}
                        onChange={(e) => {
                          this.setState({ commands: e.target.value });
                        }}
                        className="removeBorder w-100 card-bg zzz"
                        placeholder="Write a response..."
                        style={{ height: "28px", paddingTop: "5px"}}
                      />
                    </div>
                    <div className=" text-right" style={{ width: "15%" }}>
                      <div>
                        <StarRatings
                          rating={this.state.rating}
                          starRatedColor="orange"
                          changeRating={(rate) => this.setState({ rating: rate })}
                          starDimension="15px"
                          starSpacing="0px"
                          numberOfStars={5}
                          name="rating"
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div style={{ width: "8%" }}>
                  <Button
                    style={{color: "white", backgroundColor: "dodgerblue", padding: "12px, 10px", borderRadius: "5px", padding: "5px 7px"}}
                    onClick={() => this.sendPost()}
                  >
                    Post
                    <ArrowForwardIcon style={{marginLeft: "4px"}}/>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <br />
          <div>
            <CommentsCard
              currentUser={this.props.currentUser}
              documentType={this.state.documentType}
              data={this.props.data}
              _id={this.state._id}
              ref={this.child}
              _calculateRatings={this._calculateRatings}
              blogType={blogType}
            />
          </div>
          <br />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
  currentNavItem: state.manageCoauthorNavReducer.currentCoauthorNavItem,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocumentPreview));
