import React, { useEffect, useState } from 'react';
import '../FormComponents/CoAuthor_M.css'
import Switch from '@mui/material/Switch';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Modal as B_Modal, Form } from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import FeedbackIcon from '@mui/icons-material/Feedback';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SelectDropdownMui from "../../ReusableComponents/SelectDropdownMui";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import citationService from '../../../services/citationService';
import { v4 as uuidv4 } from 'uuid';
import { CoAuthor_Searchcomponent } from './CoAuthor_Search';
import { CoAuthor_Aireviewcomponent } from './CoAuthor_Aireview';
import { Coauthor_Notescomponent } from './CoAuthor_Notes';
import { Coauthor_Citationcomponent } from './CoAuthor_Citation';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import CoAuther_PlagiarismChecker_Text from './CoAuther_PlagiarismChecker_Text';
import CoAuther_PlagiarismChecker_addWords from './CoAuther_PlagiarismChecker_addWords';
import CoAuther_PlagiarismChecker_Blank from './CoAuther_PlagiarismChecker_Blank';
import aireviewicon from "../../../static/AI_Review_color.png"
import aireviewiconwhite from "../../../static/AI_Review_white.png"

import plagiarismicon from "../../../static/Plagiarism_logo.png"
import plagiarismiconwhite from "../../../static/Plagiarism_white.png"

import { getNotes } from "../../../services/notesService";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { closeLeftNavbar, closeRightModal, closeRightModalAndOpenLeftNavbar, openLeftNavbar, openRightModal, openRightModalAndCloseLeftNavbar } from '../../../redux/handleSideNavBarAndModel/handleStatus.action';
import { fullSizeEditor } from '../../../redux/manageFullSizeEditor/fullSizeEditor.action';
import { checkFeatureAccess } from '../../featureAccess';
import plagiarismiconGray from "../../../static/Plagiarism 2.png"
import aireviewiconGray from "../../../static/AI Review Icon 1.png"
import { useCheckFeatureAccess } from '../../CheckFeature';


const label = { inputProps: { 'aria-label': 'Switch demo' } };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  icon: {
    color: 'inherit',
    transition: 'color 0.3s',
  },
});


const CoauthorModal = (props) => {
  const classes = useStyles();
  const [sourceList, setsourceList] = useState([
    {
      "value": "Individual",
      "name": "Individual"
    },
    {
      "value": "Organization",
      "name": "Organization"
    }
  ]);
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "email from 1");
  // const [activeButton, setActiveButton] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showResearchContent, setShowResearchContent] = useState(true);
  const [showTextSnippetContent, setShowTextSnippetContent] = useState(false);
  const [showFormatQuoteContent, setShowFormatQuoteContent] = useState(false);
  const [showAirplayContent, setShowAirplayContent] = useState(false);
  const [showAireviewContent, setshowAireviewContent] = useState(false)
  const [noteInput, setNoteInput] = useState('');
  const [coAuther_PlagiarismChecker, setCoAuther_PlagiarismChecker] = useState(false)
  const [hoveredPC, setHoveredPC] = useState(false);
  const [hoveredAI, setHoveredAI] = useState(false);
  const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
  const dispatch = useDispatch();
  const { rightModalOpen, leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);
  const { isFullSize } = useSelector((state) => state.manageFullSizeEditor);
  const accessToSearch = useCheckFeatureAccess(currentNavItem,'search')
  const accessToCitation = useCheckFeatureAccess(currentNavItem,'citation')
  const accessToAiReview = useCheckFeatureAccess(currentNavItem,'AIReview')
  const accessToPlagiarism = useCheckFeatureAccess(currentNavItem,'plagiarism')

  const handletextContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(true)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)
    props.setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());
  }


  const handleresarchContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(true)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)
    props.setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());
  }

  const handcitationContente = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(true)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(false)
    props.setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());

  }
  const handleAireview = () => {
    console.log("model opened");
    setShowModal(true)
    setshowAireviewContent(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setCoAuther_PlagiarismChecker(false)
    props.setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());
    // dispatch(openRightModal())
    // dispatch(closeLeftNavbar())
  }

  const handleCloseModal = () => {
    console.log("model closed");
    setShowModal(false);
    
    props.setTrackIsModalOpen(false);
    dispatch( props.mobileView ? closeRightModal() : closeRightModalAndOpenLeftNavbar());
    if(isFullSize){
      dispatch(fullSizeEditor())
      dispatch(closeLeftNavbar())
    }
    // props.resetTranscript()
    // dispatch(closeRightModal())
    // dispatch(openLeftNavbar())
  };


  const handlePlagiarismCheck = () => {
    setShowModal(true)
    setShowTextSnippetContent(false)
    setShowResearchContent(false)
    setShowFormatQuoteContent(false)
    setshowAireviewContent(false)
    setCoAuther_PlagiarismChecker(true)
    props.setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());
  }

  const handleTextInput = (text) => {
    setNoteInput(text);
  };
  const autherNameFromSearch = (name) => {
    // alert("name i modal", name)
    props.passNametoCoNewProject(name)
  }

  const handleMouseEnterPC = () => {
    setHoveredPC(true);
  };

  const handleMouseLeavePC = () => {
    setHoveredPC(false);
  };
  
  const handleMouseEnterAI = () => {
    setHoveredAI(true);
  };

  const handleMouseLeaveAI = () => {
    setHoveredAI(false);
  };

  return (
    <>
      <div>
        {/* <IconButton style={{position:"absolute",top:"100px",right:"10px"}} onClick={() => setShowModal(true)}>
        Open Modal
          </IconButton> */}

        <div className='changeBG' style={{ width: props.mobileView ? '100%' : '6%', boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)', borderRadius: props.mobileView ? '10px' : '35px', height: props.mobileView ? '' : '330px', display: 'flex', flexDirection: props.mobileView ? 'row' : 'column', alignContent: 'center', position: props.mobileView ? '' : 'fixed', top: '265px', right: '10px', marginTop: props.mobileView ? '10px' : '' }}>
          <div className='search_Hover' style={{width: props.mobileView ? '17%' : ''}}>
            <div
              onClick={() => accessToSearch && handleresarchContente()}
              style={{ display: 'flex', flexDirection: 'column', color: accessToSearch ? '#8A2BE2' : "gray", textDecoration: 'none', alignItems: 'center', paddingTop: '5px', margin: props.mobileView ? '5px 0px' : '0px 0px 10px 0px' }}>
              <LanguageIcon 
              // className={classes.icon}
              style={{color: accessToSearch ? '#8A2BE2' : "gray",}}
              />
              <h6 style={{fontSize: props.mobileView ? '10px' : ''}}>Search</h6>
            </div>
          </div>
          <div className='notes_Hover' style={{width: props.mobileView ? '17%' : ''}}>

            <div
              onClick={() => handletextContente()}
              style={{ display: 'flex', flexDirection: 'column', color: '#FFA07A', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
              <TextSnippetIcon 
              // className={classes.icon}
              style={{color: '#FFA07A',}}
              />
              <h6 style={{fontSize: props.mobileView ? '10px' : ''}}>Notes</h6>
            </div>
          </div>
          <div className='citation_Hover' style={{width: props.mobileView ? '20%' : ''}}>

            <div
              onClick={() => accessToCitation && handcitationContente()}
              style={{ display: 'flex', flexDirection: 'column', color: accessToCitation ? '#ff1694' : "gray", textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
              <FormatQuoteIcon 
              // className={classes.icon}
              style={{color: accessToCitation ? '#ff1694' : "gray",}}
              />
              <h6 style={{fontSize: props.mobileView ? '10px' : ''}}>Citations</h6>
            </div>
          </div>
          <div className='aireview_Hover' style={{width: props.mobileView ? '22%' : ''}}>
            <div
              onMouseEnter={handleMouseEnterAI}
              onMouseLeave={handleMouseLeaveAI}
              onClick={() => accessToAiReview && handleAireview()}
              style={{ display: 'flex', flexDirection: 'column', color: accessToAiReview ? 'green' : "gray", textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
              {/* <AirplayIcon className={classes.icon} /> */}
              <img
                    src={hoveredAI ? aireviewiconwhite : accessToAiReview ? aireviewicon : aireviewiconGray}
                    alt="AI Review"
                    style={{width:'30px'}}
                    className={classes.icon}
                />
              <h6 style={{fontSize: props.mobileView ? '10px' : ''}}>AI Review</h6>
            </div>
          </div>
          <div className='plagiarism_Hover' style={{width: props.mobileView ? '24%' : ''}}>

            <div
              onClick={() => accessToPlagiarism && handlePlagiarismCheck()}
              onMouseEnter={handleMouseEnterPC}
              onMouseLeave={handleMouseLeavePC}
              style={{ display: 'flex', paddingBottom: "10px", flexDirection: 'column', color: accessToPlagiarism ? 'red' : "gray", textDecoration: 'none', textAlign: 'center', marginTop: "10px", cursor: "pointer", alignItems: "center" }}>
              {/* <ManageSearchIcon className={classes.icon} /> */}
              <img
                    src={hoveredPC ? plagiarismiconwhite : accessToPlagiarism ? plagiarismicon : plagiarismiconGray}
                    alt="Plagiarism Checker"
                    className={classes.icon}
                    style={{width:'21px'}}
                />
                  
              <h6 style={{fontSize: props.mobileView ? '10px' : ''}}>Plagiarism Checker</h6>
            </div>
          </div>


          {/* <div title="Notes" onClick={() => handletextContente()} className='notes_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><TextSnippetIcon /></div>
            <div><h6>Notes</h6></div>
          </div> */}
          {/* <div title="Citations" onClick={() => handcitationContente()} className='citation_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><FormatQuoteIcon /></div>
            <div><h6>Citations</h6></div>
          </div>
          <div title="AI Review" onClick={() => handleAireview()} className='aireview_Hover' style={{ display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0' }}>
            <div><AirplayIcon /></div>
            <div><h6>AI Review</h6></div>
          </div> */}
          {/* <div  title="Plagiarism Checker" onClick={() => handlePlagiarismCheck()} style={{ display: 'flex', paddingBottom: "10px", flexDirection: 'column', color: 'black', textDecoration: 'none', textAlign: 'center', marginTop: "10px", cursor: "pointer" }}>
            <div><ManageSearchIcon /></div>
            <div><h6 >Plagiarism Checker</h6></div>
          </div> */}


          {/* <div> <LightTooltip title="Feedback"> 
          <div style={{ display: 'flex', flexDirection: 'column',cursor: "pointer", color: 'black', textDecoration: 'none', alignItems: 'center', margin: '10px 0', marginTop: '50px' }}>
          <div>
            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.75 14C11.9926 14 13 15.0074 13 16.25V17.752L12.9921 17.8604C12.6814 19.9866 10.7715 21.009 7.56679 21.009C4.37361 21.009 2.4333 19.9983 2.01446 17.8966L2 17.75V16.25C2 15.0074 3.00736 14 4.25 14H10.75ZM7.5 6C9.433 6 11 7.567 11 9.5C11 11.433 9.433 13 7.5 13C5.567 13 4 11.433 4 9.5C4 7.567 5.567 6 7.5 6ZM19.75 2C20.9926 2 22 3.00736 22 4.25V7.75C22 8.99264 20.9926 10 19.75 10H18.1972L15.6555 12.2071C15.2385 12.5691 14.6069 12.5245 14.2448 12.1075C14.0869 11.9256 14 11.6928 14 11.4523L13.9993 9.98619C12.8747 9.86153 12 8.90791 12 7.75V4.25C12 3.00736 13.0074 2 14.25 2H19.75Z" fill='#1976D7'/>
            </svg>
            </div>
            <div style={{color:'#1976D7'}}><h6>Feedback</h6></div>
          </div>
          </LightTooltip></div> */}

        </div>
        {/* changed */}
        {/* {showModal &&  */}
        {rightModalOpen &&
          <div >
            <div className="Serive_div blackBg" style={{border:"10px solid yellow", width: props.mobileView ? '70%' : '468px', zIndex: '1000', overflowX:'hidden', overflowY: 'auto', height: props.mobileView ? '80vh' : '100vh', position: props.mobileView ? 'fixed' : "absolute", top: props.mobileView ? '90px' : "67px", right: props.mobileView ? '5px' : "0px", border: '1px solid  grey', padding: "none" }}>
              <div style={{display: 'flex', width:props.mobileView ? '100%' : '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", marginTop: "0px" }}>
                <div style={{width:props.mobileView ? "85%" : "80%", display: 'flex',flexWrap:props.mobileView ? "wrap" : "", marginBottom: "0px" }}>
                  <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none', alignItems: "center" }}>
                    {showResearchContent ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: showResearchContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", marginBottom: "0px", marginTop: "0px" }}
                        onClick={() => handleresarchContente()}>
                        <div style={{ color: '#8A2BE2', display: 'flex', alignItems: "center" }}>< LanguageIcon />
                          {showResearchContent && !props.mobileView &&
                            <h6 style={{ color: '#8A2BE2', marginLeft: '3px', marginBottom: "0px" }}>Search</h6>
                          }
                        </div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Search">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: showResearchContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", marginBottom: "0px", marginTop: "0px" }}
                          onClick={() => accessToSearch && handleresarchContente()}>
                          <div style={{ color: accessToSearch ? '#8A2BE2' : "gray", display: 'flex', alignItems: "center" }}>< LanguageIcon />
                            {showResearchContent &&
                              <h6 style={{ color: '#8A2BE2', marginLeft: '3px', marginBottom: "0px" }}>Search</h6>
                            }
                          </div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none', }}>
                    {showTextSnippetContent ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: showTextSnippetContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", }}
                        onClick={() => handletextContente()}>
                        <div style={{ color: '#FFA07A', display: 'flex', alignItems: "center" }}><TextSnippetIcon />
                          {showTextSnippetContent && !props.mobileView &&
                            <h6 style={{ color: '#FFA07A', marginLeft: '3px', marginBottom: "0px" }}>Notes</h6>
                          }
                        </div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Notes">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: showTextSnippetContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", }}
                          onClick={() => handletextContente()}>
                          <div style={{ color: '#FFA07A', display: 'flex', alignItems: "center" }}><TextSnippetIcon />
                            {showTextSnippetContent &&
                              <h6 style={{ color: '#FFA07A', marginLeft: '3px', marginBottom: "0px" }}>Notes</h6>
                            }
                          </div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                    {showFormatQuoteContent ? (
                      <IconButton
                        style={{ backgroundColor: showFormatQuoteContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", alignItems: "center", display: 'flex' }}
                        disableTouchRipple
                        onClick={() => handcitationContente()}>
                        <div style={{ color: '#ff1694', display: 'flex', alignItems: "center" }}><FormatQuoteIcon />
                          {showFormatQuoteContent && !props.mobileView &&
                            <h6 style={{ color: showFormatQuoteContent ? '#ff1694' : 'black' , marginLeft: '2px', marginBottom: "0px" }}>Citation</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Citation">
                        <IconButton
                          style={{ backgroundColor: showFormatQuoteContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", alignItems: "center", display: 'flex' }}
                          disableTouchRipple
                          onClick={() => accessToCitation && handcitationContente()}>
                          <div style={{ color: accessToCitation ? '#ff1694' : "gray", display: 'flex', alignItems: "center" }}><FormatQuoteIcon />
                            {showFormatQuoteContent &&
                              <h6 style={{ color: '#ff1694', marginLeft: '2px', marginBottom: "0px" }}>Citation</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
                    {showAireviewContent ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: showAireviewContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                        onClick={() => handleAireview()}>
                        <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                          {/* <AirplayIcon /> */}
                          <img
                              src={aireviewicon}
                              alt="AI Review"
                              style={{width:'21px'}}
                          /> 
                          {showAireviewContent && !props.mobileView &&
                            <h6 style={{ color: showAireviewContent ? 'green' : 'black', marginLeft: '4px', marginBottom: "0px" }}>AI Review</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Ai Review">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: showAireviewContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                          onClick={() => accessToAiReview && handleAireview()}>
                          <div style={{ color: accessToAiReview ? 'black' : "gray", display: 'flex', alignItems: "center" }}>
                            {/* <AirplayIcon /> */}
                            <img
                                src={accessToAiReview ? aireviewicon : aireviewiconGray}
                                alt="AI Review"
                                style={{width:'21px'}}
                            />
                            {showAireviewContent &&
                              <h6 style={{ color: 'black', marginLeft: '4px', marginBottom: "0px" }}>AI Review</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
                    {coAuther_PlagiarismChecker ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: coAuther_PlagiarismChecker ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                        onClick={() => handlePlagiarismCheck()}>
                        <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                        <img
                                src={plagiarismicon}
                                alt="Plagiarism Checker"
                                className={classes.icon}
                                style={{width:'21px'}}
                            />
                          {coAuther_PlagiarismChecker && !props.mobileView &&
                            <h6 style={{ color: coAuther_PlagiarismChecker ? 'red' : 'black', marginLeft: '4px', marginBottom: "0px" }}>Plagiarism Checker</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Plagiarism Checker">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: coAuther_PlagiarismChecker ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                          onClick={() => accessToPlagiarism && handlePlagiarismCheck()}>
                          <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                            {/* <ManageSearchIcon /> */}
                            <img
                                src={accessToPlagiarism ? plagiarismicon : plagiarismiconGray}
                                alt="Plagiarism Checker"
                                className={classes.icon}
                                style={{width:'21px'}}
                            />
                            {coAuther_PlagiarismChecker &&
                              <h6 style={{ color: coAuther_PlagiarismChecker ? 'red' : 'black', marginLeft: '4px', marginBottom: "0px" }}>Plagiarism Checker</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                </div>
                <div style={{width:props.mobileView ? "10%" : "15%"}} onClick={handleCloseModal}>
                  <CloseIcon className='whiteText' style={{ cursor: "pointer" }} />
                </div>
              </div>


              {coAuther_PlagiarismChecker &&
                props.count == 0 && (
                  <CoAuther_PlagiarismChecker_Blank handleModal={props.handleModal} close={handleCloseModal} />
                )
              }
              {coAuther_PlagiarismChecker &&
                props.count == 1 && (
                  <CoAuther_PlagiarismChecker_addWords handleModal={props.handleModal} close={handleCloseModal} />
                )
              }



              {coAuther_PlagiarismChecker &&
                props.count > 1 && (
                  <CoAuther_PlagiarismChecker_Text handleModal={props.handleModal} editorState={props.state} state={props.state} setEditorState={props.setState} mobileView={props.mobileView} />
                )
              }



              {showResearchContent && (
                <CoAuthor_Searchcomponent handleModal={props.handleModal} autherName={autherNameFromSearch} trial={props.trial} userId={props.userId} mobileView={props.mobileView} />
              )}


              {showTextSnippetContent && (
                <Coauthor_Notescomponent handleModal={props.handleModal} noteInput={noteInput} handleTextInput={handleTextInput} />
              )}



              {showFormatQuoteContent && (
                <Coauthor_Citationcomponent handleModal={props.handleModal} handleInsertClick={props.handleInsertClick} trial={props.trial} userId={props.userId} mobileView={props.mobileView} />

              )}

              {showAireviewContent && (
                <>
                  < CoAuthor_Aireviewcomponent handleModal={props.handleModal} editorState={props.state} count={props.count} onChange={props.onChange} selectedText={props.selectedEditorText}
                    selectedWordCount={props.wordCount} selectText={props.selectText} trial={props.trial} userId={props.userId} mobileView={props.mobileView} />
                </>
              )}
            </div>
          </div>

        }
      </div>
    </>
  )
}

export default CoauthorModal;