import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from 'crypto-js';
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./LoginForm.css";
import { getHomeCMS } from "../services/adminServices";
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import PetLogo from "../static/POET_Logo_Mid.png"
import googleSignin from "../static/signup_login/googleSignin.png"
import facebookSignin from "../static/signup_login/facebookSignin.png"
import tick from "../static/signup_login/Check.png"

import Login1 from "../static/login-3.png";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import CheckboxMui from "./ReusableComponents/CheckBoxMui";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui";
import InputBoxMobile from "./ReusableComponents/InputBoxforMobile";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import OtpInputBox from "./ReusableComponents/OtpInputBox";
import { checkOtpStatus, sendOtp, verifyOtp } from "../services/authService";
import { countries } from "./ReusableComponents/countryList";
import { getRoles } from "../services/roleServices";
import { addUserLogs } from "../services/userLogServices";
import PoetNewlogopen from '../static/newlogoPoet2.png'

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    dense: {
        marginTop: 19,
    },
}));

function SignUp({ history }) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [values, setValues] = useState("");
    const [username, setUserName] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [password, setPassword] = useState("");
    const [isTermsAccepted, setAcceptTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [disable, setdisable] = useState(true);
    const [showPassword] = useState(false);
    const [cms, setCms] = useState({});
    const [roleList, setRoleList] = useState([
        {
            "value": "Individual",
            "name": "Individual"
        },
        {
            "value": "Organization",
            "name": "Organization"
        }
    ]);
    const [role, setRole] = useState("")
    const [userRoleList, setUserRoleList] = useState([])
    const [userRole, setUserRole] = useState("")
    const [region, setRegion] = useState("")
    const [countryList, setCountryList] = useState([])
    const [otpValue, setOtpValue] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [isOtpShown, setIsOtpShown] = useState(false)
    const [isOtpVerify, setIsOtpVerify] = useState(false)
    const [verifyDisable, setVerifyDisable] = useState(true)
    const [timer, setTimer] = useState(0);
    const [isResendCodeDisable, setisResendCodeDisable] = useState(true)
    const [isResendButton, setIsResendButton] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showRoles, setShowRoles] = useState(false);
    const [otpErrorMsg, setErrorMsg] = useState(null);

    const selectedCountry = countries.find(country => country.phone === countryCode.slice(1));
    const countryName = selectedCountry ? selectedCountry.label : "";
    const getCms = async () => {
        let cms = await getHomeCMS();
        setCms(cms.data);
        console.log(cms);
    };

    useEffect(() => {
        getCms();
    }, []);

    async function handleRegisterSubmit() {
        if (!username) {
            toast.warn("Please enter the username");
            return;
        }
        if (!email) {
            toast.warn("Please enter the email");
            return;
        }
        // Email validation using a regular expression
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            toast.warn("Please enter a valid email address.");
            return;
        }

        if (role == "Organization") {
            // if (!region) {
            //     setLoading(false);
            //     toast.warn("Please enter Region");
            //     return
            // }
            if (!organizationName) {
                toast.warn("Please enter Organization Name");
                return;
            }
            if (!organizationId) {
                toast.warn("Please enter Organization ID");
                return;
            }
            if (!userRole) {
                toast.warn("Please select User Role");
                return;
            }
        }
        if (!password) {
            toast.warn("Please enter the password");
            return;
        }
        if (!mobileNo) {
            toast.warn("Please enter the Mobile Number");
            return;
        }
        if (!isTermsAccepted) {
            toast.warn("Please accept the Terms & Conditions");
            return;
        }
        if (password.length < 6) {
            toast.warn("Password must contain at least 6 characters");
        } else {
            const hashedPassword = CryptoJS.SHA256(password).toString();
            let user = {
                username: username,
                email: email,
                // password: password,
                password: hashedPassword,
                phone: countryCode + mobileNo,
                // organizationId: organizationId,
                // organizationName: organizationName,
                referedEmail:
                    localStorage.getItem("referedEmail") !== "undefined" || null
                        ? localStorage.getItem("referedEmail")
                        : "",
                Role: role,
                organizationName: role == "Organization" ? organizationName : "",
                organizationId: role == "Organization" ? organizationId : "",
                userRole: role == "Organization" ? userRole : "",
                // region: role == "Organization" && region,
                country: countryName,
            };
            console.log("user_", user);
            setLoading(true)
            const result = await userService.register(user);
            setLoading(false)
            console.log(result);
            if (result.status !== 201) {
                if(result?.Role == "Individual"){
                    toast.info(result.message);
                    history.push({
                        pathname: `/afterRegistration`,
                        state: { detail: user },
                    });

                }else{
                    toast.warn(result.message);
                }
            } else {
                localStorage.removeItem("referedEmail");
                localStorage.removeItem("accountType");
                toast.info(result.message);
                if(!result?.users){ 
                    history.push({
                        pathname: `/afterRegistration`,
                        state: { detail: user },
                    });
                }
                try {
                    const data = {
                        userId: result?.data?._id,
                        organizationId: result?.data?.organizationId,
                        actionType: "signup"
                    }
                    const addLogs = await addUserLogs(data)
                    if (addLogs.status !== 200) {
                        toast.error(addLogs.message)
                    }
                } catch (error) {
                    toast.error("Internal server error")
                }
               
            
                // toast.info("Please check your email to verify");
            }
        }
    }
    function handleAcceptTerms(e) {
        console.log("value", e.target.checked);
        const isChecked = e.target.checked;
        setAcceptTerms(isChecked);
    }
    function handlePassword(e) {
        let pass = e.target.value;

        if (pass.length > 0) {
            if (pass.length <= 7) {
                setErrors("Password contains minimum 8 Characters");
                setIsPasswordValid(false);
            } else {
                setErrors("");

                var upperCaseLetters = /[A-Z]/g;
                var numbers = /[0-9]/g;
                var specialCharacters = /[!@#$%^&*(),.?":{}|<>]/g; // Add your desired special characters inside the brackets

                if (!pass.match(upperCaseLetters)) {
                    setErrors("Password contains at least 1 Capital letter");
                    setIsPasswordValid(false);
                } else if (!pass.match(numbers)) {
                    setErrors("Password contains at least 1 number");
                    setIsPasswordValid(false);
                } else if (!pass.match(specialCharacters)) {
                    setErrors("Password contains at least 1 special character");
                    setIsPasswordValid(false);
                } else {
                    setErrors("");
                    setIsPasswordValid(true);
                    setPassword(pass);
                }
            }
        } else {
            setErrors("");
            setIsPasswordValid(true);
        }
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRole = (e) => {
        console.log("eData_", e)
        setRole(e.target.value)
    }

    const handleUserRole = (e) => {
        console.log("eData_", e)
        setUserRole(e.target.value)
    }

    const handleRegion = (e) => {
        console.log("handleRegion", e, region)
        setRegion(e.target.value)
    }

    const handleCountry = (event, value) => {
        console.log("params_", event, value)
        if (value.phone !== "" && mobileNo !== "") {
            setVerifyDisable(false)
            setIsOtpShown(false)
            setIsResendButton(false)
        } else {
            setVerifyDisable(true)
        }
        setCountryCode(`+${value.phone}`)
    }
    const handleNumber = (e) => {
        console.log("eData_", e, e.length, countryCode);

        // Map for country-specific phone number lengths
        const countryLengthMap = {
            "+1": 10,
            "+93": 9,    // Afghanistan
            "+355": 8,   // Albania
            "+213": 9,   // Algeria
            "+376": 6,   // Andorra
            "+244": 9,   // Angola
            "+1-264": 10, // Anguilla
            "+1-268": 10, // Antigua and Barbuda
            "+54": 10,   // Argentina
            "+374": 8,   // Armenia
            "+297": 7,   // Aruba
            "+61": 9,    // Australia
            "+43": 13,   // Austria
            "+994": 9,   // Azerbaijan
            "+1-242": 10, // Bahamas
            "+973": 8,   // Bahrain
            "+880": 11,  // Bangladesh
            "+1-246": 10, // Barbados
            "+32": 9,    // Belgium
            "+501": 8,   // Belize
            "+229": 8,   // Benin
            "+1-441": 10, // Bermuda
            "+975": 8,   // Bhutan
            "+591": 8,   // Bolivia
            "+387": 8,   // Bosnia and Herzegovina
            "+267": 7,   // Botswana
            "+55": 11,   // Brazil
            "+1-284": 10, // British Virgin Islands
            "+673": 7,   // Brunei
            "+359": 8,   // Bulgaria
            "+226": 8,   // Burkina Faso
            "+257": 7,   // Burundi
            "+855": 8,   // Cambodia
            "+237": 9,   // Cameroon
            "+1": 10,    // Canada
            "+238": 7,   // Cape Verde
            "+1-345": 10, // Cayman Islands
            "+236": 7,   // Central African Republic
            "+235": 8,   // Chad
            "+56": 9,    // Chile
            "+86": 11,   // China
            "+61": 9,    // Christmas Island
            "+61": 9,    // Cocos (Keeling) Islands
            "+57": 10,   // Colombia
            "+269": 7,   // Comoros
            "+242": 7,   // Congo
            "+243": 9,   // Democratic Republic of the Congo
            "+682": 5,   // Cook Islands
            "+506": 8,   // Costa Rica
            "+225": 10,  // Côte d'Ivoire
            "+385": 9,   // Croatia
            "+53": 8,    // Cuba
            "+599": 7,   // Curaçao
            "+357": 8,   // Cyprus
            "+420": 9,   // Czech Republic
            "+45": 8,    // Denmark
            "+253": 6,   // Djibouti
            "+1-767": 10, // Dominica
            "+1-809": 10, // Dominican Republic
            "+593": 9,   // Ecuador
            "+20": 10,   // Egypt
            "+503": 8,   // El Salvador
            "+240": 9,   // Equatorial Guinea
            "+291": 7,   // Eritrea
            "+368": 6,   // Estonia
            "+251": 10,  // Ethiopia
            "+500": 4,   // Falkland Islands
            "+298": 6,   // Faroe Islands
            "+679": 7,   // Fiji
            "+358": 9,   // Finland
            "+33": 9,    // France
            "+594": 9,   // French Guiana
            "+689": 6,   // French Polynesia
            "+241": 7,   // Gabon
            "+220": 7,   // Gambia
            "+995": 9,   // Georgia
            "+49": 11,   // Germany
            "+233": 10,  // Ghana
            "+350": 5,   // Gibraltar
            "+30": 10,   // Greece
            "+299": 6,   // Greenland
            "+1-473": 10, // Grenada
            "+590": 10,  // Guadeloupe
            "+1-671": 10, // Guam
            "+502": 8,   // Guatemala
            "+224": 9,   // Guinea
            "+245": 9,   // Guinea-Bissau
            "+595": 9,   // Paraguay
            "+509": 9,   // Haiti
            "+379": 13,  // Holy See
            "+504": 8,   // Honduras
            "+852": 8,   // Hong Kong
            "+36": 9,    // Hungary
            "+354": 7,   // Iceland
            "+91": 10,   // India
            "+62": 10,   // Indonesia
            "+98": 10,   // Iran
            "+964": 10,  // Iraq
            "+353": 9,   // Ireland
            "+972": 9,   // Israel
            "+39": 10,   // Italy
            "+1-876": 10, // Jamaica
            "+81": 10,   // Japan
            "+44": 10,   // Jersey
            "+962": 9,   // Jordan
            "+7": 10,    // Kazakhstan
            "+254": 10,  // Kenya
            "+686": 5,   // Kiribati
            "+965": 8,   // Kuwait
            "+996": 9,   // Kyrgyzstan
            "+856": 8,   // Laos
            "+371": 8,   // Latvia
            "+961": 8,   // Lebanon
            "+266": 8,   // Lesotho
            "+231": 7,   // Liberia
            "+218": 10,  // Libya
            "+423": 7,   // Liechtenstein
            "+370": 8,   // Lithuania
            "+352": 8,   // Luxembourg
            "+261": 10,  // Madagascar
            "+265": 8,   // Malawi
            "+60": 10,   // Malaysia
            "+960": 7,   // Maldives
            "+223": 8,   // Mali
            "+356": 8,   // Malta
            "+692": 7,   // Marshall Islands
            "+596": 10,  // Martinique
            "+222": 9,   // Mauritania
            "+230": 8,   // Mauritius
            "+262": 10,  // Mayotte
            "+52": 10,   // Mexico
            "+691": 7,   // Micronesia
            "+373": 8,   // Moldova
            "+377": 8,   // Monaco
            "+976": 8,   // Mongolia
            "+1-664": 10, // Montserrat
            "+212": 9,   // Morocco
            "+258": 9,   // Mozambique
            "+95": 9,    // Myanmar
            "+264": 9,   // Namibia
            "+674": 8,   // Nauru
            "+977": 10,  // Nepal
            "+31": 9,    // Netherlands
            "+599": 10,  // Netherlands Antilles
            "+687": 6,   // New Caledonia
            "+64": 9,    // New Zealand
            "+505": 8,   // Nicaragua
            "+227": 8,   // Niger
            "+234": 10,  // Nigeria
            "+683": 5,   // Niue
            "+64": 9,    // Norfolk Island
            "+850": 8,   // North Korea
            "+1-670": 10, // Northern Mariana Islands
            "+47": 8,    // Norway
            "+968": 8,   // Oman
            "+92": 10,   // Pakistan
            "+680": 7,   // Palau
            "+970": 9,   // Palestine
            "+507": 8,   // Panama
            "+675": 7,   // Papua New Guinea
            "+595": 8,   // Paraguay
            "+51": 9,    // Peru
            "+63": 10,   // Philippines
            "+48": 9,    // Poland
            "+351": 9,   // Portugal
            "+1-787": 10, // Puerto Rico
            "+974": 8,   // Qatar
            "+40": 10,   // Romania
            "+7": 10,    // Russia
            "+250": 10,  // Rwanda
            "+590": 10,  // Saint Barthélemy
            "+1-869": 10, // Saint Kitts and Nevis
            "+1-758": 10, // Saint Lucia
            "+1-784": 10, // Saint Vincent and the Grenadines
            "+685": 5,   // Samoa
            "+378": 10,  // San Marino
            "+239": 7,   // Sao Tome and Principe
            "+966": 9,   // Saudi Arabia
            "+221": 9,   // Senegal
            "+381": 9,   // Serbia
            "+248": 7,   // Seychelles
            "+232": 8,   // Sierra Leone
            "+65": 8,    // Singapore
            "+421": 9,   // Slovakia
            "+386": 8,   // Slovenia
            "+677": 6,   // Solomon Islands
            "+252": 8,   // Somalia
            "+27": 10,   // South Africa
            "+82": 10,   // South Korea
            "+211": 9,   // South Sudan
            "+34": 9,    // Spain
            "+94": 10,   // Sri Lanka
            "+249": 10,  // Sudan
            "+597": 8,   // Suriname
            "+268": 7,   // Eswatini
            "+41": 9,    // Switzerland
            "+963": 9,   // Syria
            "+886": 10,  // Taiwan
            "+992": 9,   // Tajikistan
            "+255": 10,  // Tanzania
            "+66": 9,    // Thailand
            "+670": 7,   // Timor-Leste
            "+228": 8,   // Togo
            "+690": 6,   // Tokelau
            "+676": 4,   // Tonga
            "+1-868": 10, // Trinidad and Tobago
            "+216": 8,   // Tunisia
            "+90": 10,   // Turkey
            "+993": 9,   // Turkmenistan
            "+1-649": 10, // Turks and Caicos Islands
            "+688": 5,   // Tuvalu
            "+256": 10,  // Uganda
            "+380": 9,   // Ukraine
            "+971": 9,   // United Arab Emirates
            "+44": 10,   // United Kingdom
            "+1": 10,    // United States
            "+598": 8,   // Uruguay
            "+998": 9,   // Uzbekistan
            "+678": 5,   // Vanuatu
            "+379": 13,  // Vatican City
            "+58": 10,   // Venezuela
            "+84": 10,   // Vietnam
            "+681": 4,   // Wallis and Futuna
            "+212": 9,   // Western Sahara
            "+967": 9,   // Yemen
            "+260": 10,  // Zambia
            "+263": 9,    // Zimbabwe
            "+1684": 7,   // American Samoa (AS)
            "+358-18": 9, // Åland Islands (AX)
            "+590": 10,   // Saint Barthélemy (BL)
            "+375": 9,    // Belarus (BY)
            "+501": 8,    // Belize (BZ)
            "+383": 9,    // Kosovo (XK)
            "+1-340": 10, // United States Virgin Islands (VI)
            "+1-284": 10, // British Virgin Islands (VG)
            "+690": 4,    // Tokelau (TK)
            "+262": 9,    // French Southern Territories (TF)
            "+235": 8,    // Chad (TD)
            "+239": 7,    // São Tomé and Príncipe (ST)
            "+268": 7,    // Eswatini (SZ)
            "+290": 4,    // Saint Helena (SH)
            "+46": 10,    // Sweden (SE)
            "+262": 9,    // Réunion (RE)
            "+872": 9,    // Pitcairn Islands (PN)
            "+382": 9,    // Montenegro (ME)
            "+389": 9,    // North Macedonia (MK)
            "+269": 7,    // Comoros (KM)
            "+44": 10,    // Jersey (JE)
            "+44": 10,    // Isle of Man (IM)
            "+672": 6,    // Heard Island and McDonald Islands (HM)
            "+592": 9,    // Guyana (GY)
            "+500": 4,    // South Georgia and the South Sandwich Islands (GS)
            "+500": 4,    // Falkland Islands (FK)
        };

        // Get the required length for the given country code
        const maxLength = countryLengthMap[countryCode] || 10; // Default to 10 if country code isn't found

        // Validation based on country-specific length
        if (e.length > maxLength) {
            return;
        }

        // Set error message if the length is less than required
        if (e.length < maxLength && e.length > 0) {
            setErrorMessage(`Please enter a ${maxLength} digit phone number.`);
        } else {
            setErrorMessage('');
        }

        // Check for valid lengths for China or other countries
        let isChina = e.length === 11 && countryCode === "+86";
        let isOtherCountries = e.length === maxLength && countryCode !== "+86" && countryCode !== "";

        if (isChina || isOtherCountries) {
            setVerifyDisable(false);
            setIsOtpShown(false);
            setIsResendButton(false);
        } else {
            setVerifyDisable(true);
        }

        setMobileNo(e);
    };
    const handleOTP = async (e) => {
        console.log("otpdata_", e.length, e)
        setOtpValue(e)

        if (e.length == 6) {
            setLoading(true)
            let payload = {
                "phone": countryCode + mobileNo,
                "otp": e
            }
            let verifyOtpresponse = await verifyOtp(payload)
            console.log("verifyOtp_", verifyOtpresponse)
            if (verifyOtpresponse.status == 200) {
                setLoading(false)
                setIsOtpShown(false)
                setIsOtpVerify(true)
                setVerifyDisable(true)
                setdisable(false)
                setisResendCodeDisable(false)
                setIsResendButton(false)
            } else if (verifyOtpresponse.status == 400) {
                console.log("bnbnbnb");
               setLoading(false)
                setIsOtpVerify(false)
                setIsOtpShown(true)
                setisResendCodeDisable(true)
                setIsResendButton(true)
                setErrorMsg(verifyOtpresponse.message)
                return
            } else if (verifyOtpresponse.status == 429) {
                console.log("bnbvvnbnb");
               setLoading(false)
                setIsOtpVerify(false)
                setIsOtpShown(true)
                setisResendCodeDisable(true)
                setIsResendButton(true)
                setErrorMsg(verifyOtpresponse.message)
                return
            } else {
                setLoading(false)
                setIsOtpVerify(false)
                setIsOtpShown(true)
                setisResendCodeDisable(true)
                setIsResendButton(true)
            }
        }
    }

    // useEffect(()=>{
    //     if(role !=="" && username !== "" && email !== "" && mobileNo !== "" && password !== ""){
    //         setdisable(false)
    //     } else{
    //         setdisable(true)
    //     }
    // },[role,username,email,mobileNo,password])


    const handleVerify = async () => {
        console.log("resendOTP")
        setOtpValue("")
        setTimer(60)
        // setVerifyDisable(true)
        setIsOtpVerify(false)
        // setIsOtpShown(false)
        if (!role) {
            toast.warn("Please select role.");
            return;
        }
        if (!isPasswordValid) {
            toast.warn("Please enter a valid password.");
            return;
        }
        if (!username) {
            toast.warn("Please enter the username");
            return;
        }
        if (!email) {
            toast.warn("Please enter the email");
            return;
        }
        if (role == "Organization") {
            if (!organizationName) {
                toast.warn("Please enter Organization Name");
                return;
            }
            if (!organizationId) {
                toast.warn("Please enter Organization ID");
                return;
            }
            if (!userRole) {
                toast.warn("Please select User Role");
                return;
            }
        }
        if (!password) {
            toast.warn("Please enter the password");
            return;
        }
        if (!mobileNo) {
            toast.warn("Please enter the Mobile Number");
            return;
        }

        let payload = {
            // "email": email,
            "phone": countryCode + mobileNo
        }

        // Check OTP status before proceeding
        let otpStatusResponse = await checkOtpStatus(payload); // Backend call to get OTP status
        if (otpStatusResponse.status === 200) {
        let otpDetails = otpStatusResponse.data;
        let currentTime = new Date();

        // Check if the user is currently locked
        if (otpDetails.lockUntil && new Date(otpDetails.lockUntil) > currentTime && otpDetails.attempts == 3) {
            let unlockTime = new Date(otpDetails.lockUntil).toLocaleString();
            toast.warn(`You are locked due to failed attempts. Try again after ${unlockTime}.`);
            return;
        }


        if (otpDetails.lockUntil && new Date(otpDetails.lockUntil) > currentTime) {
            let unlockTime = new Date(otpDetails.lockUntil).toLocaleString();
            toast.warn(`You are locked due to failed attempts. Try again after ${unlockTime}.`);
            return;
        }
        }

        let sentresponse = await sendOtp(payload);
        console.log("sentresponse_", sentresponse.message)

        if (sentresponse.status == 200) {
            toast.success("OTP Sent Successfully")
            setIsResendButton(true)
            setIsOtpShown(true)
            setVerifyDisable(true)
       
        } else {
            toast.warn("Invalid Phone Number")
            return
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        if (timer == 0) {
            setisResendCodeDisable(false);
            setIsOtpShown(false)
        } else {
            setisResendCodeDisable(true);

        }
        return () => {
            clearTimeout(timerId);
        };
    }, [timer]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        fetchCountryList()
    }, [])

    const fetchCountryList = () => {
        let finalArr = []
        countries.map((obj) => {
            finalArr.push({
                name: obj.label,
                value: obj.label
            })
        })
        setCountryList(finalArr)
    }


    const getUserRoles = async () => {
        try {
            if (organizationId) {
                setLoading(true);
                const data = { organizationId }
                const checkUsersExist = await getRoles(data)
                if (checkUsersExist?.isUsersExist) {
                    setUserRoleList(checkUsersExist?.userRoleList);
                } else {
                    setUserRoleList(checkUsersExist?.userRoleList);
                }
                setShowRoles(true);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error finding roles list:', error);
            setLoading(false);
        }
    }


    useEffect(()=>{
        getUserRoles();
    },[organizationId])


    return (
        <div className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "auto",
                minHeight: "-webkit-fill-available",
                position: "absolute",
                top: 0,
                left: 0,
                // position: "relative"
            }}
        >
            <ToastContainer />
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2 col-md-12">
                <div className="d-flex col-5">
                    <Link to="/">
                        <img
                            className="petLogo"
                            alt="Group570"
                            src={PoetNewlogopen}
                            style={{ width: '35%' }}
                        // src={
                        //     cms && cms.Navbar_logo
                        //         ? cms.Navbar_logo.Image
                        //         : `${imgUrl}/pet-website-static-images/Group+571.png`
                        // }
                        ></img>
                    </Link>
                </div>

                <div className="d-flex justify-content-center col-7">
                    <Card
                        className="justify-content-center card-comp px-5 p-3"
                    >
                        <div className="text-center">
                            <h3 className="mainHeading">Signup</h3>
                        </div>
                        <div className="mt-3 py-1">
                            <SelectDropdownMui
                                optionsList={roleList}
                                selectLabel={"Role"}
                                onChange={handleRole}
                                value={role}
                            />
                        </div>

                        {/* <div className="py-2">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Role"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div> */}

                        {role == "Organization" &&
                            <>
                                {/* <div className="mt-2 py-1">
                                    <SelectDropdownMui
                                        optionsList={countryList}
                                        selectLabel={"Region*"}
                                        onChange={handleRegion}
                                        value={region}
                                    />
                                </div> */}

                                <div className="mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization*"
                                        // value={username}
                                        onChange={(e) => setOrganizationName(e.target.value)}
                                    // placeholder="Username"
                                    />

                                </div>

                                <div className=" mt-2 py-1">
                                    <InputBoxMui
                                        fullWidth
                                        type="text"
                                        label="Organization ID*"
                                        // value={username}
                                        // onChange={(e) => setOrganizationId(e.target.value)}
                                        onChange={(e) => {
                                            setOrganizationId(e.target.value);
                                            setUserRole("");
                                            setShowRoles(false);
                                        }}
                                    // placeholder="Username"
                                    />
                                    {/* <button
                                        className={`btn ml-1 ${isOtpVerify ? 'btn-secondary' : 'btn-primary'}`} 
                                        onClick={getUserRoles}
                                        disabled={isOtpVerify}
                                        style={{cursor : isOtpVerify ? "" : "pointer"}}
                                    >
                                        Get Roles
                                    </button> */}

                                </div>

                                {organizationId && showRoles &&
                                    <div className="mt-2 py-1">
                                        <SelectDropdownMui
                                            optionsList={userRoleList}
                                            selectLabel={"User Role"}
                                            onChange={handleUserRole}
                                            value={userRole}
                                        />
                                    </div>
                                }


                            </>
                        }

                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="text"
                                label="Username*"
                                // value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            // placeholder="Username"
                            />
                        </div>



                        <div className="mt-2 py-1">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email*"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            // placeholder="Email"
                            />
                            {/* <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {emailerrors}
                            </p> */}
                        </div>


                        <div className="mt-2 py-1">
                            <InputBoxPasswordMui
                                fullWidth
                                label="Password*"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={(e) => handlePassword(e)}
                            // placeholder="Password"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {errors}
                            </p>
                        </div>
                        <div className="mt-2 py-1 row">
                            <div className="col-md-9">
                                <InputBoxMobile
                                    onChange={(e) => handleNumber(e)} // Ensure to pass the value
                                    handleCountryCode={handleCountry}
                                    value={mobileNo}
                                />
                                {/* {errorMessage && <div className="text-danger">{errorMessage}</div>} Display error message */}
                            </div>
                            <Button
                                variant="primary"
                                className="loginBtnVerify"
                                disabled={verifyDisable}
                                onClick={handleVerify}
                            >
                                Verify
                            </Button>
                            {errorMessage && <div className="text-danger" style={{margin: "15px 15px 0px 15px"}}>{errorMessage}</div>}
                        </div>

                        {isOtpShown &&
                            <>
                                <div className="mt-2 d-flex justify-content-center">
                                    <OtpInputBox value={otpValue} onChange={(e) => handleOTP(e)} />
                                </div>
                                {(!isOtpVerify && otpValue?.length == 6) && <div>
                                    {otpErrorMsg && <p className="errorMsg mt-1">{otpErrorMsg}</p>}
                                    {/* <p className="errorMsg mt-1">Please enter the correct OTP </p> */}
                                </div>
                                }
                            </>}

                        <div className="mt-2 d-flex">
                            {isResendButton && <Button className="otpresend p-0" onClick={handleVerify} disabled={isResendCodeDisable}>Resend Code</Button>}
                            {/* <a href="handleResend">Resend Code</a> */}
                            {isOtpShown && <p className="ml-5"> {isOtpShown && formatTime(timer)}</p>}
                        </div>

                        {(isOtpVerify) &&
                            <div className="d-flex mt-2">
                                <p className="verifyMsg">Phone Number Verified </p><img className="tickicon ml-2 mt-1" src={tick}></img>
                            </div>
                        }

                        <div className="">
                            <CheckboxMui
                                label="I agree with terms and conditions"
                                isChecked={isTermsAccepted}
                                onChange={(e) => handleAcceptTerms(e)}
                            />
                        </div>

                        <div className="text-center"
                        // style={{ marginTop: "2%" }}
                        >
                            <Button
                                variant="primary"
                                className="loginBtnRegister"
                                disabled={disable}
                                onClick={handleRegisterSubmit}
                            >
                                Sign up
                            </Button>
                        </div>

                        <div className="text-center mt-1">
                            <p
                                style={{
                                    // fontSize: "10px",
                                    fontSize: "0.9rem",
                                    lineHeight: "1.67",
                                    textAlign: "center",
                                    color: "#43425d",
                                }}
                            >
                                Already have an account?{" "}
                                <Link
                                    to="/login"
                                    className="signinLink"
                                    style={{ cursor: "pointer", fontSize: "0.9rem" }}
                                >
                                    Login
                                </Link>
                            </p>
                        </div>

                        {/* {role !== "Organization" &&
                            <>
                                <p className="hr-lines ">OR</p>
                                <div className="text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        href="https://api.mypoet360.com/api/auth/google"
                                        // href={`${apiUrl}/auth/google`}
                                    >
                                        <img
                                            className="smImg"
                                            src={googleSignin}
                                        />
                                        <a
                                            href="https://api.mypoet360.com/api/auth/google"
                                            // className="googlefblink"
                                        >Sign up with <strong>Google</strong></a>

                                    </Button>
                                </div>

                                <div className="mt-2 text-center">
                                    <Button
                                        variant="primary"
                                        className="smBtn"
                                        // href="https://api.mypoet360.com/api/auth/facebook"
                                        href={`${apiUrl}/auth/facebook`}
                                    >
                                        <img
                                            className="smImg"
                                            src={facebookSignin}
                                        />
                                        <a
                                            // href="https://www.professionaleditingtool.com/api/auth/facebook"
                                            // href="https://api.mypoet360.com/api/auth/facebook"
                                            className="googlefblink"
                                        >Sign up with <strong>Facebook</strong></a>

                                    </Button>
                                </div>
                            </>
                        } */}
                        <div className="text-center mt-2">
                            <Link to="/privacypolicy" target={"_blank"} className="privacyLink">
                                Term of use. Privacy policy
                            </Link>
                        </div>

                    </Card>
                </div>
            </div >
        </div >
    );
}
export default withRouter(SignUp);
