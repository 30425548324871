import React, { useState } from "react";
import Loader from "../utils/loader.js";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from 'crypto-js';
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import "./LoginForm.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions.js";
import { setUserFeatures } from "../redux/features/features.action.js";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import {
    Card,
    Button,
} from "react-bootstrap";
import Login1 from "../static/login-3.png";
import PetLogo from "../static/signup_login/PetLogo.png"
import OTPconfirmImage from "../static/signup_login/OTPconfirmImage.png"
import OtpInputBox from "./ReusableComponents/OtpInputBox.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";
import { sendOtpForLogin, verifyOtpForLogin } from "../services/authService.js";

function OTPVerification({ setCurrentUser, currentUser, setUserFeatures }) {
    const history = useHistory()
    const [loading, setLoading, showPassword] = useState(false);
    const location = useLocation()
    const loginData = location.state;
    const [email, setEmail] = useState(loginData.email)
    const [phoneNo, setPhoneNo] = useState(loginData.phone)
    const [otp, setOtp] = useState("")
    const [isVerifyOtp, setIsVerifyOtp] = useState(false)
    const [timer, setTimer] = useState(0);
    const [isResendOtp, setIsResendOtp] = useState(false)
    const [isConfirm, setIsConfirm] = useState()


    function handleBackButton() {
        history.push({
            pathname: `/login`,
        });
    }

    useEffect(() => {
        handleSendOtp()
    }, [])

    const handleSendOtp = async () => {
        console.log("handleOtpSend")
        setIsResendOtp(false)
        setIsVerifyOtp(false)
        setTimer(60)
        let payload = {
            "email": email
        }
        let otpresponse = await sendOtpForLogin(payload)
        console.log("otpresponse_", otpresponse)
        if (otpresponse.status == 200) {
            toast.success(otpresponse.message)
        } else {
            toast.warn("")
        }
    }

    const handleOtpConfirm = async () => {
        console.log("handleOtpSend")
        let otpPayload = {
            "email": email,
            "otp": otp
        }
        let otpVerifyResponse = await verifyOtpForLogin(otpPayload)
        console.log("otpVerifyResponse_", otpVerifyResponse)
        if (otpVerifyResponse.status == 200) {
            toast.success(otpVerifyResponse.message)
            history.push({
                pathname: `/dashboard`,
            });
            setIsVerifyOtp(false)
        } else {
            setIsVerifyOtp(true)
            return
        }
    }

    const handleOTP = async (e) => {
        console.log("otpdata_", e.length, e)
        setOtp(e)
        setIsVerifyOtp(false)
    }


    useEffect(() => {
        const timerId = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        if (timer == 0) {
            setIsResendOtp(true)
            setOtp("")
        } else {
            setIsResendOtp(false)
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [timer]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
                // position: "relative"
            }}
        >
             
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2">
                <Link to="/">
                    <img
                        className="petLogo"
                        alt="Group570"
                        src={PetLogo}
                    ></img>
                </Link>
            </div>

            <div
                className="row justify-content-center"
            >
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center text-center">
                    <Card
                        className="justify-content-center card-comp px-3 p-2"
                    >
                        <div className="d-flex justify-content-start p-2">
                            <button
                                onClick={handleBackButton}
                                style={{
                                    borderStyle: "none",
                                    borderRadius: "8px",
                                    // boxShadow: "2px 2px 5px -2px #000000",
                                    backgroundColor: "#ffffff",
                                    color: "#121212",
                                    fontFamily: "Poppins, sans-serif !important",
                                    fontWeight: "500",
                                    fontSize: "1.1rem",
                                    padding: "7px",
                                    // width: "150px",
                                }}
                            >
                                <KeyboardBackspaceRoundedIcon
                                    className="mr-3"
                                    fontSize="large"
                                    color="#121212"
                                />
                                <strong>Back</strong></button>
                        </div>
                        <div>
                            <img
                                style={{ width: "60%" }}
                                src={OTPconfirmImage}
                            />
                            <p
                                style={{
                                    fontFamily: "'Poppins', sans-serif !important",
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    color: "#121212",
                                    textAlign: "center"
                                }}
                            >
                                We have the following number on record for you.
                                <br />
                                We can send a code via SMS to authenticate you.
                                <br />
                                XXX - XXX - {phoneNo.substr(phoneNo.length - 4)}
                            </p>
                            <p
                                style={{
                                    fontFamily: "'Poppins', sans-serif !important",
                                    fontWeight: "600",
                                    fontSize: "0.9rem",
                                    color: "#121212",
                                    textAlign: "center"
                                }}
                            >
                                Enter the verification code below, or
                            </p>
                            {isResendOtp ? <button
                                style={{
                                    borderStyle: "none",
                                    borderRadius: "8px",
                                    backgroundColor: "transparent",
                                    color: "#0E73BE",
                                    fontFamily: "Poppins, sans-serif !important",
                                    fontWeight: "500",
                                    fontSize: "1.rem",
                                    padding: "7px",
                                    textDecorationLine: "underline",
                                }}
                                onClick={handleSendOtp}
                            >send a new code</button> :
                                <>
                                    <p>{formatTime(timer)}</p>
                                    <div className="d-flex justify-content-center p-3">
                                        <OtpInputBox
                                            value={otp}
                                            onChange={(e) => handleOTP(e)}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        {/* <div className="d-flex justify-content-center p-3">
                            <OtpInputBox
                                value={otp}
                                onChange={(e) => handleOTP(e)}
                            />
                        </div> */}
                        {isVerifyOtp && <div>
                            <p className="errorMsg py-2">Please enter the correct OTP </p>
                        </div>}

                        <div className="text-center">
                            <Button
                                variant="primary"
                                className="loginBtn"
                                onClick={handleOtpConfirm}
                                disabled={isConfirm}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Card>
                </div>
            </div >
        </div >
    );
}

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setUserFeatures: (features) => dispatch(setUserFeatures(features)),
});

export default connect(null, mapDispatchToProps)(withRouter(OTPVerification));
