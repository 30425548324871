import React, { Component } from "react";
import Loader from "../../utils/loader";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "../Editor/utilize";
import ReactHtmlParser from "react-html-parser";
import dateFn from "date-fn";
import StarRatings from "react-star-ratings";
import Rating from "react-rating";
import lastModified from "../../utils/lastModfied";
import { getPublicBlog } from "../../services/blogServices";
import { Button, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Group570 from "../../static/Group570.png";
import publicblog404 from "../../static/404publicblog.png";
import noBlog from "../../static/noBlog.png";
import HeadingB from "../Cards/HeadingB";
import SEO from "../SEO";
import seoServices from "../../services/seoServices";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import Defblog from "../../static/blogssdef.jpg";
import subscribedservices from "../../services/subscribeusersservices";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { camelCase, paramCase } from "change-case";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import publicBlogService from "../../services/publicBlogService";
// import Button from '@material-ui/core/Button';
import { getProducts }  from '../../services/productService';
import configdata from "../../services/config.json";

class BlogAccessForPublic extends Component {
  state = {
    loading: true,
    id: "",
    category: "",
    documentType: "",
    itemsToShow: 5,
    expanded: false,
    tags: [],
    comments: [],
    blogFound: false,
    url: "",
    description: "",
    title: "",
    isSEOBlogs: false,
    email: "",
    sidelistBlog: [],
    allBlogs: [],
    totalLength: null,
    currentIndex: null,
    disablePrevious: false,
    disableNext: false,
    keywords: "",
    postComment: "",
    postUsername: "",
    postUserEmail: "",
    publicComments: [],
    imageUrl: [],
    products: []
  };

  componentDidMount() {
    this.initialFunction();
    // this.getcomments();
  }
  initialFunction = async () => {
    let products = await getProducts();
    this.setState({ products: products.data.products });

    if (this.props.match.params.id) {
      let param = this.props.match.params.id;
      let arr = param.split("-");
      let id = arr[arr.length - 1];
      this.setState({ documentType: "blog", id: id }, () => {
        this.getBlogById(id);
      });
    } else {
      if (this.props.location.state) {
        let {
          _id: id,
          title,
          metaDescription,
          urlValue,
          onlyDocumentContent,
          tags,
          documentName,
          metaKeywords,
          category,
          imageUrl
        } = this.props.location.state.detail;
        if (id) {
          this.setState(
            {
              category: category,
              id: id,
              tags: tags ? (tags.length > 0 ? tags : []) : [],
              title,
              documentName,
              description: metaDescription,
              url: `https://www.professionaleditingtool.com/${urlValue}`,
              isSEOBlogs: true,
              loading: false,
              blogFound: true,
              keywords: metaKeywords,
              imageUrl
            },
            () => {
              this.convertToHtml(onlyDocumentContent);
              this.getcomments();
            }
          );
        }
      } else {
        this.getBlogByUrlValue(this.props.match.params.name);
      }
    }
    this.sideListBlogs();
    // this.getcomments();
  };
  sideListBlogs = async () => {
    if(this.props.location.state){
      let allBlogs = this.props.location.state.blogs;
      if(allBlogs){
        let all = [...allBlogs];
      let viewedBlog = this.props.location.state.detail;
      let index = allBlogs.indexOf(viewedBlog);
      let totalLength = all.length;
      let Array = all.splice(index, 1);
      let sideBlogs = allBlogs;
      this.setState({
        sidelistBlog: sideBlogs,
        allBlogs: allBlogs,
        totalLength: totalLength,
        currentIndex: index,
      });
      }
    }else{
      let { data: SEOBlog } = await seoServices.getSEOBlogsByCategoryWise(
        10,
        1,
        'blog'
      );
      SEOBlog = SEOBlog.filter(blog => blog._id !== this.state.id);
      this.setState({sidelistBlog: SEOBlog})
    }
  };

  viewBlog = async (data) => {
    this.props.history.push(
      {
        pathname: `/${paramCase(data.urlValue)}`,
        state: { detail: data, blogs: this.state.allBlogs },
      },
      this.setState(
        {
          id: data._id,
          documentName: data.documentName,
          tags: data.tags ? (data.tags.length > 0 ? data.tags : []) : [],
          onlyDocumentContent: data.onlyDocumentContent,
          date: data.date,
          blogFound: true,
          keywords: data.metaKeywords,
          description: data.metaDescription,
          imageUrl: data.imageUrl,
          category: "blog"
        },
        () => {
          this.convertToHtml(this.state.onlyDocumentContent);
          this.getcomments();
        }
      )
    );
  };

  getBlogByUrlValue = async (name) => {
    let response = await seoServices.getSEOBlogByUrlValue(name);
    if (response.status === 200) {
      let {
        _id: id,
        title,
        metaDescription,
        urlValue,
        onlyDocumentContent,
        tags,
        documentName,
        metaKeywords,
        category,
        imageUrl
      } = response.data;
      this.setState(
        {
          id: id,
          tags: tags ? (tags.length > 0 ? tags : []) : [],
          title,
          documentName,
          description: metaDescription,
          url: `https://www.professionaleditingtool.com/${urlValue}`,
          isSEOBlogs: true,
          loading: false,
          blogFound: true,
          keywords: metaKeywords,
          category,
          imageUrl
        },
        () => {
          this.convertToHtml(onlyDocumentContent);
          this.getcomments();
        }
      );
    }else {
      this.setState({ blogFound: false, loading: false, isSEOBlogs: true });
    }
  };
  getBlogById = async (id) => {
    let data = await getPublicBlog(id);
    this.setState({ loading: false });

    if (data.status === 200) {
      const {
        documentName,
        tags,
        onlyDocumentContent,
        comments,
        ratings,
        totalRatings,
        date,
        category,
        imageUrl
      } = data.data;
      const { username, display_picture } = data.data.editor;
      this.setState(
        {
          documentName,
          tags: tags ? (tags.length > 0 ? tags : []) : [],
          onlyDocumentContent,
          comments,
          ratings,
          totalRatings,
          date,
          username,
          display_picture,
          blogFound: true,
          category,
          imageUrl,
        },
        () => {
          this.convertToHtml(onlyDocumentContent);
          this.getcomments();
        }
      );
    } else {
      this.setState({ blogFound: false });
    }
  };

  convertToHtml = (data) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    // let viewedBlog = this.props.location.state.detail;
    editorContentHtml = editorContentHtml.replace(
      /<img[^>]*>/,
      `<img style="width:100%;" src=${configdata.imgUrl}/${this.state.imageUrl[0]}" />`
    );
    let htmlContent = ReactHtmlParser(editorContentHtml);
    this.setState({ htmlContent });
  };

  showMore = () => {
    this.state.itemsToShow === 5
      ? this.setState({
          itemsToShow: this.state.tags
            ? this.state.tags.length > 0
              ? this.state.tags.length
              : 0
            : 0,
          expanded: true,
        })
      : this.setState({ itemsToShow: 5, expanded: false });
  };

  subscribe = async () => {
    let data = {
      email: this.state.email,
    };
    let response = await subscribedservices.blogSubscribedUsers(data);

    if (response.status !== 200) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
    }
    this.setState({ email: "" }); // clear input field after submission
  };

  onChangeData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handlePreviousBlog = () => {
    let allBlogs = this.props.location.state.blogs;
    let all = [...allBlogs];
    let viewedBlog = this.props.location.state.detail;
    let index = all.indexOf(viewedBlog);
    if (index !== 0) {
      let array = all.splice(index - 1, 1);
      this.props.history.push(
        {
          pathname: `/${paramCase(array[0].urlValue)}`,
          state: { detail: array[0], blogs: this.state.allBlogs },
        },
        this.setState(
          {
            id: array[0]._id,
            documentName: array[0].documentName,
            tags: array[0].tags
              ? array[0].tags.length > 0
                ? array[0].tags
                : []
              : [],
            onlyDocumentContent: array[0].onlyDocumentContent,
            date: array[0].date,
            blogFound: true,
            disablePrevious: false,
            disableNext: false,
            keywords: array[0].metaKeywords,
            description: array[0].metaDescription,
          },
          () => {
            this.convertToHtml(this.state.onlyDocumentContent);
            this.getcomments();
          }
        )
      );
    } else if (index === 0) {
      this.setState({
        disablePrevious: true,
      });
    }
  };
  handleNextBlog = () => {
    let allBlogs = this.props.location.state.blogs;
    let all = [...allBlogs];
    let viewedBlog = this.props.location.state.detail;
    let index = all.indexOf(viewedBlog);
    if (index !== all.length - 1) {
      let array = all.splice(index + 1, 1);
      this.props.history.push(
        {
          pathname: `/${paramCase(array[0].urlValue)}`,
          state: { detail: array[0], blogs: this.state.allBlogs },
        },
        this.setState(
          {
            id: array[0]._id,
            documentName: array[0].documentName,
            tags: array[0].tags
              ? array[0].tags.length > 0
                ? array[0].tags
                : []
              : [],
            onlyDocumentContent: array[0].onlyDocumentContent,
            date: array[0].date,
            blogFound: true,
            disableNext: false,
            disablePrevious: false,
            keywords: array[0].metaKeywords,
            description: array[0].metaDescription,
          },
          () => {
            this.convertToHtml(this.state.onlyDocumentContent);
            this.getcomments();
          }
        )
      );
    } else if (index === this.state.totalLength - 1) {
      this.setState({
        disableNext: true,
      });
    }
  };

  postComment = async () => {
    let { postComment, postUsername, postUserEmail } = this.state;
    if (postComment === "" || postUsername === "" || postUserEmail === "") {
      toast.warn("Please enter required field to post comment");
      return;
    }

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let test = regex.test(String(postUserEmail).toLowerCase());
    if (!test) {
      toast.warn("Please enter valid email address");
      return;
    }

    this.setState({ loading: true });

    const commentData = {
      comment: postComment,
      blogId: this.state.id,
      rating: 0,
      userName: postUsername,
      userEmail: postUserEmail,
      userDP: "",
    };
    let response = await publicBlogService.postPublicBlogComment(commentData);
    if (response.status === 200) {
      toast.success(
        "Comment added on blog. It will be displayed after admin approves it!"
      );
      this.getcomments();
      this.setState({
        loading: false,
        postComment: "",
        postUsername: "",
        postUserEmail: "",
      });
    } else {
      toast.error("Oops something went wrong");
    }
  };

  getcomments = async () => {
      let response = await publicBlogService.getPublicBlogComment(this.state.id);
      if (response.status === 200) {
        this.setState({
          publicComments: response.data,
        });
      } else toast.error(response.message);
  };

  handleAboutRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#about`;
    } else {
      window.location.href = "https://www.professionaleditingtool.com/#about";
    }
  };

  handleFeaturesRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#features`;
    } else {
      window.location.href =
        "https://www.professionaleditingtool.com/#features";
    }
  };

  handlePricingRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#pricing`;
    } else {
      window.location.href = "https://www.professionaleditingtool.com/#pricing";
    }
  };

  handleContactRoute = () => {
    if (process.env.NODE_ENV !== "production") {
      window.location.href = `${configdata.frontEndUrl}/#contacts`;
    } else {
      window.location.href =
        "https://www.professionaleditingtool.com/#contacts";
    }
  };

  handleProductBlog = async (category) => {
    const { history } = this.props
    const product = {
      main_category: "products",
      category: category
    }
    const { data, status } = await seoServices.getProductSEOBlog(product);

    if(status === 200){
      history.push(
        {
          pathname: `/${paramCase(data.urlValue)}`,
          state: { detail: data, blogs: this.state.allBlogs },
        },
        this.setState(
          {
            id: data._id,
            documentName: data.documentName,
            tags: data.tags ? (data.tags.length > 0 ? data.tags : []) : [],
            onlyDocumentContent: data.onlyDocumentContent,
            date: data.date,
            blogFound: true,
            keywords: data.metaKeywords,
            description: data.metaDescription,
            imageUrl: data.imageUrl,
            category: "products"
          },
          () => {
            this.convertToHtml(this.state.onlyDocumentContent);
            this.getcomments();
          }
        )
      );
    }else {
      this.setState({ blogFound: false });
    }
  }

  render() {
    let {
      documentName,
      tags,
      comments,
      ratings,
      totalRatings,
      date,
      username,
      display_picture,
      htmlContent,
      blogFound,
      isSEOBlogs,
      title,
      url,
      description,
      sidelistBlog,
      disablePrevious,
      disableNext,
      keywords,
      publicComments,
    } = this.state;
    const today = new Date();

    return (
      <div>
        <ToastContainer />
        <Loader loading={this.state.loading} />
        <SEO
          description={description}
          title={title}
          url={url}
          keywords={keywords}
        />
        <Navbar
          expand="lg"
          fixed="top"
          style={{
            backgroundColor: "#1976d2",
            paddingLeft: "7%",
          }}
        >
          <Navbar.Brand style={{ color: "white" }} href="/">
            <img
              className="petLogo"
              alt="Group570"
              style={{ width: "auto", height: "60px" }}
              src={Group570}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navLinks ml-auto" style={{ marginRight: "20%" }}>
              <Nav.Link
                className="loginLink"
                style={{ color: "white", marginLeft: "15%" }}
              >
                <NavLink className="text-white ft_md" to={{ pathname: "/" }}>
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link className="ft_md text-white">
                <span
                  className="text-white ft_md"
                  onClick={this.handleAboutRoute}
                >
                  About
                </span>
              </Nav.Link>
              <Nav.Link className="ft_md text-white">
                <span
                  className="text-white ft_md"
                  onClick={this.handleFeaturesRoute}
                >
                  Features
                </span>
              </Nav.Link>
              <Nav.Link className="ft_md text-white">
                <span
                  className="text-white ft_md"
                  onClick={this.handlePricingRoute}
                >
                  Pricing
                </span>
              </Nav.Link>
              <Nav.Link className="ft_md text-white">
                <span
                  className="text-white ft_md"
                  onClick={this.handleContactRoute}
                >
                  Contact
                </span>
              </Nav.Link>
              <Nav.Link className="ft_md text-white">
                <NavLink
                  className="ft_md text-white"
                  to={{ pathname: "/blog" }}
                >
                  Blogs
                </NavLink>
              </Nav.Link>
              <NavDropdown title="Products" id="basic-nav-dropdown">
                { this.state.products.map(product => (
                  <NavDropdown.Item onClick={() => this.handleProductBlog(product)}>
                    {  (product.charAt(0).toUpperCase() + product.slice(1)).replace(/_/g, ' ') }
                  </NavDropdown.Item>
                )) }
              </NavDropdown>

              <Nav.Link
                className="loginLink"
                style={{ color: "white", marginLeft: "5%" }}
              >
                <NavLink
                  className="text-white ft_md"
                  to={{ pathname: "/login" }}
                >
                  Login
                </NavLink>
              </Nav.Link>
              &nbsp;&nbsp;
              <Link to={{ pathname: "/signup" }}>
                <Button
                  className="ft_md"
                  style={{
                    textAlign: "center",
                    color: "#4085f6",
                    borderRadius: "20px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    backgroundColor: "white",
                  }}
                >
                  SignUp
                </Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div className="row pt-5 py-3">
          <div className="col-md-8">
            {blogFound ? (
              <div className="container-fluid" style={{ marginTop: "7%" }}>
                <div id="mainPreview" className="mt-5">
                  {!isSEOBlogs && (
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="d-flex align-items-center">
                          <img
                            className="docImg"
                            src={display_picture}
                            alt="dp"
                          />

                          <p className="ml-3 mb-0">
                            {username}
                            <br />
                            <span className="ft_xs text-e">
                              {dateFn.date(date, 110)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isSEOBlogs && <hr />}

                  <div className="row">
                    <div className={!isSEOBlogs ? "col-sm-5" : "col-sm-12"}>
                      <div className="d-flex align-items-center">
                        <h1 className="ft_28 mb-2"> {documentName} </h1>
                      </div>
                    </div>

                    {!isSEOBlogs && (
                      <div className="col-sm-7 text-right rateCol">
                        <StarRatings
                          rating={ratings || 0}
                          starRatedColor="orange"
                          starDimension="15px"
                          starSpacing="0px"
                          numberOfStars={5}
                          name="rating"
                          style={{ paddingBottom: "2%" }}
                        />

                        <div>
                          <span
                            className="ft_sm mt-2"
                            style={{ opacity: "0.5" }}
                          >
                            {totalRatings} ratings
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row pl-3">
                    {tags && tags.length > 0 &&
                      tags.slice(0, this.state.itemsToShow).map((tag) => (
                        <button
                          key={tag}
                          type="button"
                          className="border ft_xs mr-2 p-1"
                          style={{
                            backgroundColor: "#f1f1f1",
                            borderRadius: "4px",
                            color: "#757575",
                          }}
                        >
                          {tag}
                        </button>
                      ))}
                    {tags.length > 5 && (
                      <a
                        className="ft_xs"
                        onClick={this.showMore}
                        style={{
                          cursor: "pointer",
                          color: "#2196f3",
                          textDecoration: "underline",
                          marginTop: "1%",
                        }}
                      >
                        {this.state.expanded ? (
                          <span>Show less</span>
                        ) : (
                          <span>Show more</span>
                        )}
                      </a>
                    )}
                  </div>

                  <br />
                  {/* <div className="d-flex justify-content-between align-items-start mb-5"> */}
                  <div className="mb-5">
                    <p className="text-left"> {htmlContent} </p>
                  </div>
                  <hr />
                  <div
                    className="d-flex align-items-center"
                    // style={{ paddingLeft: "2%" }}
                  >
                    <div className="d-flex mx-2">
                      <FacebookShareButton
                        url={url}
                        hashtag={`#ProfessionalEditingTool`}
                        quote={documentName}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </div>
                    <div className="d-flex mx-2">
                      <TwitterShareButton
                        url={url}
                        title={documentName}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div>
                    <div className="d-flex mx-2">
                      <LinkedinShareButton
                        url={url}
                        title={documentName}
                        summary={description}
                        className="Demo__some-network__share-button"
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </div>
                  </div>
                  <br />
                  <br />

                  {sidelistBlog.length > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="ft-22 mb-3">
                        <u>You might also like</u>
                      </h3>
                      <div className="row">
                        {sidelistBlog.slice(-3).map((blog) => (
                          <div className=" col-md-4 mb-2 text-center">
                            <div className="p-2">
                              {blog.imageUrl && blog.imageUrl.length > 0 ? (
                                <img
                                  src={
                                    `${configdata.imgUrl}/` +
                                    blog.imageUrl[0]
                                  }
                                  onClick={() => this.viewBlog(blog)}
                                  alt="BlogImage"
                                  style={{
                                    width: "33ch",
                                    height: "20vh",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <img
                                  src={Defblog}
                                  onClick={() => this.viewBlog(blog)}
                                  alt="BlogImage"
                                  style={{ width: "100px", cursor: "pointer" }}
                                />
                              )}
                            </div>

                            <div className="p-1">
                              <h5
                                // id="blogtitle"
                                style={{ fontSize: "18px", cursor: "pointer" }}
                                onClick={() => this.viewBlog(blog)}
                              >
                                {blog.documentName}
                              </h5>

                              <p>
                                <small className="text-muted">
                                  {lastModified(today, new Date(blog.date))}
                                </small>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  )}
                  <br />
                  <br />
                  
                  { this.state.category === "blog" && (
                  <div
                    className="container"
                    style={{ border: "1px solid rgba(0,0,0,.1)" }}
                  >
                    <div className="row px-3 mt-3">
                      <div className="col-md-12">
                        {" "}
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label="Enter your Comment"
                          name="postComment"
                          value={this.state.postComment}
                          onChange={(e) =>
                            this.setState({
                              postComment: e.target.value,
                            })
                          }
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="row mt-4 mb-3 ml-2">
                      <div className="col-md-4">
                        {/* <AccountBoxIcon className="" style={{height:"3vh"}}/> */}
                        <h3>
                          <i
                            className="fa fa-user-circle fa-2x pr-3 pt-2"
                            aria-hidden="true"
                          ></i>
                          Comment as:
                        </h3>
                      </div>

                      <div className="col-md-3">
                        <TextField
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          name="postUsername"
                          value={this.state.postUsername}
                          onChange={(e) =>
                            this.setState({
                              postUsername: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          name="postUserEmail"
                          value={this.state.postUserEmail}
                          onChange={(e) =>
                            this.setState({
                              postUserEmail: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <button
                          type="button"
                          onClick={this.postComment}
                          class="btn btn-danger"
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                  )}

                  {/* comments */}
                  <div className="mt-5 mb-5">
                    {publicComments.map((com, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between pt-3 ml-5"
                      >
                        <div className="d-flex">
                          <img
                            src={
                              com.userDP
                                ? com.userDP
                                : "https://img.icons8.com/bubbles/100/000000/edit-user.png"
                            }
                            alt=""
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                          />

                          <div className="mx-3 ft_xs">
                            <p className="m-0" style={{ fontWeight: "900" }}>
                              {com.userName}
                              <small className="ml-2">
                                {lastModified(today, new Date(com.date))}
                              </small>
                            </p>

                            <p className="ft_sm "> {com.comment} </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* *********** */}
                  {isSEOBlogs &&
                    comments.map((obj) => (
                      <div
                        key={obj._id}
                        className="d-flex justify-content-between pb-4"
                      >
                        <div className="d-flex">
                          <img
                            src={obj.postedBy.display_picture}
                            alt="dp"
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                          />

                          <div className="mx-3 ft_xs">
                            <p className="m-0" style={{ fontWeight: "900" }}>
                              {obj.postedBy.username}
                              <small className="ml-2">
                                {lastModified(today, new Date(obj.date))}
                              </small>
                            </p>

                            <Rating
                              initialRating={obj.rating}
                              emptySymbol={
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                              }
                              fullSymbol={
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              }
                              style={{ color: "orange" }}
                              readonly
                            />
                            <br />
                            <p className="ft_sm "> {obj.comment} </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="text-center" style={{ marginTop: "10%" }}>
                <img className="img-fluid" src={noBlog} alt="404blog" />
                <HeadingB className="alertHead" text="No Such Blog" />
              </div>
            )}
          </div>

          <div className="col-md-4 pr-5 pl-5">
            <div
              className="container-fluid text-center py-3"
              style={{ backgroundColor: "#3C589A", marginTop: "15%" }}
            >
              <div className="text-white mx-3">
                <h3 className="ft-20 mb-4"> Subcribe!</h3>
              </div>

              <div>
                <input
                  id="newsInput"
                  className="border-0 w-75 p-3"
                  placeholder="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeData}
                  required
                ></input>
                <br />
                <br />
                <button
                  type="button"
                  className="btn btn-primary px-5"
                  id="msgBtn"
                  onClick={this.subscribe}
                >
                  Submit
                </button>
              </div>
            </div>

            {sidelistBlog.length > 0 && (
            <div className="mt-5">
              <h5 className="ft-20 mb-3"> Recent Posts</h5>

              <div className="mb-4">
                {sidelistBlog.slice(0, 4).map((blog) => (
                  <div className="d-flex mb-2">
                    <div className="p-2">
                      {blog.imageUrl && blog.imageUrl.length > 0 ? (
                        <img
                          src={
                            `${configdata.imgUrl}/` +
                            blog.imageUrl[0]
                          }
                          onClick={() => this.viewBlog(blog)}
                          alt="BlogImage"
                          style={{
                            width: "100px",
                            height: "70px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <img
                          src={Defblog}
                          onClick={() => this.viewBlog(blog)}
                          alt="BlogImage"
                          style={{ width: "100px", cursor: "pointer" }}
                        />
                      )}
                    </div>

                    <div className="p-2 flex-grow-1">
                      <h5
                        // id="blogtitle"
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        onClick={() => this.viewBlog(blog)}
                      >
                        {blog.documentName}
                      </h5>

                      <p>
                        <small className="text-muted">
                          {lastModified(today, new Date(blog.date))}
                        </small>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const linkStyle = {
  float: "left",
  color: "white",
  padding: "14px 16px",
};

const btnStyle = {
  backgroundColor: "white",
  border: "none",
  color: "black",
  padding: "3px 16px",
  textAlign: "center",
  display: "inline-block",
  margin: "11px 9px",
  borderRadius: "12px",
};
export default withRouter(BlogAccessForPublic);
