import React, { useEffect, useState } from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import "./PricePlans/plans.css";
import BasicPlan from "./PricePlans/BasicPlan";
import BreadCrumb from "./Cards/BreadCrumbs";
import { getAllPlan } from "../services/plans"
import subscription from "../services/subscription";

export default function PriceDetail({breadCrumb}) {
  const [planList, setplanList] = useState([]);
  const user = localStorage.getItem("Role")
  useEffect(() => {
    // onLoad();
    // initalCall();
    getSubscriptions();
  }, [user]);


  const getSubscriptions = async () => {
    try {
      // setLoading(true)
      const response = await subscription.getSubscriptions();
      if (response.status === 200) {
        const allSubscriptions = response?.data;
 
        // Separate subscriptions by userType
        const organizationPlans = allSubscriptions.filter(
          (plan) => plan.userType === user
        );
        const plans = organizationPlans;
        console.log("plans===>",plans);

        if (plans.length >= 3) {
          const temp = plans[1];
          plans[1] = plans[2];
          plans[2] = temp;
      }
      
        let html = plans.map((plan) => {
          const content = unOrderList(plan.billingCycle);
          const planName = plan?.name;
          const nameOfTerms = plan?.billingCycle;
          const planMode = { mode: plan?.planMode };
          const termDetails = convertToObj(plan?.billingCycle);
          const plan_id = plan?._id;
          const defaultPlan=plan?.isDefault;
          return (
            <BasicPlan
            heading={planName}
            ul={content}
            btn_list={nameOfTerms}
            price_detail={termDetails}
            plan_mode={planMode}
            plan_id={plan_id}
            defaultPlan={defaultPlan}
            />
          );
        });
        setplanList(html);
        // setLoader(false);
      }
    } catch (error) {
      console.log(error, "Error while fetching subscriptions");
    }finally{
      // setLoading(false)
    }
  };

  const initalCall = async() =>{
    const data = await getAllPlan();
    if(data.status !== 200)
      return
    
    const plans = data.plan;
    let html = plans.map((plan) =>{
      const content = unOrderList(plan.content);
      const planName = plan.planName;
      const nameOfTerms = plan.nameOfTerms;
      const planMode = { mode: plan.planMode };
      const termDetails = convertToObj(plan.termDetails);
      return (
        <BasicPlan
          heading={planName}
          ul={content}
          btn_list={nameOfTerms}
          price_detail={termDetails}
          plan_mode={planMode}
          hide ={false}
        />
      );
    });
    setplanList(html);
  }

  // const convertToObj = (termDetails) =>{
  //   let termObj = termDetails.reduce((newObj, obj) =>{
  //     const {term, price, user_count} = obj;
  //     if(!newObj[obj.term]){
  //       newObj = {...newObj,  [term]:[] }
  //     }
  //     let term_arr = newObj[term];
  //     term_arr.push({price, user_count})
  //     return newObj
  // },{})

  // return termObj;
  // }

  const convertToObj = (termDetails) => {
    const termObj = termDetails.reduce((newObj, obj) => {
      const { duration: term, price, totalUsers, userCounts } = obj;
  
      // Skip entries with missing term (duration)
      if (!term) {
        console.warn("Encountered an item with an undefined term:", obj);
        return newObj;
      }
  
      // Initialize the term key if it doesn't exist
      if (!newObj[term]) {
        newObj[term] = [];
      }
  
      // Push the relevant data to the term's array
      newObj[term].push({ price, totalUsers, userCounts });
  
      return newObj;
    }, {});
  
    console.log(termObj, "termObj");
    return termObj;
  };


  // const unOrderList = contentObj => {
  //   if (contentObj) {
  //     const ul = Object.keys(contentObj).map(key => {
  //       let get_data = contentObj[key];
  //       if (typeof get_data == "object") {
  //         const lh = <lh>{key}</lh>;
  //         const li = get_data.map(text => <li>{text}</li>);
  //         return (
  //           <ul>
  //             {" "}
  //             {lh} {li}{" "}
  //           </ul>
  //         );
  //       }
  //     });
  //     return ul;
  //   }
  // };

  // const unOrderList = (contentObj) => {
  //   console.log(contentObj,"contentObj")
  //   if (contentObj) {
  //     const ul = contentObj.map((cycle, index) => {
  //       // Extract necessary properties
  //       const { duration, price, totalUsers, userCounts } = cycle;
  
  //       // Construct the subscription text
  //       const subscriptionText = `${duration.charAt(0).toUpperCase() + duration.slice(1)} subscription: $${price}/${duration}, Total Users: ${totalUsers}`;
  
  //       // Construct userCounts text
  //       const userCountText = `Users: Admin - ${userCounts?.admin}, Power Users - ${userCounts?.powerUser}, Regular Users - ${userCounts?.regularUser}`;
  //       console.log(subscriptionText,userCountText,"userCountText")
  //       return (
  //         <li key={index}>
  //           <div>{subscriptionText}</div>
  //           <div>{userCountText}</div>
  //         </li>
  //       );
  //     });
  //     console.log(ul,"ullll")
  //     return (
  //       <ul>
  //         <lh>Subscription Details</lh>
  //         {ul}
  //       </ul>
  //     );
  //   }
  
  //   return null;
  // };
  const unOrderList = (contentObj) => {
    console.log(contentObj, "contentObj");
    
    if (contentObj) {
      const ul = contentObj.map((cycle, index) => {
        // Extract necessary properties
        const { duration, price, totalUsers, userCounts } = cycle;
  
        // Initialize the subscription and user count text
        let subscriptionText = null;
        let userCountText = null;
  
        // Construct the subscription text only if necessary fields are present
        if (duration && price !== undefined && totalUsers !== undefined) {
          subscriptionText = `${duration.charAt(0).toUpperCase() + duration.slice(1)} subscription: $${price}/${duration}, Total Users: ${totalUsers}`;
        }
  
        // Construct the user counts text only if userCounts is available
        if (userCounts) {
          const { admin, powerUser, regularUser } = userCounts;
          if (admin !== undefined || powerUser !== undefined || regularUser !== undefined) {
            userCountText = `Users: Admin - ${admin || 0}, Power Users - ${powerUser || 0}, Regular Users - ${regularUser || 0}`;
          }
        }
  
        // If neither subscriptionText nor userCountText is available, skip rendering this item
        if (!subscriptionText && !userCountText) return null;
  
        return (
          <li key={index}>
            {subscriptionText && <div>{subscriptionText}</div>}
            {userCountText && <div>{userCountText}</div>}
          </li>
        );
      }).filter(Boolean); // Filter out null values
  
      if (ul.length === 0) {
        // Return null if no valid data exists
        return null;
      }
  
      return (
        <ul>
          <lh>Subscription Details</lh>
          {ul}
        </ul>
      );
    }
  
    return null;
  };
  
  return (
    <div>
      {breadCrumb && <BreadCrumb
        l1="Dashboard"
        l2="Subcriptions"
        l3="pricedetails"
        dashboardlink="admindashboard"
        documentLink="pricing"
      />}
      <div className="container plans">
        <ScrollUpButton />
        <div className="d-flex justify-content-center">
          <h2 className="text-center text-dark w-50 my-4">
            Get Instant Access to the Professional Editing Tool Choose Your Plan
            and Get Started
          </h2>
        </div>

        {planList}
      </div>
    </div>
  );
}
