import http from "./httpService";
// import {
//   apiUrl,
//   herokuUrl
// } from "./config.json";
import configdata from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = configdata.apiUrl + "/feedback";
} else {
  apiEndpoint = configdata.herokuUrl + "/feedback";
}

export async function addRating(data) {
  const response = await http.post(`${apiEndpoint}/addRating`, data);
  console.log(response);
  return response;
}

export async function addComments(data) {
  const response = await http.post(`${apiEndpoint}/addCommentsNew`, data);
  console.log(response);
  return response;
}

export async function addBlogComments(data) {
  const response = await http.post(`${apiEndpoint}/addBlogComments`, data);
  return response;
}

export async function addAdminComments(data) {
  const response = await http.post(`${apiEndpoint}/addAdminComments`, data);
  console.log(response);
  return response;
}

export async function getCommentsForDocs(_id, type) {
  const data = await http.post(`${apiEndpoint}/getCommentsForDocs`, {
    _id,
    type
  });
  return data;
}

export async function getComments() {
  const data = await http.get(`${apiEndpoint}/getComments`);
  console.log(data);
  return data;
}

export default {
  //export all the above mentioned api call functions here
  addRating,
  addComments,
  getCommentsForDocs,
  getComments,
  addAdminComments,
  addBlogComments
};