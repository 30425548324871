import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Card,
} from "@material-ui/core";
// import Button from "./ReusableComponents/Button";
import Loader from "../utils/loader";
import { Editor, EditorState, ContentState, RichUtils } from "draft-js";
import styled from "styled-components";
import { Text_Align, styleMap } from "./Editor/utilize";
import RedoIcon from "@mui/icons-material/Redo";
import { InlineBold, InlineStyleControls, InlineSuperscript, InlineUnderline, OLStyleControls, ULStyleControls } from "./Editor/StyleButton";
import UndoIcon from "@mui/icons-material/Undo";
import TitleIcon from "@mui/icons-material/Title";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import ImageIcon from "@mui/icons-material/Image";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SegmentIcon from "@mui/icons-material/Segment";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import "draft-js/dist/Draft.css";
import GrammarCheckAssistant from "./ReusableComponents/GrammarCheckAssistant";
import { CoAuthor_Aireviewcomponent } from "./Forms/FormComponents/CoAuthor_Aireview";
import AIReview_Modal from "./MyDocumentsAIRModal";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MicIcon from "@mui/icons-material/Mic";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { InlineItalic } from "./Editor/StyleButton";
import {
  SuggestionCards,
  RewriteCard,
  StatisticalFactCard,
  ExplainCard,
} from "./MyDocumentsNewProjectCards";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast, ToastContainer } from "react-toastify";
import createUndoPlugin from "draft-js-undo-plugin";
import myDocumentsNewProjectServices from "../services/myDocumentsNewProjectServices";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import "./MyDocumentsNewProject.css";
import { InlineAlign, InlineSize } from "./Editor/SelectItem";
import { checkPhrasing } from "../services/englishCheckers";
import { useSelector } from "react-redux";
import { addUserLogs } from "../services/userLogServices";

const MyDocumentsNewProject = () => {
  const userDetails = useSelector((user) => user)
const userID = userDetails.user.currentUser._id
  const [titleEditorState, setTitleEditorState] = useState(
    EditorState.createEmpty()
  );
  const [contentEditorState, setContentEditorState] = useState(
    EditorState.createEmpty()
  );
  const [customText, setCustomText] = useState("Custom")
  const [contentValue, setContentValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [rewrittenText, setRewrittentext] = useState("");
  const [statisticalData, setStatisticalData] = useState("");
  const [explanationText, setExplanationText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAIReviewOpen, setIsAIReviewOpen] = useState(false);
  const [showHintIcon, setShowHintIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTone, setSelectedTone] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [rewriteCard, setRewriteCard] = useState(false);
  const [statisticalFactCard, setStatisticalFactCard] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [maxCountDisplayed, setMaxCountDisplayed] = useState(false);
  const [transcript, resetTranscript] = useState();
//   const { transcript, resetTranscript } = useSpeechRecognition();
  const [inputKey, setInputKey] = useState();
  const [suggestionText, setSuggestionText] = useState("Custom")
  const [explainCard, setExplainCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [professionList, setProfessionList] = useState([
    {
      value: "",
      name: "Select",
    },
    {
      value: "Content Writer",
      name: "Content Writer",
    },
    {
      value: "Business Owner",
      name: "Business Owner",
    },
    {
      value: "HR Professional",
      name: "HR Professional",
    },
    {
      value: "Marketing",
      name: "Marketing",
    },
    {
      value: "Product Manager",
      name: "Product Manager",
    },
    {
      value: "Medical Professional",
      name: "Medical Professional",
    },
    {
      value: "Legal Professional",
      name: "Legal Professional",
    },
  ]);


  const [toneList, setToneList] = useState([
    {
      "value": "standard",
      "name": "Standard"
    },
    {
      "value": "formal",
      "name": "Formal"
    },
    {
      "value": "casual",
      "name": "Casual"
    },
    {
      "value": "positive",
      "name": "Positive"
    },
    {
      "value": "confident",
      "name": "Confident"
    },
    {
      "value": "friendly",
      "name": "Friendly"
    },
    {
      "value": "simple",
      "name": "Simple"
    },
    {
      "value": "shorten",
      "name": "Shorten"
 
    },
    {
      "value": "expand",
      "name": "Expand"
 
    },
 
  ]);

  const SuggestionList=
  [
    {
      "value": "Like a Story",
      "name": "Like a Story"
    },
    {
      "value": "Like a Poem",
      "name": "Like a Poem"
    },
    {
      "value": "Like a Ceo",
      "name": "Like a Ceo"
    },
    {
      "value": "Like a Doctor",
      "name": "Like a Doctor"
    },
    {
      "value": "Like a News",
      "name": "Like a News"
    },
    {
      "value": "Like a Sales Executive",
      "name": "Like a Sales Executive"
    },
    {
      "value": "Like a Letter",
      "name": "Like a Letter"
    },
    {
      "value": "Custom",
      "name": "Custom"

    }
 
  ];


  const [selectedProfession, setSelectedProfession] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");

  // Undo redo Functionality
  const undoRedoPlugin = createUndoPlugin();
  const { UndoButton, RedoButton } = undoRedoPlugin;
  const undoredoPlugin = [undoRedoPlugin];

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleRewriteClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getRewriteText(text);
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setRewrittentext(response.rewrittenText.output[0]);
        setRewriteCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const handleStatisticalDataClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getStatisticalData(
        text
      );
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setStatisticalData(response.statisticalData.output[0]);
        setStatisticalFactCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const handleExplainClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getExplanation(text);
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setExplanationText(response.Explaination.output[0]);
        setExplainCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleMicClick = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      if (isRecording) {
        SpeechRecognition.stopListening();
      } else {
        SpeechRecognition.startListening({ continuous: true });
      }
      setIsRecording(!isRecording);
    } else {
      toast.error("Speech recognition is not supported by your browser.");
    }
  };

  function handleCopyFormain() {
    let sentence = contentEditorState.getCurrentContent().getPlainText();
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleWordCountExceed = () => {
    if (!maxCountDisplayed) {
      toast.warn("Word count exceeded than 1500");
      setMaxCountDisplayed(true);
    }
  };

  const handleFileChange = (e) => {
    console.log("inside upload");

    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const text = content.toString();
        const newEditorState = EditorState.createWithContent(
          ContentState.createFromText(text)
        );
        let currentText = newEditorState.getCurrentContent().getPlainText();

        const wordCount = currentText.trim().split(/\s+/).length;

        console.log("currentText", currentText, wordCount);

        if (wordCount > 1500) {
          handleWordCountExceed();
          return;
        }

        // Always continue with the upload process, regardless of the word count
        setContentEditorState(EditorState.createEmpty());
        // setTotalWords(wordCount);
        // setInputKey((prevKey) => prevKey + 1);
        setContentEditorState(newEditorState);
        setMaxCountDisplayed(false);
      };

      reader.readAsText(file);
    }
  };

  const handleTranscriptChange = () => {
    // This function will be called whenever the transcript changes
    if (transcript) {
      handleSpeechInput(transcript);
    }
  };

  const handleSpeechInput = (input) => {
    // Handle the speech input, for example, update the editor state
    const newContentState = ContentState.createFromText(input);
    const newEditorState = EditorState.createWithContent(newContentState);
    setContentEditorState(newEditorState);
  };

  useEffect(() => {
    handleTranscriptChange();
  }, [transcript]);

  const handleAIReview = () => {
    setIsAIReviewOpen(!isAIReviewOpen);
  };

  const handleTitleEditorChange = (newEditorState) => {
    setTitleEditorState(newEditorState);
    const contentState = titleEditorState.getCurrentContent();
    const contentText = contentState.getPlainText();

    setTitleValue(contentText);
  };

  const handleContentEditorChange = (event) => {
    setContentEditorState(event);
    const contentState = contentEditorState.getCurrentContent();
    const contentText = contentState.getPlainText();

    const isContentEmpty = contentState.getPlainText().trim() === "";
    setShowHintIcon(!isContentEmpty);

    setContentValue(contentText);
  };

  const handleTipsAndUpdatesClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (option === "rewrite") {
      handleRewriteClick(contentValue);
    }
    if (option === "statisticalFact") {
      handleStatisticalDataClick(contentValue);
    }
    if (option === "explain") {
      handleExplainClick(contentValue);
    }
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseRewriteCard = () => {
    setRewriteCard(false);
  };

  const handleCloseStatisticalFactCard = () => {
    setStatisticalFactCard(false);
  };

  const handleCloseExplainCard = () => {
    setExplainCard(false);
  };

  const iconButtonStyle = {
    width: "20px",
    height: "20px",
    margin:"1px",
    borderRadius: "0",
  };

  const iconButtonBorderStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "0",
    borderLeft: "2px solid #ccc",
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleUndo = () => {
    handleContentEditorChange(EditorState.undo(contentEditorState));
  };

  const handleRedo = () => {
    handleContentEditorChange(EditorState.redo(contentEditorState));
  };

  const handleFontColorToggle = (style) => {
    handleContentEditorChange(RichUtils.toggleInlineStyle(contentEditorState, style));
  };

  const toggleBlockType = (blockType) => {
    handleContentEditorChange(RichUtils.toggleBlockType(contentEditorState, blockType));
  };



  ////////////////////////////////////////////Tone//////////////////////

  useEffect(() => {
    if (selectedTone !== "" && selectedTone !== "custom") {
      handleToneSelection(selectedTone)
    }
    // else if (selectedTone == "custom") {
    //   setIsCustomOpen(true)
    // }
  }, [selectedTone])

  useEffect(() => {
    if (selectedProfession !== "" && selectedProfession !== "custom") {
      handleToneSelection(selectedProfession)
    }
    // else if (selectedProfession == "custom") {
    //   setIsCustomOpen(true)
    // }
  }, [selectedProfession])

  const handleToneSelection = (type) => {
    //  setActiveEditor('paraphrase');
    grammarCheckForParaphrase(type)
  }



  const grammarCheckForParaphrase = async (type) => {
 
 
    console.log("editorState_", contentEditorState, contentEditorState.getCurrentContent().getPlainText());
     if(type == "Custom"){
      return
     }
     
     const plainText = contentEditorState.getCurrentContent().getPlainText();
     if (!plainText.trim()) {
         return toast.warn("Please enter some text");
     }
 
    let data = {
      text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${contentEditorState.getCurrentContent().getPlainText()}`,
      userId: userID,
        isCoAuthor: false
    }
    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        // let wordLength = responseOutput.split(" ")
        console.log("paraphraseResponse_Pankaj", responseOutput);
        const contentState = ContentState.createFromText(responseOutput[0]);
        const newEditorState = EditorState.createWithContent(contentState);
        console.log("newEditorStateeeeeeee ", newEditorState)
        setContentEditorState(newEditorState);
        const data={
          userId:sessionStorage.getItem("userId"),
          organizationId:sessionStorage.getItem("organizationId"),
          actionType:"paraphrase"
        }
        const addLogs = await addUserLogs(data)
        if (addLogs.status !== 200) {
            toast.error(addLogs.message)
        }
        handleClose()
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setCustomText("Custom")
    setLoading(false)

  }
 
  const handleTone = (e) => {
    console.log("selectedTone", selectedTone)
    // let value = e.target.value
    setSelectedTone(e)
    console.log("Value_", e)
    setCustomText("Custom")
  }
 

  const handleCustomSelection = (type) => {
    let splitCustomText = type.split(' ');
    let suggestionType = splitCustomText[2];
 
 
    setCustomText(type);
    console.log(type, "type");
    // setIsCustomOpen(false);
    setIsCustomSuggestion(true);
    setSuggestionText("Custom");
    // setCustomTextValue("");
 
    grammarCheckForParaphrase(suggestionType);
  };
  return (
    <>
    <Loader loading={loading} />
      <div className="container-fluid row">
        <div className="col-md-5">
          <Link to="/mydocuments">
            <Button
              variant="contained"
              color="primary"
              startIcon={<KeyboardBackspaceIcon />}
              style={{ marginRight: "7px", whiteSpace: "nowrap" }}
            >
              Back
            </Button>
          </Link>

          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            style={{ marginRight: "7px", whiteSpace: "nowrap" }}
          >
            Tone
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                height: "fit-content",
                backgroundColor: "white",
                border: "1px solid #000",
                boxShadow: 24,
                padding: "20px",
                p: 4,
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography id="modal-modal-title" style={{margin:"6px",marginBottom:"8px",marginTop:"4px"}} component="h2">
                {/* <b>Formality</b> */}
                <b>Tone</b>
              </Typography>
              {/* <div>
                <Button className="button">Neutral</Button>
                <Button className="button">Casual</Button>
                <Button className="button">Formal</Button>
              </div> */}
              <div>
              {toneList.map((tone, index) => (
                <Button key={index} onClick={() => handleTone(tone.value)} className="button">
                    {tone.value}
                </Button>
                ))}
            </div>

              {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <b>Suggestion</b>
              </Typography>
              <div>
                <Button className="button">Personable</Button>
                <Button className="button">Confident</Button>
                <Button className="button">Empathetic</Button>
                <Button className="button">Engaging</Button>
                <Button className="button">Witty</Button>
                <Button className="button">Direct</Button>
              </div> */}

              <Typography id="modal-modal-title"  component="h2"  style={{margin:"4px",marginBottom:"8px",marginTop:"8px"}}>
                {/* <b>Formality</b> */}
                <b>Suggestion:</b>
              </Typography>
              <div>
              {SuggestionList.map((suggestionText, index) => (
                <Button key={index} onClick={() => handleCustomSelection(suggestionText.value)} className="button">
                    {suggestionText.value}
                </Button>
                ))}
            </div>
  

              <Typography id="modal-modal-title"  component="h2" style={{margin:"4px",marginBottom:"8px",marginTop:"8px"}}>
                <b>Your Profession</b>
              </Typography>
              <div
                className="ml-2 bg-white"
                style={{ width: "150px", color: "blue" }}
              >
                <SelectDropdownMui
                  optionsList={professionList}
                  selectLabel={"Select Profession"}
                  onChange={(e) => setSelectedProfession(e.target.value)}
                  value={selectedProfession}
                />
              </div>
            </Box>
          </Modal>

          <div
            style={{
              maxWidth: "100%",
              marginBottom: "5px",
              marginTop: "3%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "5px",
              borderRadius: "8px",
              background: "white",
              display:'flex',
              alignItems:"center",
            }}
          >
            <IconButton
              style={iconButtonStyle}
              onClick={() => {
                handleUndo();
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
            >
              <UndoIcon />
            </IconButton>
            <IconButton
              title="Redo"
              disableTouchRipple
              style={iconButtonStyle}
              onClick={() => {
                handleRedo();
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "white")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "transparent")
              }
            >
              <RedoIcon />
            </IconButton>

            <div style={{width:"1px",backgroundColor:"#D3d3d3",height:"25px",margin:"4px",marginLeft:"4px"}}>
            {/* fake border  */}
            </div>
         
            {/* <IconButton style={iconButtonBorderStyle}>
              <TitleIcon />
            </IconButton> */}
            
            <IconButton
            disableTouchRipple
            style={iconButtonStyle}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
            <InlineSuperscript
            editorState={contentEditorState}
            onToggle={(style) => handleFontColorToggle(style)}
            />
            </IconButton>
            <IconButton
            disableTouchRipple
            style={iconButtonStyle}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
            <InlineUnderline
            editorState={contentEditorState}
            onToggle={(style) => handleFontColorToggle(style)}
            />
            </IconButton>

            <IconButton
            disableTouchRipple
            style={iconButtonStyle}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
            <InlineBold
            editorState={contentEditorState}
            onToggle={(style) => handleFontColorToggle(style)}
            />
            </IconButton>

            <IconButton
            disableTouchRipple
            style={iconButtonStyle}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
            <InlineItalic
            editorState={contentEditorState}
            onToggle={(style) => handleFontColorToggle(style)}
            />   
            </IconButton>

            <div style={{width:"1px",backgroundColor:"#D3d3d3",height:"25px",margin:"4px",marginLeft:"0px"}}>
            {/* fake border  */}
            </div>
{/*           
            <IconButton style={iconButtonBorderStyle}>
              <SegmentIcon />
            </IconButton> */}

         

            <IconButton
            disableTouchRipple
            style={{marginLeft:"3px",width:"20px",height:"20px"}}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
              <ULStyleControls
              editorState={contentEditorState}
                onToggle={(blockType) => {
                    toggleBlockType(blockType);
                }}/> 
            </IconButton>

            <IconButton
            disableTouchRipple
            style={{marginLeft:"3px",width:"20px",height:"20px"}}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
              <OLStyleControls
              editorState={contentEditorState}
                onToggle={(blockType) => {
                    toggleBlockType(blockType);
                }}/> 
            </IconButton>

            <IconButton
            title="Text Align"
            disableTouchRipple
            style={{width:"fit-content",height:"20px",padding:"0px"}}
            onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
            onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
            }
        >
            <InlineAlign
            placeholder={<SegmentIcon />}
            list={Text_Align}
            currentStyle={contentEditorState.getCurrentInlineStyle()}
            onToggle={(style) => handleFontColorToggle(style)}
        />
        </IconButton>

        <div style={{width:"1px",backgroundColor:"#D3d3d3",height:"25px",margin:"4px",marginLeft:"6px",marginRight:'0px'}}>
            {/* fake border  */}
            </div>
           
            <IconButton style={iconButtonStyle}>
              <AttachFileIcon />
            </IconButton>
            <IconButton style={iconButtonStyle}>
              <ImageIcon />
            </IconButton>
          </div>

          <div
            style={{
              marginTop: "10px",
              minWidth: "100%",
              maxWidth: "100%",
              boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
              padding: "10px 10px",
              borderRadius: "10px",
              background: "white",
              minHeight: "40px",
              maxHeight: "40px",
              overflowY: "auto",
            }}
          >
            <Editor
              editorState={titleEditorState}
              onChange={handleTitleEditorChange}
              placeholder="Title"
            />
          </div>
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                marginTop: "10px",
                minWidth: "100%",
                maxWidth: "100%",
                boxShadow: "3px 0px 5px 0px rgba(0,0,0,0.25)",
                padding: "25px 10px",
                borderRadius: "10px 10px 0px 0px",
                backgroundColor: "white",
                overflowY: "auto",
                minHeight: "270px",
                maxHeight: "270px",
              }}
            >
              <Editor
                editorState={contentEditorState}
                onChange={handleContentEditorChange}
                customStyleMap={styleMap()}
                placeholder="Document Content "
                plugins={undoredoPlugin}
              />
              {showHintIcon && (
                <IconButton
                  onClick={handleTipsAndUpdatesClick}
                  style={{ position: "absolute", top: "1px", right: "1px" }}
                >
                  <TipsAndUpdatesIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => handleMenuItemClick("rewrite")}
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <strong>Rewrite</strong>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px", margin: "0" }}>
                        Rewrite the sentence
                      </p>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuItemClick("statisticalFact")}
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <strong>Statistical Fact</strong>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px", margin: "0" }}>
                        Add Quantitative data
                      </p>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuItemClick("explain")}
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <strong>Explain</strong>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px", margin: "0" }}>
                        Add a detailed explanation
                      </p>
                    </div>
                  </div>
                </MenuItem>
              </Menu>
            </div>

            {rewriteCard && (
              <RewriteCard
                handleCloseRewriteCard={handleCloseRewriteCard}
                rewrittenText={rewrittenText}
                contentEditorState={contentEditorState}
                setContentEditorState={setContentEditorState}
              />
            )}
            {statisticalFactCard && (
              <StatisticalFactCard
                handleCloseStatisticalFactCard={handleCloseStatisticalFactCard}
                statisticalData={statisticalData}
                contentEditorState={contentEditorState}
                setContentEditorState={setContentEditorState}
              />
            )}
            {explainCard && (
              <ExplainCard
                handleCloseExplainCard={handleCloseExplainCard}
                explanationText={explanationText}
                contentEditorState={contentEditorState}
                setContentEditorState={setContentEditorState}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "100%",
              maxWidth: "100%",
              boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.25)",
              padding: "10px 10px",
              borderRadius: "0px 0px 10px 10px",
              background: "white",
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  boxShadow: "3px 2px 8px 0px rgba(0,0,0,0.25)",
                  borderRadius: "20px",
                  width: "40px",
                  height: "40px",
                }}
              >
                <h3 style={{ position: "relative", top: "9px", left: "14px" }}>
                  4
                </h3>
              </div>
              <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                <Button variant="outlined" color="primary">
                  Fix Errors
                </Button>
              </div>
            </div>

            <div>
              <IconButton
                style={{ width: "30px", height: "20px", marginTop: "5px" }}
              >
                <AssignmentTurnedInIcon />
              </IconButton>
              <IconButton
                title={"Mic"}
                onClick={handleMicClick}
                style={{
                  width: "30px",
                  height: "20px",
                  marginTop: "5px",
                  color: isRecording ? "red" : "grey",
                }}
              >
                <MicIcon />
              </IconButton>
              <IconButton
                title={"Upload"}
                style={{ width: "30px", height: "20px", marginTop: "5px" }}
                component="label"
                htmlFor="newDocumentFileInput"
              >
                <UploadIcon />
                <VisuallyHiddenInput
                  id="newDocumentFileInput"
                  type="file"
                  key={inputKey}
                  onChange={handleFileChange} // Directly call the function here
                />
              </IconButton>
              <IconButton
                title={"Copy"}
                onClick={handleCopyFormain}
                style={{ width: "30px", height: "20px", marginTop: "5px" }}
              >
                <ContentCopyIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div style={{ marginTop: "45px", overflowY: "auto" }}>
            <h3>Suggestions</h3>
            <SuggestionCards />
            <SuggestionCards />
            <SuggestionCards />
          </div>
        </div>

        <div className="col-md-3">
          <GrammarCheckAssistant handleAIReview={handleAIReview} />

          {isAIReviewOpen && (
            <AIReview_Modal handleClose={() => setIsAIReviewOpen(false)} />
          )}
        </div>
      </div>
    </>
  );
};

export default MyDocumentsNewProject;
