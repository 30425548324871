// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { getSubscriptions } from "../services/subscription";
// import { toast } from 'react-toastify';

// export function useCheckSubscriptionPlanAccess(screenName) {
//     const screenData = useSelector((state) => state.manageSubscriptionPlan.subscriptionPlan);
//     const userDetails = useSelector((user) => user)
//     const role = userDetails?.user?.currentUser?.Role
//     console.log(screenData,"ssscreenData");
//     console.log(screenName,"xxssz",role);
//     const [planData, setPlanData] = useState(null);

//     useEffect(() => {
//         const fetchPlanData = async () => {
//             try {
//                 const response = await getSubscriptions() 
//                 console.log(response.data,"ffrfr");

//                 setPlanData(response.data);
//             } catch (error) {
//                 console.error("Error fetching subscription plan data", error);
//             }
//         };

//         fetchPlanData();

//     }, []);

//     function isSameOrBefore(date1, date2) {
//         return date1.toISOString().split('T')[0] <= date2.toISOString().split('T')[0];
//       }

//     console.log(planData,"jjjjnn");


//     if (screenData && planData) {
//         const defaultPlanForRole = planData.find(plan => plan.isDefault && plan.userType == role);
//         console.log(defaultPlanForRole,"defaultPlanForRole");
//         console.log(screenData.name ,"rrrrrrrrrrrr", defaultPlanForRole.name);

//         if(screenData.name !== defaultPlanForRole.name){
//             alert("inside")
//             const currentDate = new Date();
//             const isPlanValid =  isSameOrBefore(currentDate, new Date(screenData.endDate))
//             console.log(isPlanValid,"isPlanValid")
//             if (!isPlanValid) { 
//                return false
//             }
//         }else{
//             const feature = screenData?.features?.find(f => f.name === screenName);
//             console.log(feature,"oofeatureb");

//             return feature ? feature?.access === true : false;
//             // return feature?.access === true;
//         }

//         // return false;

//     }

//     // if (screenData) {
//     //     const feature = screenData?.features?.find(f => f.name === screenName);
//     //     return feature ? feature?.access === true : false;
//     // }

//     // return false;
// }





import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSubscriptions } from "../services/subscription";
import { toast } from 'react-toastify';

export function useCheckSubscriptionPlanAccess(screenName) {
    const screenData = useSelector((state) => state.manageSubscriptionPlan.subscriptionPlan);
    console.log(screenData, "ghfhdl");

    const userDetails = useSelector((user) => user);
    const role = userDetails?.user?.currentUser?.Role;

    const [planData, setPlanData] = useState(null);
    const [hasAccess, setHasAccess] = useState(null);  // To store the result
    const [loading, setLoading] = useState(true);      // To track if the subscription data is still being fetched

    useEffect(() => {
        const fetchPlanData = async () => {
            try {
                const response = await getSubscriptions();
                setPlanData(response.data);
            } catch (error) {
                console.error("Error fetching subscription plan data", error);
            } finally {
                setLoading(false); // Set loading to false once the data is fetched
            }
        };

        fetchPlanData();
    }, []);

    const isSameOrBefore = (date1, date2) => {
        // return date1.toISOString().split('T')[0] <= date2.toISOString().split('T')[0];
        if (date1?.toString() !== "Invalid Date" && date2?.toString() !== "Invalid Date") {
            console.log("date1_", date1, date2)
            return date1?.toISOString()?.split('T')[0] <= date2?.toISOString().split('T')[0];
        } else {
            return true
        }
    };

    useEffect(() => {
        console.log("screenData_", screenData, planData, !loading,role)
        if (screenData && planData && !loading) {
            const defaultPlanForRole = planData.find(plan => plan.isDefault && plan.userType === role);
            console.log("screenData_if", defaultPlanForRole)

            if (defaultPlanForRole) {
                if (screenData.isDefault == false) {
                    const currentDate = new Date();
                    const isPlanValid = isSameOrBefore(currentDate, new Date(screenData?.endDate));

                    if (!isPlanValid) {
                        setHasAccess(false);  // If plan is not valid, set hasAccess to false
                    } else {
                        // Check feature access if the plan is valid
                        const feature = screenData?.features?.find(f => f.name === screenName);
                        setHasAccess(feature ? feature?.access === true : false);  // Set based on feature access
                    }
                } else {
                    // Plan is valid, check the feature access
                    const feature = screenData?.features?.find(f => f.name === screenName);
                    console.log("feature______", feature)
                    setHasAccess(feature ? feature?.access === true : false);  // Set based on feature access
                }
            } else {
                // If there's no default plan for the role, deny access
                setHasAccess(false);
            }
        }
    }, [screenData, planData, role, screenName, loading]);

    return hasAccess;  // Return the final result (true or false)
}




