// import React, { Component } from "react";
// import DocTags from "../DocTags";
// import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
// import SideNavBar from "../SideNavBar";
// class DocTagsPage extends Component {
//   render() {
//     return (
//       <SideNavBar>
//         <DocTags />
//       </SideNavBar>
//     );
//   }
// }

// export default NetworkDetector(DocTagsPage);


import React, { useEffect, useState } from "react";
import DocTags from "../DocTags";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import { useCheckSubscriptionPlanAccess } from "../SubscriptionAccess";
import Loader from "../../utils/loader";

const DocTagsPage = () => {

  const [hasAccessToTags, setHasAccessToTags] = useState();
  // const [loading, setLoading] = useState(true);
  const accessToTagsScreen = useCheckSubscriptionPlanAccess("Tags");

  useEffect(() => {
    console.log("accessToTagsScreen_",accessToTagsScreen)
    if (accessToTagsScreen !== null && accessToTagsScreen !== undefined) {
      setHasAccessToTags(accessToTagsScreen);
      // setLoading(false); 
    }
  }, [accessToTagsScreen]);


  // if (loading) {
  //   return <Loader loading={loading} />;
  // }


  return (
    <SideNavBar>
      {accessToTagsScreen !== null && <DocTags  hasAccessToTags={hasAccessToTags}/>}
    </SideNavBar>
  );
};

export default NetworkDetector(DocTagsPage);

