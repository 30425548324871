// import React, { Component } from "react";
// import DocumentPreview from "../documentPreview";
// import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
// import SideNavBar from "../SideNavBar";
// import AdminNavBar from "../AdminSideNavBar";
// import authService from "../../services/authService";
// import SubAdminSideBar from "../SubAdminSideBar";

// class DocumentPreviewPage extends Component {
//   state = {};

//   render() {
//     const { detail, isReviewing } = this.props.history.location.state;
//     const user = authService.getCurrentUser();
//     console.log(user,"holla operator")
//     if (user.isAdmin) {
    
//       return (
//         <AdminNavBar>
//         <DocumentPreview data={detail} />
//       </AdminNavBar>
//       );
//     } else if(user.isSubAdmin){
//       return(
//       <SubAdminSideBar>
//           <DocumentPreview data={detail} />
//         </SubAdminSideBar>
//       );
//     }else {
//       return (
//         <SideNavBar>
//           <DocumentPreview data={detail} />
//         </SideNavBar>
//       );
//     }
//   }
// }

// export default NetworkDetector(DocumentPreviewPage);




import React, { useEffect, useState } from "react";
import DocumentPreview from "../documentPreview";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";
import SubAdminSideBar from "../SubAdminSideBar";
import { useCheckFeatureAccess } from "../CheckFeature";
import { useSelector } from "react-redux";
import Loader from "../../utils/loader";

const DocumentPreviewPage = (props) => {
  const { detail } = props.history.location.state;
  const user = authService.getCurrentUser();
  console.log(user, "holla operator");
  const [hasAccessToEditBlogs, setHasAccessToEditBlogs] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
  const accessToEditBlog = useCheckFeatureAccess(currentNavItem,'writeBlog');

  useEffect(() => {
    if (accessToEditBlog !== undefined) {
      // alert(hasAccessToEditBlogs)
      setHasAccessToEditBlogs(accessToEditBlog);
      setLoading(false); 
    }
  }, [accessToEditBlog]);


  if (loading) {
    return <Loader loading={loading} />;
  }






  if (user.isAdmin) {
    return (
      <AdminNavBar>
        <DocumentPreview data={detail} />
      </AdminNavBar>
    );
  } else if (user.isSubAdmin) {
    return (
      <SubAdminSideBar>
        <DocumentPreview data={detail} />
      </SubAdminSideBar>
    );
  } else {
    return (
      <SideNavBar>
        <DocumentPreview data={detail} hasAccessToEditBlogs={hasAccessToEditBlogs} />
      </SideNavBar>
    );
  }
};

export default NetworkDetector(DocumentPreviewPage);

