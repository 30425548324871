import http from "./httpService";
// import { apiUrl, herokuUrl } from "./config.json";
import configdata from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/plagiarism`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/plagiarism`;
}


export async function checkPlagiarism(data,userId,isCoAuthor){
  const response = await http.post(`${apiEndpoint}/checkPlagiarism`,{data,userId,isCoAuthor});
  return response;
}

export async function getPlagiarismDetails(data){
  const response = await http.post(`${apiEndpoint}/plagiarismDetails`,data);
  return response;
}

export async function getUniqueSentence(data){
  const response = await http.post(`${apiEndpoint}/uniqueSentence`,data);
  return response;
}

export default {
    checkPlagiarism,
    getPlagiarismDetails,
    getUniqueSentence
}