import http from "./httpService";
import configdata from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/teams`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/teams`;
}

export async function reviewDocument(id) {
  const data = await http.post(`${apiEndpoint}/reviewDocument`, id);
  return data;
}

export async function getCollabDocuments(mergeDocuments,userId) {
  const data = await http.post(`${apiEndpoint}/getCollabDocuments`,{
    mergeDocuments,
    userId
  });
  return data;
}

export async function mergeDocument(Document) {
  const data = await http.post(`${apiEndpoint}/mergeDocument`,Document);
  return data;
}

export async function removeCollaborater(id,userId) {
  const data = await http.post(`${apiEndpoint}/removeCollaborater`, {id,userId});
  return data;
}

  export default{
    reviewDocument,
    getCollabDocuments,
    mergeDocument,
    removeCollaborater
  }