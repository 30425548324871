const initialState = {
    isFullSize: false,
    isFullScreen:false
};

const manageFullsizeEditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FULL_SIZE_EDITOR':
            return {
                ...state,
                isFullSize: true,
                isFullScreen:false
            };
        case 'NORMAL_SIZE_EDITOR':
            return {
                ...state,
                isFullSize: false,
                isFullScreen:false
            };
        case 'Full_Screen':
            return {
                ...state,
                isFullScreen: true,
                isFullSize:false
            };
     
        case 'Exit_Screen':
            return {
                ...state,
                isFullScreen: false,
                isFullSize:false
            };
     
        default:
            return state;
    }
};

export default manageFullsizeEditorReducer;
