import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../utils/loader";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import "./settings.css";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/dist/style.css";
import ThemeSwitch from "./ThemeSwitch";
import { Link } from "react-router-dom";
import authService, { sendOtp, verifyOtp } from "../services/authService";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import isEmptyObject from "../utils/isEmptyObject";
import BreadCrumb from "./Cards/BreadCrumbs";
import adminServices from "../services/adminServices";
import "./hover-min.css";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import {
  addDictionary,
  getmywords,
  deletemywords,
} from "../services/englishCheckers";
import { connect, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import uploadImageServices from "../services/uploadImageServices";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getProducts, addProduct, deleteProduct } from '../services/productService';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PreferencesAccordionMui from "./ReusableComponents/PreferenceAccordionMui";
import RadioButtonMui from "./ReusableComponents/RadioButtonMui";
import ImageAvatarsMui from "./ReusableComponents/ImageAvatarsMui";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { Button } from "@mui/material";
import CryptoJS from 'crypto-js';
import { TwoFactorComp } from "./ReusableComponents/TwoFactorComp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { countries } from "./ReusableComponents/countryList";
import InputBoxMobile from "./ReusableComponents/InputBoxforMobile";
import { updatePhoneFromSettings } from "../services/userService";
import OtpInputBox from "./ReusableComponents/OtpInputBox";
import tick from "../static/signup_login/Check.png"
import { addUserLogs } from "../services/userLogServices";


const inputLabel = {
  fontSize: "15px",
  color: "#707070",
  lineHeight: "1.33",
  width: "90%",
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "none",
    // fontSize: 20,
    textAlign: "left",
    width: "auto",
    fontWeight: "bold",
    // padding: "8px 10px",
    marginTop: "-7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
      borderBottom: "2px solid #1976d2",
    },
  },
}))(InputBase);

const SettingsNew = ({ props }) => {
  const userDetails = useSelector((user) => user)
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const fileInputRef = useRef(null);
  const [error, setError] = useState({});
  const [npassword, setNPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [display, setDisplay] = useState(false);
  const [readonly, setReadonly] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [visibility, setVisibility] = useState("hidden");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [dashLink, setDashLink] = useState("/dashboard");
  const [isDark, setIsDark] = useState("false");
  const [errors, setErrors] = useState("");
  const [cerrors, setCErrors] = useState("");
  const [disable, setDisable] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addWord, setAddWord] = useState("");
  const [Dictionary, setDictionary] = useState([]);
  const [characterLimit, setCharacterLimit] = useState(0);
  const [plagiarismLimit, setPlagiarismLimit] = useState(0);
  const [paraphraseLimit, setParaphraseLimit] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [key, setKey] = useState("");
  const [manageUser, setManageUser] = useState(false);
  const [sentenceHighlighter, setSentenceHighlighter] = useState("");
  const [products, setProducts] = useState([]);
  const [addProduct, setAddProduct] = useState("");
  const [productModal, setProductModal] = useState(false);
  const [radioSelection, setRadioSelection] = useState(1);
  const history = useHistory()
  const [isDeleted, setIsDeleted] = useState(false);
  const [radioOptions, setRadioOptions] = useState([
    {
      label: "American English",
      value: 1,
      status: true
    },
    {
      label: "Canadian English",
      value: 2,
      status: false
    },
    {
      label: "British English",
      value: 3,
      status: false
    }
  ]);
  const [is2Factor, setIs2Factor] = useState(true);
  const [is2FEmailChecked, setIs2FEmailChecked] = useState(false);
  const [is2FMobileChecked, setIs2FMobileChecked] = useState(true);
  const [is2FactorApproved, setIs2FactorApproved] = useState(false);
  const [is2FReqApprovedMsg, setIs2FReqApprovedMsg] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false)
  // const [accountType, setAccountType] = useState(localStorage.getItem("accountType"));
  const [accountType, setAccountType] = useState(userDetails.user.currentUser.accountType);
  // const [role, setRole] = useState(localStorage.getItem("Role"));
  const [role, setRole] = useState(userDetails.user.currentUser.Role);
  const [organizationId, setOrganizationId] = useState(userDetails.user.currentUser.organizationId);
  const [currentUserEmail, setCurrentUserEmail] = useState(userDetails.user.currentUser.email);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fontSize, setFontSize] = useState(20)
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [readonlyOrgId, setReadonlyOrgId] = useState(true);
  const [readonlyOrgName, setReadonlyOrgName] = useState(true);
  const [readonlyEmail, setReadonlyEmail] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [readonlyMobileNumber, setReadonlyMobileNumber] = useState(true);


  const data2F = { is2Factor, is2FEmailChecked, is2FactorApproved, is2FReqApprovedMsg, isRequestSent, email, isSubAdmin }
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth <= 768);
  const [hoveredField, setHoveredField] = useState(null);
  const [isEmailChange, setisEmailChange] = useState(false);
  const [showEditUserPopup, setEditUserPopup] = useState(false);
  const [editedUser, setEditedUser] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNo, setMobileNo] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [countryList, setCountryList] = useState([])
  const [selectedCountryCode, setSelectedCountryCode] = useState("")
  console.log(showEditUserPopup, "showEditUserPopup")
  const [isOtpShown, setIsOtpShown] = useState(false)
  const [isOtpVerify, setIsOtpVerify] = useState(false)
  const [verifyDisable, setVerifyDisable] = useState(false)
  const [timer, setTimer] = useState(0);
  const [isResendCodeDisable, setisResendCodeDisable] = useState(true)
  const [isResendButton, setIsResendButton] = useState(false)
  const [otpValue, setOtpValue] = useState("")
  const [userCurrentData, setUserCurrentData] = useState()
  const [verifiedEmail, setVerifiedEmail] = useState("")
  useEffect(() => {
    if (isEmailChange) {
      history.push({
        pathname: `/logout`,
      });
    }
  }, [isEmailChange]);

  let selectedCountry = countries.find(country => selectedCountryCode ? country.code === selectedCountryCode : `+${country.phone}` === countryCode);
  console.log(selectedCountry, "selectedCountry");

  const countryName = selectedCountry ? selectedCountry.label : "";
  console.log(countryName, "__countryName")

  const handleCountry = (event, value) => {
    console.log("params_", event, value, "mobileNo", mobileNo);

    if (value.phone !== "" && mobileNo !== "") {
      setVerifyDisable(false)
      setIsOtpShown(false)
      setIsResendButton(false)
    } else {
      setVerifyDisable(true)
    }

    const newCountryCode = `+${value.phone}`;
    setCountryCode(newCountryCode);
    setSelectedCountryCode(value.code)
    // Clear the mobile number when the country is changed
    setMobileNo(''); // Reset the phone number to blank

    // Update editedUser with the current country
    setEditedUser(prev => ({
      ...prev,
      phone: '', // Set phone in editedUser to blank
      country: value.label, // Update the country based on the selected value
    }));

  };


  const fetchCountryList = () => {
    let finalArr = []
    countries.map((obj) => {
      finalArr.push({
        name: obj.label,
        value: obj.label
      })
    })
    setCountryList(finalArr)
  }

  const countryLengthMap = {
    "+1": 10,
    "+93": 9,    // Afghanistan
    "+355": 8,   // Albania
    "+213": 9,   // Algeria
    "+376": 6,   // Andorra
    "+244": 9,   // Angola
    "+1-264": 10, // Anguilla
    "+1-268": 10, // Antigua and Barbuda
    "+54": 10,   // Argentina
    "+374": 8,   // Armenia
    "+297": 7,   // Aruba
    "+61": 9,    // Australia
    "+43": 13,   // Austria
    "+994": 9,   // Azerbaijan
    "+1-242": 10, // Bahamas
    "+973": 8,   // Bahrain
    "+880": 11,  // Bangladesh
    "+1-246": 10, // Barbados
    "+32": 9,    // Belgium
    "+501": 8,   // Belize
    "+229": 8,   // Benin
    "+1-441": 10, // Bermuda
    "+975": 8,   // Bhutan
    "+591": 8,   // Bolivia
    "+387": 8,   // Bosnia and Herzegovina
    "+267": 7,   // Botswana
    "+55": 11,   // Brazil
    "+1-284": 10, // British Virgin Islands
    "+673": 7,   // Brunei
    "+359": 8,   // Bulgaria
    "+226": 8,   // Burkina Faso
    "+257": 7,   // Burundi
    "+855": 8,   // Cambodia
    "+237": 9,   // Cameroon
    "+1": 10,    // Canada
    "+238": 7,   // Cape Verde
    "+1-345": 10, // Cayman Islands
    "+236": 7,   // Central African Republic
    "+235": 8,   // Chad
    "+56": 9,    // Chile
    "+86": 11,   // China
    "+61": 9,    // Christmas Island
    "+61": 9,    // Cocos (Keeling) Islands
    "+57": 10,   // Colombia
    "+269": 7,   // Comoros
    "+242": 7,   // Congo
    "+243": 9,   // Democratic Republic of the Congo
    "+682": 5,   // Cook Islands
    "+506": 8,   // Costa Rica
    "+225": 10,  // Côte d'Ivoire
    "+385": 9,   // Croatia
    "+53": 8,    // Cuba
    "+599": 7,   // Curaçao
    "+357": 8,   // Cyprus
    "+420": 9,   // Czech Republic
    "+45": 8,    // Denmark
    "+253": 6,   // Djibouti
    "+1-767": 10, // Dominica
    "+1-809": 10, // Dominican Republic
    "+593": 9,   // Ecuador
    "+20": 10,   // Egypt
    "+503": 8,   // El Salvador
    "+240": 9,   // Equatorial Guinea
    "+291": 7,   // Eritrea
    "+368": 6,   // Estonia
    "+251": 10,  // Ethiopia
    "+500": 4,   // Falkland Islands
    "+298": 6,   // Faroe Islands
    "+679": 7,   // Fiji
    "+358": 9,   // Finland
    "+33": 9,    // France
    "+594": 9,   // French Guiana
    "+689": 6,   // French Polynesia
    "+241": 7,   // Gabon
    "+220": 7,   // Gambia
    "+995": 9,   // Georgia
    "+49": 11,   // Germany
    "+233": 10,  // Ghana
    "+350": 5,   // Gibraltar
    "+30": 10,   // Greece
    "+299": 6,   // Greenland
    "+1-473": 10, // Grenada
    "+590": 10,  // Guadeloupe
    "+1-671": 10, // Guam
    "+502": 8,   // Guatemala
    "+224": 9,   // Guinea
    "+245": 9,   // Guinea-Bissau
    "+595": 9,   // Paraguay
    "+509": 9,   // Haiti
    "+379": 13,  // Holy See
    "+504": 8,   // Honduras
    "+852": 8,   // Hong Kong
    "+36": 9,    // Hungary
    "+354": 7,   // Iceland
    "+91": 10,   // India
    "+62": 10,   // Indonesia
    "+98": 10,   // Iran
    "+964": 10,  // Iraq
    "+353": 9,   // Ireland
    "+972": 9,   // Israel
    "+39": 10,   // Italy
    "+1-876": 10, // Jamaica
    "+81": 10,   // Japan
    "+44": 10,   // Jersey
    "+962": 9,   // Jordan
    "+7": 10,    // Kazakhstan
    "+254": 10,  // Kenya
    "+686": 5,   // Kiribati
    "+965": 8,   // Kuwait
    "+996": 9,   // Kyrgyzstan
    "+856": 8,   // Laos
    "+371": 8,   // Latvia
    "+961": 8,   // Lebanon
    "+266": 8,   // Lesotho
    "+231": 7,   // Liberia
    "+218": 10,  // Libya
    "+423": 7,   // Liechtenstein
    "+370": 8,   // Lithuania
    "+352": 8,   // Luxembourg
    "+261": 10,  // Madagascar
    "+265": 8,   // Malawi
    "+60": 10,   // Malaysia
    "+960": 7,   // Maldives
    "+223": 8,   // Mali
    "+356": 8,   // Malta
    "+692": 7,   // Marshall Islands
    "+596": 10,  // Martinique
    "+222": 9,   // Mauritania
    "+230": 8,   // Mauritius
    "+262": 10,  // Mayotte
    "+52": 10,   // Mexico
    "+691": 7,   // Micronesia
    "+373": 8,   // Moldova
    "+377": 8,   // Monaco
    "+976": 8,   // Mongolia
    "+1-664": 10, // Montserrat
    "+212": 9,   // Morocco
    "+258": 9,   // Mozambique
    "+95": 9,    // Myanmar
    "+264": 9,   // Namibia
    "+674": 8,   // Nauru
    "+977": 10,  // Nepal
    "+31": 9,    // Netherlands
    "+599": 10,  // Netherlands Antilles
    "+687": 6,   // New Caledonia
    "+64": 9,    // New Zealand
    "+505": 8,   // Nicaragua
    "+227": 8,   // Niger
    "+234": 10,  // Nigeria
    "+683": 5,   // Niue
    "+64": 9,    // Norfolk Island
    "+850": 8,   // North Korea
    "+1-670": 10, // Northern Mariana Islands
    "+47": 8,    // Norway
    "+968": 8,   // Oman
    "+92": 10,   // Pakistan
    "+680": 7,   // Palau
    "+970": 9,   // Palestine
    "+507": 8,   // Panama
    "+675": 7,   // Papua New Guinea
    "+595": 8,   // Paraguay
    "+51": 9,    // Peru
    "+63": 10,   // Philippines
    "+48": 9,    // Poland
    "+351": 9,   // Portugal
    "+1-787": 10, // Puerto Rico
    "+974": 8,   // Qatar
    "+40": 10,   // Romania
    "+7": 10,    // Russia
    "+250": 10,  // Rwanda
    "+590": 10,  // Saint Barthélemy
    "+1-869": 10, // Saint Kitts and Nevis
    "+1-758": 10, // Saint Lucia
    "+1-784": 10, // Saint Vincent and the Grenadines
    "+685": 5,   // Samoa
    "+378": 10,  // San Marino
    "+239": 7,   // Sao Tome and Principe
    "+966": 9,   // Saudi Arabia
    "+221": 9,   // Senegal
    "+381": 9,   // Serbia
    "+248": 7,   // Seychelles
    "+232": 8,   // Sierra Leone
    "+65": 8,    // Singapore
    "+421": 9,   // Slovakia
    "+386": 8,   // Slovenia
    "+677": 6,   // Solomon Islands
    "+252": 8,   // Somalia
    "+27": 10,   // South Africa
    "+82": 10,   // South Korea
    "+211": 9,   // South Sudan
    "+34": 9,    // Spain
    "+94": 10,   // Sri Lanka
    "+249": 10,  // Sudan
    "+597": 8,   // Suriname
    "+268": 7,   // Eswatini
    "+41": 9,    // Switzerland
    "+963": 9,   // Syria
    "+886": 10,  // Taiwan
    "+992": 9,   // Tajikistan
    "+255": 10,  // Tanzania
    "+66": 9,    // Thailand
    "+670": 7,   // Timor-Leste
    "+228": 8,   // Togo
    "+690": 6,   // Tokelau
    "+676": 4,   // Tonga
    "+1-868": 10, // Trinidad and Tobago
    "+216": 8,   // Tunisia
    "+90": 10,   // Turkey
    "+993": 9,   // Turkmenistan
    "+1-649": 10, // Turks and Caicos Islands
    "+688": 5,   // Tuvalu
    "+256": 10,  // Uganda
    "+380": 9,   // Ukraine
    "+971": 9,   // United Arab Emirates
    "+44": 10,   // United Kingdom
    "+1": 10,    // United States
    "+598": 8,   // Uruguay
    "+998": 9,   // Uzbekistan
    "+678": 5,   // Vanuatu
    "+379": 13,  // Vatican City
    "+58": 10,   // Venezuela
    "+84": 10,   // Vietnam
    "+681": 4,   // Wallis and Futuna
    "+212": 9,   // Western Sahara
    "+967": 9,   // Yemen
    "+260": 10,  // Zambia
    "+263": 9,    // Zimbabwe
    "+1684": 7,   // American Samoa (AS)
    "+358-18": 9, // Åland Islands (AX)
    "+590": 10,   // Saint Barthélemy (BL)
    "+375": 9,    // Belarus (BY)
    "+501": 8,    // Belize (BZ)
    "+383": 9,    // Kosovo (XK)
    "+1-340": 10, // United States Virgin Islands (VI)
    "+1-284": 10, // British Virgin Islands (VG)
    "+690": 4,    // Tokelau (TK)
    "+262": 9,    // French Southern Territories (TF)
    "+235": 8,    // Chad (TD)
    "+239": 7,    // São Tomé and Príncipe (ST)
    "+268": 7,    // Eswatini (SZ)
    "+290": 4,    // Saint Helena (SH)
    "+46": 10,    // Sweden (SE)
    "+262": 9,    // Réunion (RE)
    "+872": 9,    // Pitcairn Islands (PN)
    "+382": 9,    // Montenegro (ME)
    "+389": 9,    // North Macedonia (MK)
    "+269": 7,    // Comoros (KM)
    "+44": 10,    // Jersey (JE)
    "+44": 10,    // Isle of Man (IM)
    "+672": 6,    // Heard Island and McDonald Islands (HM)
    "+592": 9,    // Guyana (GY)
    "+500": 4,    // South Georgia and the South Sandwich Islands (GS)
    "+500": 4,    // Falkland Islands (FK)
  };

  const handleNumber = (e) => {
    const inputValue = e; // Use the input value directly, e.g., from an event handler
    console.log("eData_", inputValue, inputValue.length, countryCode);

    const maxLength = countryLengthMap[countryCode] || 10; // Default to 10 if country code isn't found

    // Validation based on country-specific length
    if (inputValue.length > maxLength) {
      return;
    }

    // Set error message if the length is less than required
    if (inputValue.length < maxLength && inputValue.length > 0) {
      setErrorMessage(`Please enter a ${maxLength} digit phone number.`);
    } else {
      setErrorMessage('');
    }

    // Check for valid lengths for China or other countries
    let isChina = e.length === 11 && countryCode === "+86";
    let isOtherCountries = e.length === maxLength && countryCode !== "+86" && countryCode !== "";

    if (isChina || isOtherCountries) {
      // alert("in china || is othercounrty")
      setVerifyDisable(false);
      setIsOtpShown(false);
      setIsResendButton(false);
    } else {
      setVerifyDisable(true);
    }

    // Update mobileNo and editedUser with the new phone number
    setMobileNo(inputValue);

    // Only update editedUser with the full phone number if inputValue is not blank
    if (inputValue.length > 0) {
      setEditedUser(prev => ({
        ...prev,
        phone: `${countryCode}${inputValue}`, // Update phone in editedUser
      }));
      console.log(`${countryCode}${inputValue}`, "nnnnnnnnnnn")
    } else {
      // If inputValue is empty, also update editedUser phone to an empty string
      setEditedUser(prev => ({
        ...prev,
        phone: '', // Clear phone in editedUser when input is empty
      }));
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updateTheme = () => {
      const theme = localStorage.getItem('ThemeSwitch');
      setIsDarkMode(theme === 'true');
    };
    updateTheme();
    window.addEventListener('storage', updateTheme);
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === 'ThemeSwitch') {
        updateTheme();
      }
    };
    return () => {
      window.removeEventListener('storage', updateTheme);
      localStorage.setItem = originalSetItem;
    };
  }, []);

  useEffect(() => {
    console.log("props", props, userDetails)
    return () => {

    };
  }, []);

  const onRadioChange = (e) => {
    console.log('radio_checked', e.target.value);
    const value = e.target.value;

    const updatedStatusList = radioOptions.map((item) => ({
      ...item,
      status: item.value === value,
    }));

    setRadioSelection(value);
    setRadioOptions(updatedStatusList);
  };

  const handleChangeSidenavBar = (event) => {
    const newData = { ...data, [event.target.name]: event.target.checked };
    console.log(newData);
    console.log(newData.sidenavOption);

    setCurrentUser(newData);
    setData(newData);
  };

  const handleToggle = (value) => {
    const newData = { ...data, [value]: data[value] ? !data[value] : true };
    setCurrentUser(newData);
    setData(newData);
  };

  const handleChange = ({ target: { value, name } }) => {
    const newData = { ...data, [name]: value };
    console.log(newData);
    setCurrentUser(newData);
    setData(newData);
    setUsername(newData.username)
    // setOrgId(newData.newOrgId)
    setOrgName(newData.newOrgName)
    setEmail(newData.email)
    setMobileNumber(newData.phone)
  };


  const handleEdit = () => {
    setReadonly(false); // Set readonly to false to make the input editable
  };

  const handleEditOrgId = () => {
    if (!isSubAdmin) {
      return toast.error("This action is restricted to admin only.")
    }
    setReadonlyOrgId(false);
  };
  const handleEditOrgName = () => {
    if (!isSubAdmin) {
      return toast.error("This action is restricted to admin only.")
    }
    setReadonlyOrgName(false);
  };

  const handleEditMobileNumber = () => {

    setReadonlyMobileNumber(false);
  };
  const handleEditEmail = () => {

    setReadonlyEmail(false);
  };

  const onChangeCPassword = (e) => {
    const cpass = e.target.value;
    setCPassword(cpass)
    const npass = data.npassword; // Assuming you have 'npassword' in your data state
    setData({ ...data, cpassword: cpass });

    // if (cpass.length > 0 && npass.length > 0) {
    if (cpass.length > 0 && npassword.length > 0) {
      if (errors.length > 1) {
        setDisable(true);
      } else {
        if (cpass !== npassword) {
          setDisable(true);
          setCErrors("Password & Confirm Password Field Should Match");
        } else {
          setErrors("");
          setCErrors("");
          setDisable(false);
        }
      }
    } else {
      setErrors("");
      setCErrors("");
      setDisable(true);
    }
  };

  const userProfileData = async () => {
    let params = props.currentUser.email;
    try {
      let userData = await userService.getUserProfile(params);
      console.log("data", userData);
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const onChangeNPassword = (e) => {
    const npass = e.target.value;
    const cpass = cpassword; // Assuming you have 'cpassword' in your state
    setNPassword(npass);

    if (npass.length > 0) {
      if (npass.length <= 7) {
        setDisable(true);
        setErrors("Password must contain minimum 8 Characters");
      } else {
        setErrors("");
        const upperCaseLetters = /[A-Z]/g;
        const numbers = /[0-9]/g;

        if (!npass.match(upperCaseLetters)) {
          setDisable(true);
          setErrors("Password must contain at least 1 Capital letter");
        } else {
          setErrors("");
          if (!npass.match(numbers)) {
            setDisable(true);
            setErrors("Password must contain at least 1 number");
          } else {
            setErrors("");
          }
        }
      }
    } else {
      setErrors("");
    }

    if (cpass.length > 7 && npass.length > 7 && errors.length < 1 && npass === cpass) {
      setErrors("");
      setCPassword("");
      setDisable(false);
    }
  };

  const onClickResetPassword = async () => {
    const hashedPassword = CryptoJS.SHA256(npassword).toString();
    const hashConfirmPassword = CryptoJS.SHA256(cpassword).toString();
    let user = {
      email: data.email,
      password: hashedPassword,
      cpassword: hashConfirmPassword,
    };
    console.log("onClickResetPassword", user)
    const result = await authService.resetPassword(user);
    if (result.status === "good") {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    setNPassword("");
    setCPassword("");
  };

  const onChangePhone = (e) => {
    const newData = { ...data, phone: e };
    setCurrentUser(newData);
    setData(newData);
  };

  const onChangeTimezone = (e) => {
    const newData = { ...data, timezone: e };
    setCurrentUser(newData);
    setData(newData);
  };

  const getProfileData = async () => {
    // console.log("props",props)
    // let params = props.currentUser.email;
    let params = userDetails.user.currentUser.email
    try {
      let res = await userService.getUserProfile(params);
      // let userData = { ...user.data };
      let userData = res.data.user
      let otp = res.data.OTP
      console.log("profileData", userData, res);
      setCurrentUser(userData);
      setUserCurrentData(res.data.user)
      setData(userData);
      setIsAdmin(userData.isAdmin);
      setIsSubAdmin(userData.isSubAdmin);
      setLoading(false);
      setImagePreviewUrl("dummy");
      setIsUser(!userData.isAdmin);
      //setReadonly(false);
      setExpiryDate(userData.planID ? userData.planID.expireDate : "");
      if(userData.Role == "Individual" && userData?.plan?.isDefault == false){
        setExpiryDate(userData?.plan?.endDate);
      }

      setIs2Factor(userData.is2Factor);
      setIs2FEmailChecked(userData.is2FEmailChecked);
      setIsRequestSent(userData.isRequestSent)
      setIs2FactorApproved(otp?.isApproved ? true : false);
      setIs2FReqApprovedMsg(otp?.message ? otp.message : "");
      setUsername(userData.username);
      setEmail(userData.email);
      setVerifiedEmail(userData.email);
      setOrgId(userData.organizationId);
      setOrgName(userData.organizationName);
      setMobileNumber(userData.phone)

      if (userData.isAdmin) {
        getCharacterLimit();
        getParaphraseLimit();
        getPlagiarismLimit();
        getSentenceLimit();
      }

    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  console.log(userCurrentData, "userCurrentData")
  console.log(data, "dataaaa")

  const handle2FactorSwitch = () => {
    setIs2Factor(!is2Factor);
    setIs2FMobileChecked(!is2FMobileChecked);
    setIs2FEmailChecked(is2Factor === false && false);
  };

  const handleEmailCheckBox = () => {
    setIs2FEmailChecked(!is2FEmailChecked);
  };

  const handleDeleteOrganizationUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your Request will be sent to Admin for Approval !!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Send Delete Request!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        try {
          let data = {
            email: currentUserEmail,
            typeOfRequest: "Delete"
          };
          let response = await adminServices.updateUserRequest(data);

          if (response.status === 200) {
            toast.info(response.message);
            toast.info("Delete Request has been sent to Organization Admin");
            saveUserLogs()
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.error("Error sending delete request:", error);
        }
      } else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  const saveUserLogs = async () => {
    try {
      const data = {
        organizationId: userDetails.user.currentUser.organizationId,
        actionType: "userRequest",
        typeOfRequest: "Request Delete Account",
        userId: userDetails.user.currentUser._id,
      }
      const addLogs = await addUserLogs(data)
      if (addLogs.status !== 200) {
        toast.error(addLogs.message)
      }
    } catch (error) {
      toast.error("Error in saving user logs.")
    }
  }

  const handleDeleteAccount = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "It will be permanently deleted!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        try {
          let response = await userService.deleteAccount();
          if (response.status === 200) {
            toast.success(response.message);
            setIsDeleted(true);
          } else {
            toast.error(response.message);
          }
          Swal.fire("Deleted!", "Your Account has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting account:", error);
        }
      } else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  useEffect(() => {
    if (isDeleted) {
      history.push({
        pathname: `/`,
      });
    }
  }, [isDeleted, history]);

  // useEffect(() => {
  //   getProfileData();
  // }, [props.currentUser]);

  useEffect(() => {
    getProfileData();
  }, []);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       let { currentUser, initialData, initialManageUser } = props
  //       console.log("componenet", currentUser, initialData, initialManageUser,userDetails);
  //       let {isSubAdmin} = userDetails.user.currentUser
  //       let params = currentUser.email;

  //       // if (initialManageUser) {
  //       //   setDashLink("/admindashboard");
  //       //   setIsAdmin(true);
  //       //   setReadonly(true);
  //       //   setData(initialData);
  //       //   setLoading(false);
  //       //   setIsUser(false);
  //       //   setManageUser(initialManageUser);
  //       //   getProfileData(params);
  //       // } 
  //        if (currentUser.isSubAdmin === true) {
  //         console.log(currentUser);
  //         setDashLink("/subAdminDashboard");
  //         getProfileData(params);
  //       } 
  //       else if (currentUser && Object.keys(currentUser).length !== 0) {
  //         setDashLink(currentUser.isAdmin ? "/admindashboard" : "/dashboard");
  //         setIsAdmin(currentUser.isAdmin);
  //         setData(currentUser);
  //         setIsUser(!currentUser.isAdmin);
  //         setIsSubAdmin(currentUser.isSubAdmin);
  //         setReadonly(false);
  //         setLoading(false);
  //         setExpiryDate(currentUser.planID ? currentUser.planID.expireDate : "");

  //         if (currentUser.isAdmin) {
  //           getCharacterLimit();
  //           getParaphraseLimit();
  //           getPlagiarismLimit();
  //           getSentenceLimit();
  //           Blog_Products();
  //         } else {
  //           getDictionary();
  //           getProfileData(params);
  //         }
  //       }
  //     };

  //     fetchData();
  //   // }, [props.currentUser, props.initialData, props.initialManageUser]);
  // }, []);

  useEffect(() => {
    const openFileInput = () => {
      $("#fileup").trigger("click");
    };

    $("#open").click(openFileInput);

    return () => {
      $("#open").off("click", openFileInput);
    };
  }, []);


  const handleImageChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    let file = e.target.files[0];
    let key = document.getElementById("UploadprofilePic")?.getAttribute("name") || null;

    try {
      const uploadConfig = await uploadImageServices.UploadImageToS3(file, key);
      let user = { ...data };
      user.display_picture = uploadConfig.data.displayUrl;
      user.key = uploadConfig.data.key;
      setCurrentUser(user);

      setData(user);
      setImagePreviewUrl(uploadConfig.data.displayUrl);
      setLoading(false);
      updateProfile();
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
    }
  };

  const uploadPic = () => {
    const { isUser, manageUser } = props;

    if (isUser || (!manageUser && !isUser)) {
      fileInputRef.current.click();
    }
  };

  const updateProfile = async () => {
    //const { setCurrentUser, currentUser } = props;
    const userId = sessionStorage.getItem("userId")
    let changeEmail = false
    if (userCurrentData.email !== data.email) {
      changeEmail = true
    }
    
    const updatedData = { ...data, is2Factor, is2FMobileChecked, is2FEmailChecked, userId,changeEmail };

    console.log("profileData", updatedData);

    setLoading(true);

    try {
      if (updatedData.username === "" || updatedData.email === "" || updatedData.phone === "" || updatedData.newOrgName === "") {
        setLoading(false);
        toast.warn("All fields are mandatory. Please fill them out.");
      } else if (updatedData.email.length < 5 || !/^\S+@\S+\.\S+$/.test(updatedData.email)) {
        setLoading(false);
        return toast.warn("Please enter a valid email (at least 5 characters and in the correct format).");
      } else {
        const user = await userService.updateProfile(updatedData);

        if (user.status !== 200) {
          setLoading(false);
          toast.error(user.message);
        } else {
          toast.success(user.message);
          setCurrentUser(updatedData);
          //console.log(currentUser);
          setData(updatedData);
          setLoading(false);
          setReadonly(true)
          setReadonlyOrgId(true)
          setReadonlyOrgName(true)
          setReadonlyEmail(true)
          setReadonlyMobileNumber(true)
          sessionStorage.setItem('organizationId', user?.organizationId);
          sessionStorage.setItem('currentUserEmail', user?.user?.email);
          if (user?.isEmailChanged) {
            setisEmailChange(true)
          }

          try {
            const data = {
              organizationId: userDetails.user.currentUser.organizationId,
              actionType: "updateProfile",
              userId: userDetails.user.currentUser._id,
            }
            const addLogs = await addUserLogs(data)
            if (addLogs.status !== 200) {
                toast.error(addLogs.message)
                setLoading(false);
            }
        } catch (error) {
            console.error('Error in saving userlog:', error);
            setLoading(false);
        }

        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setLoading(false);
    }
  };

  const handleActivateUser = async () => {
    try {
      const user = await adminServices.activateUser({ email: data.email });

      if (user.status !== 200) {
        toast.error(user.message);
      } else {
        toast.info(user.message);
        getManagedUserProfile(user.data);
      }
    } catch (error) {
      console.error('Error activating user:', error);
    }
  };

  const handleDeactivateUser = async () => {
    try {
      const user = await adminServices.freezeuser({ email: data.email });

      if (user.status !== 200) {
        toast.error(user.message);
      } else {
        toast.info(user.message);
        getManagedUserProfile(user.data);
      }
    } catch (error) {
      console.error('Error deactivating user:', error);
    }
  };

  const getManagedUserProfile = (userData) => {
    setData(userData);
  };

  const addWordDictonary = async () => {
    try {
      let data = await addDictionary(addWord);
      if (data.status !== 200) {
        toast.error('Failed to add the word');
      } else {
        getDictionary();
      }
      setAddWord('');
    } catch (error) {
      console.error('Error adding word to dictionary:', error);
    }
  };

  const getDictionary = async () => {
    try {
      let data = await getmywords();
      if (data.status === 200) {
        setDictionary(data.words);
      }
    } catch (error) {
      console.error('Error getting dictionary:', error);
    }
  };

  const removeWord = async (word) => {
    try {
      let data = await deletemywords(word);
      if (data.status !== 200) {
        toast.error('Failed to delete the word');
      } else {
        getDictionary();
      }
    } catch (error) {
      console.error('Error removing word:', error);
    }
  };

  const Blog_Products = async () => {
    try {
      let { data } = await getProducts();
      setProducts(data.products);
    } catch (error) {
      console.error('Error getting products:', error);
    }
  };

  const addNewProduct = async () => {
    try {
      let product = addProduct.trim().toLowerCase().replace(/ /g, '_');
      let data = await addProduct(product);
      if (data.status !== 200) {
        toast.error('Failed to add product');
      } else {
        Blog_Products();
      }
      setAddProduct('');
    } catch (error) {
      console.error('Error adding new product:', error);
    }
  };

  const removeProduct = async (product) => {
    try {
      let data = await deleteProduct(product);
      if (data.status !== 200) {
        toast.error('Failed to delete the product');
      } else {
        Blog_Products();
      }
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  const getCharacterLimit = async () => {
    try {
      let data = await adminServices.getCharacterLimit();
      setCharacterLimit(data.character_limit);
    } catch (error) {
      console.error('Error getting character limit:', error);
    }
  };

  const setCharacterLimitValue = async (limit) => {
    try {
      let data = await adminServices.setCharacterLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getCharacterLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting character limit:', error);
    }
  };

  const getSentenceLimit = async () => {
    try {
      let data = await adminServices.getSentenceLimit();
      setSentenceHighlighter(data.sentence_limit);
    } catch (error) {
      console.error('Error getting sentence limit:', error);
    }
  };

  const setSentenceLimitValue = async () => {
    try {
      let limit = sentenceHighlighter.trim();
      if (limit[limit.length - 1] === ',') {
        limit = limit.slice(0, -1);
      }
      setSentenceHighlighter(limit);
      let data = await adminServices.setSentenceLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getSentenceLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting sentence limit:', error);
    }
  };

  const getPlagiarismLimit = async () => {
    try {
      let data = await adminServices.getPlagiarismLimit();
      setPlagiarismLimit(data.plagiarism_limit);
    } catch (error) {
      console.error('Error getting plagiarism limit:', error);
    }
  };

  const setPlagiarismLimitValue = async (limit) => {
    try {
      let data = await adminServices.setPlagiarismLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getPlagiarismLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting plagiarism limit:', error);
    }
  };

  const getParaphraseLimit = async () => {
    try {
      let data = await adminServices.getParaphraseLimit();
      setParaphraseLimit(data.paraphrase_limit);
    } catch (error) {
      console.error('Error getting paraphrase limit:', error);
    }
  };

  const setParaphraseLimitValue = async (limit) => {
    try {
      let data = await adminServices.setParaphraseLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getParaphraseLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting paraphrase limit:', error);
    }
  };

  const handleLimit = (e) => {
    setFontSize(e.target.value);
  };

  const biggify = () => {
    if (fontSize < 20) {
      setFontSize((prevSize) => prevSize + 2);
    } else {
      toast.warn("You have reached the maximum font limit");
    }
  };

  const smallify = () => {
    if (fontSize > 8) {
      setFontSize((prevSize) => prevSize - 2);
    } else {
      toast.warn("You have reached the minimum font limit");
    }
  };

  const fontReset = () => {
    setFontSize(12);
  };
  console.log(editedUser, "__XXXX")
  const editUserData = () => {
    console.log("dataxx", data);
    setEditedUser(data);
    fetchCountryList();
    const fullPhoneNumber = data.phone || '';
    let countryCode = "";
    let phoneNumber = "";

    for (const code in countryLengthMap) {
      if (fullPhoneNumber.startsWith(code)) {
        countryCode = code; // Set the country code if it matches
        phoneNumber = fullPhoneNumber.slice(code.length); // Get the actual phone number
        break; // Exit loop once the country code is found
      }
    }

    const selectedCountry = countries.find(country => country.label === data.country);
    const countryName = selectedCountry ? selectedCountry.label : ""; // Get the country name
    console.log(selectedCountry, "countryNamekkk")
    // Update state with extracted values
    setCountryCode(selectedCountry.phone);
    setSelectedCountryCode(selectedCountry.code)
    setMobileNo(phoneNumber);
    setEditedUser(prev => ({
      ...prev,
      country: countryName, // Set country in editedUser
    }));
    setEditUserPopup(true);
  };

  const handleSave = async () => {
    const userId = sessionStorage.getItem("userId")
    const updatedData = { ...editedUser, userId };

    console.log("usercred", updatedData);

    setLoading(true);

    try {
      if (updatedData.phone === "") {
        setLoading(false);
        return toast.warn("All fields are mandatory. Please fill them out.");
      } else if (errorMessage) {
        setLoading(false);
        return toast.warn("Please fill valid phone number.")
      } else if (!isOtpVerify) {
        setLoading(false);
        return toast.warn("Your phone number isn't verified yet. Please verify to continue.")
      } else {
        const user = await updatePhoneFromSettings(updatedData);

        if (user.status !== 200) {
          setLoading(false);
          toast.error(user.message);
        } else {
          toast.success(user.message);
          //console.log(currentUser);
          //   setData(updatedData);
          getProfileData()
          setEditUserPopup(false)
          setLoading(false);
          setIsOtpVerify(false)
        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setLoading(false);
    }
  };

  console.log(editedUser, "VVVVV")
  //otp
  const handleVerify = async () => {
    // alert("sendOTP")
    setOtpValue("")
    setTimer(60)
    setIsOtpVerify(false)
    if (!editedUser.phone) {
      toast.warn("Please provide phone number.");
      return;
    }
    if (editedUser.phone == data.phone) {
      return toast.warn("This phone number matches your current one. Enter a new number to get the OTP.")
    }
    console.log(editedUser.phone, "editedUser.phone")
    if (!editedUser.phone.startsWith('+')) {
      editedUser.phone = `+${editedUser.phone}`;
    }
    console.log(editedUser.phone, "editedUser.phone")
    let payload = {
      // "email": email,
      "phone": editedUser.phone
    }
    let sentresponse = await sendOtp(payload);
    console.log("sentresponse_", sentresponse.message)

    if (sentresponse.status == 200) {
      toast.success("OTP Sent Successfully")
      setIsResendButton(true)
      setIsOtpShown(true)
    } else {
      toast.warn("Invalid Phone Number")
      return
    }
  }
  console.log(data, "__data")

  const handleOTP = async (e) => {
    console.log("otpdata_", e.length, e)
    setOtpValue(e)
    if (!editedUser.phone.startsWith('+')) {
      editedUser.phone = `+${editedUser.phone}`;
    }
    console.log(editedUser.phone, "inside handleOTP")

    if (e.length == 6) {
      setLoading(true)
      let payload = {
        "phone": editedUser.phone,
        "otp": e
      }
      let verifyOtpresponse = await verifyOtp(payload)
      console.log("verifyOtp_", verifyOtpresponse)
      if (verifyOtpresponse.status == 200) {
        setLoading(false)
        setIsOtpShown(false)
        setIsOtpVerify(true)
        setVerifyDisable(true)
        setisResendCodeDisable(false)
        setIsResendButton(false)
      } else {
        setLoading(false)
        setIsOtpVerify(false)
        setIsOtpShown(true)
        setisResendCodeDisable(true)
        setIsResendButton(true)
      }
    }
  }
  console.log(isOtpVerify, "isOtpVerify")

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
    if (timer == 0) {
      setisResendCodeDisable(false);
      setIsOtpShown(false)
    } else {
      setisResendCodeDisable(true);

    }
    return () => {
      clearTimeout(timerId);
    };
  }, [timer]);

  return (
    <div>
      <Loader loading={loading} />

      <div className={isDarkMode ? "account-setting-main" : "bg-white rounded shadow"} style={{ padding: windowWidth ? "10px" : "" }}>
        <br />
        {/* <form> */}
        <div className="row mt-2 d-flex align-items-center">
          <div className="col-4 col-sm-3 col-md-2 firstcol">
            <div>
              <div className="hvrpic" name={data?.key}>
                <ImageAvatarsMui
                  id="UploadprofilePic"
                  alt={data?.username}
                  src={data?.display_picture}
                />
                {isUser || !manageUser ? (
                  <div className="middleIcon">
                    <Button component="span" ref={fileInputRef} onClick={uploadPic}>
                      <CloudUploadRoundedIcon />
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <br />
              <input
                className="fileInput"
                type="file"
                id="fileup"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
                style={{ display: "none" }}
                readOnly={readonly}
              />
            </div>
          </div>

          <div
            className=" col-8 col-sm-9 col-md-10"
            style={{ textAlign: "left", marginTop: windowWidth ? "15px" : "" }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: "5px"
              }}
              onMouseEnter={() => setHoveredField('username')}
              onMouseLeave={() => setHoveredField(null)}
            >
              <input
                value={username}
                id="bootstrap-input"
                onChange={handleChange}
                name="username"
                readOnly={readonly}
                autoComplete="off"
                style={{
                  backgroundColor: isDarkMode ? "black" : "white",
                  color: isDarkMode ? "#ECE6F0" : "black",
                  border: "none",
                  borderBottom: readonly ? "none" : "2px solid #1976d2",
                  borderRadius: "5px",
                  outline: "none",
                  // padding: "10px 40px 10px 10px",

                }}
              />
              {readonly && (
                <i
                  onClick={handleEdit}
                  className="fa fa-pencil point"
                  aria-hidden="true"
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    color: 'rgb(25, 118, 210)',
                    display: hoveredField === 'username' ? 'inline' : 'none',
                  }}
                ></i>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: "5px"
              }}
              onMouseEnter={() => setHoveredField('email')}
              onMouseLeave={() => setHoveredField(null)}
            >
              <input
                className="sub-headings zzz"
                value={email}
                id="bootstrap-input"
                onChange={handleChange}
                name="email"
                readOnly={readonlyEmail}
                autoComplete="off"
                style={{
                  backgroundColor: isDarkMode ? "black" : "white",
                  border: "none",
                  borderBottom: readonlyEmail ? "none" : "2px solid #1976d2",
                  borderRadius: "5px",
                  outline: "none",
                  width: "300px"

                }}
              />
              {readonlyEmail && (
                <i
                  onClick={handleEditEmail}
                  className="fa fa-pencil point"
                  aria-hidden="true"
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    color: 'rgb(25, 118, 210)',
                    display: hoveredField === 'email' ? 'inline' : 'none',
                  }}
                ></i>
              )}
            </div>

          </div>


        </div>

        <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto", }} />

        {/* display pic and name */}
        <div className="row mt-3">
          <div className="col-md-12 firstcol">

            <p className="h6-headings" style={{ color: isDarkMode ? "#ECE6F0" : "" }}>Email Verified <CheckCircleRoundedIcon />  </p>
            <p className="sub-headings" style={{ color: isDarkMode ? "white" : "" }}>{verifiedEmail}</p>
          </div>
        </div>
        <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto" }} />
        {data?.Role == "Organization" &&
          <>
            <div className="row mt-3">
              <div className="col-md-12 firstcol">

                <p className="h6-headings" style={{ color: isDarkMode ? "#ECE6F0" : "" }}>Organization Details</p>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  Organization Id: <span className="sub-headings zzz" style={{ marginLeft: "15px" }}>{orgId}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                  onMouseEnter={() => setHoveredField('orgName')}
                  onMouseLeave={() => setHoveredField(null)}
                >
                  Organization Name:
                  <input
                    className="sub-headings zzz"
                    value={orgName}
                    id="bootstrap-input"
                    onChange={handleChange}
                    name="newOrgName"
                    readOnly={readonlyOrgName}
                    autoComplete="off"
                    style={{
                      backgroundColor: isDarkMode ? "black" : "white",
                      // color: isDarkMode ? "#ECE6F0" : "gray",
                      border: "none",
                      borderBottom: readonlyOrgName ? "none" : "2px solid #1976d2",
                      borderRadius: "5px",
                      outline: "none",
                      marginLeft: "15px",
                    }}
                  />
                  {readonlyOrgName && (
                    <>
                      <i className="fa fa-pencil point" aria-hidden="true"
                        onClick={handleEditOrgName}
                        style={{
                          marginLeft: '10px',
                          cursor: 'pointer',
                          color: 'rgb(25, 118, 210)',
                          display: hoveredField === 'orgName' ? 'inline' : 'none',
                        }}
                      ></i>
                    </>
                  )}

                </div>
              </div>
            </div>
            <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto" }} />
          </>
        }
        <div className="row mt-2">
          <div className="col-md-12 firstcol">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
              onMouseEnter={() => setHoveredField('mobileNumber')}
              onMouseLeave={() => setHoveredField(null)}
            >  <p className="h6-headings" style={{ color: isDarkMode ? "#ECE6F0" : "", margin: "0px" }}>Phone</p>
              <div
                className="sub-headings zzz"
                style={{
                  backgroundColor: isDarkMode ? "black" : "white",
                  // color: isDarkMode ? "#ECE6F0" : "black",
                  border: "none",
                  borderBottom: readonlyMobileNumber ? "none" : "2px solid #1976d2",
                  borderRadius: "5px",
                  outline: "none",
                  // padding: "10px 40px 10px 10px",
                  marginLeft: "15px",

                }}
              >{mobileNumber}</div>
              <button
                onClick={editUserData}
                data-toggle="modal"
                // className="accountSettingsBtn"
                style={{
                  borderStyle: "none",
                  borderRadius: "8px",
                  boxShadow: "2px 2px 5px -2px #000000",
                  backgroundColor: "#ffffff",
                  color: "#1976D2",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "500",
                  fontSize: "0.9rem",
                  padding: "7px",
                  width: "150px",
                  marginLeft: "20px",
                }}
              >
                Update Phone
              </button>
              {/* {readonlyMobileNumber && (
                <i
                  onClick={editUserData}
                  className="fa fa-pencil point"
                  aria-hidden="true"
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    color: 'rgb(25, 118, 210)',
                    display: hoveredField === 'mobileNumber' ? 'inline' : 'none',
                  }}
                ></i>
              )} */}
            </div>
          </div>
        </div>
        <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto" }} />
        <div className="row mt-2">
          <div
            className="col-md-12 firstcol"
          >

            <div>
              <p className="h6-headings" style={{ color: isDarkMode ? "#ECE6F0" : "" }}>Subscription Plan</p>
              <p className="sub-headings"><i
                className="fa fa-check  icon_color mr-2"
                aria-hidden="true"
              ></i> {data?.planName || "free Trial"}</p>
              <p className="sub-headings2" >Expiry :{" "}
                {expiryDate
                  ? new Date(expiryDate)
                    .toString()
                    .slice(0, 15)
                  : "No Expiry Date"}</p>
            </div>

            <div className="mt-2">
              <Link to={{ pathname: "/pricing" }}>
                <button
                  style={{
                    border: `2px solid ${isDarkMode ? "#ECE6F0" : "#1976d2"}`,
                    borderRadius: "8px",
                    boxShadow: "3px 3px 7px -2px #000000",
                    backgroundColor: isDarkMode ? "black" : "#ffffff",
                    color: isDarkMode ? "#ECE6F0" : "#1976D2",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    padding: "7px",
                    width: "150px"
                  }}
                >
                  Upgrade Plans
                </button>
              </Link>
            </div>

          </div>
        </div>
        <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto" }} />
        {/* <div className="row mt-2">
          <div className="col-md-11 firstcol">
            <PreferencesAccordionMui
              // is2Factor={is2Factor}
              // handle2FactorSwitch={handle2FactorSwitch}
              // is2FEmailChecked={is2FEmailChecked}
              // handleEmailCheckBox={handleEmailCheckBox}
              renderComponent={() => <RadioButtonMui
                optionsList={radioOptions}
                selectedValue={radioSelection}
                handleChange={(event) => onRadioChange(event)}
              />}
            />
            {
              (role == "Organization" || isSubAdmin == true) &&
              <TwoFactorComp
                data={is2FReqApprovedMsg !== "" ? data2F : data2F}
                getProfile={getProfileData}
              />
            }

          </div>
        </div> */}

        {!manageUser && isAdmin ? (
          <div>
            <div className="row mt-3">
              <h4 className="ft_lg firstcol">Character Limit</h4>
            </div>
            <hr />

            <div className="row firstcol">
              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Document: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${character_limit === undefined
                      ? 0
                      : character_limit
                      }`}
                    type="number"
                    name="character_limit"
                    value={character_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setCharacterLimit(character_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Paraphrase: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${paraphrase_limit === undefined
                      ? 0
                      : paraphrase_limit
                      }`}
                    type="number"
                    name="paraphrase_limit"
                    value={paraphrase_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setParaphraseLimitValue(paraphrase_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Plagiarism: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${plagiarism_limit === undefined
                      ? 0
                      : plagiarism_limit
                      }`}
                    type="number"
                    name="plagiarism_limit"
                    value={plagiarism_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setPlagiarismLimitValue(plagiarism_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Sentence Highlighter: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`${sentence_highlighter === undefined
                      ? 0
                      : sentence_highlighter
                      }`}
                    type="text"
                    name="sentence_highlighter"
                    value={sentence_highlighter}
                    onChange={(e) => setState({ sentence_highlighter: e.target.value })}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={setSentenceLimit}
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

            </div>
          </div>
        ) : (
          ""
        )}

        {!manageUser && isAdmin ? (
          <div className="mb-5">
            <div className="row mt-5">
              <h4 className="ft_lg firstcol">Blog Settings</h4>
            </div>
            <hr />
            <br />
            <div className="row firstcol">
              <div className="d-inline-block ft_md point font-weight-bold del">
                <button className="btn btn-primary" onClick={() => setProductModal(true)}>
                  Add Blog Products
                </button>
              </div>
            </div>
          </div>
        ) : ("")}

        {isUser ? (
          <div className="row firstcol">
            <div className="">
              {" "}

              <div className="d-inline-block ft_md point font-weight-bold del  mt-3">
                <button
                  data-toggle="modal"
                  data-target="#ChangePassModal"
                  // className="accountSettingsBtn"
                  style={{
                    borderStyle: "none",
                    borderRadius: "8px",
                    boxShadow: "2px 2px 5px -2px #000000",
                    backgroundColor: "#ffffff",
                    color: "#1976D2",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    padding: "7px",
                    width: "150px",
                  }}
                >
                  Change Password
                </button>
              </div>
              <p className="ft_xs mt-2">
                Upgrade your security check, by changing your password.
              </p>

              {isUser &&
                <div>
                  <div className="d-inline-block ft_md point font-weight-bold del">
                    <button
                      // className={!isUser ? "AdminVisible" : "accountSettingsBtn"}
                      style={{
                        borderStyle: "none",
                        borderRadius: "8px",
                        boxShadow: "2px 2px 5px -2px #000000",
                        backgroundColor: "#ffffff",
                        color: "#1976D2",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                        fontSize: "0.9rem",
                        padding: "7px",
                        width: "150px",
                      }}
                      onClick={role == "Organization" ? handleDeleteOrganizationUser : handleDeleteAccount}
                    >
                      Delete Account {isUser}
                    </button>
                  </div>
                  <p className="ft_xs mt-2">
                    This account is no longer available, and all your saved data
                    will be permanently deleted.
                  </p>
                </div>
              }
            </div>

            <div className="col-sm-6  mt-4">
              <div>

              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <hr style={{ width: windowWidth ? "100%" : "95%", margin: "15px auto", color: "#444444" }} />

        <div className="">
          {!manageUser || isUser ? (
            <div className="text-center">
              <Link
                to={{
                  pathname: isAdmin
                    ? "/admindashboard"
                    : isSubAdmin
                      ? "/subAdminManageUser"
                      : "/dashboard",
                }}
              >
                <button
                  className="btn btn-outline-primary mr-3 mb-4"
                  type="button"
                  id="backBtn"
                  style={{
                    width: "20%",
                  }}
                >
                  Back
                </button>
              </Link>

              <button
                className="btn btn-primary mb-4"
                type="submit"
                id="backBtn"
                onClick={updateProfile}
                style={{
                  width: "20%",
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button
                type="button"
                id="backBtn"
                className="btn btn-outline-primary mr-3 mb-4"
                style={{
                  width: "20%",
                }}
                disabled={!data.freeze ? true : false}
                onClick={handleActivateUser}
              >
                Activate
              </button>
              <button
                type="button"
                id="backBtn"
                className="btn btn-primary mb-4"
                style={{
                  width: "20%",
                }}
                disabled={data.freeze ? true : false}
                onClick={handleDeactivateUser}
              >
                Deactivate
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}
        centered
        id="shareModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Personal Dictionary
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="input-group mb-3">
              <input
                type="text"
                name="addWord"
                value={addWord}
                className="form-control"
                placeholder="Add a new word"
                aria-label="Add a new word"
                onChange={(e) => setAddWord(e.target.value)}
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={addWordDictonary}
                >
                  <i className="fa fa-plus ft_sm"></i> ADD
                </button>
              </div>
            </div>
            <small>
              These words will no longer be flagged as misspellings.
            </small>
            <hr id="dictHr" />
            <div>
              {Dictionary.length > 0 &&
                Dictionary.map((word) => {
                  return (
                    <div
                      key={word}
                      className="d-flex justify-content-between"
                    >
                      <p className="ft_sm">
                        <i
                          className="fa fa-book text-primary mr-2"
                          aria-hidden="true"
                        ></i>
                        {word}
                      </p>

                      <p
                        className="point ft_sm"
                        onClick={() => removeWord(word)}
                      >
                        <i
                          className="fa fa-trash point mr-2 text-primary"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal */}

      {/* Product Modal */}
      <Modal
        show={productModal}
        onHide={() => setProductModal(false)}
        centered
        id="shareModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Blog Products
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="input-group mb-3">
              <input
                type="text"
                name="addWord"
                value={addProduct}
                className="form-control"
                placeholder="Add a new product"
                aria-label="Add a new product"
                onChange={(e) => setAddWord(e.target.value)}
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={addNewProduct}
                >
                  <i className="fa fa-plus ft_sm"></i> ADD
                </button>
              </div>
            </div>
            <hr id="dictHr" />
            <div>
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <div
                      key={product}
                      className="d-flex justify-content-between"
                    >
                      <p className="ft_sm">
                        <i
                          className="fa fa-product-hunt text-primary mr-2"
                          aria-hidden="true"
                        ></i>
                        {product}
                      </p>

                      <p
                        className="point ft_sm"
                        onClick={() => removeProduct(product)}
                      >
                        <i
                          className="fa fa-trash point mr-2 text-primary"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Product Modal */}

      {/* ........ */}

      <Modal
        show={showEditUserPopup}
        onHide={() => setEditUserPopup(false)}
        centered
      >
        <Modal.Header
          toggle={() => setEditUserPopup(false)}
        >

          Edit Phone Number
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-between align-items-center">
              <p>Phone: </p>
              <div style={{ marginBottom: "10px", marginLeft: "15px", width: "80%" }}>
                <InputBoxMobile
                  onChange={(e) => handleNumber(e)} // Ensure to pass the value
                  handleCountryCode={handleCountry}
                  value={mobileNo}
                  countryCode={countryCode}
                  selectedCountryCode={selectedCountryCode}
                />
              </div>
            </div>
            {errorMessage && <div className="text-danger mt-1">{errorMessage}</div>}
          </div>
          {isOtpShown &&
            <>
              <div className="mt-2 d-flex justify-content-center">
                <OtpInputBox value={otpValue} onChange={(e) => handleOTP(e)} />
              </div>
              {(!isOtpVerify && otpValue?.length == 6) && <div>
                <p className="errorMsg mt-1">Please enter the correct OTP </p>
              </div>
              }
            </>}

          <div className="mt-2 d-flex">
            {isResendButton && <Button className="otpresend p-0" onClick={handleVerify} disabled={isResendCodeDisable}>Resend Code</Button>}
            {/* <a href="handleResend">Resend Code</a> */}
            {isOtpShown && <p className="ml-5"> {isOtpShown && formatTime(timer)}</p>}
          </div>

          {(isOtpVerify) &&
            <div className="d-flex mt-2">
              <p className="verifyMsg">Phone Number Verified </p><img className="tickicon ml-2 mt-1" src={tick}></img>
            </div>
          }

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "25px" }}>
            <button className="btn btn-primary" disabled={verifyDisable} onClick={handleVerify}>Verify</button>
            <button className="btn btn-secondary ml-1" onClick={() => setEditUserPopup(false)}>Cancel</button>
            <button className="btn btn-primary ml-1" onClick={handleSave}>Save</button>
          </div>
        </Modal.Body>

      </Modal>

      <div class="modal fade" id="ChangePassModal">
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ width: "360px" }}
        >
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <div className="modal-title h4">Change Password</div>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div className="row ">
                <label style={{ marginLeft: "35%", marginTop: "3%" }}>
                  New Password
                </label>
              </div>
              <div className="row">
                <input
                  type="password"
                  className="form-control"
                  name="npassword"

                  value={npassword}
                  onChange={(e) => onChangeNPassword(e)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />
              </div>
              <p
                className="p-1 text-center"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors}
                <br />
                {cerrors}
              </p>
              <div className="row">
                <label style={{ marginLeft: "30%", marginTop: "3%" }}>
                  Confirm New Password
                </label>
              </div>
              <div className="row">
                <input
                  type="password"
                  className="form-control"
                  name="cpassword"
                  value={cpassword}
                  onChange={(e) => onChangeCPassword(e)}
                  disabled={npassword == "" ? true : errors !== "" ? true : false}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />
              </div>
              <div className="row" style={{ marginTop: "8%" }}>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    autoComplete="current-password"
                    onClick={onClickResetPassword}
                    style={{
                      // color: "white",
                      // backgroundColor: "#1976d2",
                      width: "50%",
                    }}
                    disabled={disable}
                    data-dismiss="modal"
                  >
                    UPDATE PASSWORD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ................ */}
    </div >
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsNew); 
