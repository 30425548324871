export const fullSizeEditor = () => {
    return {
        type: 'FULL_SIZE_EDITOR'
    };
};

export const normalSizeEditor = () => {
    return {
        type: 'NORMAL_SIZE_EDITOR'
    };
};

export const fullscreen = () => {
    return {
        type: 'Full_Screen'
    };
};
export const exitScreen = () => {
    return {
        type: 'Exit_Screen'
    };
};
