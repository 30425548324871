import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, IconButton, Modal, Card, CardContent, CardMedia, Typography, Grid, Menu, MenuItem, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { Modal as M_Modal, Button as B_Button, Form } from 'react-bootstrap';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import WindowIcon from '@mui/icons-material/Window';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Link } from 'react-router-dom';
import { DocumentGridCard, DocumentListCard } from './MyDocumentsCards';
import documentService from '../services/document';
import { ListItemText } from "@material-ui/core";
import { toast } from 'react-toastify';
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { uploadDocument } from '../redux/coauthor/coauthor.action';
import { PermanentDeleteAllModal, DeleteAllMyDocsModal } from './MyDocumentsCardsModal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { shareFolderId } from '../redux/coauthor/coauthor.action';
import LoopIcon from '@mui/icons-material/Loop';
import { getTeam } from '../services/userService';
import "./MyDocuments.css"
import Loader from "../utils/loader";
import mammoth from "mammoth";
import axios from 'axios';
// const pdfjs = require('pdfjs-dist/legacy/build/pdf');
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { checkFeatureAccess } from './featureAccess';
import { addUserLogs } from '../services/userLogServices';
import SubscriptionRequired from './SubscriptionRequired';
import { useCheckFeatureAccess } from './CheckFeature';
import { useCheckSubscriptionPlanAccess } from './SubscriptionAccess';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const MyDocumentsNew = () => {

    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectAllDeleteChecked, setSelectAllDeleteChecked] = useState(false);
    const [selectAllSharedChecked, setSelectAllSharedChecked] = useState(false);
    const [isPermanentDeleteAllModalOpen, setIsPermanentDeleteAllModalOpen] = useState(false);
    const [selectedIdsDeletePermanently, setSelectedIdsDeletePermanently] = useState()
    const [selectedIdsDeleteMyDocs, setSelectedIdsDeleteMyDocs] = useState()
    const [isDeleteAllMyDocsModalOpen, setIsDeleteAllMyDocsModalOpen] = useState(false);
    const [isDeleteAllSharedDocsModalOpen, setIsDeleteAllSharedDocsModalOpen] = useState(false);
    const [selectedIdsDeleteSharedDocs, setSelectedIdsDeleteSharedDocs] = useState()
    const [isCreateNewFolderModalOpen, setIsCreateNewFolderModalOpen] = useState(false)
    const [folderName, setFolderName] = useState('');
    const [folderId, setFolderId] = useState('')
    const [folderOpen, setFolderOpen] = useState(false)

    const [listView, setListView] = useState(false)
    const [documents, setDocuments] = useState([])
    const [sortOrder, setSortOrder] = useState('asc');
    const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
    const [subReqOpen, setSubReqOpen] = useState(false)


    const [combinedMyDocs, setCombinedMyDocs] = useState([])
    const [combinedDeletedDocs, setCombinedDeletedDocs] = useState([])
    const [myFolders, setMyFolders] = useState([])
    const [deletedFolders, setDeletedFolders] = useState([])
    const [initialMyFolders, setInitialMyFolders] = useState([])
    const [initialDeletedFolders, setInitialDeletedFolders] = useState([])

    const [myDocs, setMyDocs] = useState([]);
    const [deletedDocs, setDeletedDocs] = useState([]);
    const [sharedDocs, setSharedDocs] = useState([]);

    const [myDocsCount, setMyDocsCount] = useState();
    const [deletedDocsCount, setDeletedDocsCount] = useState();
    const [sharedDocsCount, setSharedDocsCount] = useState();

    const [initialMyDocs, setInitialMyDocs] = useState([])
    const [initialDeletedDocs, setInitialDeletedDocs] = useState([])
    const [initialSharedDocs, setInitialSharedDocs] = useState([])
    const [myDocsCurrentPage, setMyDocsCurrentPage] = useState(1);
    const [sharedDocsCurrentPage, setSharedDocsCurrentPage] = useState(1);
    const [deletedDocsCurrentPage, setDeletedDocsCurrentPage] = useState(1);
    const [userProfileColor, setUserProfileColor] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [itemsPerPage, setItemsPerPage] = useState( windowWidth > 991 ? 9 : windowWidth > 767 ? 8 : 3);
    const Role = sessionStorage.getItem('Role');

    const [loading, setLoading] = useState(false);
    const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
    const canAddNewdoc = useCheckFeatureAccess(currentNavItem,'newDocument')
    const canUploadDoc = useCheckFeatureAccess(currentNavItem,'uploadDocumnet')
    const canCreateFolder = useCheckFeatureAccess(currentNavItem,'createFolder')
    const canDeleteAllDocs = useCheckFeatureAccess(currentNavItem,'deletAll')
    const accessToPermanantDelete = useCheckFeatureAccess(currentNavItem,'deletePermanantly')
   
    // const accessToPermanantDelete = checkFeatureAccess(currentNavItem,'deletePermanantly')
    const subscriptionPlan = useSelector((state) => state.manageSubscriptionPlan.subscriptionPlan);
    console.log(subscriptionPlan,"kksubscriptionPlan")
    const accessToDocScreen = useCheckSubscriptionPlanAccess("Document")
   
    console.log(accessToDocScreen,"jjkaccessToDocScreen");
    
    

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    useEffect(()=>{
        console.log("accessToDocScreen_",accessToDocScreen)
        if(accessToDocScreen == false ){
         setSubReqOpen(true)
        }
     },[accessToDocScreen])
    
    useEffect(() => {
        const handleResize = () => {
          const newWindowWidth = window.innerWidth;
          setWindowWidth(newWindowWidth);
          
          // Update itemsPerPage based on the new window width
          if (newWindowWidth > 991) {
            setItemsPerPage(9);
          } else if (newWindowWidth > 767) {
            setItemsPerPage(8);
          } else {
            setItemsPerPage(3);
          }
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const history = useHistory();
    console.log("Role", Role)
    // Select all cards
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllChecked(isChecked);
        setCombinedMyDocs((prevDocuments) => {
            return prevDocuments.map((doc) => ({
                ...doc,
                isChecked: isChecked
            }));
        });


        // setDeletedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });

        // setSharedDocs((prevDocuments) => {
        //     return prevDocuments.map((doc) => ({
        //         ...doc,
        //         isChecked: isChecked
        //     }));
        // });
    };


    const handleSelectAllDeleteChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllDeleteChecked(isChecked);
        setCombinedDeletedDocs((prevDocuments) => {
            return prevDocuments.map((doc) => ({
                ...doc,
                isChecked: isChecked
            }));
        });
    }

    const handleSelectAllSharedChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAllSharedChecked(isChecked);
        setSharedDocs((prevDocuments) => {
            return prevDocuments.map((doc) => ({
                ...doc,
                isChecked: isChecked
            }));
        });
    }

    const closePermanentDeleteAllModal = () => {
        setIsPermanentDeleteAllModalOpen(false);
    };

    const closeDeleteAllMyDocsModal = () => {
        setIsDeleteAllMyDocsModalOpen(false);
    };

    const closeDeleteAllSharedDocsModal = () => {
        setIsDeleteAllSharedDocsModalOpen(false);
    };

    // Download pdf and word doc
    const handleDownloadDropdownClick = (event) => {
        setDownloadAnchorElDropdown(event.currentTarget);
    };

    const handleDownloadDropdownClose = () => {
        setDownloadAnchorElDropdown(null);
    };


    const handleMyDocsPageChange = (event, page) => {
        setMyDocsCurrentPage(page);
    };

    const handleSharedDocsPageChange = (event, page) => {
        setSharedDocsCurrentPage(page);
    };

    const handleDeletedDocsPageChange = (event, page) => {
        setDeletedDocsCurrentPage(page);
    };


    // Get documents for the current page
    const getMyDocsCurrentPage = () => {
        const startIndex = (myDocsCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return combinedMyDocs.slice(startIndex, endIndex);
    };

    const getSharedDocsCurrentPage = () => {
        const startIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sharedDocs.slice(startIndex, endIndex);
    };

    const getDeletedDocsCurrentPage = () => {
        const startIndex = (deletedDocsCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return combinedDeletedDocs.slice(startIndex, endIndex);
    };


    const dispatch = useDispatch();

    const convertTextToHtml = (text) => {
        // Split the text into paragraphs based on line breaks
        const paragraphs = text.split('\n');

        // Map each paragraph to wrap it with <p> tags and handle line breaks
        const htmlContent = paragraphs.map((paragraph) => {
            // Replace consecutive line breaks with <br> tags within the paragraph
            const paragraphWithBreaks = paragraph.replace(/\n{2,}/g, '<br><br>');
            // Wrap the paragraph with <p> tags
            return `<p>${paragraphWithBreaks}</p>`;
        });

        // Join the paragraphs into a single HTML string
        return htmlContent.join('');
    };

    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    


    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        console.log('Selected file:', file);
    
        if (file) {
            const reader = new FileReader();
    
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const fileName = file.name;
                let contentToSend;
    
                if (fileName.endsWith('.txt')) {
                    const textContent = new TextDecoder().decode(arrayBuffer);
                    contentToSend = convertTextToHtml(textContent); // Your function to convert plain text to HTML
                } else if (fileName.endsWith('.docx')) {
                    try {
                        // Use mammoth to convert DOCX to HTML
                        let { value: htmlContent, messages } = await mammoth.convertToHtml({ arrayBuffer });
    
                        // Optionally log any warnings from mammoth (e.g., unsupported elements)
                        if (messages && messages.length) {
                            console.warn('Mammoth warnings:', messages);
                        }
                        if(!htmlContent){
                            // alert("inside")

                            // Convert arrayBuffer to string to apply regex
                            const decoder = new TextDecoder("utf-8");
                            const textContent = decoder.decode(arrayBuffer);
                        
                            // Use regex to extract content from body tag
                            const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
                            const match = bodyContentRegex.exec(textContent);
                            console.log(match,"match")
                            htmlContent = match ? match[1] : '';
                        }
    
                        // Set the HTML content to be sent
                        contentToSend = htmlContent;
                        console.log(contentToSend, "__contentToSend");
    
                    } catch (error) {
                        console.error("Error reading DOCX file", error);
                        toast.error("Error reading DOCX file");
                        return;
                    }
                }  else if (fileName.endsWith('.pdf')) {
                    try {

                        const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
                        let htmlContent = '';
           
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const content = await page.getTextContent();
           
                            // Create a div to wrap the content of each page
                            let pageHTML = `<div style="margin: 20px 0;">`;
           
                            // Group text items into paragraphs based on their y-coordinate proximity
                            let paragraphs = [];
                            let currentParagraph = [];
                            let lastY = null;
           
                            content.items.forEach((item) => {
                                const text = item.str;
                                const y = item.transform[5];
           
                                // If there's a significant difference in y-coordinates, consider it a new paragraph
                                if (lastY !== null && Math.abs(lastY - y) > 10) {
                                    paragraphs.push(currentParagraph);
                                    currentParagraph = [];
                                }
           
                                currentParagraph.push(text);
                                lastY = y;
                            });
           
                            // Add the last paragraph
                            if (currentParagraph.length > 0) {
                                paragraphs.push(currentParagraph);
                            }
           
                            // Convert paragraphs into HTML
                            paragraphs.forEach((paragraph) => {
                                pageHTML += `<p>${paragraph.join(' ')}</p>`;
                            });
           
                            pageHTML += `</div>`;
                            htmlContent += pageHTML;
                        }
           
                        console.log(htmlContent, "pdffiletext");
                        contentToSend = htmlContent

                        
                    } catch (error) {
                        console.error("Error reading  file", error);
                        return;
                    }
                
                } else if (fileName.endsWith('.doc')) {
                        // For .doc files, send the file to the backend API for processing
                        const formData = new FormData();
                        formData.append('file', file);
    
                        const response = await axios.post('https://api.mypoet360.com/api/texteditor/readDocFile', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            }
                        });
    
                        // Check if the response contains the processed content
                        if (response && response.data) {
                            console.log(response,"__res")
                            if(fileName.endsWith('.pdf')){
                                contentToSend = response.data
                            }else{
                                contentToSend = response.data.content._body;
                            }
                            
                            console.log(contentToSend, "__contentToSend");
                        } else {
                            console.error('No content received from API');
                            return;
                        }
                }else {
                    toast.warning("Please upload .txt, .doc, or .docx file");
                    return;
                }
    
                // Navigation logic based on the role
                if (Role === "Organization") {
                    history.push({
                        pathname: '/grammarcheck',
                        state: { fileContent: contentToSend, fileName }
                    });
                    dispatch(uploadDocument(true));
                } else {
                    history.push({
                        pathname: '/mynewdocuments',
                        state: { fileContent: contentToSend, fileName }
                    });
                    dispatch(uploadDocument(true));
                }
            };
    
            // Read the file as ArrayBuffer for mammoth.js and other types
            reader.readAsArrayBuffer(file);
            // reader.readAsText(file);
        }
    }; //docx txt working



    const handleWordDownload = async() => {
        const selectedDocuments = combinedMyDocs.filter((docs) =>
            docs.isChecked
        );


        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }

        const wordDocContent = selectedDocuments
            .map(
                (doc) => {
                    // const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
                    let description = doc.onlyDocumentContent
                    return `<head><style>
                    table, th, td {
                        border: 1px solid black;
                        border-collapse: collapse;
                    }
                    th, td {
                        padding: 5px;
                        text-align: left;
                    }
                </style></head><body>${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/></body>`
                }
            )
            .join('');


        asBlob(wordDocContent).then(docx => {
            saveAs(docx, `Documents.docx`);
        });

        const data = {
            userId: sessionStorage.getItem("userId"),
            organizationId: sessionStorage.getItem("organizationId"),
            actionType: "downloadDoc",
          };
    
          const addLogs = await addUserLogs(data);
          if (addLogs.status !== 200) {
            toast.error(addLogs.message);
          }
    }


    const handlePDFDownload = async() => {
        const selectedDocuments = combinedMyDocs.filter((docs) => docs.isChecked);

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to download`);
            return;
        }

        const pdfContent = selectedDocuments
            .map((doc) => {
                // const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = doc.onlyDocumentContent

                // Customize the HTML structure as needed
                return `<head><style>
                table, th, td {
                    border: 1px solid black;
                    border-collapse: collapse;
                }
                th, td {
                    padding: 5px;
                    text-align: left;
                }
            </style></head><body><div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div></body>`;
            })
            .join('');

        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: 'Documents.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, 'Documents.pdf');
        });

        const data = {
            userId: sessionStorage.getItem("userId"),
            organizationId: sessionStorage.getItem("organizationId"),
            actionType: "downloadDoc",
          };
    
          const addLogs = await addUserLogs(data);
          if (addLogs.status !== 200) {
            toast.error(addLogs.message);
          }
    };





    const handleDropdownSelectforDownload = (selectedOption) => {
        console.log(selectedOption, "selected option");
        switch (selectedOption) {
            case "MS-Word":
                handleWordDownload()
                break;
            case "PDF":
                console.log("PDF")
                handlePDFDownload();
                break;
        }
        handleDownloadDropdownClose();
    };



    // Sorting documents
    const handleSortClick = () => {
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newSortOrder);
        const sortMyDocs = [...myDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortSharedDocs = [...sharedDocs].sort((a, b) => {
            const titleA = a.documentName.toUpperCase();
            const titleB = b.documentName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortMyFolders = [...myFolders].sort((a, b) => {
            const titleA = a.folderName.toUpperCase();
            const titleB = b.folderName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });
        const sortDeletedFolders = [...deletedFolders].sort((a, b) => {
            const titleA = a.folderName.toUpperCase();
            const titleB = b.folderName.toUpperCase();

            return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
        });

        setMyDocs(sortMyDocs);
        setDeletedDocs(sortDeletedDocs);
        setSharedDocs(sortSharedDocs);
        setMyFolders(sortMyFolders);
        setDeletedFolders(sortDeletedFolders);

    };


    // Search Documents
    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase();

        const filteredMyDocs = initialMyDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredSharedDocs = initialSharedDocs.filter((doc) =>
            doc.documentName.toLowerCase().includes(newSearchTerm)
        );
        const filteredMyFolders = initialMyFolders.filter((doc) =>
            doc.folderName.toLowerCase().includes(newSearchTerm)
        );
        const filteredDeletedFolders = initialDeletedFolders.filter((doc) =>
            doc.folderName.toLowerCase().includes(newSearchTerm)
        );

        setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
        setDeletedDocs(() => (newSearchTerm ? filteredDeletedDocs : initialDeletedDocs));
        setSharedDocs(() => (newSearchTerm ? filteredSharedDocs : initialSharedDocs));
        setMyFolders(() => (folderOpen) ? [] : (newSearchTerm ? filteredMyFolders : initialMyFolders));
        setDeletedFolders(() => (newSearchTerm ? filteredDeletedFolders : initialDeletedFolders))
    };


    // Individual Card Selection
    const handleMyDocsCardChange = (index) => {
        const pageIndex = (myDocsCurrentPage - 1) * itemsPerPage;
        const docIndex = pageIndex + index; // Calculate the index of the document in the entire list
        setCombinedMyDocs((prevDocuments) => {
            const newDocuments = [...prevDocuments];
            newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

            // Check if all individual cards are checked or not
            const allChecked = newDocuments.every((doc) => doc.isChecked);
            setSelectAllChecked(allChecked);

            return newDocuments;
        });
    };


    const handleDeletedDocsCardChange = (index) => {
        const pageIndex = (deletedDocsCurrentPage - 1) * itemsPerPage;
        const docIndex = pageIndex + index;
        setCombinedDeletedDocs((prevDocuments) => {
            const newDocuments = [...prevDocuments];
            newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

            // Check if all individual cards are checked or not
            const allChecked = newDocuments.every((doc) => doc.isChecked);
            setSelectAllDeleteChecked(allChecked);

            return newDocuments;
        });
    };

    const handleSharedDocsCardChange = (index) => {
        const pageIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
        const docIndex = pageIndex + index;
        setSharedDocs((prevDocuments) => {
            const newDocuments = [...prevDocuments];
            newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

            // Check if all individual cards are checked or not
            const allChecked = newDocuments.every((doc) => doc.isChecked);
            setSelectAllSharedChecked(allChecked);

            return newDocuments;
        });
    };


    const handleDeleteAllPermanentlyClick = () => {


        const selectedDocuments = combinedDeletedDocs.filter((docs) => docs.isChecked);

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to delete`);
            return;
        }

        const selectedIds = selectedDocuments.map((doc) => doc._id);

        setSelectedIdsDeletePermanently(selectedIds)

        setIsPermanentDeleteAllModalOpen(true);

    }    

    const handleDeleteAllMyDocs = () => {

        const access = checkFeatureAccess(currentNavItem,'deletAll')
        if(!access){
            toast.error("You do not have access to delete")
            return
        }


        const selectedDocuments = combinedMyDocs.filter((docs) => docs.isChecked);

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to delete`);
            return;
        }

        const selectedIds = selectedDocuments.map((doc) => doc._id);

        setSelectedIdsDeleteMyDocs(selectedIds)

        setIsDeleteAllMyDocsModalOpen(true);

    }

    const handleDeleteAllSharedDocs = () => {


        const selectedDocuments = sharedDocs.filter((docs) => docs.isChecked);

        if (selectedDocuments.length === 0) {
            toast.warn(`Select documents to delete`);
            return;
        }

        const selectedIds = selectedDocuments.map((doc) => doc._id);

        setSelectedIdsDeleteSharedDocs(selectedIds)

        setIsDeleteAllSharedDocsModalOpen(true);

    }

    // const handleDeletedDocsCardChange = (index) => {
    //     setDeletedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };

    // const handleSharedDocsCardChange = (index) => {
    //     setSharedDocs((prevDocuments) => {
    //       const newDocuments = [...prevDocuments];
    //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

    //       // Check if all individual checkboxes are checked
    //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
    //       return newDocuments;
    //     });
    //   };



    // API Calls for getting docs, deleted docs and shared docs


    // const getDocs = async (id) => {
    //     try {
    //         const response = await documentService.getAllDocuments(id);
    //         if (response.status === 200) {
    //             setMyDocs(response.data);
    //             console.log("mystatus", response.data);
    //             setInitialMyDocs(response.data);
    //             //setMyDocsCount(response.data.length)
    //             console.log("running out side");
    //         } else {
    //             console.error("Error fetching data:", response.message);
    //         }
    //     } catch (error) {
    //         console.error("Error calling backend API:", error.message);
    //     }
    // };

    const getDocs = async (id) => {
        try {
            setLoading(true)
            const response = await documentService.getAllDocuments(id);
            if (response.status === 200) {
                setLoading(false)
                const modifiedData = response.data.map(doc => ({
                    ...doc,
                    ownerName: ""
                }));
                console.log("mystatus", modifiedData);
                setMyDocs(modifiedData);
                setInitialMyDocs(modifiedData);
                //setMyDocsCount(modifiedData.length)
                console.log("running out side");
                console.log(myDocs,"myDocsmmm")
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

   
    // const getDeletedDocs = async () => {
    //     try {
    //         const response = await documentService.getDeletedDocuments();
    //         if (response.status === 200) {
    //             setDeletedDocs(response.data);
    //             setInitialDeletedDocs(response.data);
    //             //setDeletedDocsCount(response.data.length)
    //         } else {
    //             console.error("Error fetching data:", response.message);
    //         }
    //     } catch (error) {
    //         console.error("Error calling backend API:", error.message);
    //     }
    // };
    const getDeletedDocs = async () => {
        try {
            const response = await documentService.getDeletedDocuments();
            if (response.status === 200) {
                const modifiedData = response.data.map(doc => ({
                    ...doc,
                    ownerName: ""
                }));

                setDeletedDocs(modifiedData);
                setInitialDeletedDocs(modifiedData);
                //setDeletedDocsCount(modifiedData.length)
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };


    const getSharedDocs = async () => {
        try {
            const response = await documentService.getSharedDocuments();
            console.log("response.data", response.data);
            // console.log("sharedData", sharedData);
            if (response.status === 200) {
                setSharedDocs(response.data);
                setInitialSharedDocs(response.data)
                setSharedDocsCount(response.data.length)
            } else {
                console.error("Error fetching data:", response.message);
                setSharedDocs([])
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getAllDocuments = () => {
        getDocs();
        getDeletedDocs();
        getSharedDocs();
        getFolders();
        getDeletedFolders();
        handleDocsBackClick();

    }

    useEffect(() => {
        getAllDocuments()
    }, []);


    useEffect(() => {
        setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
        setCombinedMyDocs([...myFolders, ...myDocs])
        setCombinedDeletedDocs([...deletedFolders, ...deletedDocs])
    }, [myDocs, deletedDocs, sharedDocs, myFolders, deletedFolders]);

    useEffect(() => {
        if (folderOpen) {
            getDocs(folderId)
            setMyFolders("")
        } else {
            getAllDocuments()
        }
    }, [folderOpen])

    useEffect(() => {
        setMyDocsCount(combinedMyDocs.length)
        setDeletedDocsCount(combinedDeletedDocs.length)
    }, [combinedMyDocs, combinedDeletedDocs])

    const fetchUserColor = async () => {
        let response = await getTeam();
        console.log("fetchUserColor", response);
        setUserProfileColor(response.data.user)
    }

    useEffect(() => {
        fetchUserColor()
    }, [])

    const handleCreateNewFolder = () => {
        const access = checkFeatureAccess(currentNavItem,'createFolder')
        if(!access){
            toast.error("You do not have access to create folder")
            return
        }
        setIsCreateNewFolderModalOpen(true)
    }

    const closeCreateNewFolderModal = () => {
        setIsCreateNewFolderModalOpen(false)
        setFolderName('')
    }


    const createNewFolderClick = async (folderName) => {
        if (folderName.length === 0) {
            toast.warning("Please enter folder name")
            return
        }

        if (myFolders.some(folder => folder.folderName === folderName)) {
            toast.warning("Folder name already exists");
            return;
        }


        try {
            const response = await documentService.createFolder(folderName);
            console.log(response.data, "folder data");
            if (response.status === 200) {
                toast.success("Folder created successfully")
                getFolders()
            }

        } catch (error) {
            toast.error("Error creating folder")
            console.error('Error creating folder:', error);
        }


        closeCreateNewFolderModal()
        setFolderName("")
    }

    const getFolders = async () => {
        try {
            const response = await documentService.getAllFolders();
            if (response.status === 200) {
                const foldersReverse = response.data.reverse()
                setMyFolders(foldersReverse);
                setInitialMyFolders(foldersReverse)



            } else {
                console.error("Error fetching folders:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getDeletedFolders = async () => {
        try {
            const response = await documentService.getDeletedFolders();
            if (response.status === 200) {
                const foldersReverse = response.data.reverse()
                setDeletedFolders(foldersReverse);
                setInitialDeletedFolders(foldersReverse)



            } else {
                console.error("Error fetching folders:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };


    const handleFolderClick = (id) => {
        setFolderId(id)
        setFolderOpen(true)
        dispatch(shareFolderId(id))
    }

    const handleDocsBackClick = () => {
        setFolderId('')
        setFolderOpen(false)
        dispatch(shareFolderId(''))
    }

    const handleRefresh = () => {
        getAllDocuments();
    }

    const handleNewDocument = () =>{
        // const access = checkFeatureAccess(currentNavItem,'newDocument')
        // if(!access){
        //     toast.error("You do not have access to create new document")
        //     return
        // }
        history.push('/grammarcheck');
    }

    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const updateTheme = () => {
          const theme = localStorage.getItem('ThemeSwitch');
          setIsDarkMode(theme === 'true');
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
          originalSetItem.apply(this, arguments);
          if (key === 'ThemeSwitch') {
            updateTheme(); 
          }
        };
        return () => {
          window.removeEventListener('storage', updateTheme);
          localStorage.setItem = originalSetItem; 
        };
      }, []);

      const openRestrictCollaboration = () => {
        setSubReqOpen(true)
      }

    return (
        <>
        <Loader loading={loading} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6 mb-2 mb-md-0'>
                        <TextField label="Search" className='Search_Input_bar' variant="outlined" size="small" style={{ width: '100%' }} onChange={handleSearchChange} />
                    </div>
                    <div className='col-md-3 mb-2 mb-md-0'>
                        
                            <Button className='diabledInDarkMode' variant="contained" color="primary"
                                startIcon={<AddIcon />}
                                style={{ width: '100%', whiteSpace: 'nowrap',}}
                                onClick={()=>handleNewDocument()}
                                disabled={!canAddNewdoc}
                            >
                                New Document
                            </Button>
                       
                    </div>
                    <div className='col-md-3 mb-2 mb-md-0'>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleUploadFile}
                        />
                        <Button
                            // variant="outlined"
                            className='OutlinedDarkBtn diabledInDarkMode'
                            // color="primary"
                            startIcon={<UploadIcon />}
                            style={{ width: '100%', whiteSpace: 'nowrap' }}
                            onClick={handleUploadButtonClick}
                            disabled={!canUploadDoc}
                            
                        >
                            Upload Document
                        </Button>
                    </div>
                </div>

                <div className='row' style={{ marginTop: '10px' }}>
                    <div className='col-md-7 mt-2'>
                        <FormControl>
                            {/* <FormControlLabel
                                style={{ marginLeft: '10px' }}
                                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                                label={<Typography>Select All</Typography>}
                            /> */}
                        </FormControl>
                    </div>
                    <div className='col-md-5 d-flex justify-content-end'>
                        {!folderOpen &&
                            <IconButton disabled={!canCreateFolder}>
                                <LightTooltip title="New folder">
                                    <CreateNewFolderIcon style={{ color: canCreateFolder ? "gold" : "gray" }} onClick={() => handleCreateNewFolder()} />
                                </LightTooltip>
                            </IconButton>

                        }

                        <NewFolderModal show={isCreateNewFolderModalOpen} onHide={closeCreateNewFolderModal} onCreateClick={createNewFolderClick} folderName={folderName} setFolderName={setFolderName} />





                        {/* <IconButton>
                            {listView ? <div title='Grid View'><WindowIcon onClick={() => setListView(false)} /></div> : <div title='List View'><TableRowsIcon onClick={() => setListView(true)} /></div>}
                        </IconButton> */}

                        <LightTooltip title={listView ? "Grid View" : "List View"}>
                            <IconButton onClick={() => setListView(!listView)}>
                                {listView ? <WindowIcon style={{ color: "deepskyblue" }} /> : <TableRowsIcon style={{ color: "deepskyblue" }} />}
                            </IconButton>
                        </LightTooltip>

                        <IconButton>
                            <div ><LightTooltip title="Sort"><SortByAlphaIcon style={{ color: "coral" }} onClick={() => handleSortClick()} /></LightTooltip></div>
                        </IconButton>

                        <IconButton>
                            <div
                                onClick={handleDownloadDropdownClick}
                            >
                                <LightTooltip title="Download"><DownloadIcon style={{ color: "green" }} /></LightTooltip></div>
                        </IconButton>



                        <IconButton disabled={!canDeleteAllDocs}>
                            <div ><LightTooltip title="Delete"><DeleteIcon style={{ color: canDeleteAllDocs ? "red" : "gray" }} onClick={() => handleDeleteAllMyDocs()} /></LightTooltip></div>
                        </IconButton>
                        <DeleteAllMyDocsModal show={isDeleteAllMyDocsModalOpen} onHide={closeDeleteAllMyDocsModal} ids={selectedIdsDeleteMyDocs} getAllDocuments={getAllDocuments} />

                        <Menu
                            anchorEl={downloadAnchorElDropdown}
                            open={Boolean(downloadAnchorElDropdown)}
                            onClose={handleDownloadDropdownClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >

                            <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
                                <ListItemText primary="MS-Word" />
                            </MenuItem>

                            <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
                                <ListItemText primary="PDF" />
                            </MenuItem>


                        </Menu>

                    </div>
                </div>

                {(getMyDocsCurrentPage().length > 0) ?
                    <>
                        <div className='row'>
                            <div className='col-md-12 mt-1 mb-3'>
                                <hr />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h2 className='whiteHeading'>My Documents ({myDocsCount})</h2>
                                    <LightTooltip title="Refresh">
                                        <IconButton onClick={handleRefresh} style={{ position: 'absolute', fontSize: '0.875rem', color: '#3f51b5', right: "20px" }} >
                                            <LoopIcon />
                                        </IconButton>
                                    </LightTooltip>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <FormControl>
                                            <FormControlLabel
                                                style={{ marginLeft: '7px' }}
                                                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange}  />}
                                                label={<Typography>Select All </Typography>}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            
                            </div>
                            <div className='col-md-12 mb-3'>
                                {folderOpen &&
                                    <Button variant="contained" color="primary" onClick={() => { handleDocsBackClick() }}><KeyboardBackspaceIcon /></Button>
                                }
                            </div>



                            {getMyDocsCurrentPage().map((document, index) => {
                                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                                // let description = parsedJson.blocks[0].text;
                                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                                description = description?.replace(/&nbsp;/g, ' ');
                                console.log("description_", description, document)
                                let toStatus = document.tostatus



                                return (
                                    <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3'} key={index} >
                                        {listView ? (
                                            <DocumentListCard

                                                description={description}
                                                onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocuments'}
                                                document={document}
                                                toStatus={toStatus}
                                                handleFolderClick={handleFolderClick}
                                                userProfileColor={userProfileColor}
                                                restrictCollaboration={openRestrictCollaboration}

                                            />
                                        ) : (

                                            <DocumentGridCard

                                                description={description}
                                                onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'MyDocuments'}
                                                pendingDocuments={document.pendingDocuments}
                                                document={document}
                                                toStatus={toStatus}
                                                setMyDocs={setMyDocs}
                                                handleFolderClick={handleFolderClick}
                                                userProfileColor={userProfileColor}
                                                restrictCollaboration={openRestrictCollaboration}
                                            />
                                        )}
                                    </div>
                                )

                            })}


                        </div>


                        {combinedMyDocs.length > itemsPerPage && (

                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Pagination
                                    count={Math.ceil(combinedMyDocs.length / itemsPerPage)}
                                    page={myDocsCurrentPage}
                                    onChange={handleMyDocsPageChange}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"

                                />
                            </div>

                        )
                        }

                    </>
                    :
                    <>
                        <div className='row'>
                        <div className='col-md-12 mt-1 mb-3'><hr /><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2 className='whiteHeading'>My Documents ({myDocsCount})</h2>

                                <LightTooltip title="Refresh">
                                        <IconButton onClick={handleRefresh} style={{ position: 'absolute', fontSize: '0.875rem', color: '#3f51b5', right: "20px" }} >
                                            <LoopIcon />
                                        </IconButton>
                                    </LightTooltip>
                            </div></div>

                            <div className='col-md-12'>
                                {folderOpen &&
                                    <Button variant="contained" color="primary" onClick={() => { handleDocsBackClick() }}><KeyboardBackspaceIcon /></Button>
                                }
                            </div>
                           {!myDocs && <div className='col-md-12 mt-4 mb-3'><h3>No Documents present</h3></div>}

                        </div>

                    </>

                }

                {(getSharedDocsCurrentPage().length > 0) &&
                    <>
                        <div className='row'>
                            <div className='col-md-12 mt-1 mb-3'><hr /><h2 className='whiteHeading'>Shared With Me ({sharedDocsCount})</h2></div>

                            {/* <div className='col-md-12 mt-2 mb-3'>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <FormControl>
                                            <FormControlLabel
                                                style={{ marginLeft: '10px' }}
                                                control={<Checkbox checked={selectAllSharedChecked} onChange={handleSelectAllSharedChange} />}
                                                label={<Typography>Select All </Typography>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='col-md-5 d-flex justify-content-end'>
                                        <LightTooltip title={"Delete"}>
                                            <IconButton onClick={() => handleDeleteAllSharedDocs()}>
                                                <DeleteIcon style={{ color: "red" }} />
                                            </IconButton>
                                        </LightTooltip>

                                        <DeleteAllMyDocsModal show={isDeleteAllSharedDocsModalOpen} onHide={closeDeleteAllSharedDocsModal} ids={selectedIdsDeleteSharedDocs} getAllDocuments={getAllDocuments} />
                                    </div>
                                </div>
                            </div> */}

                            {getSharedDocsCurrentPage().map((document, index) => {
                                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                                // let description = parsedJson.blocks[0].text;

                                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                                description = description?.replace(/&nbsp;/g, ' ');
                                let toStatus = document.tostatus
                                return (
                                    <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3'} key={index}>
                                        {listView ? (
                                            <DocumentListCard
                                                description={description}
                                                onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'SharedWithMe'}
                                                toStatus={toStatus}
                                                document={document}


                                            />
                                        ) : (
                                            <DocumentGridCard
                                                description={description}
                                                onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                                                getAllDocuments={getAllDocuments}
                                                type={'SharedWithMe'}
                                                toStatus={toStatus}

                                                document={document}

                                            />
                                        )}
                                    </div>
                                )

                            })}

                        </div>

                        {sharedDocs.length > itemsPerPage && (

                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Pagination
                                    count={Math.ceil(sharedDocs.length / itemsPerPage)}
                                    page={sharedDocsCurrentPage}
                                    onChange={handleSharedDocsPageChange}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"

                                />
                            </div>

                        )
                        }
                    </>

                }

                {(getDeletedDocsCurrentPage().length > 0) &&
                    <>
                        <div className='row'>
                            <div className='col-md-12 mt-1 mb-3'><hr /><h2 className='whiteHeading'>Deleted Documents ({deletedDocsCount})</h2></div>
                            <div className='col-md-12 mt-2 mb-3'>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <FormControl>
                                            <FormControlLabel
                                                style={{ marginLeft: '6px' }}
                                                control={<Checkbox checked={selectAllDeleteChecked} onChange={handleSelectAllDeleteChange} />}
                                                label={<Typography>Select All </Typography>}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='col-md-5 d-flex justify-content-end'>
                                        <LightTooltip title={"Delete Permanently"}>
                                            <IconButton disabled={!accessToPermanantDelete} onClick={() => handleDeleteAllPermanentlyClick()}>
                                                <DeleteIcon style={{ color: accessToPermanantDelete ? "red" : "gray" }} />
                                            </IconButton>
                                        </LightTooltip>

                                        <PermanentDeleteAllModal show={isPermanentDeleteAllModalOpen} onHide={closePermanentDeleteAllModal} ids={selectedIdsDeletePermanently} getAllDocuments={getAllDocuments} />
                                    </div>
                                </div>
                            </div>


                            {getDeletedDocsCurrentPage().map((document, index) => {
                                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                                description = description?.replace(/&nbsp;/g, ' ');
                                let toStatus = document.tostatus

                                return (
                                    <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3'} key={index}>
                                        {listView ? (
                                            <DocumentListCard

                                                description={description}
                                                getAllDocuments={getAllDocuments}
                                                type={'DeletedDocuments'}
                                                onCardCheckboxChange={() => handleDeletedDocsCardChange(index)}
                                                document={document}
                                                toStatus={toStatus}

                                            />
                                        ) : (
                                            <DocumentGridCard

                                                description={description}
                                                getAllDocuments={getAllDocuments}
                                                type={'DeletedDocuments'}
                                                onCardCheckboxChange={() => handleDeletedDocsCardChange(index)}
                                                document={document}
                                                toStatus={toStatus}
                                            />
                                        )}
                                    </div>
                                )

                            })}

                        </div>
                        {combinedDeletedDocs.length > itemsPerPage && (

                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                <Pagination
                                    count={Math.ceil(combinedDeletedDocs.length / itemsPerPage)}
                                    page={deletedDocsCurrentPage}
                                    onChange={handleDeletedDocsPageChange}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"

                                />
                            </div>

                        )
                        }

                    </>
                }

             
                    <SubscriptionRequired open={subReqOpen} setOpen={setSubReqOpen}/>
                

                
            </div>
        </>
    )
}



export default MyDocumentsNew


const NewFolderModal = ({ show, onHide, onCreateClick, folderName, setFolderName }) => {


    const handleFolderNameChange = (e) => {
        setFolderName(e.target.value);
    };



    return (
        <M_Modal show={show} onHide={onHide} centered id="permanentDeleteAllModal">
            <M_Modal.Header closeButton>
                <M_Modal.Title id="contained-modal-title-vcenter">
                    Create New Folder
                </M_Modal.Title>
            </M_Modal.Header>
            <M_Modal.Body>
                <Form.Control type="text" placeholder="Enter folder name" value={folderName} maxLength={25} onChange={handleFolderNameChange} />
            </M_Modal.Body>
            <M_Modal.Footer>
                <B_Button variant="secondary" onClick={onHide}>
                    Cancel
                </B_Button>
                <B_Button variant="primary" onClick={() => onCreateClick(folderName)}>
                    Create New Folder
                </B_Button>
            </M_Modal.Footer>
        </M_Modal>
    );
};