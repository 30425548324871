import React from "react";
import paymentServices from "../services/paymentServices";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { withRouter } from "react-router-dom";
class PaypalCheckOutButton extends React.Component {
  componentDidMount() {
    const { price, planName, period, plan_mode, user_count,plan_id } = this.props.detail;
    console.log('planName',planName);
    console.log('plan_id',plan_id);
    const { setCurrentUser,history } = this.props;
    window.paypal
      ?.Buttons({
        style: {
          color: "blue",
          shape: "pill",
          label: "pay",
          height: 40
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price || "0.01"
                }
              }
            ]
          });
        },
        onError: function (err) {
          toast.error("Payment Failed");
          // this.props.history({ pathname: "/pricing" })
          // Show an error page here, when an error occurs
        },
        onCancel: function (data) {
          toast.warn("Payment Cancelled");
          // Show a cancel page, or return to cart
          
        },
        onApprove: async function (data, actions) {
          let payment = await actions.order
            .capture()
            .then(async function (details) {
              // Call your server to save the transaction
              const paymentDetails = {
                amount: price || "0.01",
                orderID: data.orderID,
                planName,
                period,
                plan_mode,
                user_count: parseInt(user_count) || 0,
                data,
                details,
                plan_id,
              };
              console.log(paymentDetails);
              return await paymentServices.validatePayment(paymentDetails);
            });
          if (payment.status === 200) {
            toast.success("Payment validated");
            setCurrentUser(payment.user);
            setTimeout(() => {
              history.push('/invoices');
            },2000)
          } else {
            toast.warn(payment.message);
          }
        }
      })
      .render("#paypal-button-container");
  }
  render() {
    return <React.Fragment>
      <ToastContainer/>
        <div id="paypal-button-container"></div>
      </React.Fragment>;
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaypalCheckOutButton));
