import configdata from "./config.json";
import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${configdata.apiUrl}/userLogs`;
   
  } else {
    apiEndpoint = `${configdata.apiUrl}/userLogs`;
   
  }
  export async function addUserLogs(data){
    const response=await httpService.post(`${apiEndpoint}/addUserLogs`,{data});
    return response;
  }

  export async function getUserLogs(data){
    const response=await httpService.post(`${apiEndpoint}/getUserLogsByOrganizationId`,{data});
    return response;
  }


 
   
  export default {
    addUserLogs,
    getUserLogs,
  }