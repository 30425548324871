import React, { useState, useEffect, useMemo } from 'react';
import citationService from '../../services/citationService';
import { toast } from 'react-toastify';
import { Card, CardContent, IconButton, Menu, MenuItem, Typography, Checkbox, Button } from '@material-ui/core';
import { Form, Row, Col } from 'react-bootstrap';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import'./Citation-1.css';
import { addUserLogs } from '../../services/userLogServices';
import { checkFeatureAccess } from '../featureAccess';
import { useCheckFeatureAccess } from '../CheckFeature';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



export const CitationCard = ({ setEditModalTitle, handleModalShow, setFormData, setContributors, selectedValue, setSelectedValue, setEditClicked, setWebsiteData, citation, setCitationId, handleEditModalShow, handleCardCheckboxChange, handleDeleteCitation, fetchCitationDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardChecked, setCardChecked] = useState(false);
  const accessToDelete = useCheckFeatureAccess('citationGenerator','Delete')
  const accessToEdit = useCheckFeatureAccess('citationGenerator','edit')

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

  };

  //Duplicate function /////////////////////////////////////////////////
  // const handleDuplicateCitation = async(id) => {
  //   console.log('working');
  //   citationService.Duplicate(id).then((response) => {
  //     if (response.status === 200) {
  //       toast.success("Citation duplicated successfully")
  //       fetchCitationDetails()

  //       const data = {
  //         userId: sessionStorage.getItem("userId"),
  //         organizationId: sessionStorage.getItem("organizationId"),
  //         actionType: "duplicateCitation",
  //       };
  
  //       const addLogs = await addUserLogs(data);
  //       if (addLogs.status !== 200) {
  //         toast.error(addLogs.message);
  //       }
  //     }
  //   })
  //   handleMenuClose();
  // }

  const handleDuplicateCitation = async (id) => {
    console.log('working');
  
    try {
      const response = await citationService.Duplicate(id);
  
      if (response.status === 200) {
        toast.success("Citation duplicated successfully");
        fetchCitationDetails();
  
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "duplicateCitation",
        };
  
        const addLogs = await addUserLogs(data);
  
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      } else {
        toast.error("Failed to duplicate citation");
      }
    } catch (error) {
      console.error("Error duplicating citation:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      handleMenuClose();
    }
  };
  

  const handleEditCitation2 = async (id, sourceType) => {
    setCitationId(id);
    setEditModalTitle(sourceType)
    handleEditModalShow();
    handleMenuClose();
    setWebsiteData(null);

  }



  //handleViewsourceCitation   View source citation function
  const handleViewsourceCitation = (id) => {
    citationService.GetOnecitation(id).then((response) => {
      if (response.status === 200) {
        const websiteUrl = response.citationDetails.url;
        if (websiteUrl) {
          let formattedUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://")
            ? websiteUrl
            : `https://${websiteUrl}`;

          window.open(formattedUrl, "_blank");
        } else {
          toast.error("No URL found for the citation");
        }
      }
    })
    handleMenuClose();
  }



  return (

    <>

      <Card
        style={{
          // border: "1px solid #ddd",
          border: "1px solid blue",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
          backgroundColor: 'white',
          width:"100%",
          minWidth:"300px",
          display:"flex"
        }}
      >
        <CardContent style={{width:"100%",display:"flex", position: "relative", padding: "10px" }}>
            <div style={{width:"10%"}}>
            <Checkbox
            className='zzz'
            style={{ position: "absolute", top: "10px", left: "10px" }}
            checked={citation.isChecked || cardChecked}
            onChange={() => handleCardCheckboxChange(citation._id)}
          />
            </div>
           


          <div style={{width:"75%"}}>
            <Typography className='zzz' variant="h6">{citation.title}</Typography>
            <Typography className='zzz' variant="body2" style={{ fontSize: '12px' }}>
              Full Citation: {citation.fullCitation}
            </Typography>
            <Typography className='zzz' variant="body2" style={{ fontSize: '11px' }}>
              In-text Citation: ({citation.inTextCitation})
            </Typography>
            <Typography className='zzz'  variant="body2" style={{ fontSize: '11px' }}>
              Citation Style: {citation.textStyle}
            </Typography>
          </div>


        <div style={{width:"10%"}}>
        <IconButton
            style={{ position: "absolute", top: "5px", right: "10px" }}
            onClick={handleMenuClick}
          >
            <MoreVertIcon className='zzz' />
          </IconButton>
        </div>
    


          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled={!accessToDelete} onClick={() => {
              handleDeleteCitation(citation._id)
              handleMenuClose()
            }}>Delete</MenuItem>
            <MenuItem onClick={() => handleDuplicateCitation(citation._id)}>Duplicate</MenuItem>
            <MenuItem disabled={!accessToEdit} onClick={() => handleEditCitation2(citation._id, citation.sourceType)}>Edit</MenuItem>
            {citation.url && (
              <MenuItem onClick={() => handleViewsourceCitation(citation._id)}>View Source</MenuItem>
            )}
          </Menu> 
        </CardContent>

      </Card>

    </>

  );
};





export const CoAuthor_CitationCard = ({ setSelectedHeading, handleInsertClick, citation, index, fetchCitationDetails, setComposestate, setCreateClassstate, setFormData, setCheckEdit, setContributors, setCitationID }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const accessToDelete = useCheckFeatureAccess('citationGenerator','Delete')
  const accessToEdit = useCheckFeatureAccess('citationGenerator','edit')
  const handleMenuClick = (event, citationId) => {
    setAnchorEl(event.currentTarget);

  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditCitation = () => {



    const initialData = {
      heading: citation.sourceType || "", // Set a default value or leave it empty depending on your use case
      title: citation.title || "",
      description: citation.description || "",
      websiteName: citation.websiteName || "",
      contributors: (citation.contributors || []).map((contributor) => ({
        id: contributor.id || "",
        position: contributor.position || "",
        firstName: contributor.firstName || "",
        middleName: contributor.middleName || "",
        lastName: contributor.lastName || "",
        suffix: contributor.suffix || "",
      })),
      url: citation.url || "",
      doi: citation.doi || "",
      journalName: citation.journalName || "",
      edition: citation.edition || "",
      publisher: citation.publisher || "",
      articleNumber: citation.articleNumber || "",
      volumeNumber: citation.volumeNumber || "",
      issueNumber: citation.issueNumber || "",
      page: citation.page || "",
      libraryDatabase: citation.libraryDatabase || "",
      ISBN: citation.ISBN || "",
      ISSN: citation.ISSN || "",
      publicationDate: citation.publicationDate
        ? {
          year: citation.publicationDate.year || "",
          month: citation.publicationDate.month || "",
          day: citation.publicationDate.day || "",
        }
        : {},
      accessDate: citation.accessDate
        ? {
          year: citation.accessDate.year || "",
          month: citation.accessDate.month || "",
          day: citation.accessDate.day || "",
        }
        : {},
    };

    // console.log('inintialData',initialData)
    setSelectedHeading(citation.sourceType)
    setCheckEdit(true)
    setFormData(initialData)
    setCitationID(citation._id)
    setCreateClassstate(false)
    setComposestate(true)
    setContributors(initialData.contributors);
  }


  const handleDeleteCitation = async (id) => {
    // Show a confirmation toast
    const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?",
      () => handleConfirmDeleteCitation(id, toastId), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );

  };

  const handleConfirmDeleteCitation = async (id) => {
    try {
      // Call the API to delete the citation
      const response = await citationService.deleteCitation(id);

      console.log('Backend Response:', response);
      if (response.status === 200) {
        toast.success('Deleted successfully');
        fetchCitationDetails();
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "deleteCitation",
        };
  
        const addLogs = await addUserLogs(data);
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      }
    } catch (error) {
      console.error('Error calling backend API:', error.message);
    }
    handleMenuClose();
  };

  // const handleDuplicateCitation = async(id) => {
  //   console.log('working');
  //   citationService.Duplicate(id).then((response) => {
  //     if (response.status === 200) {
  //       toast.success("Citation duplicated successfully")
  //       fetchCitationDetails()

  //       const data = {
  //         userId: sessionStorage.getItem("userId"),
  //         organizationId: sessionStorage.getItem("organizationId"),
  //         actionType: "duplicateCitation",
  //       };
  
  //       const addLogs = await addUserLogs(data);
  
  //       if (addLogs.status !== 200) {
  //         toast.error(addLogs.message);
  //       }
  //     }
  //   })
  //   handleMenuClose();
  // }

  const handleDuplicateCitation = async (id) => {
    console.log("working");
  
    try {
      const response = await citationService.Duplicate(id);
  
      if (response.status === 200) {
        toast.success("Citation duplicated successfully");
        fetchCitationDetails();
  
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "duplicateCitation",
        };
  
        const addLogs = await addUserLogs(data);
  
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      } else {
        toast.error("Failed to duplicate citation");
      }
    } catch (error) {
      console.error("Error duplicating citation:", error);
      toast.error("An error occurred while duplicating the citation.");
    } finally {
      handleMenuClose();
    }
  };
  

  const handleViewsourceCitation = (id) => {
    citationService.GetOnecitation(id).then((response) => {
      if (response.status === 200) {
        const websiteUrl = response.citationDetails.url;
        if (websiteUrl) {
          let formattedUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://")
            ? websiteUrl
            : `https://${websiteUrl}`;

          window.open(formattedUrl, "_blank");
        } else {
          toast.error("No URL found for the citation");
        }
      }
    })
    handleMenuClose()
  }

  const showConfirmationToast = (message, onConfirm, onCancel) => {
    const toastId = toast.info(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px' }}><h4>Delete Citation</h4></div>
        <p style={{ marginBottom: '10px' }}>{message}</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Delete Citation
          </button>
          <button
            onClick={() => {
              onCancel();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Do not auto-close the toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    return toastId;
  };

  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);





  return (
    <>
      <Card
        key={index} // Add a key to each Card element
          className='cardStyle'
        style={{
          border: isDarkMode ? "1px solid black" :  "1px solid #ddd",
          borderRadius: "8px",
          marginBottom: "10px",
          boxShadow: "3px 1px 8px 0px rgba(0,0,0,0.25)",
          backgroundColor: 'white',
          position: 'relative'
        }}
      >
        <CardContent style={{ position: "relative", padding: "10px" }}>
          <div style={{ marginLeft: "30px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Typography variant="body1"><strong>{citation.title}</strong></Typography>
            </div>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              Full Citation: {citation.fullCitation}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '10px' }}>
              In-text Citation: ({citation.inTextCitation})
            </Typography>
            <Typography variant="body2" style={{ fontSize: '10px' }}>
              Citation Style: {citation.textStyle}
            </Typography>
          </div>

          <IconButton
            style={{ position: "absolute", top: "5px", right: "10px" }}
            onClick={(event) => handleMenuClick(event)}
          >
            <MoreVertIcon />
          </IconButton>


          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled={!accessToDelete} onClick={() => handleDeleteCitation(citation._id)}>Delete</MenuItem>
            <MenuItem onClick={() => handleDuplicateCitation(citation._id)}>Duplicate</MenuItem>
            <MenuItem disabled={!accessToEdit} onClick={() => handleEditCitation(citation._id)}>Edit</MenuItem>
            {citation.url && (
              <MenuItem onClick={() => handleViewsourceCitation(citation._id)}>View Source</MenuItem>
            )}
          </Menu>
        </CardContent>
      </Card>
    </>
  )
}



export const ContributorForm = ({ contributor, handleDelete, handleInputChange }) => {
  return (
    <>
      <Form>
        <Row>
          {/* Position */}
          <Col md={2}>
            <Form.Group controlId={`position_${contributor.id}`}>
              <Form.Label style={{ fontSize: '1rem' }}>Position</Form.Label>
              <Form.Control
                type="text"
                value={contributor.position}
                onChange={(e) => handleInputChange('position', e.target.value)}
              />
            </Form.Group>
          </Col>

          {/* First Name */}
          <Col md={2}>
            <Form.Group controlId={`firstName_${contributor.id}`}>
              <Form.Label style={{ fontSize: '1rem' }}>First Name</Form.Label>
              <Form.Control
                type="text"
                value={contributor.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={2}>
            <Form.Group controlId={`middleName_${contributor.id}`}>
              <Form.Label style={{ fontSize: '1rem' }}>Middle </Form.Label>
              <Form.Control
                type="text"
                value={contributor.middleName}
                onChange={(e) => handleInputChange('middleName', e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={2}>
            <Form.Group controlId={`lastName_${contributor.id}`}>
              <Form.Label style={{ fontSize: '1rem' }}>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={contributor.lastName}
                onChange={(e) => handleInputChange('lastName', e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={2}>
            <Form.Group controlId={`suffix_${contributor.id}`}>
              <Form.Label style={{ fontSize: '1rem' }}>Suffix</Form.Label>
              <Form.Control
                type="text"
                value={contributor.suffix}
                onChange={(e) => handleInputChange('suffix', e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          {/* Middle Name */}
          {/* ... (similar structure for other fields) */}

          {/* Delete Icon */}
          <Col md={1}>
            <LightTooltip title="Delete">
              <IconButton onClick={handleDelete} style={{ marginTop: '15px' }}>
              
                <DeleteIcon style={{ marginTop: '7px', color: "red" }} />
              
              </IconButton>
              </LightTooltip>
          </Col>
        </Row>
      </Form>
    </>
  );
}


