const initialState = {
    currentCoauthorNavItem: null,
  };
  
  // to check which screen is open - (grammar check or coauthor or document)
  const coauthorNavReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_NAV_ITEM_FOR_COAUTHOR":
        return {
          ...state,
          currentCoauthorNavItem: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default coauthorNavReducer;