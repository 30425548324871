
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dashboard from "@material-ui/icons/Equalizer";
import Review from "@material-ui/icons/Chat";
import { Link, NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Documents from "@material-ui/icons/Description";
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import BookIcon from "@material-ui/icons/Book";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import PaymentIcon from "@material-ui/icons/Payment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { fetchUserData, getProfile } from "../services/userService";
import "./Navbar.css";
import "./AdminSideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect, useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationServices from "../services/notficationService";
import { getHomeCMS } from "../services/adminServices";
// import PetLogo from "../static/signup_login/PetLogo.png"
import PetLogo from "../static/POET_Logo_Mid.png";
import { imgUrl } from "../services/config.json"
import PetPenLogo from '../static/POET_Logo_Pen.png'
import HomeIcon from '@mui/icons-material/Home';
import { markAllRead } from "../services/notficationService";
import { closeLeftNavbar, openLeftNavbar } from "../redux/handleSideNavBarAndModel/handleStatus.action";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { Button } from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PoetNewlogopen from '../static/newlogoPoet.png'


const drawerWidth = 250;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    // border: "2px solid black",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative", // Default positioning
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer, // Default zIndex
    [theme.breakpoints.down("sm")]: {
      position: "fixed", // Overlay the content
      height: "100%", // Full height
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 1, // Higher zIndex for small screens
      transform: "translateX(-100%)", // Initially hidden on small screens
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(0)", // Show when open on small screens
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#B4D9FF',
    },
  },
}));

function AdminHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [emailId, setemailId] = useState("");
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setAdmin] = React.useState(false);
  const [notifications, setnotifications] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cms, setCms] = useState({});


  const [bottomText, setBottomText] = useState("")
  const [username, setusername] = useState("");
  const [selectedMenu, setSelectedMenu] = useState('Professional Editing Tool');
  console.log(user, "username");

  const [popoverOpen, setPopoverOpen] = useState(false);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { leftSubadminNavbarOpen } = useSelector(state => state.handleSubAdminSideBar);
  console.log(leftSubadminNavbarOpen, "__leftSubadminNavbarOpen")
  const [userRoles, setUserRoles] = useState([]);



  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log(windowWidth, "windowWidth")
  const mobileView = windowWidth <= 600;

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const formatDate = () => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date();
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.toLocaleDateString('en-US', { day: 'numeric' });
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return `${month} ${day} | ${year}`;
  };


  useEffect(() => {
    const currentPath = window.location.pathname;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    switch (currentPath) {
      case '/subAdminDashboard':
        const capitalizedUsername = capitalizeFirstLetter(user);
        setSelectedMenu(`Welcome ${capitalizedUsername}`);
        setBottomText(`${formatDate()}`);
        break;
      case '/subAdminManageUser':
        setSelectedMenu(' Manage Users');
        break;
      case '/subAdminDocuments':
        setSelectedMenu('Documents');
        break;
      case '/subAdminUserRequest':
        setSelectedMenu('User Request');
        break;
      case '/accountsettings':
        setSelectedMenu('Account Settings');
        break;
      case '/pricing':
        setSelectedMenu('Subcriptions');
        break;
      case '/invoices':
        setSelectedMenu('Invoice');
        break;
      case '/userLogs':
        setSelectedMenu('User Logs');
        break;
      default:
        setSelectedMenu('Professional Editing Tool');
    }
  }, [user]);



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCms = async () => {
    const { currentUser, setCurrentUser } = props;
    let cms = await getHomeCMS();
    setCms(cms.data);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openLeftNavbar())
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(closeLeftNavbar())
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    console.log("inside")
    setAnchorEl(null);
    dispatch(closeLeftNavbar())
    // handleMobileMenuClose();
  };
  function handleNavigation(route) {
    history.push(`/${route}`);
  }

  useEffect(() => {
    const user = authService.getCurrentUser();
    //console.log(user);
    if (user) {
      if (user.isAdmin === true) {
        setAdmin(true);
      }
    }
    console.log(isAdmin);
  });

  useEffect(() => {
    displyPic();
    getNotifications();
    getCms();
  }, []);

  const getNotifications = async () => {
    let notifications = await NotificationServices.getnotifications();
    setnotifications(notifications);
  };

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    const { display_picture } = user;
    setProfilePic(display_picture);
    // setemailId(user.data.email);
    // setUser(user.data.username)
    setemailId(user.email);
    setUser(user.username);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const notfication_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999", minWidth: '400px' }}>
      <Popover.Content
        style={{
          overflowY: "auto",
          maxHeight: "40vh",
          position: "relative",
          minWidth: "400px",

        }}
      >
        <div
          style={{
            position: "sticky",
            top: -7,
            backgroundColor: "white",
            zIndex: 1,
            paddingTop: '5px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div>
            <h6><b>Notification</b></h6>
          </div>
          <div>
            <p style={{ color: 'blue', fontSize: '10px', position: 'relative', bottom: '-4px', cursor: "pointer" }} onClick={() => {
              markAllNotificationsRead();
              togglePopover();
            }}>Mark all as read</p>
          </div>
        </div>
        <hr />
        <NotificationCard data={notifications} />
      </Popover.Content>
    </Popover>
  );

  const markAllNotificationsRead = async () => {
    try {
      if (notifications.notificationsCount < 1) {
        return
      }

      let details = await markAllRead()
      if (details.status === 200) {
        getNotifications()
        console.log("All notifications marked as read")
      } else {
        console.log("Failed to mark all notifications as read")
      }


    } catch (error) {
      console.error('Error marking all notifications as read:', error.message);
    }

  }

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/accountsettings") && "background-grey"}
          >
            <Typography>Account Settings</Typography>
          </MenuItem>
        </NavLink>
        <NavLink
          to={{ pathname: "/pricing" }}
          style={{ color: "black", paddingTop: "2px" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/pricing") && "background-grey"}
          >
            Subscriptions
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/invoices" }} style={{ color: "black" }}>
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/invoices") && "background-grey"}
          >
            Invoices
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/logout" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );
  const getThemeSwitch = () => {
    return <GenericThemeSwitch />;
  };

  function isUrlMatch(url) {
    if (window.location.pathname.match(url)) {
      return true;
    }
    return false;
  }
  const handleNavLinkClick = () => {
    dispatch(closeLeftNavbar());
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  // Function to enter fullscreen mode
  const enterFullscreen = () => {
    const element = document.documentElement; // or specific element like a div or container
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { // IE/Edge
      element.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  // Function to exit fullscreen mode
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  };

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer />
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            style={{ color: "#4d4f5c" }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            {/* <MenuIcon /> */}
            <img
              alt="logo"
              className="img-fluid lcss"
              src={PetPenLogo}
              style={{
                width: "40px",
                height: "40px",
                objectFit: "contain",
              }}
            ></img>
          </IconButton>
          {/* <Typography variant="h6" noWrap style={{ color: "#4d4f5c" }}>
            <p>
              PROFESSIONAL <small>EDITING TOOL</small>
            </p>
          </Typography> */}
          {/* <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff",
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography> */}
          {mobileView && !leftSubadminNavbarOpen ? (
            <p>
              {selectedMenu !== 'Professional Editing Tool' ? (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "18px",
                    }}
                    className="text-custom"
                  >
                    {selectedMenu}
                  </text>
                </>
              ) : (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "18px",
                      marginRight: "5px"
                    }}
                    className="text-custom"
                  >
                    PROFESSIONAL
                  </text>
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "18px",
                      fontWeight: 300,
                    }}
                    className="text-custom"
                  >
                    EDITING TOOL
                  </small>
                </>
              )}
            </p>
          ) : (
            <Typography
              className="main-title"
              variant="h6"
              noWrap
              style={{ color: "#4d4f5c" }}
            >
              <p>
                {selectedMenu !== 'Professional Editing Tool' ? (
                  <>
                    <text
                      style={{
                        fontFamily: "ProximaNova-Semibold",
                        lineHeight: "1.18",
                        fontWeight: 600,
                        fontSize: "22px",
                      }}
                      className="text-custom"
                    >
                      {selectedMenu}
                    </text><br />
                    {bottomText && (
                      <small
                        style={{
                          fontFamily: "ProximaNova-Light",
                          lineHeight: "1.18",
                          fontSize: "14px",
                          fontWeight: 300,
                        }}
                        className="text-custom"
                      >
                        {bottomText}
                      </small>
                    )}
                  </>
                ) : (
                  <>
                    <text
                      style={{
                        fontFamily: "ProximaNova-Semibold",
                        lineHeight: "1.18",
                        fontWeight: 600,
                        fontSize: "22px",
                        marginRight: "5px"
                      }}
                      className="text-custom"
                    >
                      PROFESSIONAL
                    </text>
                    <small
                      style={{
                        fontFamily: "ProximaNova-Light",
                        lineHeight: "1.18",
                        fontSize: "22px",
                        fontWeight: 300,
                      }}
                      className="text-custom"
                    >
                      EDITING TOOL
                    </small>
                  </>
                )}
              </p>
            </Typography>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: "6%", }}>
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={openContactUs}
              >
                <ContactMailIcon />
              </IconButton> */}
              <LightTooltip title={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}>
                <Button onClick={toggleFullscreen} style={{ fontSize: props.mobileView ? '10px' : '16px', color: "#1976d2" }} >
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </Button>
              </LightTooltip>
              {getThemeSwitch()}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={notfication_popover}
                rootClose={true}
                show={popoverOpen}
              >
                <IconButton
                  classes={{ root: classes.iconButton }}
                  style={{ color: "#1976D2" }}
                  aria-label={`show ${notifications.notificationsCount} new notifications`}
                  color="inherit"
                  onClick={togglePopover}
                  disabled={notifications.notificationsCount < 1}
                >
                  <Badge
                    badgeContent={
                      notifications.notificationsCount > 0
                        ? notifications.notificationsCount
                        : null  // Set to null if you don't want to display anything when count is 0
                    }
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </OverlayTrigger>

            </div>
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <LightTooltip title={user} onClick={handleProfileMenuOpen}>
                    <Avatar
                      aria-label="account of current user"
                      aria-controls={menuId}
                      // onClick={handleProfileMenuOpen}
                      aria-haspopup="true"
                      alt={user}
                      src={profilePic ? profilePic : avatar}
                      id="profilePic"
                      className={`${classes.avatar} profilePic point`}
                      // data-toggle="tooltip"
                      data-placement="bottom"
                      // title={user}
                      color="inherit"
                    />
                  </LightTooltip>
                </OverlayTrigger>
              </Grid>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <LightTooltip title={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}>
              <Button onClick={toggleFullscreen} style={{ fontSize: props.mobileView ? '10px' : '16px', color: "#1976d2" }} >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
            </LightTooltip>
           
            {getThemeSwitch()}
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={notfication_popover}
              rootClose={true}
            >
              <IconButton
                classes={{ root: classes.iconButton }}
                style={{ color: "#1976D2" }}
                aria-label={`show ${notifications.notificationsCount} new notifications`}
                color="inherit"
                disabled={notifications.notificationsCount < 1}
              >
                <Badge
                  badgeContent={
                    notifications.notificationsCount > 0
                      ? notifications.notificationsCount
                      : null
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <MoreIcon /> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt={user}
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={emailId}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        // open={open}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        id="normalsidenav"
      >
        <div className="corner-top-navbar" style={{ display: "flex", alignItems: "center", backgroundColor: "#f0f0f7",  justifyContent: "space-between", padding: theme.spacing(0, 1), minHeight: '64px' }}>
          <IconButton className="ml-4" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <MenuIcon style={{ color: "#1976d2", fontSize: '25px' }} />
            ) : (
              <MenuIcon style={{ color: "#1976d2", fontSize: '25px' }} />
            )}
          </IconButton>
          <img
            alt="logo"
            className="img-fluid lcss"
            src={PoetNewlogopen}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+570.png"
            // }
            style={{
              width: "70%",
              height: "70%",
              objectFit: "cover",
              marginRight: '40px'
            }}
          // src={logo}
          ></img>


        </div>
        {/* <Divider /> */}
        {/* list starts */}
        <List className="drawerMenu pointer" id="menuList" style={{ borderTopRightRadius: '50px',}}>
          {/* Link 1 */}
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminDashboard" }}
            onClick={handleNavLinkClick}
            style={{border: "1px solid transparent"}}
          >
            <ListItem
              className={isUrlMatch("/subAdminDashboard") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="Dashboard"
                style={{ color: "red" }}
              >
                <LightTooltip title={open ? "" : "Dashboard"}>
                  <HomeIcon
                    className={
                      isUrlMatch("/subAdminDashboard") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>

                Dashboard
              </Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminManageUser" }}
            onClick={handleNavLinkClick}
          >
            <ListItem
              className={isUrlMatch("/subAdminManageUser") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="Manage User"
                style={{ color: "white" }}
              >
                <LightTooltip title={open ? "" : "Manage Users"}>
                  {/* <PeopleAltIcon
                    className={isUrlMatch("/subAdminManageUser") ? "iconCol" : "hvr-pulse"}
                  /> */}
                  <svg
                    className={
                      isUrlMatch("/subAdminManageUser")
                        ? "bi bi-person-fill-check iconCol"
                        : "bi bi-person-fill-check hvr-pulse"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="22px"
                    height="22px" fill="currentColor"
                    viewBox="0 0 18 18"
                    color="white"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                  </svg>
                </LightTooltip>
              </ListItemIcon>
              <Typography>

                Manage Users
              </Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminDocuments" }}
            onClick={handleNavLinkClick}
          >
            <ListItem
              className={isUrlMatch("/subAdminDocuments") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="Documents"
                style={{ color: "#53EDF3" }}
              >
                <LightTooltip title={open ? "" : "Documents"}>
                  <Documents
                    className={isUrlMatch("/subAdminDocuments") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Documents
              </Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminUserRequest" }}
            onClick={handleNavLinkClick}
          >
            <ListItem
              className={isUrlMatch("/subAdminUserRequest") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="User Request"
                style={{ color: "burlywood" }}
              >
                <LightTooltip title={open ? "" : "User Request"}>
                  <ManageAccountsRoundedIcon
                    className={isUrlMatch("/subAdminUserRequest") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                User Request
              </Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/manageOrganizationBlogs" }}
            onClick={handleNavLinkClick}
          >
            <ListItem
              className={isUrlMatch("/manageOrganizationBlogs") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="Manage Blogs"
                style={{ color: "yellow" }}
              >
                <LightTooltip title={open ? "" : "Manage Blogs"}>
                  <BookIcon
                    className={isUrlMatch("/manageOrganizationBlogs") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Manage Blogs
              </Typography>
            </ListItem>
          </NavLink>
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/userLogs" }}
          >
            <ListItem
              className={
                isUrlMatch("/userLogs") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Logs"}>
                  <Review
                    className={
                      isUrlMatch("/userLogs") ? "iconCol" : "hvr-pulse"
                    }
                    style={{ color: "#4CE4BE" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Logs</Typography>
            </ListItem>
          </NavLink>

          {/* <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/subAdminWorkFlow" }}
          >
            <ListItem
              className={isUrlMatch("/subAdminWorkFlow") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                // title="User Request"
                style={{ color: "black" }}
              >
                <LightTooltip title="Create WorkFlow">
                  <ManageAccountsRoundedIcon
                    className={isUrlMatch("/subAdminWorkFlow") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Create WorkFlow
              </Typography>
            </ListItem>
          </NavLink> */}

          {/* Link 2 */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageuser" }}
          >
            <ListItem className={isUrlMatch("/manageuser") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title=" Manage Users"
              >
                <PeopleAltIcon
                  className={isUrlMatch("/manageuser") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Users
            </ListItem>
          </Link> */}
          {/* Link 3 */}
          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/pricing" }}>
            <ListItem className={isUrlMatch("/pricing") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Manage Subscription"
              >
                <AttachMoneyIcon
                  className={isUrlMatch("/pricing") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Subscription
            </ListItem>
          </Link> */}
          {/* Link 4 */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageinvoices" }}
          >
            <ListItem
              className={isUrlMatch("/manageinvoices") && "background-red"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Manage Invoices"
              >
                <ReceiptIcon
                  className={isUrlMatch("/manageinvoices") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Invoices
            </ListItem>
          </Link> */}
          {/* <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/managereviews" }}
          >
            <ListItem
              className={isUrlMatch("/managereviews") && "background-red"}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Reviews"
              >
                <Review
                  className={isUrlMatch("/managereviews") ? "iconCol" : ""}
                />
              </ListItemIcon>
              Manage Reviews
            </ListItem>
          </Link> */}
        </List>
        {/* list over */}
        {/* <Divider /> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>


    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);

