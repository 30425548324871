import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData }) => {
    console.log(animationData,"animationData")
  const animationContainer = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    return () => animation.destroy();
  }, [animationData]);

  return (
    <div
      ref={animationContainer}
      style={{ width: '100%' }} // Set the size of the animation container
    />
  );
};

export default LottieAnimation;
