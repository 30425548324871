import { useSelector } from "react-redux";

export function useCheckFeatureAccess(screenName, featureName) {
    const featuresData = useSelector((state) => state.manageRoleFeatureReducer.roleFeatures);
    console.log(featuresData,"ssfeaturesData");
    
    console.log(screenName, featureName,"cc");
    

    const role = sessionStorage.getItem('Role');

    if (role !== 'Organization') {
        return true;
    }

    // Find the object with the matching ScreenName
    const screen = featuresData?.find(screen => screen.ScreenName === screenName);

    if (screen) {
        const feature = screen?.features?.find(f => f.name === featureName);
        return feature ? feature?.access === true : false;
    }

    return false;
}
