import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarRatings from "react-star-ratings";
import Avatar from "@material-ui/core/Avatar";
import documentService from "../services/document";
import dateFn from "date-fn";
import noreports from "../static/noreports.png";
import ReportServices from "../services/reportServices";
import SubTab from "../components/SubTab";
import GrammarReport from "./GrammarReport";
import Loader from "../utils/loader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "./Editor/utilize";
import ReactHtmlParser from "react-html-parser";
import { async } from "q";
import SearchBox from "./SearchBox";
import { capitalCase } from "change-case";
import { checkPlagiarism } from "../services/plagiarismServices";
import DocumentAnalyzer from "./DocumentAnalyzer";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import "./DocumentList.css"
import DatePickerMui from "./ReusableComponents/DatePickerMui";
import configdata from '../services/config.json'


export class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    console.log(props);
    this.state = {
      documents: [],
      loading: true,
      itemsToShow: 3,
      tags: ["abc"],
      docDetails: "",
      switchTabValue: 0,
      scores: {},
      htmlContent: null,
      sentenceLength: 0,
      grade: [],
      wordLength: 0,
      characterLength: 0,
      filterdocuments: [],
      plagScore: "",
      plagSource: "",
      startDate: null,
      endDate: null,
      windowWidth: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  async componentDidMount() {
    await this.getDocuments();
    console.log(this.props.searchDetail);
    if (this.state.documents.length > 0) {
      await this.docReportsDetails(this.state.documents[0]);
      console.log(this.props.searchDetail);
    } else {
      console.log("Document not found");
    }
    window.addEventListener('resize', this.handleResize);
  }

  getDocuments = async () => {
    let document = await documentService.getAllDocuments();
    console.log("document", document);
    if (document.data.length > 0) {
      if (document.status === 200) {
        this.setState({
          documents: document.data,
          defaultDoc: document.data[0],
          tags: document.data.tags,
          loading: false,
          filterdocuments: document.data,
        });
      } else {
        this.setState({ loading: false });
      }
    } else {
      this.setState({
        loading: false,
      });
      console.log("Document not found");
    }
    console.log(this.state.tags);
  };

  // convertToText = async (data) => {
  //   console.log(data);
  //   let content = JSON.parse(data);
  //   content = content.blocks;
  //   content = content
  //     .map((block) => (!block.text.trim() && "\n") || block.text)
  //     .join("\n")
  //     .trim();
  //   if (content) {
  //     if (content.length - 1 < 1) {
  //       this.setState({ sentenceLength: 0, wordLength: 0 });
  //     } else {
  //       let sentence = content.match(/\b((?!=|\.).)+(.)\b/g);
  //       if (sentence) {
  //         this.setState({ sentenceLength: sentence.length });
  //       }
  //       let wordLength = content.match(/\S+/g).length;
  //       this.setState({ wordLength });
  //     }
  //   } else {
  //     this.setState({ sentenceLength: 0, wordLength: 0 });
  //   }
  //   await this.readabilityCheck(content);
  // };

  convertToText = async (data) => {
    try {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = data;

      // Extract text content from the temporary element
      const textContent = tempElement.textContent || tempElement.innerText;

      const words = textContent.match(/\S+/g) || [];
      const sentences = textContent.match(/\b((?!=|\.).)+(.)\b/g) || [];
      const characters = textContent.length;


      this.setState({
        wordLength: words.length,
        sentenceLength: sentences.length,
        characterLength: characters
      });

      await this.readabilityCheck(textContent);
    } catch (error) {
      console.error('Error converting HTML to text:', error);
    }
  };


  readabilityCheck = async (data) => {
    const readability = require("readability-meter");
    const grade = await readability.ease(data);
    this.setState({ grade });
  };

  convertToHtml = (data) => {
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    return editorContentHtml;
  };

  docReportsDetails = async (data) => {
    console.log(data, "dataaa from doc report")
    //this.setState({ loading: true });
    await this.convertToText(data.onlyDocumentContent);
    let res = {
      characterLength: this.state.characterLength,
      wordLength: this.state.wordLength,
      sentenceLength: this.state.sentenceLength,
      grade: this.state.grade,
      data: data,
    };
    console.log(res);
    let details = await ReportServices.getReportDetails(data._id);
    let response = null;
    if (data.wordsCount > 30) {
      response = await ReportServices.getGrammarReportByDocsId(res);
      console.log(details);
      console.log(response, "score response");
      this.setState(
        {
          docDetails: details.data,
          tags: data.tags,
          scores: response,
          loading: false,
        }
        // ,
        // () => {
        //   if (data.wordsCount > 30) {
        //     this.setState({ loading: true }, () =>
        //       this.getPlagiarismReport(data)
        //     );
        //   } else {
        //     this.setState({
        //       plagScore: 0,
        //       plagSource: 0,
        //     });
        //   }
        // }
      );
    }

  };

  // getPlagiarismReport = async (data) => {
  //   const { scores } = this.state;
  //   let Originaldata = this.convertToHtml(data.onlyDocumentContent);
  //   Originaldata = Originaldata.replace(/(<([^>]+)>)/gi, "");
  //   let plagiarismdata = await checkPlagiarism(Originaldata);
  //   plagiarismdata = JSON.parse(plagiarismdata.response);
  //   console.log(plagiarismdata);
  //   scores["plagScore"] = plagiarismdata.plagPercent;
  //   scores["plagSource"] = plagiarismdata.sources.length;
  //   this.setState({
  //     plagScore: plagiarismdata.plagPercent,
  //     plagSource: plagiarismdata.sources.length,
  //     scores: scores,
  //     loading: false,
  //   });
  // };

  switchTab = async (value) => {
    await this.setState({
      switchTabValue: value,
    });
    console.log(this.state.switchTabValue);
  };

  downloadDocumentReport = async (data) => {
    const doc = new jsPDF();
    doc.text(`Document Report: ${data.documentName}`, 15, 25);
    doc.autoTable({ margin: { top: 40 }, html: "#my-card" });
    // doc.save("DocumentReport.pdf");
    doc.save(`${data.documentName}.pdf`);
    toast.success("Document report downloaded!")
  };

  handleSearch = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value === "") {
      this.setState({
        filterdocuments: this.state.documents,
      });
    } else {
      let value = e.target.value;
      let documents = this.state.documents;

      let result1 = [];
      result1 = documents.filter((docName) => {
        if (
          docName.documentName.toLowerCase().indexOf(value.toLowerCase()) > -1
        ) {
          return docName;
        }
      });
      this.setState({ filterdocuments: result1 });
    }
  };

  handleReset = () => {
    this.setState(
      {
        searchValue: "",
        startDate: null,
        endDate: null,
        filterdocuments: this.state.documents,
      },
      () => {
        this.filterDocuments();
      }
    );
  };

  filterDocuments = () => {
    const { documents, searchValue, startDate, endDate } = this.state;

    let filteredDocs = documents;

    if (searchValue) {
      filteredDocs = filteredDocs.filter((doc) =>
        doc.documentName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (startDate && endDate) {
      const endDateNormalized = new Date(endDate);
      endDateNormalized.setHours(23, 59, 59, 999);
      filteredDocs = filteredDocs.filter((doc) => {
        const creationDate = new Date(doc.creationDate);
        return creationDate >= startDate && creationDate <= endDateNormalized;
      });
    }

    this.setState({ filterdocuments: filteredDocs });
  };

  editdoc = async (doc) => {
    console.log(doc);
    this.props.history.push({
      pathname: `/texteditor`,
      data: { document: doc },
    });
  };

  //   componentDidMount() {

  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }


  render() {
    const {
      documents,
      docDetails,
      tags,
      switchTabValue,
      scores,
      filterdocuments,
      plagScore,
      plagSource,
    } = this.state;
    const mobileView = this.state.windowWidth < 1500;
    const smallMobile = this.state.windowWidth < 790
    const smallDeviceTable = this.state.windowWidth < 460
    console.log(plagSource);
    console.log(switchTabValue, "switchTabValue", scores, "scores", "mode", this.props.isDarkMode)
    return (
      <div >
        <Loader loading={this.state.loading} />


        {mobileView ? (
          <>

            <div className="responsive-container">
              {/* Search Bar */}
              <div className="search-bar-container">
                <input
                  className="form-control searchBar inputThemeStyle"
                  value={this.state.searchValue}
                  name="searchQuery"
                  placeholder="&#xF002; Search Document"
                  onChange={(e) => this.handleSearch(e)}
                  aria-label="Search"
                  style={{
                    fontFamily: "FontAwesome",
                    // border: "1px solid #a8a8a8 ",
                    width: "100%",
                    float: "left",
                    marginRight: '8px',
                    height: '52px'
                  }}
                />
              </div>

              {/* Date Pickers and Reset Button */}
              <div className="filters-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    isDarkMode={this.props.isDarkMode}
                    label="Start Date"
                    value={this.state.startDate}
                    onChange={(date) => this.setState({ startDate: date }, this.filterDocuments)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" className="date-picker responsive-date-picker" />
                    )}
                  />

                  <span className="date-separator separatorMargin" style={{ marginRight: "5px", marginLeft: "5px" }}>TO</span>

                  <DatePicker
                    isDarkMode={this.props.isDarkMode}
                    label="End Date"
                    value={this.state.endDate}
                    onChange={(date) => this.setState({ endDate: date }, this.filterDocuments)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" className="date-picker responsive-date-picker" />
                    )}
                  />
                </LocalizationProvider>

                <div className="reset_button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleReset}
                    className="reset-button responsive-reset-button"
                  >
                    Reset
                  </Button>
                </div>
              </div>

              {/* SubTab */}
              <div className="subtab-container">
                <SubTab docLength={documents.length} handleChangeTab={this.switchTab} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-5 mt-2">
                <SubTab
                  docLength={documents.length}
                  handleChangeTab={this.switchTab}
                />
              </div>
              <div className="col-md-7 mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  className="form-control searchBar inputThemeStyle"
                  value={this.state.searchValue}
                  name="searchQuery"
                  placeholder="&#xF002; Search Document"
                  onChange={(e) => this.handleSearch(e)}
                  aria-label="Search"
                  style={{
                    fontFamily: "FontAwesome",
                    // border: "1px solid #a8a8a8 ",
                    width: "40%",
                    float: "left",
                    marginRight: '8px',
                    height: '52px'
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={this.state.startDate}
                    onChange={(date) => this.setState({ startDate: date }, this.filterDocuments)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" style={{ width: "25%", marginRight: "8px" }} />
                    )}
                    style={{ border: "2px solid red" }}
                  />

                  {/* <DatePickerMui
                    fullDate={true} 
                    onChangeYear={(date) => this.setState({ startDate: date }, this.filterDocuments)}
                    isDarkMode={this.props.isDarkMode}
                  /> */}

                  <span style={{ margin: "0 8px" }}>TO</span>

                  <DatePicker
                    label="End Date"
                    value={this.state.endDate}
                    onChange={(date) => this.setState({ endDate: date }, this.filterDocuments)}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" style={{ width: "25%", marginRight: "8px" }} />
                    )}
                  />
                </LocalizationProvider>


                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleReset}
                  style={{ marginLeft: '8px' }}
                >
                  Reset
                </Button>
              </div>
            </div>
          </>
        )}




        {filterdocuments.length > 0 ? (
          <>
            <div className="row">
              <div className="col-md-4 reportour-3" style={{
                height: smallMobile ? "" : "570px",
                overflowY: "auto",
                overflowX: "hidden",
              }}>
                {filterdocuments.length > 0 &&
                  filterdocuments.map((document, index) => (
                    <div key={index} className=" bubble-right">
                      <Card
                        className="mt-2 point "
                        onClick={() => this.docReportsDetails(document)}
                      >
                        <CardHeader
                          title={
                            <h6
                              component={"span"}
                              style={{
                                fontWeight: "900",
                                overflowWrap: "break-word",
                              }}
                            >
                              {document.documentName}
                            </h6>
                          }
                          action={
                            <div className="p-2">
                              <small style={{ fontSize: "10px" }}>
                                {dateFn.date(document.date, 110)}
                              </small>
                            </div>
                          }
                        ></CardHeader>
                        <CardContent>
                          <div style={{ display: "flex", marginLeft: "-15px" }}>
                            {/* <div
                        className="MuiCardContent-root "
                        id="doclistRoot"
                      ></div> */}
                            <div className="col-md-6" color="textSecondary">
                              {document.tags.length > 0 &&
                                document.tags
                                  .slice(0, this.state.itemsToShow)
                                  .map((tag) => (
                                    <button
                                      key={tag}
                                      type="button"
                                      className="border ft_xs mr-2 p-1"
                                      style={{
                                        backgroundColor: "#f1f1f1",
                                        borderRadius: "4px",
                                        color: "#757575",
                                      }}
                                    >
                                      {capitalCase(tag)}{" "}
                                    </button>
                                  ))}

                              {document.tags.length > 3 ? <p>...</p> : ""}
                            </div>
                            <div className="col-md-6">
                              <div
                                className=""
                                style={{ float: "right", marginRight: "-15px" }}
                              >
                                <StarRatings
                                  rating={document.ratings || 0}
                                  starRatedColor="orange"
                                  // changeRating={rate =>
                                  //   this.setState({ rating: rate })
                                  // }
                                  starDimension="15px"
                                  starSpacing="0px"
                                  numberOfStars={5}
                                  name="rating"
                                />{" "}
                                <small
                                  style={{ opacity: "0.5", fontSize: "10px" }}
                                >
                                  {document.totalRatings || 0} ratings
                                </small>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
              </div>

              {/* ------Right Side----- */}

              {switchTabValue === 0 ? (
                <div className="col-md-8 reportour-4" style={{ position: "sticky" }}>
                  {docDetails !== "" ? (
                    <Card className="mt-2 ">
                      <CardHeader
                        id="card-details"
                        title={
                          <h6
                            component={"span"}
                            style={{
                              fontWeight: "900",
                              overflowWrap: "break-word",
                            }}
                          >
                            {docDetails.documentName}
                          </h6>
                        }
                        action={
                          <div className="p-2">
                            <small style={{ fontSize: "10px" }}>
                              {" "}
                              {dateFn.date(docDetails.date, 110)}
                            </small>
                          </div>
                        }
                      ></CardHeader>
                      <CardContent>
                        {/* <div
                      className="MuiCardContent-root "
                      id="doclistRoot"
                    ></div> */}
                        <div className="row">
                          <div className="col-md-6" color="textSecondary">
                            {tags.length > 0 &&
                              tags.map((tag) => (
                                <button
                                  key={tag}
                                  type="button"
                                  className="border ft_xs mr-2 p-1 "
                                  style={{
                                    backgroundColor: "#f1f1f1",
                                    borderRadius: "4px",
                                    color: "#757575",
                                  }}
                                >
                                  {capitalCase(tag)}
                                </button>
                              ))}
                          </div>
                          <div className="col-md-6">
                            <div className="pr-1" style={{ float: "right" }}>
                              <StarRatings
                                rating={docDetails.ratings || 0}
                                starRatedColor="orange"
                                // changeRating={rate =>
                                //   this.setState({ rating: rate })
                                // }
                                starDimension="15px"
                                starSpacing="0px"
                                numberOfStars={5}
                                name="rating"
                              />{" "}
                              <small
                                style={{ opacity: "0.5", fontSize: "10px" }}
                              >
                                {docDetails.totalRatings || 0} ratings
                              </small>
                            </div>
                          </div>
                        </div>

                        {docDetails.drafts.length > 0 ? (
                          <>
                            <div className="row mt-5" style={{ width: "100%", overflowY: "auto", }}>
                              <table
                                className="table table-hover p-4 "
                                style={{ fontSize: 12, textAlign: "center", }}
                                id="my-card"
                              >
                                <thead style={{ textAlign: "center", }}>
                                  <tr>
                                    <th></th>
                                    <th style={{ color: this.props.isDarkMode ? "white" : "black" }}>Shared Team</th>
                                    <th style={{ color: this.props.isDarkMode ? "white" : "black" }}>Shared Date</th>
                                    <th style={{ color: this.props.isDarkMode ? "white" : "black" }}>Submitted Date</th>
                                    <th style={{ color: this.props.isDarkMode ? "white" : "black" }}>Access</th>
                                    <th style={{ color: this.props.isDarkMode ? "white" : "black" }}>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {docDetails.drafts.length > 0 &&
                                    docDetails.drafts.map((draft) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td style={{ color: this.props.isDarkMode ? "white" : "black" }}>
                                          {" "}
                                          <Avatar
                                            src={draft.editor ? draft.editor.display_picture : `${configdata.imgUrl}/common-profile-pic/common-profile-pic.png`}
                                            style={{ width: 32, height: 32 }}
                                          />
                                        </td>
                                        <td style={{ color: this.props.isDarkMode ? "white" : "black" }}>{draft.editor ? draft.editor.username : "PET User"}</td>
                                        <td style={{ color: this.props.isDarkMode ? "white" : "black" }}> {dateFn.date(draft.date, 110)}</td>
                                        <td style={{ color: this.props.isDarkMode ? "white" : "black" }}>
                                          {draft.reviewDate !== ""
                                            ? dateFn.date(draft.reviewDate, 110)
                                            : "-"}
                                        </td>
                                        <td style={{ color: this.props.isDarkMode ? "white" : "black" }}>
                                          {draft.readOnly ? "View" : "Edit"}
                                        </td>
                                        <td>
                                          {docDetails.mergeDocuments.includes(
                                            draft._id
                                          ) === true ? (
                                            <span style={{ color: "green" }}>
                                              Collaboration Done
                                            </span>
                                          ) : draft.reviewStatus === true ? (
                                            <span style={{ color: "red" }}>
                                              Collaboration Pending
                                            </span>
                                          ) : (
                                            <span style={{ color: "red" }}>
                                              Not Submitted for Review
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>

                            <hr />
                            <div className="row">
                              <div
                                className="ml-auto mr-3 text-primary"
                                onClick={() =>
                                  this.downloadDocumentReport(docDetails)
                                }
                              >
                                <i
                                  className="fa fa-download pr-1"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <label
                                  className="downloadBtn"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  Download pdf Report
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )}
                </div>
              ) : switchTabValue === 1 ? (
                // plagScore && scores ? (
                //   <GrammarReport
                //     data={scores}
                //     document={docDetails}
                //     plagscores={plagScore}
                //     plagSource={plagSource}
                //   />

                scores ? (
                  <GrammarReport
                    data={scores}
                    document={docDetails}
                    isDarkMode={this.props.isDarkMode}
                  />
                ) : (
                  <div className="col-md-8">
                    <div className="container bg-white rounded shadow mt-5">
                      <div
                        className="pt-3 pb-3"
                        style={{ textAlign: "center" }}
                      >
                        <h3> Write Away!!</h3>
                        <h4>
                          PET needs at least 30 words to calculate document
                          statistics.
                        </h4>

                        {/* <Link to={{ pathname: "/pricing" }}> */}
                        <button
                          className="btn btn-primary hvr-float-shadow p-3 buysub"
                          onClick={() => this.editdoc(docDetails)}
                        >
                          <i className="fa fa-edit" aria-hidden="true"></i>
                          &nbsp; Edit Document
                        </button>
                        {/* </Link>  */}
                      </div>
                    </div>
                  </div>
                )
              ) : switchTabValue === 2 ? (
                <DocumentAnalyzer data={scores} document={docDetails} isDarkMode={this.props.isDarkMode} />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <h1 className="mt-5" style={{ textAlign: "center", width: "100%" }}>
            <img src={noreports} style={{ width: smallMobile ? "100%" : "50%" }} />
          </h1>
        )}
      </div>
    );
  }
}

export default withRouter(DocumentList);