import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import DataGridMui from "./ReusableComponents/DataGridMui";
import { getUserLogs } from "../services/userLogServices";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import SubscriptionRequired from "./SubscriptionRequired";
import { useCheckSubscriptionPlanAccess } from "./SubscriptionAccess";
 
const UserLogs = () => {
    const [loading, setLoading] = useState(true);
    const [organizationId, setOrganizationId] = useState(sessionStorage.getItem("organizationId"));
    const [userLogs, setUserlogs] = useState([]); // Original logs from API
    const [filteredLogs, setFilteredLogs] = useState([]); // Logs filtered by date
    const [selectedLogs, setSelectedLogs] = useState([]); // Logs of a specific user
    const [showLogsGrid, setShowLogsGrid] = useState(false); // Toggle for logs DataGrid
 
    // Default dates
    const today = format(new Date(), "yyyy-MM-dd");
    const yesterday = format(new Date(new Date().setDate(new Date().getDate() - 1)), "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(yesterday);
    const [toDate, setToDate] = useState(today);

    const accessToUserLogs = useCheckSubscriptionPlanAccess("User Logs")
    const [subReqOpen, setSubReqOpen] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const updateTheme = () => {
            const theme = localStorage.getItem('ThemeSwitch');
            setIsDarkMode(theme === 'true');
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
            originalSetItem.apply(this, arguments);
            if (key === 'ThemeSwitch') {
            updateTheme(); 
            }
        };
        return () => {
            window.removeEventListener('storage', updateTheme);
            localStorage.setItem = originalSetItem; 
        };
        }, []);

    useEffect(()=>{
        if(accessToUserLogs == false){
         setSubReqOpen(true)
        }
     },[accessToUserLogs])
 
    // Fetch all user logs and apply the date filter
    const getAllUserLogs = async () => {
        try {
            const response = await getUserLogs(organizationId);
            if (response?.status === 200) {
                const groupedLogs = response.userLog
                    .filter(log => log.userId && log.userId._id) // Exclude logs with null or invalid userId
                    .reduce((acc, log) => {
                        const { userId } = log;
                        if (!acc[userId._id]) {
                            acc[userId._id] = { ...log, allLogs: [log] };
                        } else {
                            acc[userId._id].allLogs.push(log);
                        }
                        return acc;
                    }, {});
               
                const logsArray = Object.values(groupedLogs);
                setUserlogs(logsArray);
                setFilteredLogs(filterLogsByDate(logsArray, fromDate, toDate));
                setLoading(false);
            } else {
                toast.warn(response?.message);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    };
 
    useEffect(() => {
        getAllUserLogs();
    }, [organizationId]);
 
    useEffect(() => {
        setFilteredLogs(filterLogsByDate(userLogs, fromDate, toDate));
    }, [fromDate, toDate, userLogs]);
 
    // Filter logs by date
    const filterLogsByDate = (logs, from, to) => {
        if (!from || !to) return logs;
 
        const fromDateTime = new Date(from).setHours(0, 0, 0, 0);
        const toDateTime = new Date(to).setHours(23, 59, 59, 999);
 
        return logs
            .map((log) => {
                const filteredAllLogs = log?.allLogs?.filter((innerLog) => {
                    const logDate = new Date(innerLog?.date_created).getTime();
                    return logDate >= fromDateTime && logDate <= toDateTime;
                });
 
                return filteredAllLogs.length > 0
                    ? { ...log, allLogs: filteredAllLogs }
                    : null;
            })
            .filter((log) => log !== null);
    };
 
    // Define the columns for the main DataGrid
    const columns = [
        {
            field: 'userId.username',
            headerName: 'User Name',
            flex: 0.3,
            valueGetter: (params) => params.row.userId?.username || "",
        },
        {
            field: 'userId.email',
            headerName: 'Email',
            flex: 0.3,
            valueGetter: (params) => params.row.userId?.email || "",
        },
        {
            field: 'userRole',
            headerName: 'User Role',
            flex: 0.2,
        },
        {
            field: 'viewLogs',
            headerName: 'User Logs',
            flex: 0.2,
            renderCell: (params) => (
                <IconButton onClick={() => handleViewLogs(params.row.allLogs)}>
                    <VisibilityIcon style={{ color: "#3d83fa" }} />
                </IconButton>
            ),
        },
    ];
 
    // Handle "View Logs" button click
    const handleViewLogs = (logs) => {
        setSelectedLogs(logs);
        setShowLogsGrid(true);
    };
 
    // Define the columns for the logs DataGrid
    const logsColumns = [
        { field: 'actionType', headerName: 'Action Type', flex: 0.3 },
        {
            field: 'date_created',
            headerName: 'Date',
            flex: 0.4,
            valueGetter: (params) => new Date(params.row.date_created).toLocaleString(),
        },
        { field: 'description', headerName: 'Description', flex: 0.5 },
    ];
 
    return (
        <div>
            <Loader loading={loading} />
            <SubscriptionRequired open={subReqOpen} setOpen={setSubReqOpen}/>
            <div className="container-fluid" style={{ background: isDarkMode?"black": "#f0f0f7" }}>
                <div className="row mt-2 w-100">
                    <div className="col-md-12">
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <h3 style={{ color: "#3d83fa" }}>User Logs</h3>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="From"
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginRight: 2 }}
                                />
                                <TextField
                                    label="To"
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                        <DataGridMui
                            height="60vh"
                            width="90vw"
                            getRowId={(data) => data._id}
                            columns={columns}
                            rows={filteredLogs}
                            checkboxSelection={false}
                        />
                    </div>
                </div>
            </div>
 
            {showLogsGrid && (
                <Box
                    sx={{
                        position: "fixed",
                        top: "10%",
                        left: "50%",
                        transform: "translate(-50%, 0)",
                        width: "70%",
                        bgcolor: isDarkMode?"#2a2a2a":"white",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        maxHeight: "70vh",
                        overflowY: "auto",
                        zIndex: 900,
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <h3 style={{color:isDarkMode?'white':''}}>User Logs</h3>
                        <IconButton onClick={() => setShowLogsGrid(false)}>
                            <CloseIcon style={{ color: "red" }} />
                        </IconButton>
                    </Box>
                    <DataGridMui
                        height="50vh"
                        width="65vw"
                        getRowId={(data) => data._id}
                        columns={logsColumns}
                        rows={selectedLogs}
                        checkboxSelection={false}
                    />
                </Box>
            )}
        </div>
    );
};
 
export default UserLogs;