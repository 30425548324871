import React, { useEffect, useState } from "react";
import SideNavBar from "../SideNavBar";
import Myteam from "../teamMembers";
import ChatContextProvider from "../../contexts/ChatContext";
import { useCheckSubscriptionPlanAccess } from "../SubscriptionAccess";
import Loader from "../../utils/loader";

const TeamMembersPage = () => {
  const [hasAccess, setHasAccess] = useState();
  const [hasAccessToChat, setHasAccessToChat] = useState();
  const [loading, setLoading] = useState(true);
  const accessToTeamsScreen = useCheckSubscriptionPlanAccess("Teams");
  const accessToChatScreen = useCheckSubscriptionPlanAccess("Chat")

  useEffect(() => {
    console.log("teamsConsole_",accessToTeamsScreen,accessToChatScreen)
    if (accessToTeamsScreen !== undefined && accessToChatScreen !== undefined && accessToTeamsScreen !== null && accessToChatScreen !== null) {
      setHasAccess(accessToTeamsScreen);
      setHasAccessToChat(accessToChatScreen)
      setLoading(false); 
    }else{
      setLoading(false)
    }
  }, [accessToTeamsScreen,accessToChatScreen]);


  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <SideNavBar>
      <ChatContextProvider>
      {accessToTeamsScreen !== null && <Myteam hasAccess={hasAccess} hasAccessToChat={hasAccessToChat} />}
      </ChatContextProvider>
    </SideNavBar>
  );
};

export default TeamMembersPage;
