import configdata from "./config.json";

 
 
import httpService from "./httpService.js";
 
let apiEndpoint = "";
 
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/subscription`;
 
} else {
  apiEndpoint = `${configdata.apiUrl}/subscription`;
 
}


export async function getSubscriptions(){
  const response=await httpService.get(`${apiEndpoint}/getSubscriptions`);
  return response;
}

export async function addSubscriptions(data){
  const response=await httpService.post(`${apiEndpoint}/addplan`,{data});
  return response;
}
export async function updateSubscription(data){
  const response=await httpService.post(`${apiEndpoint}/updatePlan`,{data});
  return response;
}
export async function deletePlan(data){
  const response=await httpService.post(`${apiEndpoint}/deletePlan`,{data});
  return response;
}



 
export default {
  getSubscriptions,
  addSubscriptions,
  updateSubscription
}
