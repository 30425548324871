import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import { Tabs, Tab, Box, AppBar, InputAdornment, FormControlLabel, Select, Modal, FormControl, InputLabel, NativeSelect, TextField, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';

import { Modal as B_Modal, Form } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ContributorForm } from './CitationCard';
import './Citation-1.css'
import { checkFeatureAccess } from '../featureAccess';
import { useCheckFeatureAccess } from '../CheckFeature';

const CitationModal = ({publicationYearError,setPublicationYearError, accessYearError, setAccessYearError,publicationDayError, 
  setPublicationDayError,accessDayError, setAccessDayError,selectedValue,setSelectedValue, handleSaveCitations, formData, 
  setFormData, show, setShow, contributors, setContributors, setSearchInput, setWebsiteData,publicationYear, setPublicationYear,publicationMonth, setPublicationMonth,publicationDay, setPublicationDay,accessYear, setAccessYear,accessMonth, setAccessMonth,accessDay, setAccessDay}) => {


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToCiteManually = useCheckFeatureAccess('citationGenerator','citeManually')
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    
    useEffect(()=>{
      setSearchInput('')
      setWebsiteData(null)
    },[selectedValue])


    const currentYear = new Date().getFullYear();

    const handlePublicationYearChange = (e) => {
      const inputYear = e.target.value;
    
      // Check if the input is not empty and is a valid 4-digit year with only numeric characters
      if (inputYear === '' || (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)) {
        setPublicationYearError('');
        setPublicationYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = publicationMonth;
        const inputDay = publicationDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setPublicationDayError('Invalid day');
        } else {
          setPublicationDayError('');
          handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
        }
      } else {
        setPublicationYearError('Invalid year');
      }
    };

    const calculateMaxDay = (month, year) => {
      switch (month) {
        case "January":
        case "March":
        case "May":
        case "July":
        case "August":
        case "October":
        case "December":
          return 31;
        case "April":
        case "June":
        case "September":
        case "November":
          return 30;
        case "February":
          // Check for leap year
          return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28;
        default:
          return 31; // Default to 31 if month is not recognized (for safety)
      }
    };

    const handlePublicationMonthChange = (e) => {
      const inputMonth = e.target.value;
      setPublicationMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = publicationYear;
      const inputDay = publicationDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };

    
    const handlePublicationDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = publicationMonth;
      const inputYear = publicationYear;
      
      let maxDay = 31; // Default maximum days
      
      // Update maxDay based on the month
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        // Check for leap year
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
      
      // Check if the input is not empty and within the valid range
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        setPublicationDay(inputDay);
        handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };
    
    
    const handleAccessYearChange = (e) => {
      const inputYear = e.target.value;
    
      // Check if the input is not empty and is a valid 4-digit year with only numeric characters
      if (inputYear === '' || (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)) {
        setAccessYearError('');
        setAccessYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = accessMonth;
        const inputDay = accessDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setAccessDayError('Invalid day');
        } else {
          setAccessDayError('');
          handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
        }
      } else {
        setAccessYearError('Invalid year');
      }
    };


    const handleAccessMonthChange = (e) => {
      const inputMonth = e.target.value;
      setAccessMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = accessYear;
      const inputDay = accessDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };


    const handleAccessDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = accessMonth;
      const inputYear = accessYear;
  
      let maxDay = 31;
  
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
  
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        setAccessDay(inputDay);
        handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };
  

    const getHeadingFromSelectedValue = () => {
      switch (selectedValue) {
        case 0:
          return "Webpage";
        case 1:
          return "Journal Article";
        case 2:
          return "Book";
        case "Video":
        case "Online News Article":
        case "Image":
        case "Report":
        case "Website":
        case "Book Chapter":
        case "Online Dictionary Entry":
          setShow(true)
          return selectedValue;
        default:
          setShow(false)
          return "";
      }
    };
  
  
  
    const handleAddMember = () => {
      setContributors((prevContributors) => [
        ...prevContributors,
        { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
      ]);
    };
  
    const handleDeleteMember = (id) => {
      setContributors((prevContributors) => prevContributors.filter((contributor) => contributor.id !== id));
    };
  
    const handleInputChange = (field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      console.log(formData, "formdata");
    };
  
    const handleContributorChange = (id, field, value) => {
      setContributors((prevContributors) =>
        prevContributors.map((contributor) =>
          contributor.id === id ? { ...contributor, [field]: value } : contributor
        )
      );
    };
  
  
   
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    return (
      <>
        {/* <a href="#" className="cite-button" onClick={handleModalShow} style={{ whiteSpace: "nowrap" }}>
          <AddIcon className="plus-icon" /> Cite Manually
        </a> */}
  
        <B_Modal
         
          show={show}
          onHide={() => {
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              url: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            });
            setShow(false)
            setSelectedValue(0)
  
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])
            setPublicationYearError('')
            setAccessYearError('')
            setPublicationDayError('')
            setAccessDayError('')
            setPublicationDay('')
            setPublicationMonth('')
            setPublicationYear('')
            setAccessDay('')
            setAccessMonth('')
            setAccessYear('')
          }}
          centered
          style={{
            fontSize: "13px",
            zIndex: 5000
          }}
          size='lg'
        >
          <B_Modal.Header closeButton>
            <B_Modal.Title>
              <h3 className='zzz'>{getHeadingFromSelectedValue()}</h3>
            </B_Modal.Title>
          </B_Modal.Header>
          <B_Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden', paddingLeft: '60px' }}>
            <Form>
  
  
              {(selectedValue === 'Book Chapter' || selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
            <Form.Group controlId="formTitle" className="row align-items-center">
            <Form.Label className="col-md-3 col-4 pt-2 zzz">Title</Form.Label>
            <Form.Control
              className="col-md-8 col-7"
              type="text"
              value={formData.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
          </Form.Group>
              )}
  
  
  
  
              {(selectedValue === 1 ) && (
             <Form.Group controlId="formJournalName" className="row align-items-center">
             <Form.Label className="col-md-3 col-4 pt-2 zzz">Journal Name</Form.Label>
             <Form.Control
               className="col-md-8 col-7"
               type="text"
               value={formData.journalName}
               onChange={(e) => handleInputChange("journalName", e.target.value)}
             />
           </Form.Group>
           
              )}
              
  
              {(selectedValue === 0 || selectedValue === 'Image' ) && (
             <Form.Group controlId="formDescription" className="row align-items-center">
             <Form.Label className="col-md-3 col-4 pt-2 zzz">Description</Form.Label>
             <Form.Control
               className="col-md-8 col-7"
               type="text"
               value={formData.description}
               onChange={(e) =>
                 handleInputChange("description", e.target.value)
               }
             />
           </Form.Group>
           
              )}
  
  
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report') && (
              <Form.Group controlId="formWebsiteName" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Website Name</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  // placeholder="Website Name"
                  value={formData.websiteName}
                  onChange={(e) =>
                    handleInputChange("websiteName", e.target.value)
                  }
                />
              </Form.Group>
              )}
  
              {/* Contributors Dropdown */}
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report') && (
              <Form.Group controlId="formContributors">
                <Accordion style={{ width: '93.3%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className='zzz'>Contributors</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    {contributors.map((contributor, index) => (
                      <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                        <ContributorForm
                          contributor={contributor}
                          handleDelete={() => handleDeleteMember(contributor.id)}
                          handleInputChange={(field, value) =>
                            handleContributorChange(contributor.id, field, value)
                          }
                        />
                      </div>
                    ))}
                    <div style={{ width: '100%', marginBottom: '10px' }}>
                      <B_Button variant="primary" onClick={handleAddMember}>Add Member +</B_Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
  
              </Form.Group>
              )}
  
  
            {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formEdition" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Edition</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.edition}
                  onChange={(e) => handleInputChange("edition", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
              {(selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formVolumeNumber" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Volume Number</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.volumeNumber}
                  onChange={(e) => handleInputChange("volumeNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formIssueNumber" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Issue Number</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.issueNumber}
                  onChange={(e) => handleInputChange("issueNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formLibraryDatabase" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Library Database</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.libraryDatabase}
                  onChange={(e) => handleInputChange("libraryDatabase", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formPage" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Page</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.page}
                  onChange={(e) => handleInputChange("page", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formdoi" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">DOI</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.doi}
                  onChange={(e) => handleInputChange("doi", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {/* Publication Date */}
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue
              === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
                <Form.Group controlId="formPublicationDate" className="row align-items-center ">
                <div className="col-md-3 col-4">
                  <Form.Label className="pt-2 zzz">Publication Date</Form.Label>
                </div>
                
                <div className="row col-md-8 col-7 justify-content-between" style={{ marginLeft: '-4%' }}>
                  <div className="col-md-4">
                    <Form.Control
                      type="text"
                      placeholder="Year"
                      value={publicationYear}
                      onChange={handlePublicationYearChange}
                      // style={{ marginRight: '8px' }}
                    />
                    {publicationYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationYearError}</div>}
                  </div>
                  
                  <div className="col-md-4">
                    <Form.Control
                      as="select"
                      placeholder="Month"
                      value={publicationMonth}
                      onChange={handlePublicationMonthChange}
                      // style={{ marginRight: '8px' }}
                    >
                      <option value="" disabled>Month</option>
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  
                  <div className="col-md-4">
                    <Form.Control
                      type="number"
                      placeholder="Day"
                      value={publicationDay}
                      onChange={handlePublicationDayChange}
                    />
                    {publicationDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationDayError}</div>}
                  </div>
                </div>
              </Form.Group>
              
              )}
  
              
  
              {(selectedValue === 0 || selectedValue === 'Video' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
            <Form.Group controlId="formAccessDate" className="row align-items-center">
            <div className="col-md-3 col-4">
              <Form.Label className="pt-2 zzz">Access Date</Form.Label>
            </div>
            
            <div className="row col-md-8 col-7" style={{ marginLeft: '-4%' }}>
              <div className="col-md-4">
                <Form.Control
                  type="text"
                  placeholder="Year"
                  value={accessYear}
                  onChange={handleAccessYearChange}
                  // style={{ marginRight: '15px' }}
                />
                {accessYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessYearError}</div>}
              </div>
              
              <div className="col-md-4">
                <Form.Control
                  as="select"
                  placeholder="Month"
                  value={accessMonth}
                  onChange={handleAccessMonthChange}
                  // style={{ marginRight: '8px' }}
                >
                  <option value="" disabled>Month</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
              </div>
              
              <div className="col-md-4">
                <Form.Control
                  type="number"
                  placeholder="Day"
                  value={accessDay}
                  onChange={handleAccessDayChange}
                />
                {accessDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessDayError}</div>}
              </div>
            </div>
          </Form.Group>
          
              )}
  
  
            {(selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formPublisher" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Publisher</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.publisher}
                  onChange={(e) => handleInputChange("publisher", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
  
  
  
  
              {(selectedValue === 0 || selectedValue === 'Image' || selectedValue === 'Video' || selectedValue === 1 || selectedValue === 2 || selectedValue === 'Book Chapter' || selectedValue === 'Online Dictionary Entry' || selectedValue === 'Online News Article' || selectedValue === 'Report' || selectedValue === 'Website') && (
              <Form.Group controlId="formURL" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">URL</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.url}
                  onChange={(e) => handleInputChange("url", e.target.value)}
                />
              </Form.Group>
              )}

  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formISBN" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">ISBN</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.ISBN}
                  onChange={(e) => handleInputChange("ISBN", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 2 || selectedValue === 'Book Chapter') && (
              <Form.Group controlId="formISSN" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">ISSN</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.ISSN}
                  onChange={(e) => handleInputChange("ISSN", e.target.value)}
                />
              </Form.Group>
              )}
  
  
              {(selectedValue === 1 ) && (
              <Form.Group controlId="formArticleNumber" className="row align-items-center">
                <Form.Label className="col-md-3 col-4 pt-2 zzz">Article Number or eLocater</Form.Label>
                <Form.Control
                  className='col-md-8 col-7'
                  type="text"
                  value={formData.articleNumber}
                  onChange={(e) => handleInputChange("articleNumber", e.target.value)}
                />
              </Form.Group>
              )}
  
  
  
            </Form>
          </B_Modal.Body>
          <B_Modal.Footer>
      
            <B_Button className='diabledInDarkMode' disabled={!accessToCiteManually} variant="primary" onClick={handleSaveCitations}>
                Save Citations
            </B_Button>
        
          </B_Modal.Footer>
        </B_Modal>
      </>
    );
  };

  export default CitationModal