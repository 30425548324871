import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import authService from "../services/authService";
import Group244 from "../static/Group244.png";
import Group5702x from "../static/Group5702x.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./LoginForm.css";
import loginBackground from "../static/loginBackground.png";
// import PetLogo from "../static/signup_login/PetLogo.png"
import PetLogo from "../static/POET_Logo_Mid.png"
import Login1 from "../static/login-3.png";
import { getHomeCMS } from "../services/adminServices";
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel
} from "react-bootstrap";
import InputBoxMui from "./ReusableComponents/InputBoxMui";
import PoetNewlogopen from '../static/newlogoPoet.png'

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    dense: {
        marginTop: 19
    }
}));
// const headOneStyle = {
//   fontSize: "42px",
//   lineheight: "0.88",
//   textAlign: "left",
//   color: "#ffffff"
// };
// const headTwoStyle = {
//   fontSize: "23px",
//   fontWeight: "300",
//   lineHeight: "0.91",
//   textAlign: "left",
//   color: "#ffffff"
// };
export default function ForgotPassNew() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [cms, setCms] = useState({});

    const getCms = async () => {
        let cms = await getHomeCMS();
        setCms(cms.data);
        console.log(cms);
    };

    useEffect(() => {

        getCms();
        // return () => toast.dismiss();
    }, []);

    async function handleForgotPassword() {
        setLoading(true);
        if (!email) {
            setLoading(false);
            toast.warn("Please enter the email");
            return;
        }
        const result = await authService.forgotpasssword({ email });
        console.log(result);
        if (result.status === 200) {
            setLoading(false);
            toast.success(result.message);
        } else {
            setLoading(false);
            toast.error(result.message);
        }
    }


    return (
        <div
            className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
                // position: "relative"
            }}
        >
            <ToastContainer />
            <Loader loading={loading} />

            <div className="row mt-2 px-3 py-2">
                <Link to="/">
                    <img
                        className="petLogo"
                        alt="Group570"
                        src={PoetNewlogopen}
                        style={{width:'35%'}}
                    ></img>
                </Link>
            </div>

            <div
                className="row justify-content-end"
            >
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 text-center">
                    <Card
                        className="justify-content-center card-comp px-3 p-4"
                    >
                        <div className="text-center">
                            <h3 className="mainHeading"
                            style={{color: "#353535"}}
                            >Forgot your password?</h3>
                            <p
                                style={{
                                    // opacity: "0.5",
                                    // fontSize: "12px",
                                    fontSize: "1rem",
                                    lineHeight: "1.52",
                                    // textAlign: "center",
                                    color: "#000000"
                                }}
                            >
                                {/* Reset your password through email id */}
                                Enter your registered email id to reset your password
                            </p>
                        </div>

                        <div className="mt-4 py-2">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            // placeholder="Username or Email"
                            />
                        </div>

                        <div className="text-center mt-4 py-2">
                            <Button
                                className="resetPassword"
                                onClick={handleForgotPassword}
                            >
                                Reset Password
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
