import React, { useEffect, useState } from "react";
import SubscriptionCard from "./Cards/SubscriptionCard";
import subscription, { addSubscriptions, deletePlan, updateSubscription } from "../services/subscription";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./PopupDesign.css";
import { toast } from "react-toastify";
import Loader from "../utils/loader";
import { faTowerBroadcast } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

const SubscriptionAdmin = () => {
  const [subscriptiondata, setSubscription] = useState([]);
  const [organizationSubscriptions, setOrganizationSubscriptions] = useState([]);
  const [individualSubscriptions, setIndividualSubscriptions] = useState([]);
  const [subscriptionFeatures, setSubscriptionFeatures] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [formData, setFormData] = useState({
    id: "",
    planName: "",
    userType: "",
    permissions: [], // Selected features
    billingCycle: [], // All billing cycles added by the user
  });
  const [currentBillingCycle, setCurrentBillingCycle] = useState({
    duration: "",
    price: "",
    userCounts: { admin: 0, powerUser: 0, regularUser: 0 },
    totalUsers: 0,
    aiRequest: 0
  });
  console.log(formData, "formData")
  const [activeBillingCycle, setActiveBillingCycle] = useState(null);
  const [showCustomDurationInput, setShowCustomDurationInput] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showComparison, setShowComparison] = useState(false);
  const [billingData, setBillingData] = useState(false);
  useEffect(() => {
    if (dataToEdit) {
      console.log(dataToEdit, "dataToEdit__")
      setFormData({
        id: dataToEdit._id,
        planName: dataToEdit.name,
        userType: dataToEdit.userType,
        billingCycle: dataToEdit.billingCycle || [],
        permissions: dataToEdit.features
          .filter((feature) => feature.access)
          .map((feature) => feature.name),
      });
    }
  }, [dataToEdit]);

  const toggleAccordion = (index) => {
    setActiveBillingCycle((prevIndex) => (prevIndex === index ? null : index));
  };



  // Add the current billing cycle to the billingCycle array
  const addCurrentBillingCycle = () => {
    if (!currentBillingCycle.duration) {
      toast.warn("Please provide a duration for the billing cycle.");
      return;
    }
    if (!currentBillingCycle.price) {
      toast.warn("Please provide a price for the billing cycle.");
      return;
    }
    if (formData.userType === "Organization") {
      const { admin, powerUser, regularUser } = currentBillingCycle.userCounts;

      // Validate all user counts are filled
      if (admin === 0) {
        toast.warn("Please provide the Admin user count.");
        return;
      }
      if (powerUser === 0) {
        toast.warn("Please provide the Power User count.");
        return;
      }
      if (regularUser === 0) {
        toast.warn("Please provide the Regular User count.");
        return;
      }
    }

    // If all validations pass, add the billing cycle
    setFormData((prev) => ({
      ...prev,
      billingCycle: [...prev.billingCycle, currentBillingCycle],
    }));

    // Reset the current billing cycle
    setCurrentBillingCycle({
      duration: "",
      price: "",
      userCounts: { admin: 0, powerUser: 0, regularUser: 0 },
      totalUsers: 0,
      aiRequest: 0
    });

    setShowCustomDurationInput(false);
    toast.success("Billing cycle added successfully!");
  };



  const handleBillingCycleChange = (field, value) => {
    setCurrentBillingCycle((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle changes in user counts (admin, powerUser, regularUser)
  const handleBillingCycleUserCounts = (userType, value) => {
    const updatedUserCounts = {
      ...currentBillingCycle.userCounts,
      [userType]: parseInt(value, 10) || 0,
    };
    setCurrentBillingCycle({
      ...currentBillingCycle,
      userCounts: updatedUserCounts,
      totalUsers:
        updatedUserCounts.admin +
        updatedUserCounts.powerUser +
        updatedUserCounts.regularUser,
    });
  };

  // Fetch subscriptions data
  const getSubscriptions = async () => {
    try {
      setLoading(true)
      const response = await subscription.getSubscriptions();
      if (response.status === 200) {
        const allSubscriptions = response?.data;
        console.log("allSubscriptions_", allSubscriptions)

        // Separate subscriptions by userType
        const organizationPlans = allSubscriptions.filter(
          (plan) => plan.userType === "Organization"
        );
        const individualPlans = allSubscriptions.filter(
          (plan) => plan.userType === "Individual"
        );

        setSubscription(allSubscriptions);
        setOrganizationSubscriptions(organizationPlans);
        setIndividualSubscriptions(individualPlans);

        // Set features from the first plan (optional, if needed)
        if (allSubscriptions.length > 0) {
          setSubscriptionFeatures(allSubscriptions[0]?.features || []);
        }
      }
    } catch (error) {
      console.log(error, "Error while fetching subscriptions");
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleAddNewClick = () => {
    setShowPopup(true);
  };

  const handleCancel = () => {
    setShowPopup(false);
    setEditMode(false);
    setDataToEdit(null)
    setFormData({
      planName: "",
      userType: "",
      permissions: [],
      billingCycle: [],
    });
    setCurrentBillingCycle({
      duration: "",
      price: "",
      userCounts: { admin: 0, powerUser: 0, regularUser: 0 },
      totalUsers: 0,
      aiRequest: 0
    });
  };

  const isDuplicatePlan = () => {
    console.log(comparisonData?.userType, "comparisonData_userType");

    // Check if the plan name and user type match
    const isPlanNameAndUserTypeDuplicate = comparisonData?.userType?.some(
      (plan) =>
        plan.name.toLowerCase() === formData.planName.toLowerCase() &&
        plan.userType === formData.userType
    );

    // Check if features match exactly with any existing plan
    const isFeatureDuplicate = comparisonData?.userType?.some((plan) => {
      const existingFeatures = plan.features.filter((f) => f.access).map((f) => f.name);
      const formFeatures = formData.permissions;

      return (
        formFeatures.length === existingFeatures.length &&
        formFeatures.every((feature) => existingFeatures.includes(feature))
      );
    });

    // Return true if either plan name/user type or features are duplicate
    return isPlanNameAndUserTypeDuplicate || isFeatureDuplicate;
  };



  const handleSaveClick = () => {
    if (isDuplicatePlan()) {
      toast.warn("Duplicate plan details found.");
      return;
    }
    handleSave(); // Proceed with the original save functionality
  };

  const handleSave = async () => {
    setLoading(true)
    // Validation checks
    if (!formData.planName) {
      toast.warn("Please provide a plan name.");
      return;
    }
    if (!formData.userType) {
      toast.warn("Please select a user type.");
      return;
    }
    if (formData.billingCycle.length === 0) {
      toast.warn("Please add at least one billing cycle.");
      return;
    }

    // Prepare the payload
    const payload = {
      id: formData.id,
      name: formData.planName,
      billingCycle: formData.billingCycle.map((cycle) => ({
        duration: cycle.duration,
        price: cycle.price,
        ...(formData?.userType == "Organization" && {
          userCounts: cycle.userCounts,
          totalUsers: cycle.totalUsers,
        }),
        // userCounts: cycle.userCounts,
        // totalUsers: cycle.totalUsers,
        aiRequest: cycle.aiRequest
      })),
      features: subscriptionFeatures.map((feature) => ({
        name: feature.name,
        access: formData.permissions.includes(feature.name),
      })),
      isDefault: formData.planName == 'Basic/Free Plan' ? true : false,
      userType: formData.userType,
    };

    console.log(payload, "bpayload");


    try {
      if (editMode) {
        const response = await updateSubscription(payload);

        if (response.status === 200) {
          toast.success("Plan added successfully!");
          getSubscriptions();
        } else {
          toast.error(response?.message || "Failed to add plan. Please try again.");
        }
      } else {
        const response = await addSubscriptions(payload);
        if (response.status === 200) {
          toast.success("Plan added successfully!");
        } else {
          toast.error(response?.message || "Failed to add plan. Please try again.");
        }

      }
      getSubscriptions(); // Refresh the subscription list
      handleCancel(); // Close the popup

      // Reset formData and currentBillingCycle state
      setFormData({
        planName: "",
        userType: "",
        permissions: [],
        billingCycle: [],
      });
      setCurrentBillingCycle({
        duration: "",
        price: "",
        userCounts: { admin: 0, powerUser: 0, regularUser: 0 },
        totalUsers: 0,
      });

    } catch (error) {
      console.error("Error adding plan:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false)
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (permission) => {
    setFormData((prev) => {
      const permissions = prev.permissions.includes(permission)
        ? prev.permissions.filter((p) => p !== permission)
        : [...prev.permissions, permission];
      return { ...prev, permissions };
    });
  };

  const handleEdit = (data) => {
    setEditMode(true);
    setShowPopup(true);
    setDataToEdit(data);
    console.log(data, "editData")
  };


  const handleDelete = async (planId) => {
    swal({
      title: "Permanent Deletion",
      text: "Do you want to permanently delete this Plan ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (permanentDelete) => {
      if (permanentDelete) {
        setLoading(true);
        const res = await deletePlan(planId);
        if (res.status !== 200) {
          setLoading(false);
          toast.error("Plan Deletion Failed !");
        }
        else {
          toast.success("Plan deleted permanently!");
          getSubscriptions();
        }
      } else {
        setLoading(false)
      }
    });
  };


  // const handleDelete = async (id) => {
  //   try {
  //     setLoading(true)
  //     const response = await deletePlan(id);
  //     if (response.status == 200) {
  //       toast.success(response?.message);
  //       getSubscriptions();
  //     } else {
  //       toast.error(response?.message)
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //   } finally {
  //     setLoading(false)
  //   }
  // };

  const editBillingCycle = (index) => {
    // Load the selected billing cycle into currentBillingCycle for editing
    setBillingData(true);
    const cycleToEdit = formData.billingCycle[index];
    setCurrentBillingCycle({
      duration: cycleToEdit.duration,
      price: cycleToEdit.price,
      userCounts: { ...cycleToEdit.userCounts },
      aiRequest: cycleToEdit.aiRequest
    });

    // Remove the selected billing cycle from the list to avoid duplicates when editing
    setFormData((prev) => ({
      ...prev,
      billingCycle: prev.billingCycle.filter((_, i) => i !== index),
    }));
  };

  const compareWithExistingPlans = () => {
    setShowComparison(true);
  };

  const comparePlans = () => {
    const compareField = (field, value) =>
      subscriptiondata.filter((plan) => plan[field] === value);

    return {
      planName: compareField("name", formData.planName),
      userType: compareField("userType", formData.userType),
      billingCycle: compareField("billingCycle", formData.billingCycle),
      permissions: compareField(
        "features",
        subscriptionFeatures.filter((feature) =>
          formData.permissions.includes(feature.name)
        )
      ),
    };
  };

  const comparisonData = showComparison ? comparePlans() : null;
  console.log(comparisonData, "comparisonData")
  return (
    <>
      <Loader loading={loading} />
      <div style={{ width: "100%" }}>
        <div style={{ width: "96%", display: "flex", justifyContent: "flex-end" }}>
          <button
            style={{
              width: "10%",
              padding: "10px",
              border: "none",
              outline: "none",
              backgroundColor: "#007bff",
              color: "#fff",
              borderRadius: "8px",
            }}
            onClick={handleAddNewClick}
          >
            Add New
          </button>
        </div>
        <h3 style={{ color: "#1976D2" }}>Organization Plans</h3>
        <div style={styles.container}>
          {organizationSubscriptions.length > 0 ? (
            organizationSubscriptions.map((data) => (
              <div style={showPopup ? styles.bgCardContainer : styles.cardContainer} key={data?.name}>
                <SubscriptionCard
                  planName={data?.name}
                  onEdit={() => handleEdit(data)}
                  onDelete={() => handleDelete(data?._id)}
                  isPopup={showPopup}
                />
              </div>
            ))
          ) : (
            <p>No Organization Plans Available</p>
          )}
        </div>

        <h3 style={{ color: "#1976D2" }}>Individual Plans</h3>
        <div style={styles.container}>
          {individualSubscriptions.length > 0 ? (
            individualSubscriptions.map((data) => (
              <div style={showPopup ? styles.bgCardContainer : styles.cardContainer} key={data?.name}>
                <SubscriptionCard
                  planName={data?.name}
                  onEdit={() => handleEdit(data)}
                  onDelete={() => handleDelete(data?._id)}
                  isPopup={showPopup}
                />
              </div>
            ))
          ) : (
            <p>No Individual Plans Available</p>
          )}
        </div>

        {/* Popup Component */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <div className="popup-header">
                <h3 style={{ color: "#007bff" }}>
                  {editMode ? "Edit Subscription Plan" : "Add Subscription Plan"}
                </h3>
                <span onClick={handleCancel}>
                  <HighlightOffIcon style={{ color: "red" }} />
                </span>
              </div>

              <div className="popup-content">
                <form className="form-popup">
                  {/* Plan Name */}
                  <div className="form-row-popup">
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      name="planName"
                      placeholder="Enter Plan Name"
                      onChange={handleInputChange}
                      value={formData.planName || ""}
                    />
                  </div>

                  {/* User Type */}
                  <div className="form-row-popup">
                    <select
                      style={{ width: "100%" }}
                      name="userType"
                      onChange={handleInputChange}
                      value={formData.userType || ""}
                    >
                      <option value="">Select User Role</option>
                      <option value="Individual">Individual</option>
                      <option value="Organization">Organization</option>
                    </select>
                  </div>

                  {/* Billing Cycles */}

                  <div className="billing-cycles-header">
                    <h5 style={{ margin: "0px" }}>Billing Cycles</h5>
                  </div>
                  <div className="form-row-popup">
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "Other") {
                          setShowCustomDurationInput(true);
                          handleBillingCycleChange("duration", "");
                        } else {
                          setShowCustomDurationInput(false);
                          handleBillingCycleChange("duration", value);
                        }
                      }}
                      value={currentBillingCycle.duration || ""}
                    >
                      <option value="">Select Duration</option>
                      <option value="monthly" disabled={formData.billingCycle.some(cycle => cycle.duration === "monthly")}>
                        Monthly
                      </option>
                      <option value="quarterly" disabled={formData.billingCycle.some(cycle => cycle.duration === "quarterly")}>
                        Quarterly
                      </option>
                      <option value="yearly" disabled={formData.billingCycle.some(cycle => cycle.duration === "yearly")}>
                        Yearly
                      </option>
                      <option value="Other" disabled={formData.billingCycle.some(cycle => cycle.duration === "Other")}>
                        Other (Specify)
                      </option>
                    </select>

                    {showCustomDurationInput && (
                      <input
                        type="text"
                        placeholder="Enter Custom Duration"
                        onChange={(e) =>
                          handleBillingCycleChange("duration", e.target.value)
                        }
                        value={currentBillingCycle.duration || ""}
                      />
                    )}

                    <input
                      type="number"
                      placeholder="Enter Price"
                      onChange={(e) =>
                        handleBillingCycleChange("price", e.target.value)
                      }
                      value={currentBillingCycle.price || ""}
                    />

                    <input
                      type="number"
                      placeholder="Enter AI Request Limit"
                      onChange={(e) =>
                        handleBillingCycleChange("aiRequest", e.target.value)
                      }
                      value={currentBillingCycle.aiRequest || ""}
                    />
                  </div>

                  {formData.userType === "Organization" && (
                    <>
                      <h5>User Type</h5>
                      <div className="form-row-popup">
                        <input
                          type="number"
                          placeholder="Admins"
                          onChange={(e) =>
                            handleBillingCycleUserCounts("admin", e.target.value)
                          }
                          value={currentBillingCycle.userCounts.admin || ""}
                        />
                        <input
                          type="number"
                          placeholder="Power Users"
                          onChange={(e) =>
                            handleBillingCycleUserCounts("powerUser", e.target.value)
                          }
                          value={currentBillingCycle.userCounts.powerUser || ""}
                        />
                        <input
                          type="number"
                          placeholder="Regular Users"
                          onChange={(e) =>
                            handleBillingCycleUserCounts("regularUser", e.target.value)
                          }
                          value={currentBillingCycle.userCounts.regularUser || ""}
                        />
                      </div>
                    </>
                  )}

                  <button
                    className="add-btn"
                    type="button"
                    onClick={addCurrentBillingCycle}
                    disabled={!currentBillingCycle.duration || !currentBillingCycle.price}
                  >
                    Add to Billing Cycles
                  </button>

                  {formData.billingCycle.length > 0 && (
                    <>
                      <h5>Billing Summary</h5>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Billing Duration</th>
                            <th>Billing Price</th>
                            <th>AI Request</th>
                            {formData?.userType == "Organization" && <>
                              <th>Total Users</th>
                              <th>Admin Users</th>
                              <th>Power Users</th>
                              <th>Regular Users</th>
                            </>}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.billingCycle.map((cycle, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{cycle.duration}</td>
                              <td>{cycle.price}</td>
                              <td>{cycle.aiRequest}</td>
                              {formData?.userType == "Organization" && <>
                                <td>{cycle?.totalUsers}</td>
                                <td>{cycle?.userCounts?.admin}</td>
                                <td>{cycle?.userCounts?.powerUser}</td>
                                <td>{cycle?.userCounts?.regularUser}</td>
                              </>}
                              <td>
                                <button
                                  type="button"
                                  onClick={() => editBillingCycle(index)}
                                  style={{
                                    padding: "5px 10px",
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}


                  {/* Permissions */}
                  <div className="permissions-section">
                    <div className="permission-item">
                      <input
                        type="checkbox"
                        id="select-all"
                        checked={subscriptionFeatures.every((feature) =>
                          formData.permissions.includes(feature.name)
                        )}
                        onChange={() => {
                          if (
                            subscriptionFeatures.every((feature) =>
                              formData.permissions.includes(feature.name)
                            )
                          ) {
                            setFormData((prev) => ({ ...prev, permissions: [] }));
                          } else {
                            setFormData((prev) => ({
                              ...prev,
                              permissions: subscriptionFeatures.map(
                                (feature) => feature.name
                              ),
                            }));
                          }
                        }}
                      />
                      <label htmlFor="select-all">Select All</label>
                    </div>

                    {subscriptionFeatures.map((feature, index) => (
                      <div key={index} className="permission-item">
                        <input
                          type="checkbox"
                          id={`feature-${index}`}
                          checked={formData.permissions.includes(feature.name)}
                          onChange={() => handleCheckboxChange(feature.name)}
                        />
                        <label htmlFor={`feature-${index}`}>{feature.name}</label>
                      </div>
                    ))}
                  </div>
                </form>
                <button className="add-btn mt-2" onClick={compareWithExistingPlans}>Compare with Existing Plans</button>
                {showComparison && (
                  <div>
                    <h3>Comparison</h3>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                      <thead>
                        <tr>
                          <th style={{ border: "1px solid #ddd", padding: "8px" }}>Field</th>
                          <th style={{ border: "1px solid #ddd", padding: "8px" }}>Current Plan</th>
                          {comparisonData?.userType?.map((plan, index) => (
                            <th style={{ border: "1px solid #ddd", padding: "8px" }} key={index}>
                              Existing Plan {index + 1}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Plan Name */}
                        <tr>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>Plan Name</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            <strong>{formData.planName}</strong>
                          </td>
                          {comparisonData?.userType?.map((plan, index) => (
                            <td
                              style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                backgroundColor: plan.name === formData.planName ? "#d4edda" : "inherit",
                              }}
                              key={index}
                            >
                              <strong>{plan.name}</strong>
                            </td>
                          ))}
                        </tr>

                        {/* Billing Cycles & User Counts */}
                        <tr>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>Billing Cycles & User Counts</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            {formData.billingCycle.map((cycle) => (
                              <div key={cycle.duration}>
                                <strong>{cycle.duration}</strong>:
                                {formData?.userType == "Organization" && <>
                                  {` Admin: ${cycle?.userCounts?.admin}, PowerUser: ${cycle?.userCounts?.powerUser}, RegularUser: ${cycle?.userCounts?.regularUser}`}
                                </>}
                                , Price: ${cycle.price}
                              </div>
                            ))}
                          </td>
                          {comparisonData?.userType?.map((plan, index) => (
                            <td style={{ border: "1px solid #ddd", padding: "8px" }} key={index}>
                              {plan?.billingCycle?.map((cycle) => {
                                const isMatchingCycle = formData.billingCycle.some(
                                  (currentCycle) =>
                                    currentCycle.duration === cycle.duration &&
                                    currentCycle?.userCounts?.admin === cycle.userCounts?.admin &&
                                    currentCycle?.userCounts?.powerUser === cycle.userCounts?.powerUser &&
                                    currentCycle?.userCounts?.regularUser === cycle.userCounts?.regularUser &&
                                    currentCycle.price === cycle.price
                                );
                                return (
                                  <div
                                    key={cycle.duration}
                                    style={{
                                      backgroundColor: isMatchingCycle ? "#d4edda" : "inherit",
                                    }}
                                  >
                                    <strong>{cycle?.duration}</strong>:
                                    {plan?.userType == "organization" && <>
                                      {` Admin: ${cycle?.userCounts?.admin}, PowerUser: ${cycle?.userCounts?.powerUser}, RegularUser: ${cycle?.userCounts?.regularUser}`}
                                    </>}
                                    , Price: ${cycle?.price}
                                  </div>
                                );
                              })}
                            </td>
                          ))}
                        </tr>

                        {/* Features / Permissions */}
                        {/* Features / Permissions */}
                        <tr>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>Features / Permissions</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            {formData.permissions.map((feature) => (
                              <div key={feature}>
                                <strong>{feature}</strong>
                              </div>
                            ))}
                          </td>
                          {comparisonData?.userType?.map((plan, index) => (
                            <td style={{ border: "1px solid #ddd", padding: "8px" }} key={index}>
                              {plan.features
                                .filter((feature) => feature.access) // Only include features with access: true
                                .map((feature) => {
                                  const isMatchingFeature = formData.permissions.includes(feature.name);
                                  return (
                                    <div
                                      key={feature.name}
                                      style={{
                                        backgroundColor: isMatchingFeature ? "#d4edda" : "inherit",
                                      }}
                                    >
                                      <strong>{feature.name}</strong>
                                    </div>
                                  );
                                })}
                            </td>
                          ))}
                        </tr>

                      </tbody>
                    </table>
                    <button
                      onClick={() => setShowComparison(false)}
                      style={{
                        marginTop: "20px",
                        padding: "10px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Close Comparison
                    </button>
                  </div>
                )}


              </div>





              <div className="popup-buttons">
                <button className="cancel-btn" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="save-btn" onClick={handleSaveClick}>
                  {/* Save */}
                  {editMode ? "Edit" : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    justifyContent: "start",
    marginTop: "20px",
    width: "100%",
  },
  cardContainer: {
    width: "30%",
    backgroundColor: "#1976D2",
    color: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  bgCardContainer: {
    width: "30%",
    backgroundColor: "#fff",
    color: "#1976D2",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  }
};
export default SubscriptionAdmin;



