import React, { Component } from 'react'
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Subadminsidebar from "../SubAdminSideBar"
import queryString from "query-string";
import UserLogs from '../UserLogs';

const SubAdminUserRequestPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
    return (
      <Subadminsidebar>
        <UserLogs />
      </Subadminsidebar>
    );
  };
  export default NetworkDetector(SubAdminUserRequestPage);
