
import httpService from "./httpService";
import configdata from "./config.json";
let apiEndpoint = "";

if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/detectors`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/detectors`;
}
 

export async function getRewriteText(text) {
    const data = await httpService.post(`${apiEndpoint}/rewriteText`,{text});
    return data;
  }

  export async function getStatisticalData(text) {
    const data = await httpService.post(`${apiEndpoint}/statisticalData`,{text});
    return data;
  }

  export async function getExplanation(text) {
    const data = await httpService.post(`${apiEndpoint}/explanation`,{text});
    return data;
  }


    

 
export default {
    getRewriteText,
    getStatisticalData,
    getExplanation

    
}