import React from 'react'
import AdminSideNavBar from '../AdminSideNavBar'
import SubscriptionAdmin from '../SubscriptionAdmin'

const SubscriptionPage = () => {
  return (
    <AdminSideNavBar>
    <SubscriptionAdmin />
  </AdminSideNavBar>
  )
  
}

export default SubscriptionPage