import http from "./httpService";
import configdata from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/promptGenerate`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/promptGenerate`;
}



export async function getParaphrasePrompt(data){
    const response = await http.post(`${apiEndpoint}/getParaphrasePrompt`,data);
    return response;
  }

  export async function updateParaphrasePrompt(data){
    const response = await http.post(`${apiEndpoint}/updateParaphrasePrompt`,data);
    return response;
  }



  export async function getPlagiarismPrompt(data){
    const response = await http.post(`${apiEndpoint}/getPlagiarismPrompt`,data);
    return response;
  }


  export async function updatePlagiarismPrompt(data){
    const response = await http.post(`${apiEndpoint}/updatePlagiarismPrompt`,data);
    return response;
  }

  

  export async function getCitationPrompt(data){
    const response = await http.post(`${apiEndpoint}/getCitationPrompt`,data);
    return response;
  }

  export async function updateCitationPrompt(data){
    const response = await http.post(`${apiEndpoint}/updateCitationPrompt`,data);
    return response;
  }


  export async function getTranslatorPrompt(data) {
    const response = await http.post(`${apiEndpoint}/getTranslatorPrompt`, data);
    return response;
  }
 
  export async function updatePromptforTranslator(data) {
    const response = await http.post(`${apiEndpoint}/updatePromptforTranslator`, data);
    return response;
  }

  export async function getCoauthorPrompt(data) {
    const response = await http.post(`${apiEndpoint}/getCoauthorPrompt`, data);
    return response;
  }
 
  export async function updateCoauthorPrompt(data) {
    const response = await http.post(`${apiEndpoint}/updateCoauthorPrompt`, data);
    return response;
  }



  export async function getSummariserPrompt(data) {
    const response = await http.post(`${apiEndpoint}/getSummariserPrompt`, data);
    return response;
  }
 
  export async function updatePromptforSummariser(data) {
    const response = await http.post(`${apiEndpoint}/updatePromptforSummariser`, data);
    return response;
  }


  export async function getGrammerCheckPrompt(data) {
    const response = await http.post(`${apiEndpoint}/getGrammerCheckPrompt`, data);
    return response;
  }
 
  export async function updatePromptGrammarCheck(data) {
    const response = await http.post(`${apiEndpoint}/updatePromptGrammarCheck`, data);
    return response;
  }



  export async function getHintPrompt(data){
    const response = await http.post(`${apiEndpoint}/getHintPrompt`,data);
    return response;
  }

  export async function updateHintPrompt(data){
    const response = await http.post(`${apiEndpoint}/updateHintPrompt`,data);
    return response;
  }

  
 


  export default {
    getParaphrasePrompt,
    updateParaphrasePrompt,

    getPlagiarismPrompt,
    updatePlagiarismPrompt,

    getCitationPrompt,
    updateCitationPrompt,

    getTranslatorPrompt,
    updatePromptforTranslator,

    getCoauthorPrompt,
    updateCoauthorPrompt,

    getSummariserPrompt,
    updatePromptforSummariser,

    getGrammerCheckPrompt,
    updatePromptGrammarCheck

    
 
}
