import React, { useState, useEffect } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService, { getUserRoleCount } from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import team from "../static/noteams.png"
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";
import EditIcon from '@mui/icons-material/Edit';
import { updateUserData } from "../services/adminServices";
import { countries } from "./ReusableComponents/countryList";
import InputBoxMobile from "./ReusableComponents/InputBoxforMobile";
import { addUserLogs } from "../services/userLogServices";
import { Select, MenuItem } from '@mui/material';
import SubscriptionRequired from "./SubscriptionRequired";
import { useCheckSubscriptionPlanAccess } from "./SubscriptionAccess";

const SubAdminManageUser = ({ currentUser }) => {
    console.log(currentUser, "propssss")
    const [inviteEmails, setInviteEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [inviteLimit, setInviteLimit] = useState(currentUser.userCount);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [adminModalShow, setAdminModalShow] = useState(false);
    const [currentRow, setCurrentRow] = useState(null)
    const [filterTeam, setFilterTeam] = useState([]);
    const [adminChanged, setAdminChanged] = useState(false)
    const history = useHistory()
    const [showEditUserPopup, setEditUserPopup] = useState(false);
    const [editedUser, setEditedUser] = useState(currentRow);
    const [errorMessage, setErrorMessage] = useState('');
    const [mobileNo, setMobileNo] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [countryList, setCountryList] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState("")
    const [selectedEditUser, setSelectedEditUser] = useState()
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [changeRole,setChangeRole]=useState("")
    const [currentUserEmail,setCurrentUser] =useState(sessionStorage.getItem("currentUserEmail"))
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const updateTheme = () => {
            const theme = localStorage.getItem('ThemeSwitch');
            setIsDarkMode(theme === 'true');
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
            originalSetItem.apply(this, arguments);
            if (key === 'ThemeSwitch') {
            updateTheme(); 
            }
        };
        return () => {
            window.removeEventListener('storage', updateTheme);
            localStorage.setItem = originalSetItem; 
        };
        }, []);
    const roles = [
        { name: "Admin", value: "Admin" },
        { name: "Power User", value: "Power User" },
        { name: "Regular User", value: "Regular User" }
      ];

      const getRowClassName = (params) => 
        params.row.email === currentUserEmail ? 'disabled-row' : '';
      const accessToChangeRole = useCheckSubscriptionPlanAccess("Change Role")
      const [subReqOpen, setSubReqOpen] = useState(false)
    
    
    
    console.log(mobileNo,"CCCCCC")
    console.log(countryList,"countryList")
    console.log(countryCode,"countryCode")
    console.log(errorMessage,"errorMessage")
    // useEffect(() => {
    //     if (adminChanged) {
    //         history.push({
    //             pathname: `/`,
    //         });
    //     }
    // }, [adminChanged]);

    useEffect(() => {
        // getTeam()
        getOrganizationUsers()
    }, []);

    let selectedCountry = countries.find(country => selectedCountryCode ? country.code === selectedCountryCode  :  `+${country.phone}` === countryCode);
    console.log(selectedCountry, "selectedCountry");

    const countryName = selectedCountry ? selectedCountry.label : "";
    console.log(countryName,"__countryName")
   
    const handleCountry = (event, value) => { 
        console.log("params_", event, value);
        
        const newCountryCode = `+${value.phone}`;
        setCountryCode(newCountryCode);
        setSelectedCountryCode(value.code)
        // Clear the mobile number when the country is changed
        setMobileNo(''); // Reset the phone number to blank
    
        // Update editedUser with the current country
        setEditedUser(prev => ({
            ...prev,
            phone: '', // Set phone in editedUser to blank
            country: value.label, // Update the country based on the selected value
        }));
    };
    

    const fetchCountryList = () => {
        let finalArr = []
        countries.map((obj) => {
            finalArr.push({
                name: obj.label,
                value: obj.label
            })
        })
        setCountryList(finalArr)
    }

    const countryLengthMap = {
        "+1": 10,
        "+93": 9,    // Afghanistan
        "+355": 8,   // Albania
        "+213": 9,   // Algeria
        "+376": 6,   // Andorra
        "+244": 9,   // Angola
        "+1-264": 10, // Anguilla
        "+1-268": 10, // Antigua and Barbuda
        "+54": 10,   // Argentina
        "+374": 8,   // Armenia
        "+297": 7,   // Aruba
        "+61": 9,    // Australia
        "+43": 13,   // Austria
        "+994": 9,   // Azerbaijan
        "+1-242": 10, // Bahamas
        "+973": 8,   // Bahrain
        "+880": 11,  // Bangladesh
        "+1-246": 10, // Barbados
        "+32": 9,    // Belgium
        "+501": 8,   // Belize
        "+229": 8,   // Benin
        "+1-441": 10, // Bermuda
        "+975": 8,   // Bhutan
        "+591": 8,   // Bolivia
        "+387": 8,   // Bosnia and Herzegovina
        "+267": 7,   // Botswana
        "+55": 11,   // Brazil
        "+1-284": 10, // British Virgin Islands
        "+673": 7,   // Brunei
        "+359": 8,   // Bulgaria
        "+226": 8,   // Burkina Faso
        "+257": 7,   // Burundi
        "+855": 8,   // Cambodia
        "+237": 9,   // Cameroon
        "+1": 10,    // Canada
        "+238": 7,   // Cape Verde
        "+1-345": 10, // Cayman Islands
        "+236": 7,   // Central African Republic
        "+235": 8,   // Chad
        "+56": 9,    // Chile
        "+86": 11,   // China
        "+61": 9,    // Christmas Island
        "+61": 9,    // Cocos (Keeling) Islands
        "+57": 10,   // Colombia
        "+269": 7,   // Comoros
        "+242": 7,   // Congo
        "+243": 9,   // Democratic Republic of the Congo
        "+682": 5,   // Cook Islands
        "+506": 8,   // Costa Rica
        "+225": 10,  // Côte d'Ivoire
        "+385": 9,   // Croatia
        "+53": 8,    // Cuba
        "+599": 7,   // Curaçao
        "+357": 8,   // Cyprus
        "+420": 9,   // Czech Republic
        "+45": 8,    // Denmark
        "+253": 6,   // Djibouti
        "+1-767": 10, // Dominica
        "+1-809": 10, // Dominican Republic
        "+593": 9,   // Ecuador
        "+20": 10,   // Egypt
        "+503": 8,   // El Salvador
        "+240": 9,   // Equatorial Guinea
        "+291": 7,   // Eritrea
        "+368": 6,   // Estonia
        "+251": 10,  // Ethiopia
        "+500": 4,   // Falkland Islands
        "+298": 6,   // Faroe Islands
        "+679": 7,   // Fiji
        "+358": 9,   // Finland
        "+33": 9,    // France
        "+594": 9,   // French Guiana
        "+689": 6,   // French Polynesia
        "+241": 7,   // Gabon
        "+220": 7,   // Gambia
        "+995": 9,   // Georgia
        "+49": 11,   // Germany
        "+233": 10,  // Ghana
        "+350": 5,   // Gibraltar
        "+30": 10,   // Greece
        "+299": 6,   // Greenland
        "+1-473": 10, // Grenada
        "+590": 10,  // Guadeloupe
        "+1-671": 10, // Guam
        "+502": 8,   // Guatemala
        "+224": 9,   // Guinea
        "+245": 9,   // Guinea-Bissau
        "+595": 9,   // Paraguay
        "+509": 9,   // Haiti
        "+379": 13,  // Holy See
        "+504": 8,   // Honduras
        "+852": 8,   // Hong Kong
        "+36": 9,    // Hungary
        "+354": 7,   // Iceland
        "+91": 10,   // India
        "+62": 10,   // Indonesia
        "+98": 10,   // Iran
        "+964": 10,  // Iraq
        "+353": 9,   // Ireland
        "+972": 9,   // Israel
        "+39": 10,   // Italy
        "+1-876": 10, // Jamaica
        "+81": 10,   // Japan
        "+44": 10,   // Jersey
        "+962": 9,   // Jordan
        "+7": 10,    // Kazakhstan
        "+254": 10,  // Kenya
        "+686": 5,   // Kiribati
        "+965": 8,   // Kuwait
        "+996": 9,   // Kyrgyzstan
        "+856": 8,   // Laos
        "+371": 8,   // Latvia
        "+961": 8,   // Lebanon
        "+266": 8,   // Lesotho
        "+231": 7,   // Liberia
        "+218": 10,  // Libya
        "+423": 7,   // Liechtenstein
        "+370": 8,   // Lithuania
        "+352": 8,   // Luxembourg
        "+261": 10,  // Madagascar
        "+265": 8,   // Malawi
        "+60": 10,   // Malaysia
        "+960": 7,   // Maldives
        "+223": 8,   // Mali
        "+356": 8,   // Malta
        "+692": 7,   // Marshall Islands
        "+596": 10,  // Martinique
        "+222": 9,   // Mauritania
        "+230": 8,   // Mauritius
        "+262": 10,  // Mayotte
        "+52": 10,   // Mexico
        "+691": 7,   // Micronesia
        "+373": 8,   // Moldova
        "+377": 8,   // Monaco
        "+976": 8,   // Mongolia
        "+1-664": 10, // Montserrat
        "+212": 9,   // Morocco
        "+258": 9,   // Mozambique
        "+95": 9,    // Myanmar
        "+264": 9,   // Namibia
        "+674": 8,   // Nauru
        "+977": 10,  // Nepal
        "+31": 9,    // Netherlands
        "+599": 10,  // Netherlands Antilles
        "+687": 6,   // New Caledonia
        "+64": 9,    // New Zealand
        "+505": 8,   // Nicaragua
        "+227": 8,   // Niger
        "+234": 10,  // Nigeria
        "+683": 5,   // Niue
        "+64": 9,    // Norfolk Island
        "+850": 8,   // North Korea
        "+1-670": 10, // Northern Mariana Islands
        "+47": 8,    // Norway
        "+968": 8,   // Oman
        "+92": 10,   // Pakistan
        "+680": 7,   // Palau
        "+970": 9,   // Palestine
        "+507": 8,   // Panama
        "+675": 7,   // Papua New Guinea
        "+595": 8,   // Paraguay
        "+51": 9,    // Peru
        "+63": 10,   // Philippines
        "+48": 9,    // Poland
        "+351": 9,   // Portugal
        "+1-787": 10, // Puerto Rico
        "+974": 8,   // Qatar
        "+40": 10,   // Romania
        "+7": 10,    // Russia
        "+250": 10,  // Rwanda
        "+590": 10,  // Saint Barthélemy
        "+1-869": 10, // Saint Kitts and Nevis
        "+1-758": 10, // Saint Lucia
        "+1-784": 10, // Saint Vincent and the Grenadines
        "+685": 5,   // Samoa
        "+378": 10,  // San Marino
        "+239": 7,   // Sao Tome and Principe
        "+966": 9,   // Saudi Arabia
        "+221": 9,   // Senegal
        "+381": 9,   // Serbia
        "+248": 7,   // Seychelles
        "+232": 8,   // Sierra Leone
        "+65": 8,    // Singapore
        "+421": 9,   // Slovakia
        "+386": 8,   // Slovenia
        "+677": 6,   // Solomon Islands
        "+252": 8,   // Somalia
        "+27": 10,   // South Africa
        "+82": 10,   // South Korea
        "+211": 9,   // South Sudan
        "+34": 9,    // Spain
        "+94": 10,   // Sri Lanka
        "+249": 10,  // Sudan
        "+597": 8,   // Suriname
        "+268": 7,   // Eswatini
        "+41": 9,    // Switzerland
        "+963": 9,   // Syria
        "+886": 10,  // Taiwan
        "+992": 9,   // Tajikistan
        "+255": 10,  // Tanzania
        "+66": 9,    // Thailand
        "+670": 7,   // Timor-Leste
        "+228": 8,   // Togo
        "+690": 6,   // Tokelau
        "+676": 4,   // Tonga
        "+1-868": 10, // Trinidad and Tobago
        "+216": 8,   // Tunisia
        "+90": 10,   // Turkey
        "+993": 9,   // Turkmenistan
        "+1-649": 10, // Turks and Caicos Islands
        "+688": 5,   // Tuvalu
        "+256": 10,  // Uganda
        "+380": 9,   // Ukraine
        "+971": 9,   // United Arab Emirates
        "+44": 10,   // United Kingdom
        "+1": 10,    // United States
        "+598": 8,   // Uruguay
        "+998": 9,   // Uzbekistan
        "+678": 5,   // Vanuatu
        "+379": 13,  // Vatican City
        "+58": 10,   // Venezuela
        "+84": 10,   // Vietnam
        "+681": 4,   // Wallis and Futuna
        "+212": 9,   // Western Sahara
        "+967": 9,   // Yemen
        "+260": 10,  // Zambia
        "+263": 9,    // Zimbabwe
        "+1684": 7,   // American Samoa (AS)
        "+358-18": 9, // Åland Islands (AX)
        "+590": 10,   // Saint Barthélemy (BL)
        "+375": 9,    // Belarus (BY)
        "+501": 8,    // Belize (BZ)
        "+383": 9,    // Kosovo (XK)
        "+1-340": 10, // United States Virgin Islands (VI)
        "+1-284": 10, // British Virgin Islands (VG)
        "+690": 4,    // Tokelau (TK)
        "+262": 9,    // French Southern Territories (TF)
        "+235": 8,    // Chad (TD)
        "+239": 7,    // São Tomé and Príncipe (ST)
        "+268": 7,    // Eswatini (SZ)
        "+290": 4,    // Saint Helena (SH)
        "+46": 10,    // Sweden (SE)
        "+262": 9,    // Réunion (RE)
        "+872": 9,    // Pitcairn Islands (PN)
        "+382": 9,    // Montenegro (ME)
        "+389": 9,    // North Macedonia (MK)
        "+269": 7,    // Comoros (KM)
        "+44": 10,    // Jersey (JE)
        "+44": 10,    // Isle of Man (IM)
        "+672": 6,    // Heard Island and McDonald Islands (HM)
        "+592": 9,    // Guyana (GY)
        "+500": 4,    // South Georgia and the South Sandwich Islands (GS)
        "+500": 4,    // Falkland Islands (FK)
    };

    const handleNumber = (e) => {
        const inputValue = e; // Use the input value directly, e.g., from an event handler
        console.log("eData_", inputValue, inputValue.length, countryCode);
    
        const maxLength = countryLengthMap[countryCode] || 10; // Default to 10 if country code isn't found
    
        // Validation based on country-specific length
        if (inputValue.length > maxLength) {
            return;
        }
    
        // Set error message if the length is less than required
        if (inputValue.length < maxLength && inputValue.length > 0) {
            setErrorMessage(`Please enter a ${maxLength} digit phone number.`);
        } else {
            setErrorMessage('');
        }
    
        // Update mobileNo and editedUser with the new phone number
        setMobileNo(inputValue);
    
        // Only update editedUser with the full phone number if inputValue is not blank
        if (inputValue.length > 0) {
            setEditedUser(prev => ({
                ...prev,
                phone: `${countryCode}${inputValue}`, // Update phone in editedUser
            }));
        } else {
            // If inputValue is empty, also update editedUser phone to an empty string
            setEditedUser(prev => ({
                ...prev,
                phone: '', // Clear phone in editedUser when input is empty
            }));
        }
    };
    
    const getOrganizationUsers = async () => {
        let currentUserEmail = sessionStorage.getItem("currentUserEmail")
        try {
            const response = await adminServices.getAllOrganizationUsers(currentUserEmail);
            // const response2 = await adminServices.getAllUsers2()
            console.log("organizationUsers", response)
            if (response.status === 200) {
                setTeamMembers(response.data);
                setFilterTeam(response.data);
                setLoading(false);
            } else if (response.status === 404) {
                setLoading(false);
                setTeamMembers(response.data);
                setFilterTeam(response.data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    }
    console.log(filterTeam, "filterTeam")

    //  Get All Team Members
    const getTeam = async () => {
        try {
            const teamMembers = await userService.getTeam();
            if (teamMembers.status === 200) {
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
                setLoading(false);
            } else if (teamMembers.status === 404) {
                setLoading(false);
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    };

    const handleCheckFn = async (data) => {
        // console.dir(data);
    };

    // Delete Member
    const handleDelete = async (user) => {
        setLoading(true);

        try {
            const team = await userService.deleteTeam({ id: user.id });
            if (team.status !== 200) {
                toast.error(team.message);
            } else {
                toast.success(team.message);
                getTeam();
                const data = {
                    userId: sessionStorage.getItem("userId"),
                    organizationId: sessionStorage.getItem("organizationId"),
                    actionType: "deleteTeamMember",
                    userEmail:user.email
                  };
            
                  const addLogs = await addUserLogs(data);
                  if (addLogs.status !== 200) {
                    toast.error(addLogs.message);
                  }
            }
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setFilterTeam(teamMembers);
        } else {
            const value = e.target.value.toLowerCase();

            const result = teamMembers.filter((member) =>
                member.name.toLowerCase().includes(value)
            );

            setFilterTeam(result);
        }
    };

    const columns = [
        // {
        //     field: 'id', headerName: 'ID',
        //     width: 90,
        //     flex: 0.2,
        // },
        {
            field: 'username',
            headerName: 'User Name',
            // width: 150,
            flex: 0.3,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'text',
            flex: 0.3,

        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            type: 'number',
            // width: 110,
            flex: 0.3,

        },
        {
            field: 'userRole',
            headerName: 'User Role',
            flex: 0.2,
        },
        {
            field: 'planName',
            headerName: 'User Plan',
            flex: 0.2,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => deleteUser(params)}
                        disabled={params.row.email === currentUserEmail}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<DeleteRoundedIcon fontSize="small" style={{ color: "red" }} />}
                    </button>
                )
            }
        },
        {
            field: 'changeAdmin',
            headerName: 'Change role',
            renderCell: (params) => {
              console.log(params,"paramscheck")
        
                return (
                    <Select
                    value={params.row.userRole} // Set the dropdown value to params.row.userRole
                    onChange={(event) => handleChange(params, event)} // Pass params and event to handleChange
                    variant="outlined"
                    placeholder="Select Role"
                    size="small"
                    style={{ width: '100%' }}
                    disabled={params.row.email === currentUserEmail} // Disable dropdown if userRole is Admin
                >
                    {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                            {role.name}
                        </MenuItem>
                    ))}
                </Select>
                
                
                );
            }
        },
      
    
          
        {
            field: 'edit',
            headerName: 'Edit',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => editUserData(params)}
                        disabled={params.row.email === currentUserEmail}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<EditIcon fontSize="small" style={{ color: "dodgerblue" }} />}
                    </button>
                )
            }
        },


        // {
        //     field: 'age',
        //     headerName: 'Subscription Plan',
        //     type: 'number',
        //     // width: 110,
        //     flex: 0.2,

        // },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     // width: 160,
        //     flex: 0.6,
        //     valueGetter: (params) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    const deleteUser = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        setDeleteModalShow(!deleteModalShow)
    }

    const deleteUserConfirm = async () => {
        let sendData = {
            email: currentRow.email,
            isDelete: true
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminDeleteUserDirectly(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else {
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        getOrganizationUsers()
        setDeleteModalShow(false)
        setLoading(false)
    }

    const changeAdmin = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        setAdminModalShow(!adminModalShow)
    }
    const handleChange = (params, event) => {
        if(!accessToChangeRole){
            setSubReqOpen(true)
            return
        }
        // Handle the role change here
        const newRole = event.target.value;
        console.log("Changed role to:", newRole);
        // You can access params as well
        console.log("Params:", params);
        setChangeRole(newRole)
        setCurrentRow(params.row)
        // Perform your logic (e.g., update state, show modal, etc.)
        setAdminModalShow(!adminModalShow);
    };
    

    const editUserData = (params) => {
        console.log("params", params);
        setSelectedEditUser(params.row)
        setCurrentRow(params.row);
        setEditedUser(prev => ({
            ...params.row,
            country: selectedCountry ? selectedCountry.label : "", // Set the country correctly
        }));
        fetchCountryList();
        const fullPhoneNumber = params.row.phone || ''; 
        let countryCode = "";
        let phoneNumber = "";
        
        for (const code in countryLengthMap) {
            if (fullPhoneNumber.startsWith(code)) {
                countryCode = code; // Set the country code if it matches
                phoneNumber = fullPhoneNumber.slice(code.length); // Get the actual phone number
                break; // Exit loop once the country code is found
            }
        }
        
        const selectedCountry = countries.find(country => country.phone === countryCode.slice(1));
        const countryName = selectedCountry ? selectedCountry.label : ""; // Get the country name
    
        // Update state with extracted values
        setCountryCode(countryCode);
        setMobileNo(phoneNumber);
        setEditedUser(prev => ({
            ...prev,
            country: countryName, // Set country in editedUser
        }));
        setEditUserPopup(true);
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUser((prev) => ({ ...prev, [name]: value }));

    };
    console.log(editedUser, "editedUser")
    console.log(selectedEditUser, "selectedEditUser")


    const fetchAllUser = async ()=>{
        let data = {
          orgId:localStorage.getItem('organizationId'),
          userRole:changeRole
        }
        const response = await getUserRoleCount(data)
        let Response2 = await countRegularUser(response.data)
        return Response2
    
      }

      const countRegularUser = (data)=>{
        let allowedUserRole = sessionStorage.getItem("subscriptionPlan")
        let parsedObject = JSON.parse(allowedUserRole)
        if(changeRole === "Power User"){
          if(data >= parsedObject.userCounts.powerUser){
            // console.log("Power User")
            return false
          }
        }
        if(changeRole === "Regular User"){
          if(data >= parsedObject.userCounts.powerUser){
            // console.log("Regular User")
            return false
          }
        }
        if(changeRole === "Admin"){
            if(data >= parsedObject.userCounts.admin){
                // console.log("Admin")
                return false
            }
        }
       
        return true
      }

    const changeAdminConfirm = async () => {
     
        let  isSwitchAllowed = await fetchAllUser()
        if(!isSwitchAllowed){
          return toast.error(`${changeRole} limit is exceeded`)
        }
        let sendData = {
            email: currentRow.email,
            isTransfer: true,
            currentuser: currentUser?._id,
            role:changeRole
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminTransferAdminRole(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
                setAdminChanged(true)
                getOrganizationUsers();
                const data = {
                    userId: sessionStorage.getItem("userId"),
                    organizationId: sessionStorage.getItem("organizationId"),
                    actionType: "changeRole",
                    previousRole:currentRow?.userRole,
                    newRole:changeRole,
                    email:currentRow.email
                  };
            
                  const addLogs = await addUserLogs(data);
                  if (addLogs.status !== 200) {
                    toast.error(addLogs.message);
                  }
            }
            else {
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        setAdminModalShow(false)
        // getOrganizationUsers()
        setLoading(false)
    }

    const editUserDetails = async () => {
        let sendData = {
            email: currentRow.email,
            isTransfer: true,
            currentuser: currentUser?._id
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminTransferAdminRole(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
                setAdminChanged(true)
            }
            else {
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        setAdminModalShow(false)
        // getOrganizationUsers()
        setLoading(false)
    }

    const handleSave = async () => {
        const userId = sessionStorage.getItem("userId")
        let changeEmail = false
        if(selectedEditUser.email !== editedUser.email){
            changeEmail = true
        }
        const updatedData = { ...editedUser, userId, changeEmail };

        console.log("usercred", updatedData);

        setLoading(true);

        try {
            if (updatedData.username === "" || updatedData.email === "" || updatedData.phone === "") {
                setLoading(false);
               return toast.warn("All fields are mandatory. Please fill them out.");
            }else if (updatedData.email.length < 5 || !/^\S+@\S+\.\S+$/.test(updatedData.email)) {
                setLoading(false);
               return toast.warn("Please enter a valid email (at least 5 characters and in the correct format).");
            }if(errorMessage){
                setLoading(false);
                return toast.warn("Please fill valid phone number.")
            }else {
                const user = await updateUserData(updatedData);

                if (user.status !== 200) {
                    setLoading(false);
                    toast.error(user.message);
                } else {
                    toast.success(user.message);
                    //console.log(currentUser);
                    //   setData(updatedData);
                    getOrganizationUsers()
                    setEditUserPopup(false)
                    setLoading(false);

                }
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setLoading(false);
        }
    };



    return (
        <div>
            <Loader loading={loading} />
            <ToastContainer />
            <SubscriptionRequired open={subReqOpen} setOpen={setSubReqOpen}/>
            <div className="container-fluid" style={{ background: isDarkMode?"black":"#f0f0f7" }}>
                {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
                <div className="row justify-content-end w-100">
                    {/* Title */}


                    {/* Search Bar */}
                    {/* <div className="col-md-8">
                        <input
                            className="form-control searchBar"
                            name="searchQuery"
                            onChange={handleSearch}
                            type="text"
                            placeholder="&#xF002; Search group or Team Member"
                            aria-label="Search"
                            style={{
                                fontFamily: "FontAwesome",
                                border: "none",
                                //   width: "60%",
                                //   float: "left",
                            }}
                        />
                    </div> */}

                    {/* Send Invite Button */}
                    {/* <div className="col-md-2">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-outline-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Invite
                            </button>
                        </OverlayTrigger>
                    </div> */}
                    {/* <div className="col-md-2 justify-content-end">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Export
                            </button>
                        </OverlayTrigger>
                    </div> */}
                </div>

                <div className="row mt-2 w-100">
                    {/* {filterTeam.length === 0 ? ( */}
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No User Records found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data._id}
                                    columns={columns}
                                    rows={filterTeam}
                                    checkboxSelection={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Delete User Modal */}
            <Modal
                isOpen={deleteModalShow}
                toggle={() => setDeleteModalShow(!deleteModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                    color:isDarkMode?"white":""
                }}
            >
                <ModalHeader
                    toggle={() => setDeleteModalShow(!deleteModalShow)}
                >

                    Delete User
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to delete <strong>{currentRow?.username}</strong>
                        </p>

                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary hvr-float-shadow buysub"
                                    onClick={deleteUserConfirm}
                                >
                                    Confirm
                                </button>
                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={adminModalShow}
                toggle={() => setAdminModalShow(!adminModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                    color:isDarkMode?"white":""
                }}
            >
                <ModalHeader
                    toggle={() => setAdminModalShow(!adminModalShow)}
                >

                    Change Role
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to change Role {currentRow?.userRole} to {changeRole}
                        </p>

                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn m-2 btn-primary hvr-float-shadow buysub"
                                    onClick={changeAdminConfirm}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn m-2 btn-primary hvr-float-shadow buysub"
                                    onClick={() => setAdminModalShow(!adminModalShow)}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={showEditUserPopup}
                toggle={() => setEditUserPopup(false)}
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    color:isDarkMode?"white":""
                }}
            >
                <ModalHeader
                    toggle={() => setEditUserPopup(false)}
                >

                    Edit User Details
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Username: </p>
                            <Input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={editedUser?.username || ''}
                                onChange={handleInputChange}
                                style={{ marginBottom: "10px", marginLeft: "15px", background: isDarkMode?'#2a2a2a':'', color:isDarkMode?'white':'' }}
                            />

                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Email: </p>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={editedUser?.email || ''}
                                onChange={handleInputChange}
                                style={{ marginBottom: "10px", marginLeft: "15px", background: isDarkMode?'#2a2a2a':'', color:isDarkMode?'white':'' }}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Phone: </p>
                            <div  style={{ marginBottom: "10px", marginLeft: "15px" , width: "80%"}}>
                            <InputBoxMobile
                                onChange={(e) => handleNumber(e)} // Ensure to pass the value
                                handleCountryCode={handleCountry} 
                                value={mobileNo} 
                                countryCode={countryCode}
                                selectedCountryCode={selectedCountryCode} 
                            />
                            </div>
                        </div>
                        {errorMessage && <div className="text-danger mt-1">{errorMessage}</div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => setEditUserPopup(false)}>Cancel</button>
                    <button className="btn btn-primary" onClick={handleSave}>Save</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubAdminManageUser));
