
export const setNavItemForCoauthor = (navItem) => {
    console.log("setnavv",navItem)
    return {
        type: "SET_NAV_ITEM_FOR_COAUTHOR",
        payload: navItem,
    };
};


