export function checkFeatureAccess(screenName, featureName) {
    // Check the role from sessionStorage
    const role = sessionStorage.getItem('Role');

    // If the role is not "Organization", return true immediately
    if (role !== 'Organization') {
        return true;
    }

    // Get the Features data from sessionStorage
    const featuresData = JSON.parse(sessionStorage.getItem('Features'));

    // Find the object with the matching ScreenName
    const screen = featuresData?.find(screen => screen.ScreenName === screenName);

    // If the screen is found, search for the feature in its features array
    if (screen) {
        const feature = screen?.features?.find(f => f.name === featureName);

        // Check if the feature exists and has access as true
        return feature ? feature?.access === true : false;
    }

    // Return false if the screen or feature is not found
    return false;
}