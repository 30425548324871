const initialState = {
    subscriptionPlan: {},
  };
  
  const subscriptionPlanReducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "SET_SUBSCRIPTION_PLAN":
        return {
          ...state,
          subscriptionPlan: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default subscriptionPlanReducer;