import React, { useEffect, useState } from "react";
import ProgressBarMui from "./ProgressBarMui";
import ProgressBarComp from "./ProgressBarComp";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import { useSelector } from "react-redux";
import { checkFeatureAccess } from "../featureAccess";
import { useCheckFeatureAccess } from "../CheckFeature";

const GrammarCheckAssistant = ({ mobileView, setShowAssistant, handleGoals, handleAIassistant, handleAIReview, grammarFluency, trial }) => {
    const [isOpen, setIsOpen] = useState(true)
    const [correctnessPercent, setCorrectnessPercent] = useState(0);
    const [clarityPercent, setClarityPercent] = useState(0);
    const [engagePercent, setEngagePercent] = useState(0);
    const [deliveryPercent, setDeliveryPercent] = useState(0);
    const [overAllScore, setOverAllScore] = useState(0);
    const { isFullSize } = useSelector((state) => state?.manageFullSizeEditor);
    const { isFullScreen } = useSelector((state) => state?.manageFullSizeEditor);
    const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
    useEffect(() => {
        console.log("grammarFluency_", grammarFluency);
        if (grammarFluency) {
            setCorrectnessPercent(grammarFluency.correctness)
            setClarityPercent(grammarFluency.fluency)
            setEngagePercent(grammarFluency.engagement)
            setDeliveryPercent(grammarFluency.delivery)
            setOverAllScore(grammarFluency.OverallScore)
        }
    }, [grammarFluency])

    const toggle = () => {
        mobileView ? setShowAssistant(false) :
            setIsOpen(!isOpen)
    }

    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
        const updateTheme = () => {
            const theme = localStorage.getItem('ThemeSwitch');
            setIsDarkMode(theme === 'true');
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
            originalSetItem.apply(this, arguments);
            if (key === 'ThemeSwitch') {
                updateTheme();
            }
        };
        return () => {
            window.removeEventListener('storage', updateTheme);
            localStorage.setItem = originalSetItem;
        };
    }, []);

    console.log(isFullSize, isFullScreen, "checkscreen")
    const accessToAIReview = useCheckFeatureAccess(currentNavItem, 'AIReview')
    return (
        <>
        {/* {accessToAIReview && */}
        <div
            className="Paige_Container"
            style={{
                backgroundColor: isDarkMode ? "black" : "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "9px",
                overflow: "hidden",
                // height: mobileView ? '100%' : (isOpen ? "85%" : "3rem"),
                height: isFullScreen ? 635 : isFullSize ? 555 : 515,
                transition: "height 0.3s",
                minWidth: '130px',
                border: isDarkMode ? "1px solid white" : "",
                color: isDarkMode ? "white" : ""

            }}
        >
            <div
                style={{
                    color: isDarkMode ? "white" : "#393939",
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    cursor: "pointer"
                }}
                onClick={toggle}
            >
                {mobileView ? "Hide Assistant >>" : (isOpen ? "Hide Assistant >>" : "Show Assistant <<")}
            </div>
            <div
                style={{
                    height: "74vh",
                    // overflowY: "auto",
                    padding: "3px",
                    margin: "0 auto"
                }}
            >
                <div
                    className="mt-4"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left", fontSize: '13px'
                        }}
                    >Overall Score</span>
                    <span
                        style={{
                            float: "right", fontSize: '13px'
                        }}
                    >{overAllScore}</span>
                </div>
                {/* <div
                    className="mt-3"
                    onClick={handleGoals}
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >Goals</span>
                    
                </div>
                <div
                onClick={handleAIassistant}
                    className="mt-3"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >AI Assistant</span>
                    
                </div> */}
                <hr style={{ borderTop: "1px solid #A0A0A0" }} />
                {/* <ProgressBarMui /> */}
                <div className="mt-4">
                    <ProgressBarComp
                        mobileView={mobileView}
                        progressLabel="Correctness"
                        checkCircleColor="#EA1537"
                        bgColor="#40E100"
                        completed={correctnessPercent}
                        isDarkMode={isDarkMode}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                        mobileView={mobileView}
                        progressLabel="Clarity"
                        checkCircleColor="#4A6EE0"
                        bgColor="red"
                        completed={clarityPercent}
                        isDarkMode={isDarkMode}
                    />
                </div>
                <div className="mt-4">
                    <ProgressBarComp
                        mobileView={mobileView}
                        progressLabel="Engagement"
                        checkCircleColor="#4AE09E"
                        bgColor="#EAD700"
                        completed={engagePercent}
                        isDarkMode={isDarkMode}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                        mobileView={mobileView}
                        progressLabel="Delivery"
                        checkCircleColor="#9D15EA"
                        bgColor="#9D15EA"
                        completed={deliveryPercent}
                        isDarkMode={isDarkMode}
                    />
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} />

                {/* <div
                    className="mt-2"
                    style={{
                        border: "1px solid #767676",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0px auto",
                        textAlign: "center"
                    }}
                >

                    <ForumRoundedIcon style={{ color: "dodgerblue" }} />
                    <span
                    // style={{
                    //     textAlign: "left"
                    // }}
                    >Comments</span>
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} /> */}

                <div
                    className="mt-2"
                    onClick={accessToAIReview ? handleAIReview : null}  // Disable click when no access
                    style={{
                        border: accessToAIReview
                            ? (isDarkMode ? "1px solid white" : "1px solid #3295F7")
                            : "1px solid lightgray",  // Change border color when disabled
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0 auto",
                        backgroundColor: accessToAIReview ? "transparent" : "lightgray",  // Change background color when disabled
                        cursor: accessToAIReview ? "pointer" : "not-allowed",
                    }}
                >
                    <span
                        style={{
                            fontSize: mobileView ? '13px' : '',
                            color: accessToAIReview ? (isDarkMode ? "white" : "black") : "gray"  // Change text color when disabled
                        }}
                    >
                        AI Review
                    </span>
                </div>


                {/* <div
                    className="mt-2"
                    onClick={accessToAIReview ? handleAIReview : null}
                    style={{
                        border: isDarkMode? "1px solid white":"1px solid #3295F7",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0 auto",
                        cursor: "pointer"
                    }}
                >
                    <span
                   style={{fontSize: mobileView ? '13px' : ''}}
                    >AI Review</span>
                </div> */}

                {/* <ul
                    style={{
                        listStyle: "none"
                    }}
                >
                    {
                        list.map((obj) => {
                            return <li>
                                <div
                                    className="mt-2"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        borderRadius: "8px",
                                        boxShadow: "2px 2px 5px -2px #000000",
                                        padding: "7px"
                                    }}
                                >
                                    <p>{obj}</p>
                                    <p>Sept 17 | 10:00 to 11:00 AM </p>
                                    <div
                                    className="justify-content-end"
                                    >
                                    <button className="btn btn-primary">Join now</button>
                                    </div>
                                </div>
                            </li>
                        })
                    }
                </ul> */}
            </div>
        </div>
        {/* } */}
        </>
    )
}

export default GrammarCheckAssistant