import { getOrgDetails } from "../services/organizationServices";
import { fetchUserData } from "../services/userService"

export async function checkAIRequestLimit(userId) {
        try {
            // alert(userId)
            const data = {
                userId
            }
            const repsonse = await fetchUserData(data)
            if (repsonse?.user) {
                console.log(repsonse,"xxxzxzxz");
                const userDetails = repsonse.user
                const role = userDetails.Role
                
                if (role == "Individual") {
                    const ConsumedAIRequestCount = userDetails.ConsumedAIRequestCount
                    const aiRequestLimit = userDetails.plan.aiRequest
                    console.log(aiRequestLimit,ConsumedAIRequestCount,"__ConsumedAIRequestCount");
                    if(ConsumedAIRequestCount >= aiRequestLimit){
                        return false
                    }else{
                        return true
                    }
                }
                if (role == "Organization") {
                    console.log(userDetails,"userDetails");
                    
                    const organizationId = userDetails.organizationId
                    const data = { organizationId }
                    const orgdata = await getOrgDetails(data) 
                    console.log(orgdata,"vbvbvbvbvb")
                    const ConsumedAIRequestCount = orgdata?.organizatioData?.ConsumedAIRequestCount
                    const aiRequestLimit = orgdata?.organizatioData?.plan.aiRequest
                    console.log(aiRequestLimit,ConsumedAIRequestCount,"__aiRequestLimit");
                    if(ConsumedAIRequestCount >= aiRequestLimit){
                        return false
                    }else{
                        return true
                    }
                }
            }
        } catch (error) {
            console.log("Error while fetching user data.")
        }

}