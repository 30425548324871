import { combineReducers } from 'redux';
 
import templateReducer from './coauthor/coauthor.reducer';
import userReducers from './user/user.reducer';
import documentReducer from './document/document.reducer';
import featureReducer from './features/features.redcuer';
import handleModelAndSideBavBarReducer from './handleSideNavBarAndModel/handleStatus.reducer';
import handleClickReducer from './manageClick/manageClick.reducer';
import handleLoginClickReducer from './manageLoginClick/manageLoginClick.reducer';
import handleSubAdminSideBarReducer from './manageSubAdminSidebar/handleSidebarStatus.reduce';
import manageChatReducer from './manageChatArea/chatAreaStatus.reducer';
import manageFullsizeEditorReducer from './manageFullSizeEditor/manageFullsizeEditor.reducer';
import manageCoauthorSelectedContentReducer from './coauthorSelectedContent/coauthorSelectedContent.reducer';
import coauthorNavReducer from './navClickForCoauthor/navClickForCoauthor.reducer';
import roleFeatureReducer from './roleFeatures/roleFeatures.reducer';
import subscriptionPlanReducer from './subscriptionPlan/subscriptionPlan.reducer';
 
export default combineReducers({
    user : userReducers,
    document : documentReducer,
    features : featureReducer,
    templateName:templateReducer,
    handleModelAndSideBavBar: handleModelAndSideBavBarReducer,
    handleClick: handleClickReducer,
    handleLoginClick: handleLoginClickReducer,
    handleSubAdminSideBar: handleSubAdminSideBarReducer,
    manageChat : manageChatReducer,
    manageFullSizeEditor: manageFullsizeEditorReducer,
    manageCoauthorSelectedContent: manageCoauthorSelectedContentReducer,
    manageCoauthorNavReducer : coauthorNavReducer,
    manageRoleFeatureReducer : roleFeatureReducer,
    manageSubscriptionPlan : subscriptionPlanReducer
 
})