import React, { useEffect } from "react";
import MyDocuments from "../MyDocuments";
import AdminNavBar from "../AdminSideNavBar";
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import authService from "../../services/authService";
import ViewMyDocuments from "../ViewMyDocuments";
import { useParams } from 'react-router-dom';
import CoAuthorNewProject from "../Forms/CoAuthorNewProject";



const ViewMyDocumentsPage = props => {

  const { documentId } = useParams();
  const coAuthor = props.location.state;

  console.log("coAuthormm", coAuthor, props.location.state)

  const user = authService.getCurrentUser();
  let userData = {};
  if (props.location.state) {
    userData = props.location.state.userData;

  }
console.log("documentId", documentId);

  if (!user.isAdmin) {
    return (
      <SideNavBar>
        {
          <CoAuthorNewProject docId={documentId} isCoAuthor={coAuthor.data ? true : false} hidecorrectnessbar={coAuthor.hidecorrectnessbar}/>

          // <ViewMyDocuments docId={documentId} />
        }

      </SideNavBar>
    );
  } else {
    return (
      <AdminNavBar>
        <MyDocuments data={userData} />
      </AdminNavBar>
    );
  }
};

export default NetworkDetector(withRouter(ViewMyDocumentsPage));
