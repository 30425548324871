import React, { useEffect, useState } from "react";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import "./LoginForm.css";
import { Link } from "react-router-dom";
import Correct from "../static/correct.png";
import userService from "../services/userService";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LottieAnimation from "./LottieAnimation";
import EmailRegistrationAnimation from "../../src/static/EmailRegistrationAnimation.json"
import Poetlogo from '../static/POET_Logo_Mid.png'
import PoetNewlogopen from '../static/newlogoPoet2.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  dense: {
    marginTop: 19,
  },
}));

function AfterRegistration({ data }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(data.email);
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResendMail = async () => {
    setLoading(true);
    if (!email) {
      setLoading(false);
      toast.warn("Please enter the email");
      return;
    }
    if (!data.email) {
      setLoading(false);
      toast.warn("Something went wrong please try again or re-register");
      return;
    }
    console.log(email);

    const result = await userService.resendEmail(email, data.email);
    if (result.status !== 200) {
      setLoading(false);
      toast.warn(result.message);
    } else {
      setLoading(false);
      toast.info(result.message);
    }
  };
  return (
    <div>
      <Loader loading={loading} />
      <ToastContainer />
      <div className="container mt-3" style={{ width: "100%" }}>
        <div
          className="row d-inline-flex p-3 text-white"
          style={{ width: "100%" }}
        >
          {/* Flex One */}
          <div
            className="col-md-6 p-4 border"
            style={{
              // backgroundColor: "#3b81f3",
              width: "100%",
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* Flex One Content */}
            <div
              style={{
                paddingLeft: "19.8%",
                paddingRight: "14.6%",
                paddingTop: "18%",
                paddingBottom: "30%",
              }}
            >
              <div className="row">
                <div>
                  {/* <h3 className="text-center" style={headOneStyle}>
                    PROFESSIONAL
                  </h3>
                  <h3 style={headTwoStyle}>EDITING TOOL</h3> */}
                  <Link to="/">
                    <img
                      src={PoetNewlogopen}
                      alt="image1"
                      //         srcset="img/group-457@2x.png 2x,
                      //  img/group-457@3x.png 3x"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    ></img>
                  </Link>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20%" }}>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "30px",
                    fontWeight: "500",
                    lineHeight: "1.35",
                    textAlign: "left",
                    color: "#ffffff",
                  }}
                >
                  Welcome to Professional Online Editing Tool
                </p>
              </div>

              <div className="row" style={{ marginTop: "7%" }}>
                <p style={{ lineHeight: "1.72", textAlign: "justify" }}>
                  {/* Welcome to Your Professional Online Editing Tool (POET). POET is the
                  industry-leading document editing and team collaboration
                  software for Businesses, Educators, Students and Researchers.
                  POET, integration with other apps and services, with the power
                  of AI brings all the state-of-the-art, extensive features
                  which are available in multiple writing tools. */}
                  Welcome to the future of document editing and collaboration. Poet combines industry-leading tools with powerful AI to support professionals, educators, and researchers at every stage. Experience enhanced productivity with robust features, including grammar checks, plagiarism detection, and collaborative review—all in a single, integrated platform that adapts to your writing and editing needs.
                </p>
              </div>
            </div>
          </div>

          {/* Flex Two */}

          <div className="col-md-6 p-4 border" style={{ width: "100%", height: "100vh" }}>
            {/* Flex Two Content */}
            <div className="text-center"
              style={{
                border: "1px solid lightgray",
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                borderRadius: "25px",
                padding: "18px",
                margin: "80px 20px 0px 30px",
                marginLeft: windowWidth < 376 ? "5px" : "30px",
                marginRight: windowWidth < 376 ? "5px" : "20px"
              }}>
              <div className="d-flex align-items-center justify-content-center">
                <div style={{ width: windowWidth < 769 ? "50%" :  windowWidth < 991 ? "60%" : "38%", marginTop: "20px", }}>
                  <LottieAnimation animationData={EmailRegistrationAnimation} />
                </div>
              </div>
              <p style={{ color: "#007bff", fontSize: "2rem", fontWeight: "600" }}>Registration Successful!</p>
              <p style={{ color: "#6C6C6C", fontSize: "1.2rem" }}>
                We've sent a confirmation email to <span 
                style={{
                  color: "#007bff",
                  overflowWrap: "break-word",  
                  whiteSpace: "normal"
                }}>{email}</span>. Check your inbox or spam to verify and activate your account. Almost there!
              </p>
              <hr style={{ color: "#6C6C6C", margin: "15px auto" }} />
              <p style={{ color: "#6C6C6C", fontSize: "1.2rem", fontWeight: "600" }}>Didn't receive the email? <span onClick={handleResendMail} style={{ color: "#007bff", fontWeight: "600", cursor: "pointer", fontSize: "1.2rem" }}>Resend mail</span></p>
              <div className="col-md-12 d-flex justify-content-center">
                  <Link to="/signup">
                    <button
                      type="button"
                      className="btn btn-outline-primary "
                    >
                      Back
                    </button>
                  </Link>
                </div>
              {/* <img
                className=" "
                style={{ height: "25%", width: "25%" }}
                src={Correct}
                alt="404"
              ></img> */}
              {/* <HeadingB className="alertHead" text="No Payment Reports Found..!" /> */}
            </div>
            {/* <div
              style={{
                paddingLeft: "20%",
                paddingRight: "20%",
                paddingTop: "8%",
                paddingBottom: "4%",
              }}
            >
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "1.26",
                      color: "#43425d",
                    }}
                  >
                    Thank You For Registration !
                  </h3>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-12">
                  <p
                    style={{
                      // opacity: "0.5",
                      fontSize: "15px",
                      lineHeight: "1.72",
                      textAlign: "justify",
                      color: "black",
                    }}
                  >
                    <b>
                      A confirmation mail has been sent to your email. If you
                      cannot see the email from PET in inbox, make sure to check
                      your SPAM folder.
                    </b>
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "10%" }}>
                <div className="col-md-12 ">
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-dense"
                      label="Email ID"
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </form>
                </div>
              </div>
              <div className="row" style={{ marginTop: "10%" }}>
                
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 loginBtn"
                    onClick={handleResendMail}
                  >
                    Resend Mail
                  </button>
                </div>

                <div className="col-md-6">
                  <Link to="/signup">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AfterRegistration;
