// import { apiUrl, herokuUrl } from "./config.json";
import configdata from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/features`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/features`;
}


export async function getAllFeatures() {
    const data = await httpService.get(`${apiEndpoint}/getAllFeatures`);
    return data;
  }

export default {
    getAllFeatures
}