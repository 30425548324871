import configdata from "../services/config.json";
import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${configdata.apiUrl}/organizations`;
   
  } else {
    apiEndpoint = `${configdata.apiUrl}/organizations`;
   
  }
  export async function getOrgDetails(data){
    const response=await httpService.post(`${apiEndpoint}/getOrgDetails`,data);
    return response;
  }


 
   
  export default {
    getOrgDetails,
  }