import configdata from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/macros`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/macros`;
}


export async function executeRegex(request) { // this function is becoz of mozila not supporting certain regex patterns so we are executing in the node engine
    const data = await httpService.post(`${apiEndpoint}/executeRegex`, request);
    return data;
}


export default {
    executeRegex
}