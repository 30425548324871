import configdata from "./config.json";

import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${configdata.apiUrl}/roles`;
   
  } else {
    apiEndpoint = `${configdata.apiUrl}/roles`;
   
  }


  export async function getRoles(data) {
    let response = await httpService.post(`${apiEndpoint}/getRoles`,data);
    return response;
  }

   
  export async function getFeatures(id){
    // alert(id)
    const data = await httpService.post(`${apiEndpoint}/getFeature`, {id});
  return data;
  }

  export async function getRolesForSwitch() {
    let response = await httpService.post(`${apiEndpoint}/getRolesForSwitch`,);
    return response;
  }
 
   
  export default {
    getRoles,
    getRolesForSwitch,
    getFeatures,
  }