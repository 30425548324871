import React, { Component } from "react";
import Loader from "../utils/loader";
import DocumentServices from "../services/document";
import DocumentCard from "./Cards/DocumentCard";
import BreadCrumb from "./Cards/BreadCrumbs";
import { withRouter } from "react-router-dom";
import HeadingB from "./Cards/HeadingB";
import notags from "../static/notags.png";
import TagsCard from "./Cards/TagsCard";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import ReactTour from "./ReactTour";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllBlogs } from "../services/blogServices";
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Tabs, Tab } from '@material-ui/core';
import "./DocTags.css"
import SubscriptionRequired from "./SubscriptionRequired";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const steps = [
  {
    selector: '.reactour-1',
    content: 'Left side displays all the document related tags to select upon',
    position: 'right',
  },
  {
    selector: '.reactour-2',
    content: 'Over here documents will be displayed as per selected tags',
    position: 'left',
  },
];

export class DocTags extends Component {
  state = {
    loading: true,
    characters: [],
    alltags: [],
    tagList: [],
    documents: [],
    filteredDoc: [],
    intervalId: 0,
    filter: [],
    reactTour: false,
    selectedValue: 0,
    windowWidth: window.innerWidth,
    isDarkMode: false,
    searchText: "",
    subReqOpen: false,
  };


  async componentDidMount() {

    console.log("hasAccessToTags", this.props.hasAccessToTags)
    setTimeout(() => {
      if (this.props.hasAccessToTags == false) {
        this.setState({ subReqOpen: true,loading:false });
      } else {
        // this.getAllDocsTags();

        const updateTheme = () => {
          const theme = localStorage.getItem('ThemeSwitch');
          this.setState({ isDarkMode: theme === 'true' });
        };
        updateTheme();
        window.addEventListener('storage', updateTheme);
        // const originalSetItem = localStorage.setItem;
        localStorage.setItem = (key, value) => {
          // originalSetItem.apply(this, arguments);
          if (key === 'ThemeSwitch') {
            updateTheme();
          }
        };

        // Add event listener for window resize
        window.addEventListener('resize', this.handleResize);
        this.getAllDocsTags(); // Fetch initial data
        // this.getuserDocs(); // Fetch user documents if needed
        // }
      }

    }, 1000);
  }

  // if (this.props.hasAccessToTags == true){

  handleResize = () => {
    // Update state with new window width
    this.setState({ windowWidth: window.innerWidth });
  };

  // async componentDidUpdate() {
  //   console.log("hasAccessToTags", this.props.hasAccessToTags)
  //   if (this.props.hasAccessToTags == false) {
  //     this.setState({ subReqOpen: true });
  //   } else {
  //     this.getAllDocsTags();
  //   }
  //   this.getAllDocsTags();
  // }
  starting = (init, tags) => {
    return tags.filter((tag) => tag.toLowerCase().startsWith(init));
  };
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(this.scrollStep(), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  searchDoc = (tag) => {
    this.setState({
      filteredDoc: [],
    });
    let doc = this.state.documents;
    let filterDoc = doc.filter((ok) => {
      for (let i = 0; i < ok.tags.length; i++) {
        if (ok.tags[i] === tag) {
          return ok;
        }
      }
    });
    this.setState({
      filteredDoc: filterDoc,
    });
    console.log(filterDoc);
    this.scrollToTop();
  };


  getuserDocs = async () => {
    try {
      const documentsPromise = DocumentServices.getAllDocuments();
      const blogsPromise = getAllBlogs(); // Assuming you have a service for fetching blogs

      const [documentResponse, blogsResponse] = await Promise.all([documentsPromise, blogsPromise]);

      if (documentResponse.status === 200 && blogsResponse.status === 200) {
        const combinedData = [...documentResponse.data, ...blogsResponse.data]; // Combine documents and blogs data
        this.setState({
          documents: combinedData,
          loading: false,
        });
      } else {
        // Handle if either documents or blogs fetch failed
        // For example:
        console.error("Error fetching documents or blogs");
        this.setState({
          loading: false,
          error: "Error fetching documents or blogs"
        });
      }
    } catch (error) {
      console.error("Error fetching documents or blogs:", error);
      this.setState({
        loading: false,
        error: "Error fetching documents or blogs"
      });
    }
  };
  getalltags = async () => {
    const characters = [];
    const result = [];
    let tags = await DocumentServices.getAllTags();
    this.setState({ alltags: tags.data });
    tags = tags.data;
    for (let i = 65; i <= 90; i++) {
      characters.push(String.fromCharCode(i));
    }

    characters.map((char) => {
      let letter = char;
      let Tags = this.starting(char.toLowerCase(), tags);
      if (Tags.length > 0) {
        const words = {
          characters: letter,
          Tags: Tags,
        };
        result.push(words);
      }
    });
    this.setState({ tagList: result }, () => this.getuserDocs());
    console.log(this.state.alltags);
  };

  getAllDocsTags = async () => {
    const characters = [];
    const result = [];
    let tags = await DocumentServices.getAllDocsTags();
    this.setState({ alltags: tags.data });
    tags = tags.data;
    for (let i = 65; i <= 90; i++) {
      characters.push(String.fromCharCode(i));
    }

    characters.map((char) => {
      let letter = char;
      let Tags = this.starting(char.toLowerCase(), tags);
      if (Tags.length > 0) {
        const words = {
          characters: letter,
          Tags: Tags,
        };
        result.push(words);
      }
    });
    this.setState({ tagList: result }, () => this.getuserDocs());
    console.log(this.state.alltags);
  };

  getAllBlogsTags = async () => {
    const characters = [];
    const result = [];
    let tags = await DocumentServices.getAllBlogsTags();
    this.setState({ alltags: tags.data });
    tags = tags.data;
    for (let i = 65; i <= 90; i++) {
      characters.push(String.fromCharCode(i));
    }

    characters.map((char) => {
      let letter = char;
      let Tags = this.starting(char.toLowerCase(), tags);
      if (Tags.length > 0) {
        const words = {
          characters: letter,
          Tags: Tags,
        };
        result.push(words);
      }
    });
    this.setState({ tagList: result }, () => this.getuserDocs());
    console.log(this.state.alltags);
  };

  viewDocument = (document, isReviewing) => {
    const { history } = this.props;
    if (document.documentType === "document") {
      history.push({
        pathname: `/viewmydocuments/${document._id}`,
        state: { detail: document, isReviewing, isReviewing },
      });
    } else {
      history.push({
        pathname: `/blogpreview/${document._id}`,
        state: { detail: document, blogType: "all", blogTags: document.tags },
        data: { documentType: "blog" },
      });
    }
  };

  handleTeamCollab = async (data) => {
    if (this.props.features.AcceptDecline === false) {
      toast("Please upgrade your plan to enable Team Collaboration feature");
    } else {
      let check = await DocumentServices.getDocumentbyId(data._id);
      if (check.status === 200) {
        if (check.data.activeDoc === true) {
          this.props.history.push({
            pathname: "/collaboration",
            state: { document: data },
          });
        } else {
          alert("document has been deleted");
          this.getDocuments();
        }
      } else {
        alert("document has been deleted");
        this.getDocuments();
      }
    }
  };

  handleSearchChange = (event) => {
    const characters = [];
    const result = [];
    const searchText = event.target.value.toLowerCase();
    this.setState({ searchText });
    const { alltags } = this.state;
    if (searchText === "") {
      this.setState({ filteredDoc: [] });
    }

    // Filter tags based on the search text
    const filteredTags = alltags.filter(tag => tag.toLowerCase().includes(searchText));


    let tags = filteredTags;
    for (let i = 65; i <= 90; i++) {
      characters.push(String.fromCharCode(i));
    }

    characters.map((char) => {
      let letter = char;
      let Tags = this.starting(char.toLowerCase(), tags);
      if (Tags.length > 0) {
        const words = {
          characters: letter,
          Tags: Tags,
        };
        result.push(words);
      }
    });

    this.setState({ tagList: result });

  }

  handleTabChange = (event, newValue) => {
    this.setState({ filteredDoc: [] });
    this.setState({ selectedValue: newValue });
    if (newValue === 0) {
      this.getAllDocsTags()
    } else {
      this.getAllBlogsTags()
    }
  }

  render() {
    let { tagList, filteredDoc, alltags, selectedValue, windowWidth, isDarkMode, searchText, subReqOpen } = this.state;
    // let { subReqOpen } = this.state;
    console.log(this.props.hasAccessToTags, "ghghgh");

    console.log(isDarkMode, "____Daaaark")

    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    };

    const textFieldStyle = {
      flex: 1,
      minWidth: 0,
    };

    const inputAdornmentStyle = {
      marginLeft: 8,
    };

    // Conditional styles for responsive behavior
    const responsiveContainerStyle = windowWidth < 576 ?
      { ...containerStyle, flexDirection: 'column' } : containerStyle;





    // console.log(tagList);
    return (
      <div className="">
        <ToastContainer />
        <ReactTour
          steps={steps}
          isOpen={this.state.reactTour}
          closeTour={() => this.setState({ reactTour: false })}
        />
        <Loader loading={this.state.loading} />
        <SubscriptionRequired open={subReqOpen} setOpen={(val) => this.setState({ subReqOpen: val })} />

        {alltags.length === 0 ? (
          <div className="text-center">
            <img src={notags} />
          </div>
        ) : (
          <>

            <div className="row mb-2">
              <div className="col-8" style={{ display: 'flex' }}>
                {/* <HeadingB text="All Tags" /> */}
                <Tabs value={selectedValue} onChange={this.handleTabChange} aria-label="basic tabs example" textColor="primary" indicatorColor="primary" style={{ marginBottom: '5px' }}>
                  <Tab label="Documents" />
                  <Tab label="Blogs" />

                </Tabs>
                <LightTooltip title="Need Help ?">
                  <i
                    className="fa fa-question-circle mt-2"
                    aria-hidden="true"
                    // data-toggle="tooltip"
                    // title="Need Help ?"
                    style={{
                      cursor: "pointer",
                      color: "dodgerblue",
                      fontSize: "15px",
                      marginLeft: '15px'
                    }}
                    onClick={() => this.setState({ reactTour: true })}
                  ></i>
                </LightTooltip>
              </div>
              <div className="col-4">

                <div style={responsiveContainerStyle}>
                  <TextField
                    className="inputBoxStyle"
                    style={{
                      ...textFieldStyle,
                      border: searchText === "" ? "1px solid white" : "none",
                    }}
                    label="Search Tags"
                    // style={textFieldStyle}
                    onChange={this.handleSearchChange}

                    InputProps={{
                      endAdornment: this.state.windowWidth > 576 ? (
                        <InputAdornment position="end">
                          <SearchIcon className="textColor" />
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                </div>

              </div>
            </div>

            <div className="row border-top">
              <div className="col-sm-4 reactour-1 border-right pt-3" style={{
                height: "570px",
                overflowY: "auto",
                overflowX: "hidden",
              }}>
                {/* <h3>All Tags</h3> */}
                {tagList.length > 0 &&

                  tagList.map((tag, index) => (
                    <div className="">
                      <TagsCard data={tag} abc={this.searchDoc} windowWidth={windowWidth} />
                    </div>
                  ))}
              </div>
              <div
                className="col-sm-8 pt-3 reactour-2"
              // style={{ borderLeft: "2px solid rgba(0,0,0,.1)" }}
              >
                <div className="row pl-3">
                  {filteredDoc.map((document) => (
                    <div className="col-sm-11 p-2" >
                      <DocumentCard
                        key={document._id}
                        data={document}
                        showDocs={() => this.viewDocument(document, false)}
                        hide={true}
                        handleTeamCollab={this.handleTeamCollab}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(
  mapStateToProps
)(withRouter(DocTags));
