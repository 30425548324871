import React, { useEffect, useState } from "react";
import Loader from "../utils/loader";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
    getAllUsers2,
} from "../services/adminServices";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png"
import "./ManageUser.css";
import DataGridMui from "./ReusableComponents/DataGridMui";
import { useSelector } from "react-redux";
import { getOrganisationData } from "../services/dashboardServices";

const ManageOrganization = ({ history }) => {
    const userDetails = useSelector((user) => user)
    const userID = userDetails.user.currentUser._id
    let [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([])
    const [isDarkMode, setIsDarkMode] = useState(false);
    useEffect(() => {
            const updateTheme = () => {
                const theme = localStorage.getItem('ThemeSwitch');
                setIsDarkMode(theme === 'true');
            };
            updateTheme();
            window.addEventListener('storage', updateTheme);
            const originalSetItem = localStorage.setItem;
            localStorage.setItem = function (key, value) {
                originalSetItem.apply(this, arguments);
                if (key === 'ThemeSwitch') {
                updateTheme(); 
                }
            };
            return () => {
                window.removeEventListener('storage', updateTheme);
                localStorage.setItem = originalSetItem; 
            };
            }, []);

    const fetchOrganizationList = async () => {
        setLoading(true)
        try {
            let response = await getOrganisationData(userID)
            console.log("fetchOrganizationList", response)
            if (response.status == 200) {
                // let finalArr = []
                // response.orgDetails.map((obj) => finalArr.push({ name: obj.orgName, value: obj.orgId }))
                // setOrganizationList(finalArr)
                setOrganizationList(response.orgDetails)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchOrganizationList();
    }, []);

    const columns = [
        {
            // field: 'name',
            field: 'orgName',
            headerName: 'Organization Name',
            // width: 150,
            flex: 0.3,
            editable: false,
        },
        {
            field: 'numberOfUsers',
            headerName: 'No of Users',
            flex: 0.1,
            editable: false,
        },
    ];

    return (
        <div>
            <Loader loading={loading} />
            <div className="container-fluid" style={{ background: isDarkMode?"black":"#f0f0f7" }}>
                {/* <div className="row justify-content-end w-100">
                    <div className="col-md-2 justify-content-end">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Export
                            </button>
                        </OverlayTrigger>
                    </div>
                </div> */}

                <div className="row mt-2 w-100">
                    {organizationList.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No teams found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data.orgId}
                                    columns={columns}
                                    rows={organizationList}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withRouter(ManageOrganization);