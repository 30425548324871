// import { apiUrl, herokuUrl } from "./config.json";
import configdata from "./config.json";
import httpService from "./httpService";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/products`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/products`;
}

export async function getProducts() {
  const response = await httpService.get(`${apiEndpoint}/getProducts`);
  return response;
}

export async function addProduct(product) {
  const response = await httpService.post(`${apiEndpoint}/addProduct`, {
    product,
  });
  return response;
}

export async function deleteProduct(product) {
  const response = await httpService.post(`${apiEndpoint}/deleteProduct`, {
    product,
  });
  return response;
}

export default {
  getProducts,
  addProduct,
  deleteProduct,
};
