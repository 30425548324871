import React, { Component, useState, useEffect } from "react";
import Loader from "../utils/loader";
import NumberWidget from "../components/NumberWidget";
import AdminServices from "../services/adminServices";
import Apex from "./ApexCharts";
import { Dropdown } from "react-bootstrap";
import DashboardCard from "../components/Cards/DasboardCard";
import Doc from ".././static/Doc.png";
import Group from ".././static/Group.png";
import Member from ".././static/Member.png";
import Rate from ".././static/Rate.png";
import Total from ".././static/Total.png";
import {
  Card,
} from "react-bootstrap";
import PieChartComp from "./ReusableComponents/PieChartComp";
import PieChartMui from "./ReusableComponents/PieChartMui";
// import MonthYearPicker from "../utils/MonthYearPicker";
// import Chart from "../components/Stats";
import total_doc from ".././static/admin_dashboard/total_doc.png"
import individual_user from ".././static/admin_dashboard/users_subadmin.png"
import active_user from ".././static/admin_dashboard/solar_user-check-bold.png"
import documents from ".././static/admin_dashboard/doc_subadmin.png"
import sharedDocuments from ".././static/admin_dashboard/shared-document.png"
import registered_organization from ".././static/admin_dashboard/fluent_building-people-24-filled.png"
import ImageCard from "./ReusableComponents/ImageCard";
import ApexChartsComp from "./ReusableComponents/ApexChartsComp";
import dashboardServices from "../services/dashboardServices";
// import BarChartComp from "./ReusableComponents/BarChartComp";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BarChartGoogle } from "./ReusableComponents/BarChartGoogle";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment/moment";

export function SubAdminDashboard() {
  const userDetails = useSelector((user) => user)
  const email = userDetails.user.currentUser.email
  const [loading, setLoading] = useState(false)
  // -----
  const [state, setState] = useState({
    loading: true,
    totalPaidUser: 0,
    totalDocuments: 0,
    totalUsers: 0,
    monthWiseDocuments: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    monthWiseUser: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    MonthWise: [],
    UserWise: [],
    selectYear: new Date().getFullYear(),
    selectMonth: new Date().getMonth() + 1,
    years: [...Array(Number(new Date().getFullYear()) - 2018)].map((year, i) => 2019 + i),
    monthList: [
      "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ],
    cardmargin: 10,
    admindashdetail: [],
    monthwisePaidusers: [],
    monthwiseFreeusers: []
  });

  const [imageCardData, setImageCardData] = useState({
    totalUsers: "0",
    activeUsers: "0",
    document: "0",
    sharedDocuments: "0"
  })

  const [pieChartData, setPieChartData] = useState([])
  const [barChartData, setBarChartData] = useState({})

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const screenData = useSelector((state) => state.manageSubscriptionPlan.subscriptionPlan);
  const [alertForEndSub, setAlertForEndSub] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const alertShown = sessionStorage.getItem('subscriptionAlertShown');
    
    const checkSubscriptionExpiry = () => {

      if (!alertShown && screenData?.isDefault == false) {
        const now = moment().startOf('day'); 
        const expiryDate = moment(screenData?.endDate).startOf('day'); 
        const daysLeft = expiryDate.diff(now, 'days'); 

        if (daysLeft >= 1 && daysLeft <= 5) {
          setAlertForEndSub(`Your subscription will expire in ${daysLeft} day(s) on ${expiryDate.format('D MMMM YYYY')}.`);
          setShowPopup(true)
        }
        else if (daysLeft === 0) {
          setAlertForEndSub(`Your subscription expires today on ${expiryDate.format('D MMMM YYYY')}.`);
          setShowPopup(true)
        } else {
          setAlertForEndSub('');
        }
        if (daysLeft >= 1 && daysLeft <= 5 || daysLeft === 0) {
          sessionStorage.setItem('subscriptionAlertShown', 'true');
        }
      }
    };

    checkSubscriptionExpiry();
  }, [screenData?.endDate]);
  

  const handleClose = () => {
    setShowPopup(false)
  }

  useEffect(() => {
    const updateTheme = () => {
      const theme = localStorage.getItem('ThemeSwitch');
      setIsDarkMode(theme === 'true');
    };
    updateTheme();
    window.addEventListener('storage', updateTheme);
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === 'ThemeSwitch') {
        updateTheme();
      }
    };
    return () => {
      window.removeEventListener('storage', updateTheme);
      localStorage.setItem = originalSetItem;
    };
  }, []);
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    //   if(window.innerWidth <768){
    //     setSidebarOpen(!isSidebarOpen)
    //   }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //     const year = new Date().getFullYear();
  //     getDetailsAdminDash(year);
  //     getmonthwisePaidusers(year);
  //     getmonthwiseFreeusers(year);
  // }, []); 
  // Empty dependency array ensures this effect runs once on mount

  const convertForPieChart = (arr) => {
    let finalArr = []
    arr.map((obj) => {
      if (obj.value > 0) {
        finalArr.push({
          title: obj.title,
          value: Number(obj.value),
          color: obj.color
        })
      }
    })
    console.log("finalarr", finalArr)
    return finalArr
  }

  useEffect(() => {
    getSubAdminDashboard()
    getPieChartDetails()
    getBarChartDetails()
  }, [])
  const getSubAdminDashboard = async () => {
    // let email = sessionStorage.getItem("currentUserEmail")
    setLoading(true)
    try {
      let response = await dashboardServices.getDetailsSubAdminDash(email)
      console.log("getSubAdminDashboard", response)
      let { TotalUsers, ActiveUsers, TotalDocuments, TotalSharedDocuments } = response.data
      setImageCardData({
        "totalUsers": TotalUsers, "activeUsers": ActiveUsers,
        "document": TotalDocuments, "sharedDocuments": TotalSharedDocuments,
      })
    }
    catch (error) {
      console.log("error", error)
    }
    setLoading(false)
  }

  const getBarChartDetails = async () => {
    // let email = sessionStorage.getItem("currentUserEmail")
    setLoading(true)
    try {
      let response = await dashboardServices.getDetailsSubAdminBarChart(email)
      console.log("getBarChartDetails", response)
      if (response.status == 200) {
        let updatedFeatureUsage = response.featureUsage;
        const spellingChanges = {
          "Summariser": "Summarizer",
          "CoAuthor": "Co-Author"
        };

        updatedFeatureUsage.xAxisList = updatedFeatureUsage.xAxisList.map(feature => {
          return spellingChanges[feature] || feature;
        });

        setBarChartData(updatedFeatureUsage);
      }
    }
    catch (error) {
      console.log("error", error)
    }
    setLoading(false)
  }

  const getPieChartDetails = async () => {
    // let email = sessionStorage.getItem("currentUserEmail")
    setLoading(true)
    try {
      let response = await dashboardServices.getDetailsSubAdminPieChart(email)
      console.log("getPieChartDetails", response)
      let finalData = convertForPieChart(response.data)
      setPieChartData(finalData)
    }
    catch (error) {
      console.log("error", error)
    }
    setLoading(false)
  }

  // const getDetailsAdminDash = async (year) => {
  //     setState((prevState) => ({ ...prevState, loading: true }));
  //     try {
  //         const {
  //             totalDocuments,
  //             totalFreeuser,
  //             totalPaidUser,
  //             monthWiseDocuments,
  //             monthWiseUser
  //         } = await AdminServices.getDetailsAdminDash(year);

  //         const dashData = [
  //             {
  //                 title: "Total Free Users",
  //                 count: totalFreeuser,
  //                 colors: "#ff3a29",
  //                 img: Doc,
  //                 sparkData: state.monthwiseFreeusers
  //             },
  //             {
  //                 title: "Total Paid Users",
  //                 count: totalPaidUser,
  //                 colors: "#ffb200",
  //                 img: Rate,
  //                 sparkData: state.monthwisePaidusers
  //             },
  //             {
  //                 title: "Total Documents",
  //                 count: totalDocuments,
  //                 colors: "#34b53a",
  //                 img: Group,
  //                 sparkData: monthWiseDocuments
  //             }
  //         ];

  //         setState((prevState) => ({
  //             ...prevState,
  //             totalPaidUser,
  //             totalDocuments,
  //             totalUsers: totalFreeuser,
  //             monthWiseDocuments,
  //             monthWiseUser,
  //             MonthWise: monthWiseDocuments,
  //             UserWise: monthWiseUser,
  //             selectYear: year,
  //             admindashdetail: dashData,
  //             loading: false
  //         }));

  //         getmonthwisePaidusers(year);
  //         getmonthwiseFreeusers(year);
  //     } catch (error) {
  //         console.error("Error fetching data:", error);
  //         setState((prevState) => ({ ...prevState, loading: false }));
  //     }
  // };

  // const getmonthwisePaidusers = async (year) => {
  //     try {
  //         const admindata = await AdminServices.getPaidUserDetails(year);
  //         console.log(admindata);
  //         setState((prevState) => ({ ...prevState, monthwisePaidusers: admindata.monthWisePaidUser }));
  //     } catch (error) {
  //         console.error("Error fetching data:", error);
  //     }
  // };

  // const getmonthwiseFreeusers = async (year) => {
  //     try {
  //         const admindata = await AdminServices.getFreeUserDetails(year);
  //         console.log(admindata);
  //         setState((prevState) => ({ ...prevState, monthwiseFreeusers: admindata.monthWiseFreeUser }));
  //     } catch (error) {
  //         console.error("Error fetching data:", error);
  //     }
  // };

  // const getMonthYear = async (value) => {
  //     console.log(value);
  //     const year = value;

  //     const full = [
  //         "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  //     ];

  //     if (year !== state.selectYear) {
  //         await getDetailsAdminDash(year);
  //         await getmonthwisePaidusers(year);
  //         await getmonthwiseFreeusers(year);
  //         setState((prevState) => ({ ...prevState, selectYear: year, monthList: full }));
  //     }
  // };

  // const increment = () => {
  //     const selectYear = state.selectYear;
  //     const Year = selectYear + 1;
  //     console.log(Year);
  //     setState((prevState) => ({ ...prevState, selectYear: Year }));
  //     getDetailsAdminDash(Year);
  // };

  // const decrement = () => {
  //     const selectYear = state.selectYear;
  //     const Year = selectYear - 1;
  //     console.log(Year);
  //     setState((prevState) => ({ ...prevState, selectYear: Year }));
  //     getDetailsAdminDash(Year);
  // };

  // const _changeYear = async (year) => {
  //     setState((prevState) => ({ ...prevState, selectYear: year }));
  //     getDetailsAdminDash(year);
  //     getmonthwisePaidusers(year);
  //     getmonthwiseFreeusers(year);
  // };

  // ------

  // const barChartData = [
  //     {
  //         title: "Average",
  //         count: 455,
  //         colors: "#5FBDE7",
  //         // img: Group,
  //         sparkData: [80, 40, 30, 95, 45, 35, 70, 60]
  //     }
  // ]

  // const x_axis = ["Grammar Check", "Paraphraser", "Summarizer", "Co-Author", "Plagiarism", "Translator", "Documents", "Teams"]

  return (
    <div style={{ width: "100%", marginLeft: windowWidth < 768 ? "" : "2px" }}>
      <Loader loading={loading} />
      <div style={{
        borderRadius: '10px',
        width: '100%',
        // margin: '0',
        boxShadow: 'rgb(0, 0, 0) 2px 2px 5px -2px',
        backgroundColor: isDarkMode ? '#2A2A2A' : 'white',
        display: "flex",
        flexDirection: windowWidth < 768 ? "column" : "row",
        // justifyContent:"space-between"
      }}>

        <div style={{ width: windowWidth < 768 ? "80%" : "32%", display: "flex", justifyContent: "center", alignItems: "center", border: windowWidth < 768 ? "1px solid #ccc" : "", marginLeft: windowWidth < 768 ? "10%" : "", borderRadius: windowWidth < 768 ? "10px" : "", marginBottom: windowWidth < 768 ? "15px" : "", borderRight: `${windowWidth < 768 ? "1px" : "4px"} solid ${isDarkMode ? "gray" : "#ccc"}`, marginTop: windowWidth < 768 ? "15px" : "" }}>
          <Link to="/subAdminManageUser">
            <ImageCard
              bgColor={isDarkMode ? "#2A2A2A" : "white"}
              txtColor='#46A57F'
              altImg="individual user"
              imgSrc={individual_user}
              cardName="Total Users"
              number={imageCardData.totalUsers}
            />
          </Link>
          <div style={{
            position: 'absolute',
            top: '0',
            width: '2px',
            height: '5%',
            backgroundColor: 'grey'

          }}></div>
        </div>
        <div style={{ width: windowWidth < 768 ? "80%" : "35%", border: windowWidth < 768 ? "1px solid #ccc" : "", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: windowWidth < 768 ? "10%" : "", borderRadius: windowWidth < 768 ? "10px" : "", marginBottom: windowWidth < 768 ? "15px" : "", borderRight: `${windowWidth < 768 ? "1px" : "4px"} solid ${isDarkMode ? "gray" : "#ccc"}` }}>
          <Link to="/subAdminDocuments">
            <ImageCard
              bgColor={isDarkMode ? "#2A2A2A" : "white"}
              txtColor='#B57331'
              altImg="documents"
              imgSrc={documents}
              cardName="Document"
              number={imageCardData.document}
            />
          </Link>
          <div style={{
            position: "absolute",
            top: '0',
            width: '2px',
            height: '5%',
            backgroundColor: 'grey'

          }}></div>
        </div>
        <div style={{ border: windowWidth < 768 ? "1px solid #ccc" : "", width: windowWidth < 768 ? "80%" : "35%", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: windowWidth < 768 ? "10%" : "", borderRadius: windowWidth < 768 ? "10px" : "", marginBottom: windowWidth < 768 ? "15px" : "" }}>
          <Link to="/subAdminDocuments#shared-documents">
            <ImageCard
              bgColor={isDarkMode ? "#2A2A2A" : "white"}
              txtColor='#B53167'
              altImg="shared documents"
              imgSrc={sharedDocuments}
              cardName="Shared Documents"
              number={imageCardData.sharedDocuments}
              applyFilter={true}
            />
          </Link>
        </div>

      </div>

      <div style={{ width: "100%", marginTop: "20px" }}>
        <div
          style={{ width: "100%", backgroundColor: isDarkMode ? "black" : "white", boxShadow: "2px 2px 5px -2px #000000", borderRadius: '8px' }}
        >
          {/* <BarChartComp/> */}
          {pieChartData.length > 0 ?
            <PieChartComp
              chartHeading="User Roles Breakdown"
              pieChartData={pieChartData}
              isDarkMode={isDarkMode}
            /> :
            <div
              style={{ textAlign: "center", fontWeight: "bold", paddingBottom: "5px" }}
            >No Pie Chart data available</div>
          }
        </div>
        <div

          style={{ marginTop: windowWidth < 768 ? "15px" : "20px", backgroundColor: isDarkMode ? "#2A2A2A" : "white", boxShadow: "2px 2px 5px -2px #000000", borderRadius: '8px' }}
        >
          {Object.keys(barChartData).length > 0 && barChartData.xAxisValues.some((obj) => obj > 0) ?
            // <ApexChartsComp
            //     // chartHeading={barChartData.chartHeading}
            //     chartHeading="Usage of features"
            //     // cmp1={barChartData.xAxisBar1}
            //     cmp1="Features"
            //     // cmp2="Documents"
            //     // cmp3="Paid Users"
            //     // bar1={barChartData.bar1}
            //     bar1={barChartData.xAxisValues}
            //     // bar2={state.monthWiseDocuments}
            //     // linedata={state.monthwisePaidusers}
            //     xAxisList={barChartData.xAxisList}
            // />

            // <BarChartComp/>
            <BarChartGoogle
              hAxisTitle="Features"
              vAxisTitle="Usage count"
              data={barChartData}
              chartHeading="Usage of features"
              isDarkMode={isDarkMode}
            />
            :
            <div
              // className='align-items-center'
              style={{ fontWeight: "bold" }}
            >No Bar Chart data available</div>
          }
        </div>
      </div>

      <Modal
        isOpen={showPopup}
        toggle={() => setShowPopup(!showPopup)}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%"
        }}
      >
        <div style={{ padding: "25px" }}>

          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h3 style={{ color: "#1976D2", marginBottom: "0px" }}>Subscription Plan Alert</h3>
            <CloseIcon style={{ color: "red", }} onClick={handleClose} />
          </div>

          <div style={{ width: "85%", margin: " 22px auto" }}>
            {alertForEndSub}
          </div>

        </div>
      </Modal>
    </div>
  );
}


export default SubAdminDashboard;
