import http from "./httpService";
// import {
//     apiUrl,
//     herokuUrl
// } from "./config.json";
import configdata from "./config.json";


let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${configdata.apiUrl}/chats`;
} else {
    apiEndpoint = `${configdata.herokuUrl}/chats`;
}

// API To Create Contact For the First Time when they Click the Chat Icon.
export async function createContact(senderUserId, receiverUserId) {
    const data = await http.post(`${apiEndpoint}/createContact`, {
        'senderUserId': senderUserId,
        'receiverUserId': receiverUserId
    });
    return data; 
}

export async function sendMessage(senderUserId, receiverUserId, content, contentType, fileName) {
    const data = await http.post(`${apiEndpoint}/sendMessage`, {
        'senderUserId': senderUserId,
        'receiverUserId': receiverUserId,
        'content': content,
        'contentType':contentType,
        'fileName':fileName
    });
    return data;
}

export async function createGroup(groupName, memberIds, groupId) {
    const data = await http.post(`${apiEndpoint}/createGroup`, {
        'groupName': groupName,
        'memberIds': memberIds,
        'groupId': groupId,
    });
    return data;
}

export async function archiveChat(contactId, isArchive) {
    const data = await http.post(`${apiEndpoint}/manageArchiveChat`, {
        'receiverId': contactId,
        'isArchive': isArchive,
    });
    return data;
}

export async function manageArchiveGroupChat(groupId, isArchive) {
    const data = await http.post(`${apiEndpoint}/manageArchiveGroupChat`, {
        'groupId': groupId,
        'isArchive': isArchive,
    });
    return data;
}

export async function fetchMessages(userId) {
    const data = await http.post(`${apiEndpoint}/fetchMessages`, {
        'userId': userId,
    });
    return data;
}

export async function fetchContactMessages(userId, receiverId) {
    const data = await http.post(`${apiEndpoint}/fetchContactMessages`, {
        'userId': userId,
        'receiverId' : receiverId
    });
    return data;
}


export async function fetchGroupMessages(userId, groupId) {
    const data = await http.post(`${apiEndpoint}/fetchGroupMessages`, {
        'userId': userId,
        'groupId': groupId
    });
    return data;
}


export async function sendMessageInGroup(senderId, groupId, content, contentType, fileName) {
    const data = await http.post(`${apiEndpoint}/sendMessageInGroup`, {
        'groupId': groupId,
        'senderId': senderId,
        'content': content,
        'contentType': contentType,
        'fileName': fileName
    });
    return data;
}


export async function markReadMsg(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/markReadMsg`, {
        'senderId': senderId,
        'receiverId': receiverId
    });
    return data;
}

export async function markReadgroupMsg(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/markReadMsg`, {
        'senderId': senderId,
        'groupId': receiverId
    });
    return data;
}


export async function saveSocketId(userId, socketId) {
    const data = await http.post(`${apiEndpoint}/saveSocketId`, {
        'userId': userId,
        'socketId': socketId
    });
    return data;
}

export async function getSocketId(userId) {
    const data = await http.post(`${apiEndpoint}/getSocketId`, {
        'userId': userId,
    });
    return data;
}


export async function deleteMessage(senderId, receiverId, messageId) {
    const data = await http.post(`${apiEndpoint}/deleteMessage`, {
        'senderId': senderId,
        'receiverId': receiverId,
        'messageId': messageId
    });
    return data;
}

export async function deleteGroupMessage(senderId, groupId, messageId) {
    const data = await http.post(`${apiEndpoint}/deleteMessage`, {
        'senderId': senderId,
        'groupId': groupId,
        'messageId': messageId
    });
    return data;
}


export async function deleteAllMessages(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/deleteAllMessages`, {
        'senderId': senderId,
        'receiverId': receiverId
    });
    return data;
}

export async function deleteAllMessagesinGroup(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/deleteAllMessages`, {
        'senderId': senderId,
        'groupId': receiverId
    });
    return data;
}

export async function deleteContact(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/deleteContact`, {
        'senderId': senderId,
        'receiverId': receiverId
    });
    return data;
}

export async function deleteGroupContact(senderId, receiverId) {
    const data = await http.post(`${apiEndpoint}/deleteContact`, {
        'senderId': senderId,
        'groupId': receiverId
    });
    return data;
}

export async function setStatus(userId, status) {
    const data = await http.post(`${apiEndpoint}/setStatus`, {
        'userId': userId,
        'status': status
    });
    return data;
}

export async function markAsDownloaded(messageId, download) {
    const data = await http.post(`${apiEndpoint}/markAsDownloaded`, {
        'messageId': messageId,
        'download': download
    });
    return data;
}

export default {
    createContact,
    sendMessage,
    createGroup,
    archiveChat,
    fetchContactMessages,
    fetchMessages,
    fetchGroupMessages,
    sendMessageInGroup,
    markReadMsg,
    saveSocketId,
    markReadgroupMsg,
    getSocketId,
    deleteMessage,
    deleteGroupMessage,
    deleteAllMessages,
    deleteAllMessagesinGroup,
    deleteContact,
    deleteGroupContact,
    setStatus,
    manageArchiveGroupChat,
    markAsDownloaded
};
