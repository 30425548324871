import React, { useState, useEffect } from "react";
import { Container, TextField, IconButton, Modal, Card, CardContent, CardMedia, Typography, Grid, Menu, MenuItem, FormControl, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails, withStyles, Tooltip } from '@material-ui/core';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Editor, EditorState, ContentState, convertFromRaw, SelectionState, Modifier, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FileVersionCard, ChangesCard } from "./ViewMyDocumentsCards";
import { useParams } from 'react-router-dom';
import { getDocumentbyId } from "../services/document";
import documentService from "../services/document"
import { toast } from "react-toastify"
import Loader from "../utils/loader";
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import UploadIcon from '@mui/icons-material/Upload';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { PreviewDocModal, UploadDocModal } from "./MyDocumentsNewProjectCards";
import MyDocument_SideModel from "./MyDocument_SideModel";
import { ShareOptionModal } from "./MyDocumentsCardsModal";


import Avatar from "@material-ui/core/Avatar";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import StarRatings from "react-star-ratings";
import CommentsCard from "./Cards/CommentsCard";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from 'react-router-dom';
import { reviewDocument } from "../services/teamCollbarationServices";
import swal from "sweetalert";
import { addComments, getCommentsForDocs } from "../services/feedbackServices";
import { getProfile } from "../services/userService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";






import { checkPlagiarism } from "../services/plagiarismServices";
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import _ from 'lodash';
import SuggestionCard from "./Plagiarism/SuggestionCard";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { strCount } from "./Editor/utilize";
import { autoSaveDocument } from "../services/document";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setCurrentUser } from "../redux/user/user.actions";
import { addUserLogs } from "../services/userLogServices";
import { checkFeatureAccess } from "./featureAccess";
import { useCheckFeatureAccess } from "./CheckFeature";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const ViewMyDocuments = ({ docId }) => {
    const [titleEditorState, setTitleEditorState] = useState(EditorState.createEmpty());
    const [contentEditorState, setContentEditorState] = useState(EditorState.createEmpty());
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const [docName, setDocName] = useState("")
    const [editable, setEditable] = useState(true);
    const [showPlagiarism, setShowPlagiarism] = useState(false);
    const [loading, setLoading] = useState(false);

    const [plagiarozedProgress, setPlagiarozedProgress] = useState(0);
    const [uniqueProgress, setUniqueProgress] = useState(0);
    const [sourceCount, setSourceCount] = useState(0)
    const [uniqueWordsPercentage, setUniqueWordsPercentage] = useState(`0%`);
    const [progressStatus, setProgressStatus] = useState('Plagiarized');
    const [sentenceCount, setSentenceCount] = useState(0);
    const [paraCount, setParaCount] = useState(0);
    const [timeDisplay, setTimeDisplay] = useState("0 sec");
    const [uniqueWordCount, setUniqueWordCount] = useState("0");
    const [outputArray, setOutputArray] = useState([])
    const [previewClick, setPreviewClick] = useState(false)
    const [uploadDocClick, setUploadDocClick] = useState(false)
    const [showSideBar, setShowSideBar] = useState(false)
    const [showSummariser, setShowSummariser] = useState(false);
    const [showParaphraser, setShowParaphraser] = useState(false);
    const [fun_call, setfun_Call] = useState(false);
    const [fun_SummariseCall, setFun_SummariseCall] = useState(false);
    const [inputKey, setInputKey] = useState()
    const [readOnlyStatus, setReadOnlyStatus] = useState(false)
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [circularLoader, setCircularLoader] = useState(null);
    const [docData, setDocData] = useState("");
    const [docWords, setDocWords] = useState("");
    const [docCharacters, setDocCharacters] = useState("");
    const [docSaved, setDocSaved] = useState(false);
    const [docContent, setDocContent] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isTyping, setIsTyping] = useState(false);




    const [comments, setComments] = useState('')
    const [rating, setRating] = useState(0)
    const [newTotalRating, setNewTotalRating] = useState(0)
    const [maxRatings, setMaxRatings] = useState(0)
    const [avgRating, setAverageRating] = useState(0)
    const [currentUser, setCurrentUser] = useState('')
    const [commentData, setCommentData] = useState()
    const [documentType, setDocumentType] = useState('')
    const [date, setDate] = useState('')
    const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);

    const userDetails = useSelector((user) => user)
    const userID = userDetails.user.currentUser._id

    useEffect(() => {
        getDocById(docId); // Pass docId to the function
    }, []);

    const dispatch = useDispatch();


    const passedFunction = useSelector((state) => state.document.passedFunction);
    const accessToEdit = useCheckFeatureAccess(currentNavItem,'newDocument')





    const getDocById = async (docId) => {
        try {
            const response = await documentService.getDocumentbyId(docId);
            if (response.status === 200) {
                const { documentName, onlyDocumentContent, readOnly } = response.data;
                setDocName(documentName)
                setReadOnlyStatus(readOnly)

                const date = new Date(response.data.date);

                // Get the month name
                const monthNames = [
                    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
                ];
                const month = monthNames[date.getMonth()];

                // Get the day of the month
                const day = date.getDate();

                // Get the hours and minutes
                const hours = date.getHours();
                const minutes = date.getMinutes();

                // Format the time
                const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

                // Format the date string
                const formattedDate = `${month} ${day} | ${formattedTime}`;

                setDate(formattedDate)

            


                if (documentName && onlyDocumentContent) {
                    const contentData = JSON.parse(onlyDocumentContent);
                    const textContent = contentData.blocks[0].text;

                    // Set titleEditorState
                    setTitleEditorState(
                        EditorState.createWithContent(convertFromRaw({ blocks: [{ text: documentName }], entityMap: {} }))
                    );


                    // Set contentEditorState with only the text content
                    setContentEditorState(
                        EditorState.createWithContent(convertFromRaw({ blocks: [{ text: textContent }], entityMap: {} }))
                    );
                }

                else {
                    console.error("Error: Document name or content is missing in the response data", response.data);
                }
                console.log("content", contentEditorState)
                console.log(response, "response from getId");
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    // Autosave Functionality
    useEffect(() => {

        if (!contentEditorState) {
            return;
        }

        let doc = contentEditorState.getCurrentContent().getPlainText();
        setDocContent(doc);

        const [wordsCount, characterCount] = strCount(doc);
        let convertRaw = convertToRaw(contentEditorState.getCurrentContent());

        setDocData(convertRaw);
        setDocWords(wordsCount);
        setDocCharacters(characterCount);
    }, [contentEditorState]);

    useEffect(() => {
        let typingTimer;
        const handleStoppedTyping = () => {
            setIsTyping(false);
            const currentInputText = contentEditorState
                .getCurrentContent()
                .getPlainText();
            console.log("Parachanges_", currentInputText);
            handleTextEditor(docData, docWords, docCharacters);
        };
        clearTimeout(typingTimer);
        typingTimer = setTimeout(handleStoppedTyping, 1000);
        return () => {
            clearTimeout(typingTimer);
        };
    }, [contentEditorState, docData, docWords, docCharacters]);


    const handleContentEditorChange = (newState) => {
        setContentEditorState(newState)
        setCircularLoader(false);
    }
    const handleTitleEditorState = (newState) => {
        setTitleEditorState(newState)
    }

    const handleTextEditor = (data, wordsCount, characterCount) => {
        if (!docName || !docId || !data) {
            return;
        }
        const socketData = {
            documentName: docName,
            documentId: docId,
            onlyDocumentContent: JSON.stringify(data),
            formattedDocumentContent: JSON.stringify(data),
            wordsCount,
            characterCount,
        };

        autoSaveContent(socketData);
        console.log("socketData", socketData);
    };

    const autoSaveContent = async (data) => {
        console.log("callleddddddd");
        try {
            // if (JSON.parse(data.onlyDocumentContent).blocks[0].text === "") {
            //   return;
            // }
            if(!accessToEdit){
                return;
            }
            
            const response = await autoSaveDocument(data);
            console.log("responseeeeeeeeeeee", response);
            if (response.status === 200) {
                console.log("Auto save successful:", response);
                setIsSaving(true);
                setDocSaved(true);
                setCircularLoader(true);
                const data = {
                    userId: sessionStorage.getItem("userId"),
                    organizationId: sessionStorage.getItem("organizationId"),
                    actionType: "newDoc",
                  };
            
                  const addLogs = await addUserLogs(data);
                  if (addLogs.status !== 200) {
                    toast.error(addLogs.message);
                  }
            }
        } catch (error) {
            console.error("Error occurred during auto save:", error);
        } finally {
            setIsSaving(false);
        }
    };

    const deleteContent = () => {
        setContentEditorState(EditorState.createEmpty())
    }

    function handleDownload() {

        if (!contentEditorState.getCurrentContent().getPlainText()) {
            toast.warn(`You don't have text, to download`);
            return;
        }
        const blob = new Blob([contentEditorState.getCurrentContent().getPlainText()], { type: 'text/plain' });
        console.log(blob, "blob");
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = `${titleEditorState.getCurrentContent().getPlainText()}.doc`;
        anchor.click();
        URL.revokeObjectURL(anchor.href);
    }
    const handleEdit = () => {
        setEditable(false)
    }

    const showPlagiarismDiv = () => {
        if (contentEditorState.getCurrentContent().getPlainText() == "") {
            toast.warn("Nothing to plagiarise")
            return;
        } else {
            setShowPlagiarism(true)
            handleCheckPlagiarism()
        }

    }

    const hidePlagiarismDiv = () => {
        setShowPlagiarism(false)
    }

    const customStyleMap = {
        HIGHLIGHT: {
            backgroundColor: "#FF7F7F",
            color: "white",
        },
    };

    const handleCheckPlagiarism = async (outputSentence = "", isTable) => {

        setShowPlagiarism(true);

        console.log("props from coAuther", contentEditorState.getCurrentContent().getPlainText())

        //console.log("checkSentence_", outputSentence);
        let sentence = contentEditorState.getCurrentContent().getPlainText();

        setLoading(true)
        setSourceCount(0)
        countTextStats(isTable)
        uniqueWordsPercentageCalculation()
        // getParaDetails()
        let data = {
            text: sentence
        }
        let userId = sessionStorage.getItem("userId")
    let isCoAuthor = false
        let plagiarismResponse = await checkPlagiarism(data,userId,isCoAuthor)
        console.log("plagiarismResponse_", plagiarismResponse.data)
        if (plagiarismResponse.status == 400) {
            setLoading(false)
            toast.warn(plagiarismResponse.message)
            return
        }
        if (plagiarismResponse?.data?.details?.plagPercent !== 0) {
            setUniqueProgress(plagiarismResponse.data.uniquePercent)
            setPlagiarozedProgress(plagiarismResponse.data.plagPercent)
            let plagDetails = plagiarismResponse.data.details
            let sourceDetails = plagiarismResponse.data.sources
            let sentenceArray = []

            let filterPlagDetails = _.filter(plagDetails, (item, i) => item.unique == "false")

            //console.log("plagDetails_", plagDetails, sourceDetails.length, filterPlagDetails)
            setSourceCount(sourceDetails.length)
            filterPlagDetails.map((obj) => {
                //console.log("obj_", obj);
                let outObject = {
                    sentenceDetails: obj.query,
                    url: obj?.display?.url
                }
                sentenceArray.push(outObject)
            })
            console.log("current content_yyyyyyyyyyyyyyyyyyyyyyyyyyyyy pankaj", sentenceArray.sentenceDetails)
            //console.log("current_Content yyyyyyyy", sentenceArray)
            //  setHighlightSentence(sentenceArray)
            setOutputArray(sentenceArray)


            //  console.log(outputArray, "outputArrrrrrrrrrrrrrrrrrrr")
            //    console.log(outputArray.sentenceDetails, "sentenceDetailsssssss")
            // console.log(outputArray[0].sentenceDetails, "index00000000000000000000000000000")
            setLoading(false)
            //  console.log(highlightSentence, "Hightlightedddddddddd")
            applyHighlight(sentenceArray)

            const data = {
                userId: sessionStorage.getItem("userId"),
                organizationId: sessionStorage.getItem("organizationId"),
                actionType: "plagiarism",
              };
        
              const addLogs = await addUserLogs(data);
              if (addLogs.status !== 200) {
                toast.error(addLogs.message);
              }

        }
    }




    const applyHighlight = (obj) => {
        const contentState = contentEditorState.getCurrentContent();
        let newContentState = contentState;
        // if(outputArray.length == 0 || outputArray==undefined){
        //   alert("No responce in outputArr")

        // }
        // if(highlightSentence.length == 0 || highlightSentence==undefined){
        //   alert("No responce in sentenceArr")
        // }


        obj.forEach((detail) => {
            const { sentenceDetails } = detail;
            let start = 0;

            while (start < contentState.getPlainText().length) {
                const startIndex = contentState.getPlainText().indexOf(sentenceDetails, start);

                if (startIndex === -1) {
                    break;
                }

                const endIndex = startIndex + sentenceDetails.length;

                // Apply inline style to the selection
                const selection = SelectionState.createEmpty(
                    contentState.getBlockMap().first().getKey()
                )
                    .set("anchorOffset", startIndex)
                    .set("focusOffset", endIndex);

                newContentState = Modifier.applyInlineStyle(
                    newContentState,
                    selection,
                    "HIGHLIGHT"
                );

                // Update the start index to avoid an infinite loop
                start = endIndex;
            }
        });

        setEditorState(
            EditorState.push(contentEditorState, newContentState, "apply-inline-style")
        );
    };




    const calculateSpeakTime = (countstats, isTable = false) => {
        const contentState = contentEditorState.getCurrentContent();
        const rawText = convertToRaw(contentState);
        const text = rawText.blocks.map((block) => block.text).join(" ");

        if (text == "") {
            setTimeDisplay(`0 sec`)
        }

        // Estimate speaking speed (words per minute)
        const wordsPerMinute = 150; // Adjust as needed

        // Calculate the number of words in the text
        const wordCount = text.split(/\s+/).length;

        // Calculate the time it would take to speak in minutes
        const speakingTimeMinutes = wordCount / wordsPerMinute;

        // Convert minutes to minutes and seconds
        const minutes = Math.floor(speakingTimeMinutes);
        const seconds = Math.round((speakingTimeMinutes % 1) * 60);
        let finalTime = ""
        // Set the time display based on conditions
        if (speakingTimeMinutes < 1) {
            setTimeDisplay(`${seconds} sec`);
            finalTime = `${seconds} sec`

        } else {
            setTimeDisplay(`${minutes} min ${seconds} sec`);
            // addScanHistory(countstats,`${seconds} sec`)
            finalTime = `${minutes} min ${seconds} sec`

        }
        console.log("countstats_", countstats)

    };









    const countTextStats = (isTable) => {
        let inputText = contentEditorState.getCurrentContent().getPlainText()
        // Count paragraphs
        const paragraphs = inputText.split('\n').filter((line) => line.trim() !== '').length;

        // Count sentences (a basic example)
        const sentences = inputText.split(/[.!?]/).filter((sentence) => sentence.trim() !== '').length;

        // Count unique words
        const words = inputText
            .toLowerCase()
            .split(/\W+/)
            .filter((word) => word.trim() !== '');
        const uniqueWords = [...new Set(words)].length;/////////////////////////////////////////////////////////////////

        setParaCount(paragraphs);
        setSentenceCount(sentences);
        setUniqueWordCount(uniqueWords);
        let counts = {
            "paragraph": paragraphs,
            "sentence": sentences,
            "uniqueWords": uniqueWords
        }
        calculateSpeakTime(counts, isTable)

    };



    const uniqueWordsPercentageCalculation = () => {
        const currentContent = contentEditorState.getCurrentContent();
        const rawContentState = convertToRaw(currentContent);
        const text = rawContentState.blocks.map((block) => block.text).join(" ");

        const words = text.match(/\b\w+\b/g) || []; // Regular expression to match words

        const uniqueWords = new Set(words);
        const percentage = (uniqueWords.size / words.length) * 100;

        setUniqueWordsPercentage(`${percentage.toFixed(0)}%`);
    };


    const handlePreviewClick = () => {
        setPreviewClick(true);
    };

    const handleClosePreview = () => {
        setPreviewClick(false);
    };

    const handleUploadDocClick = () => {
        setUploadDocClick(true);
    };

    const handleCloseUploadDoc = () => {
        setUploadDocClick(false);
    };


    const handleCloseSideModel = () => {
        setShowSideBar(false)
        setShowParaphraser(false)
        setShowSummariser(false)

    }

    const showParaphraserModel = () => {
        setShowSideBar(true)
        setShowParaphraser(true)
        setShowSummariser(false)
        if (fun_call == false) {
            setfun_Call(true)
        } else {
            setfun_Call(false)
        }

    }

    const showSummariserModel = () => {
        setShowSideBar(true)
        setShowSummariser(true)
        setShowParaphraser(false)
        if (fun_SummariseCall == false) {
            setFun_SummariseCall(true)
        } else {
            setFun_SummariseCall(false)
        }

    }



    const handleFileChange = (e) => {
        console.log("inside upload");
        const file = e.target.files[0];
        console.log("file_", file);


        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const content = event.target.result;
                const text = content.toString();
                console.log("text_", text);

                const newEditorState = EditorState.createWithContent(ContentState.createFromText(text));
                let currentText = newEditorState.getCurrentContent().getPlainText();

                setContentEditorState(EditorState.createEmpty())
                console.log("currentText_", currentText)
                setContentEditorState(newEditorState)
                handleCloseUploadDoc();
            };

            reader.readAsText(file);
        }
    };

    const handleShare = () => {
        setIsShareModalOpen(true)
    }

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };

    const sendComments = async (comments, rating) => {
        const sendData = {
            comment: comments,
            documentId: docId,
            rating,
            date: new Date(),
            reply: false,
            commentsID: "",
            total: newTotalRating + 1,
            max: maxRatings + rating,
        };



        const response = await addComments(sendData);
        if (response.status === 200) {
            toast.success(response.message);
        } else {
            toast.error("Oops something went wrong");
        }

    }

    const getDocumentData = async () => {
        const response = await getProfile();
        const user = response.data;
        setCurrentUser(user)

        const document = await getDocumentbyId(docId);
        const documentData = document.data

        setCommentData(documentData)
        setDocumentType(document.data.documentType)


    }

    const onCommentPost = async () => {
        setLoading(true)


        if (!rating) {
            setLoading(false)
            toast.error("Please select rating");
            return;
        } else if (!comments) {
            setLoading(false)
            toast.error("Please enter your comment");
            return;
        }

        await sendComments(comments, rating)
        await getDocumentData()
        if (passedFunction) {
            passedFunction();
        }

        setRating(0)
        setComments('')



    }


    const history = useHistory();
    const handleBackClick = () => {
        history.goBack();
    }

    const submitForReview = async () => {
        swal({
            title: 'Review Document',
            text: 'Submit your Document for Review ?',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (reviewDoc) => {
            console.log("DocID_",docId)
            if (reviewDoc) {
                let payload = {
                    userId : userID,
                    id : docId
                }
                try {
                    const response = await reviewDocument(
                        payload
                    );
                    if (response.status !== 200) {
                        toast.error(response.message);
                    } else {
                        toast.info(response.message);
                    }
                    // Perform any additional actions after submission
                } catch (error) {
                    console.error('Error submitting for review:', error);
                    toast.error('An error occurred while submitting for review');
                }
            }
        });
    };


    const _calculateRatings = async (total, avg, max) => {


        setAverageRating(avg)
        setNewTotalRating(total)
        setMaxRatings(max)

    };




    return (
        <div className="row">

            <MyDocument_SideModel
                contentEditorState={contentEditorState}

                showSideBar={showSideBar}
                setShowSideBar={setShowSideBar}

                showParaphraser={showParaphraser}
                setShowParaphrase={setShowParaphraser}

                showSummariser={showSummariser}
                setShowSummariser={setShowSummariser}


                handleCloseSideModel={handleCloseSideModel}
                fun_call={fun_call}
                setfun_Call={setfun_Call}

                fun_SummariseCall={fun_SummariseCall}
            />



            {showPlagiarism && (
                <div style={{
                    position: "fixed",
                    top: "62px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    zIndex: "99999999" // Ensure the overlay is on top
                }}>


                    <Loader loading={loading} />


                    {/* You can add loading spinners or other content here */}
                    <div style={{ height: "83%", width: "85%", padding: "40px", backgroundColor: "white", position: "absolute", top: "20px" }}>


                        <button style={{ position: "absolute", top: "1%", left: "88%" }} onClick={hidePlagiarismDiv}><CloseIcon style={{ backgroundColor: "transparent" }} /></button>
                        <Button variant="contained" aria-label="outlined primary button group" style={{ position: "absolute", top: "1%", left: "76%" }} onClick={handleCheckPlagiarism} >Check Again</Button>



                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "11px", marginTop: "10px" }}>
                            <Card className="col-md-2 col-sm-3">
                                <CardContent className="">
                                    {/* <SemiCircleProgressBar percentage={uniqueProgress}>
            <div className="">
            {uniqueProgress}%
            </div>
            </SemiCircleProgressBar> */}
                                    <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                        <CircularProgressbar
                                            value={plagiarozedProgress}
                                            // text={`${plagiarozedProgress}%`}
                                            counterClockwise={true}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 0.37,
                                                strokeLinecap: "butt",
                                                pathColor: 'red',
                                                trailColor: '#F6B6B6',
                                                textColor: 'red'
                                            })}
                                        />
                                    </div>
                                    <p className="progressText" style={{ color: "red" }}>{plagiarozedProgress}% {progressStatus}</p>
                                </CardContent>
                            </Card>
                            <Card className="col-md-2 col-sm-3  mx-1">
                                <CardContent className="">
                                    <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                        <CircularProgressbar
                                            value={uniqueProgress}
                                            // text={`${uniqueProgress}%`}
                                            // counterClockwise={true}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 0.63,
                                                strokeLinecap: "butt",
                                                pathColor: '#32BC7A',
                                                trailColor: '#7FF1BA',
                                                textColor: '#32BC7A'
                                            })}
                                        />
                                    </div>
                                    <p className="progressText ml-3" style={{ color: "#32BC7A" }}>{uniqueProgress}% Unique</p>
                                </CardContent>
                            </Card>
                            <Card className="col-md-5 col-sm-6">
                                <CardContent>
                                    <div>
                                        <div>
                                            <p className="countCardheader">Extra Word Count Statistics</p>
                                        </div>
                                        <div className="countCardValues d-flex justify-content-between">
                                            <div className="d-flex mt-3">
                                                <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                    <p>Sentences</p>
                                                    <p>Unique Word(s)</p>
                                                </div>
                                                <div className="d-flex flex-column ml-2">
                                                    <p>{sentenceCount}</p>
                                                    {/* <p>{sen}</p> */}
                                                    {/* <p>{uniqueWordCount + " " + "(" + uniqueWordPercent + ")"}</p> */}
                                                    <p>{uniqueWordsPercentage}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                    <p>Paragraph(s)</p>
                                                    <p>Speak Time</p>
                                                </div>
                                                <div className="d-flex flex-column ml-2">
                                                    <p>{paraCount}</p>
                                                    {/* <p>1</p> */}
                                                    <p>{timeDisplay}</p>
                                                    {/* <p>{speakTime}</p> */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                            <div style={{
                                height: "151px",
                                width: "285px",

                                marginLeft: "5px",
                                borderRadius: "5px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)"


                            }}>

                                <div className="P_div1" style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between" }}>
                                    <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                        <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "red" }}>
                                            {/* Content for the 1st row (25% height) */}
                                        </div>
                                        <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>Identical</div>
                                            <span style={{ alignSelf: "flex-end" }}>{plagiarozedProgress}%</span>
                                        </div>                                    </div>
                                    <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                        <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "pink" }}>
                                            {/* Content for the 2nd row (25% height) */}
                                        </div>
                                        <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>Minor Changes</div>
                                            <span className="" style={{ alignSelf: "flex-end" }}>{/*{uniqueWordsPercentage}*/}0%</span>
                                        </div>
                                    </div>
                                    <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                        <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "Orange" }}>
                                            {/* Content for the 3rd row (25% height) */}
                                        </div>
                                        <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>Paraphrased</div>
                                            <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                        </div>                                    </div>
                                    <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center" }}>
                                        <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "blue" }}>
                                            {/* Content for the 4th row (25% height) */}
                                        </div>
                                        <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <div>Omitted Words</div>
                                            <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style={{ height: "65%", width: "100%", margin: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                            <div style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)", height: "100%", width: "70%", marginRight: "10px" }}>
                                <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}>
                                    <span style={{ marginLeft: "20px" }}>SUBMITTED TEXT</span>
                                    <div style={{ width: "80%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                                        <div style={{ marginRight: "15px" }}><ArrowDownwardIcon /></div>
                                        <div style={{ marginRight: "15px" }}>  <ArrowUpwardIcon /></div>
                                        <div style={{ marginRight: "15px", fontWeight: "bolder", fontSize: "20px" }}>T</div>
                                    </div>
                                </div>
                                <div style={{ padding: "10px" }}>

                                    <Editor
                                        editorState={editorState}
                                        readOnly={true}
                                        customStyleMap={customStyleMap}

                                    />

                                </div>


                            </div>


                            <div style={{ height: "100%", width: "29%", marginRight: "20px", boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)" }}>
                                {sourceCount !== 0 ? (

                                    <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />

                                ) : (<>
                                    <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}><span style={{ marginLeft: "20px" }}>RESULT</span> </div>

                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }} ><h3>Plagiarism Free</h3></div>
                                </>
                                )}

                            </div>
                        </div>


                        {/* <div className="col-md-4">
                        <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />
 
                    </div> */}




                    </div>

                </div>
            )}





            <div className="col-md-8">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Button onClick={handleBackClick} variant="contained" color="primary" startIcon={<KeyboardBackspaceIcon />} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {circularLoader !== null &&
                            (circularLoader ? (
                                <span className="mr-4 text-info">Document Saved</span>
                            ) : (
                                <CircularProgress className="mr-4" size={24} />
                            ))
                        }
                        {!readOnlyStatus &&
                            <>
                                {/* <Button variant="outlined" color="primary" style={{ marginRight: '15px' }} onClick={handleShare}>Share<ShortcutIcon /></Button> */}
                                <Button variant="outlined" color="primary" style={{ marginRight: '15px' }} onClick={handleEdit}>Edit<EditIcon /></Button>
                            </>
                        }
                        <Button variant="outlined" color="primary" style={{ marginRight: '15px' }} onClick={handleDownload}>Download<DownloadIcon /></Button>
                        {!readOnlyStatus &&
                            <Button variant="outlined" color="error" style={{ marginRight: '15px' }} onClick={deleteContent}>Delete<DeleteIcon /></Button>
                        }
                    </div>

                </div>



                {/* <ShareOptionModal show={isShareModalOpen} onHide={closeShareModal} id={docId} /> */}

                <div>
                    <div style={{ marginTop: '10px', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)', padding: '10px', borderRadius: '10px', background: 'white', minHeight: '40px', maxHeight: '40px', overflowY: 'auto' }}>
                        <Editor
                            editorState={titleEditorState}
                            onChange={handleTitleEditorState}
                            readOnly={editable}
                        />
                    </div >


                </div>
                <div>
                    <div style={{ position: 'relative', marginTop: '10px', minWidth: '100%', maxWidth: '100%', boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)', padding: '25px 20px', borderRadius: '10px', background: 'white', minHeight: '270px', maxHeight: '270px', overflow: "auto" }}>
                        <Editor
                            editorState={contentEditorState}
                            onChange={handleContentEditorChange}
                            readOnly={editable}

                        />
                    </div>

                </div>


                <div style={{ marginTop: "15px", display: 'flex', justifyContent: 'space-between' }}>
                    <div>

                        <Button variant="outlined" style={{ marginRight: '15px' }} color="primary" onClick={showPlagiarismDiv}>Check Plagiarism</Button>


                        <Button variant="outlined" style={{ marginRight: '15px' }} color="primary" onClick={showParaphraserModel}>Paraphrase</Button>


                        <Button variant="outlined" style={{ marginRight: '15px' }} color="primary" onClick={showSummariserModel}>Summarize</Button>

                    </div>

                    <div>
                        {!readOnlyStatus &&
                            <Button variant="outlined" style={{ marginRight: '15px' }} color="primary" onClick={submitForReview} >Submit for Review</Button>
                        }
                    </div>

                </div>

            </div >
            <div className="col-md-4" style={{ minHeight: '86vh', paddingBottom: '10px', height: 'fitcontent', overflow: 'auto', boxShadow: '-5px 1px 8px 0px rgba(0,0,0,0.25)', backgroundColor: 'white' }}>
                <div style={{ height: '1%' }}></div>
                {/* <div style={{ height: '30%' }}> */}
                <div>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            File Summary
                        </AccordionSummary>
                        <AccordionDetails>
                            <Card style={{ borderBottom: '2px solid black', width: '100%' }}>
                                <CardContent style={{ position: 'relative', paddingLeft: '30px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <IconButton style={{ width: '3px', height: '3px', position: 'absolute', top: '15px', left: '5px' }}><InsertDriveFileIcon /></IconButton>
                                        <div>
                                            <Typography variant="caption">
                                                {docName.length > 25 ? `${docName.slice(0, 25)}...` : docName}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="caption">Current Version</Typography>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <Typography variant="caption">{date}</Typography>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div><IconButton style={{ width: '3px', height: '3px' }}><ZoomOutMapIcon onClick={() => { handlePreviewClick() }} /></IconButton>{previewClick && <PreviewDocModal editorData={contentEditorState} docName={docName} isOpen={previewClick} handleClose={handleClosePreview} />}</div>
                                            <div><IconButton style={{ marginLeft: '10px', width: '3px', height: '3px' }}><UploadIcon onClick={() => { handleUploadDocClick() }} /></IconButton>{uploadDocClick && <UploadDocModal isOpen={uploadDocClick} handleClose={handleCloseUploadDoc} key={inputKey} handleUploadFile={handleFileChange} />}</div>
                                        </div>
                                    </div>

                                </CardContent>
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div style={{ height: '2%' }}></div>
                {/* <div style={{ height: '27%' }}> */}
                <div>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Comments
                        </AccordionSummary>
                        <AccordionDetails >


                            <div className="column" style={{ width: '100%' }}>
                                <div className="row" style={{ backgroundColor: 'white' }}>
                                    <div className="col-8 rm-focus">
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            value={comments}
                                            onChange={(e) => {
                                                setComments(e.target.value)
                                            }}
                                            className="removeBorder w-100"
                                            placeholder="Write a response..."
                                            style={{ height: "45px", marginTop: "5px" }}
                                        />
                                    </div>
                                    <div className="col-4 text-right" >
                                        <div>
                                            <StarRatings
                                                rating={rating}
                                                starRatedColor="orange"
                                                changeRating={(rate) => setRating(rate)}
                                                starDimension="12px"
                                                starSpacing="0px"
                                                numberOfStars={5}
                                                name="rating"
                                            />
                                            <p
                                                className="text-right text-primary point"
                                                onClick={() => onCommentPost()}
                                            >
                                                Post
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="row" style={{ marginLeft: '10px' }}>
                                    <CommentsCard
                                        dispatch={dispatch}
                                        currentUser={currentUser}
                                        documentType={documentType}
                                        data={commentData}
                                        _id={docId}
                                        // ref={this.child}
                                        _calculateRatings={_calculateRatings}
                                    // blogType={blogType}
                                    />
                                </div>
                                <br />
                            </div>


                        </AccordionDetails>
                    </Accordion>
                </div>

            </div>

        </div>

    )
}



export default ViewMyDocuments