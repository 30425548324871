import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import { InputGroup } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import userService from "../services/userService";
import teamService from "../services/teamService";
import { toast } from "react-toastify";
import documentService from "../services/document";
import Avatar from "@material-ui/core/Avatar";
import groupdp from "../static/groupdp.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./shareFile.css";
import BasicAvatar from "./BasicAvatar";
import { addUserLogs } from "../services/userLogServices";
import { getRoles } from "../services/roleServices";

const ShareFile = ({ shareDocumentId, closeModal, getDocuments, refreshDocument }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [color] = useState(["#bff603", "yellow", "#80d4ff", "orange"]);
  const [sharedWith, setSharedWith] = useState([]);
  const [Access, setAccess] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState("");
  const [userRoleList, setUserRoleList] = useState([]);
  console.log(shareDocumentId,"__shareDocumentId")
  useEffect(() => {
   
    fetchData();
  }, [shareDocumentId]);
  useEffect(()=>{
    getUserRoles()
    fetchData()
  },[])

  const fetchData = async () => {
    const response = await userService.getTeam();
    let AccessData = [];

    if (response.status !== 200) {
      getGroup();
      toast.error(response.message);
    } else {
      console.log("shareDocumentId.drafts", shareDocumentId.drafts)
      if (shareDocumentId.drafts.length > 0) {
        let draftData = await documentService.getAccess(
          shareDocumentId.drafts
        );
        AccessData = draftData.data;
        setAccess(AccessData);
        console.log("AccessData:", AccessData)
      }

      let alreadyShared = [];
      let teamMember = [];

      response.data.user.forEach((team) => {
        console.log("accessdata", AccessData)

        team["shared"] = false;
        team["readOnly"] = null;
        if (AccessData.length > 0) {
          AccessData.forEach((s) => {
            if (team.id === s.editor) {
              team["shared"] = true;
              team["readOnly"] = s.readOnly;
              alreadyShared.push(team);
            }
          });
        }
        teamMember.push(team);
      });

      setData(response.data.user);
      setFilter(teamMember);
      setSharedWith(alreadyShared);
      getGroup();
      console.log("userdetails:", response.data.user)
    }
  };


  useEffect(() => {
    console.log("filter", filter)
  }, [filter]);

  const getGroup = async () => {
    const groupResponse = await teamService.getAllGroups();
    if (groupResponse.status === 200) {
      let myGroup = [];
      groupResponse.data.forEach((group) => {
        let response = {
          _id: group._id,
          name: group.name,
          owner: group.owner,
          ownerName: group.ownerName,
          members: group.members,
          select: false,
          edit: false,
          selectToggle: false,
          editToggle: false,
        };
        myGroup.push(response);
      });
      setGroups(myGroup);
      setFilterGroup(myGroup);
      setLoading(false);
    } else {
      setLoading(false);
      console.log(groupResponse.message);
    }
  };

  const filterOnChange = (e) => {
    if (e.target.value === "") {
      setFilter(data);
      setFilterGroup(groups);
    } else {
      let value = e.target.value.toLowerCase();

      let result = data.filter((user) => {
        return user.name.toLowerCase().search(value) !== -1;
      });

      let resultGroup = groups.filter((group) => {
        return group.name.toLowerCase().search(value) !== -1;
      });

      setFilter(result);
      setFilterGroup(resultGroup);
    }
  };

  const shareFile = async () => {
    setLoading(true);

    let AccessData = Access;
    let draftOwner = [];
    if (AccessData.length > 0) {
      AccessData.forEach((item) => draftOwner.push(item.editor));
    }

    let team = [];

    let dataLength = filter.length;
    let dataCopy = [...filter];

    let groupLength = groups.length;
    let groupsCopy = [...groups];

    let newDocument = shareDocumentId;

    for (let i = 0; i < dataLength; i++) {
      if (dataCopy[i].select === true) {
        team.push({ id: dataCopy[i].id, readOnly: dataCopy[i].edit });
      }
    }

    for (let j = 0; j < groupLength; j++) {
      if (groupsCopy[j].select === true) {
        for (let k = 0; k < groupsCopy[j].members.length; k++) {
          let teamInc = 0;
          for (let z = 0; z < team.length; z++) {
            if (team[z].id !== groupsCopy[j].members[k]) {
              teamInc++;
            }
          }
          if (teamInc === team.length) {
            team.push({ id: groupsCopy[j].members[k], readOnly: groupsCopy[j].edit });
          }
        }
      }
    }

    let x = team.length;
    while (x--) {
      for (let y = 0; y < AccessData.length; y++) {
        if (team.length > 0) {
          if (AccessData[y].editor === team[x].id) {
            if (AccessData[y].readOnly === !team[x].readOnly) {
              team.splice(x, 1);
            }
          }
        }
      }
    }

    if (team.length < 1) {
      setLoading(false);
      toast("Incorrect option selected");
      return;
    }

    const share = {
      document: newDocument,
      team: team,
      Access: AccessData,
      draftOwner: draftOwner,
      date: new Date(),
    };

    let response = await documentService.shareDocument(share);
    if (response.status === 200) {
      setLoading(false);
      toast.success(response.message);
      const data = {
        userId: sessionStorage.getItem("userId"),
        organizationId: sessionStorage.getItem("organizationId"),
        actionType: "shareDoc",
      };

      const addLogs = await addUserLogs(data);
      refreshDocument();
      if (addLogs.status !== 200) {
        toast.error(addLogs.message);
      }
    } else {
      setLoading(false);
      toast(response.message);
    }

    closeModal();
    getDocuments();
  };

  const onChangeGroupBox = (group, edit, toggle, e) => {
    const groupsCopy = [...groups];
    const index = groupsCopy.indexOf(group);
    groupsCopy[index] = { ...groupsCopy[index] };

    groupsCopy[index].select = e.target.checked;
    groupsCopy[index].edit = edit;
    if (edit === true) {
      groupsCopy[index].editToggle = true;
    }
    if (edit === false) {
      groupsCopy[index].selectToggle = true;
    }

    if (toggle === "select") {
      if (toggle === "select" && e.target.checked === true) {
        groupsCopy[index].selectToggle = true;
      } else {
        groupsCopy[index].selectToggle = false;
      }
    }

    if (toggle === "edit") {
      if (toggle === "edit" && e.target.checked === true) {
        groupsCopy[index].editToggle = true;
      } else {
        groupsCopy[index].editToggle = false;
      }
    }

    if (groupsCopy[index].selectToggle === true) {
      groupsCopy[index].select = true;
      groupsCopy[index].edit = false;
    }

    if (groupsCopy[index].editToggle === true) {
      groupsCopy[index].select = true;
      groupsCopy[index].edit = true;
    }

    setGroups(groupsCopy);
  };

  const onChangeTeamBox = (team, edit, toggle, e) => {
    const teamMembers = [...filter];
    console.log("teammembers:", teamMembers)
    const index = teamMembers.indexOf(team);
    teamMembers[index] = { ...teamMembers[index] };

    teamMembers[index].select = e.target.checked;
    teamMembers[index].edit = edit;

    if (toggle === "select") {
      if (toggle === "select" && e.target.checked === true) {
        teamMembers[index].selectToggle = true;
      } else {
        teamMembers[index].selectToggle = false;
      }
    }

    if (toggle === "edit") {
      if (toggle === "edit" && e.target.checked === true) {
        teamMembers[index].editToggle = true;
      } else {
        teamMembers[index].editToggle = false;
      }
    }

    if (teamMembers[index].selectToggle === true) {
      teamMembers[index].select = true;
      teamMembers[index].edit = false;
    }

    if (teamMembers[index].editToggle === true) {
      teamMembers[index].select = true;
      teamMembers[index].edit = true;
    }

    setFilter(teamMembers);
    console.log("filterValues:", teamMembers)
    };

    const getUserRoles = async () => {
      try {
        const organizationId = sessionStorage.getItem("organizationId")
          if (organizationId) {
              setLoading(true);
              const data = { organizationId }
              const checkUsersExist = await getRoles(data)
              if (checkUsersExist?.isUsersExist) {
                  setUserRoleList(checkUsersExist?.userRoleList);
              } else {
                  setUserRoleList(checkUsersExist?.userRoleList);
              }
              setLoading(false);
          }
      } catch (error) {
          console.error('Error finding roles list:', error);
          setLoading(false);
      }
  }

    console.log(filter,"__filter")
    console.log(userRoleList,userRoleList[2]?.name,"__userRoleList")

  return (
    <div>
      <Loader loading={loading} />

      <input
        className="form-control search"
        type="text"
        onChange={filterOnChange}
        placeholder="&#xF002; Search Username / Group"
        aria-label="Search"
        style={{
          backgroundColor: "#f2f2f2",
          fontFamily: "FontAwesome",
          height: "50px",
          marginTop: "-16px",
          border: " 0px solid",
          paddingLeft: "25px",
          marginLeft: "-29px",
          marginBottom: "3%",
          width: "498px",
        }}
      />

      {/* MY Groups */}
      {filterGroup.length < 1 ? (
        <div style={{ marginTop: "2%" }}>
          <h6 style={{ textAlign: "center", color: "grey" }}>
            NO GROUPS ARE CREATED
          </h6>
        </div>
      ) : (
        <>
          <h5>Groups</h5>
          <div
            style={{ overflowY: "auto", overflowX: "hidden", height: "20vh" }}
          >
            {filterGroup.map((group, i) => (
              <div key={group._id} className="row">
                <div className="col-2">
                <BasicAvatar name={group.name} />
                  {/* <Avatar
                    className="res-shareFile-dp"
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "black",
                      backgroundColor: `${color[groups.indexOf(group) % 4]
                        }`,
                    }}
                  >
                    {group.name.charAt(0).toUpperCase()}
                  </Avatar> */}
                </div>
                <div className="col-5">
                  <p className="pt-1 res-shareFile-name">{group.name}</p>
                </div>
                <div className="col-5">
                  <RadioGroup
                    row
                    aria-label={i}
                    name={i}
                    onChange={(e) => {
                      if (e.target.value === "false") {
                        onChangeGroupBox(group, false, "select", e);
                      } else {
                        onChangeGroupBox(group, true, "edit", e);
                      }
                    }}
                    defaultValue=""
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="View"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio color="secondary" />}
                      label="Edit"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <hr />

      {/* My Team List */}
      {filter.length < 1 ? (
        <div>
          <h6 style={{ textAlign: "center", color: "grey" }}>
            NO TEAM MEMBERS ARE ADDED
          </h6>
        </div>
      ) : (
        <>
          <h5>Team Members</h5>
          <div
            style={{ overflowY: "auto", overflowX: "hidden", height: "20vh" }}
          >
            {filter.map((team, i) => (
              <div key={team.id} className="row">
                <div className="col-2">
                <BasicAvatar name={team.name} />
                  {/* <Avatar
                    className="res-shareFile-dp"
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "black",
                      backgroundColor: `${color[data.findIndex((member) => member.id === team.id) % 4]
                        }`,
                    }}
                  >
                    {team.name.charAt(0).toUpperCase()}
                  </Avatar> */}
                </div>
                <div className="col-5">
                  <p className="pt-1 res-shareFile-name">
                    {team.name !== "" ? team.name : team.email}
                    <span>{team.shared && <b style={{color:"green"}}>{"[Shared]"}</b>}</span>
                  </p>
                </div>
                <div className="col-5">
                  <RadioGroup
                    row
                    aria-label={i}
                    name={i}
                    onChange={(e) => {
                      if (e.target.value === "false") {
                        onChangeTeamBox(team, false, "select", e);
                      } else {
                        onChangeTeamBox(team, true, "edit", e);
                      }
                    }}
                    defaultValue={
                      team.readOnly === true
                        ? "false"
                        : team.readOnly === null
                          ? ""
                          : "true"
                    }
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary" />}
                      label="View"
                      labelPlacement="start"
                    />
                    {(team.Role === "Individual" || (team.Role === "Organization" && team.userRole !== userRoleList[2]?.name)) && (
                      <FormControlLabel
                        value="true"
                        control={<Radio color="secondary" />}
                        label="Edit"
                        labelPlacement="start"
                      />
                    )}

                    
                  </RadioGroup>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="text-center">
        <button className="btn btn-primary mt-3" onClick={shareFile}>
          Share
        </button>
      </div>
    </div>
  );
};

export default ShareFile;