import http from "./httpService";
// import {
//   apiUrl,
//   herokuUrl
// } from "./config.json";
import configdata from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${configdata.apiUrl}/texteditor`;
} else {
  apiEndpoint = `${configdata.herokuUrl}/texteditor`;
}
export async function saveDocs(saveDocument) {
  const {
    id
  } = await http.post(
    `${apiEndpoint}/postDocs`,
    saveDocument
  );
  return id;
}

// API - Get All Tags by User
export async function getAllTags() {
  const data = await http.get(`${apiEndpoint}/getallTagsByUserId`);
  return data;
}

export async function getAllDocsTags() {
  const data = await http.get(`${apiEndpoint}/getDocsTagsByUserId`);
  return data;
}

export async function getAllBlogsTags() {
  const data = await http.get(`${apiEndpoint}/getBlogsTagsByUserId`);
  return data;
}

// API - Get All Documents
export async function getAllDocuments(id) {
  const data = await http.get(`${apiEndpoint}/getDocs?id=${id}`);
  return data;
}

export async function getAllMyDocuments(id) {
  const data = await http.get(`${apiEndpoint}/getDocsAll`);
  return data;
}

// API - Get Document by ID
export async function getDocumentbyId(id) {
  const data = await http.post(`${apiEndpoint}/getDocsById`, {
    _id: id
  });
  return data;
}

// API - Get All Documents by User ID
export async function getAllDocumentsByUserId(id) {
  const data = await http.post(`${apiEndpoint}/getDocsByUserId`, {
    _id: id
  });
  return data;
}

export async function editAllDocuments(editDocument) {
  const data = await http.post(`/editDocs`, editDocument);
  return data;
}

export async function spellCheck(data) {
  const res = await http.post(`http://localhost:4000/spellCheck`, data);
  return res;
}

export async function deleteDocuments(docId) {
  const data = await http.post(`${apiEndpoint}/deleteDocs`, {
    id: docId
  });
  return data;
}

export async function restoreDocuments(docId) {
  const data = await http.post(`${apiEndpoint}/restoreDocs`, {
    id: docId
  });
  return data;
}

export async function permanentDelete(doc) {
  const response = await http.post(`${apiEndpoint}/permanentDelete`, doc);
  return response;
}

export async function permanentDeleteAllDocuments(ids) {
  console.log(ids,"hahahaha")
  const response = await http.post(`${apiEndpoint}/deleteSelectedDocumentsPermanently`, ids);
  return response;
}

export async function shareDocument(document) {
  const response = await http.post(
    `${apiEndpoint}/shareDocument`,
    document
  );
  return response;
}

export async function getAccess(drafts) {
  const data = await http.post(`${apiEndpoint}/getAccess`, {
    drafts
  });
  return data;
}

export async function getDeletedDocuments() {
  const data = await http.get(`${apiEndpoint}/getDeletedDocs`);
  return data;
}

export async function getSharedDocuments() {
  const data = await http.get(`${apiEndpoint}/getsharedWithMe`);
  return data;
}

export async function getError(_id) {
  const data = await http.get(`${apiEndpoint}/getError?_id=${_id}`);
  return data;
}

export async function autoSaveDocument(document) {
  const data = await http.post(`${apiEndpoint}/autoSaveDocs`, document);
  return data;
}

export async function deleteCurrentDocument(document) {
  const data = await http.post(`${apiEndpoint}/deleteCurrentDocs`, document);
  return data;
}

export async function uploadDocument() {
  const data = await http.post(`${apiEndpoint}/uploadDocument`);
  console.log("service")
  return data;
}

export async function generateAutoTag(data){
  const response = await http.post(`${apiEndpoint}/autoTagGenerator`,data);
  return response;
}

export async function checkDocAcceptance(document) {
  const response = await http.post(
    `${apiEndpoint}/checkDocAcceptance`,
    document
  );
  return response;
}
export async function updateDocumentStatus(document) {
  const response = await http.post(
    `${apiEndpoint}/updateDocumentStatus`,
    document
  );
  return response;
}

export async function getAllDocStatus(document) {
  const data = await http.post(
    `${apiEndpoint}/getAllDocStatus`,
    document
  );
  return data;
}











export async function createFolder(folderName) {
  const data = await http.post(`${apiEndpoint}/createFolder`, {folderName});
  return data;
}

export async function getAllFolders(selectedFolder) {
  const data = await http.get(`${apiEndpoint}/getFolders`,{selectedFolder});
  return data;
}

export async function getFolderbyId(id) {
  const data = await http.post(`${apiEndpoint}/getFolderById`, {
    _id: id
  });
  return data;
}

export async function deleteFolder(docId) {
  console.log(docId,"hello everynyan")
  const data = await http.post(`${apiEndpoint}/deleteFolder`, {
    id: docId
  });
  return data;
}

export async function getDeletedFolders() {
  const data = await http.get(`${apiEndpoint}/getDeletedFolders`);
  return data;
}


export async function restoreFolder(docId) {
  const data = await http.post(`${apiEndpoint}/restoreFolder`, {
    id: docId
  });
  return data;
}

export async function permanentDeleteFolder(docId) {
  const response = await http.post(`${apiEndpoint}/permanentDeleteFolder`, {id:docId});
  return response;
}


export async function getSearchHistory(data){
  const response = await http.post(`${apiEndpoint}/getSearchHistory`, data );
  return response;
}

export async function deleteSearchSuggestion(data){
  const response = await http.post(`${apiEndpoint}/deleteSearchSuggestion`, data );
  return response;
}

export async function saveIgnoredSuggestions(data){
  const response = await http.post(`${apiEndpoint}/saveIgnoredSuggestions`, data );
  return response;
}

export async function getIgnoredSuggestions(id) {
  const data = await http.get(`${apiEndpoint}/getIgnoredSuggestions`, {
    id : id
  });
  return data;
}


export async function getVideoUrl(fileName) {
  const response = await fetch(`${apiEndpoint}/getVideoUrl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileName }), 
  });

  if (!response.ok) {
    throw new Error('Error fetching video preview');
  }

  const blob = await response.blob();
  const contentType = response.headers.get('Content-Type'); 
  return { blob, contentType };
}

export default {
  saveDocs,
  getAllDocuments,
  getAllMyDocuments,
  getDocumentbyId,
  getAllDocumentsByUserId,
  editAllDocuments,
  shareDocument,
  deleteDocuments,
  getDeletedDocuments,
  getSharedDocuments,
  spellCheck,
  getAllTags,
  autoSaveDocument,
  deleteCurrentDocument,
  restoreDocuments,
  uploadDocument,
  getError,
  permanentDelete,
  permanentDeleteAllDocuments,
  generateAutoTag,
  getAccess,
  checkDocAcceptance,
  updateDocumentStatus,
  getAllDocStatus,
  createFolder,
  getAllFolders,
  getFolderbyId,
  deleteFolder,
  getDeletedFolders,
  restoreFolder,
  permanentDeleteFolder,
  getAllDocsTags,
  getAllBlogsTags,
  getSearchHistory,
  saveIgnoredSuggestions,
  getIgnoredSuggestions,
  getVideoUrl
};
