import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import "./TeamCollaboration.css";
import { Avatar } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import BreadCrumb from "../Cards/BreadCrumbs";
import Loader from "../../utils/loader";
import { withRouter } from "react-router-dom";
import TeamCollaborationServices from "../../services/teamCollbarationServices";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { option_ } from "../Editor/utilize";
import ReactHtmlParser from "react-html-parser";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Diff from "../ReactDiff";
import isEmptyObject from "../../utils/isEmptyObject";
import swal from "sweetalert";
import userService from "../../services/userService";
import ReactTour from "../ReactTour";
import { useSelector } from "react-redux";
import BasicAvatar from "../BasicAvatar";
import { addUserLogs } from "../../services/userLogServices";

export class TeamCollaboration extends Component {
  state = {
    userProfileColor:[],
    isAccepted: "",
    username: "",
    display_picture: "",
    originalDocument: "",
    mergeDocuments: [],
    htmlContent: null,
    compareID: "",
    compareDP: "",
    compareUserID: "",
    compareName: "",
    compareRAW: null,
    comapreContent: null,
    leftCell: [],
    rightCell: [],
    changeCount: 0,
    merging: false,
    loading: true,
    reactTour: false,
    tourChange: false,
    // userDetails: useSelector((user) => user),
    // userID: this.userDetails.user.currentUser._id,
    steps1: [
      {
        selector: '.reactour-1',
        content: 'Displays all the collaborators for current document. By clicking on it user can manually remove the collaborators from popup box',
        position: 'left',
      },
      {
        selector: '.reactour-2',
        content: () => (
          <div>
            <p>Left editor displays the current document content</p>
            <b>Legend :</b>
            <p>
              Content displayed in <span style={{ backgroundColor: "lightgreen" }}>Green Background</span> is marked as <b>new</b> content that can be added from collaborator's document.
            </p>
            <p>
              Content displayed in <span style={{ backgroundColor: "salmon" }}>Red Background</span> is marked as <b>deleted/removed</b> content which will remove that content from current document.
            </p>
          </div>
        ),
        position: 'right',
      },
      {
        selector: '.reactour-3',
        content: "Right editor displays the collaborator's document content",
        position: 'left',
      },
      {
        selector: '.reactour-4',
        content: 'This will reset all the changes made to current document during collaboration',
        position: 'top',
      },
      {
        selector: '.reactour-5',
        content: 'Save all the changes made to current document during collaboration',
        position: 'top',
      }
    ],
    steps2: [
      {
        selector: '.reactour-1',
        content: 'Displays all the collaborators for current document. By clicking on it user can manually remove the collaborators from popup box',
        position: 'left',
      },
      {
        selector: '.reactour-2',
        content: () => (
          <div>
            <p>Left editor displays the current document content</p>
            <b>Legend :</b>
            <p>
              1. Content displayed in <span style={{ backgroundColor: "lightgreen" }}>Green Background</span> is marked as <b>new</b> content that can be added from collaborator's document.
            </p>
            <p>
              2. Content displayed in <span style={{ backgroundColor: "salmon" }}>Red Background</span> is marked as <b>deleted/removed</b> content which will remove that content from current document.
            </p>
            <p>
              3. Content displayed in normal text denotes matching content
            </p>
          </div>
        ),
        position: 'right',
      },
      {
        selector: '.reactour-3',
        content: "Right editor displays the collaborator's document content",
        position: 'left',
      },
      {
        selector: '.reactour-4',
        content: "Collab toolbar",
        position: 'top',
      },
      {
        selector: '.reactour-5',
        content: "Accept all the changes made by collaborator into current document",
        position: 'top',
      },
      {
        selector: '.reactour-6',
        content: "Reject all the changes made by collaborator into current document",
        position: 'top',
      },
      {
        selector: '.reactour-7',
        content: "Merge all content made by collaborator with current content",
        position: 'top',
      },
      {
        selector: '.reactour-8',
        content: 'This will reset all the changes made to current document during collaboration',
        position: 'top',
      },
      {
        selector: '.reactour-9',
        content: 'Save all the changes made to current document during collaboration',
        position: 'top',
      }
    ],
  };


  async componentDidMount() {
    let { document } = this.props.location.state;
    let { userProfileColor } = this.props.location.state;
    this.setState({userProfileColor})
    console.log("userProfileColor", userProfileColor)
    let { currentUser } = this.props;
    let data;
    if (currentUser && !isEmptyObject(currentUser)) {
      data = { ...currentUser };
    } else {
      let user = await userService.getProfile();
      console.log(user);
      data = { ...user.data };
    }

        const onlyDocumentData = document.onlyDocumentContent
        console.log(onlyDocumentData,"data2__ ___ ___ documentdata")
        

        let transformedContent = onlyDocumentData.replace(/<p>(.*?)<\/p>/g, (match, content) => {
          return `${content.trim()}\n`; // Trim whitespace and add newline
        });
        const textWithNewlines = transformedContent.replace(/<[^>]*>/g, '');

        const finalText = textWithNewlines.replace(/&nbsp;/g, ' ');

    // Format the text into the desired JSON format
    const formattedText = {
      blocks: [
        {
          key: "9ukr0",
          text: finalText,
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {}
        }
      ],
      entityMap: {}
    };

    // Convert the formatted text to JSON string
    const jsonText = JSON.stringify(formattedText);
    console.log(jsonText, "data2___________componentmount")
    document.onlyDocumentContent = jsonText
    console.log(document, "data2___________componentmount____777777")
    this.setState(
      {
        originalDocument: document,
        username: data.username,
        display_picture: data.display_picture,
      },
      async () => {
        // const onlyDocumentData = this.state.originalDocument.onlyDocumentContent
        // const textWithoutHTML = onlyDocumentData.replace(/<[^>]*>/g, '');

        // // Replace <br> tags with newline characters (\n)
        // const textWithNewlines = textWithoutHTML.replace(/<br\s*\/?>/gi, '\n');

        // // Format the text into the desired JSON format
        // const formattedText = {
        //   blocks: [
        //     {
        //       key: "9ukr0",
        //       text: textWithNewlines,
        //       type: "unstyled",
        //       depth: 0,
        //       inlineStyleRanges: [],
        //       entityRanges: [],
        //       data: {}
        //     }
        //   ],
        //   entityMap: {}
        // };

        // // Convert the formatted text to JSON string
        // const jsonText = JSON.stringify(formattedText);

        // console.log(jsonText,"data2___________componentmount")

        await this.convertToHtml(
          this.state.originalDocument.onlyDocumentContent,
          // jsonText,
          true
        );
        await this.getCollabDocuments();
        await this.cellDetails();
      }
    );
  }

  cellDetails = async () => {
    let { originalDocument, compareRAW } = this.state;
    let OGeditor = await JSON.parse(originalDocument.onlyDocumentContent);
    let collabEditor = await JSON.parse(compareRAW);
    let leftCell = await JSON.parse(originalDocument.onlyDocumentContent)
      .blocks;
    let rightCell = await JSON.parse(compareRAW).blocks;

    if (leftCell.length < rightCell.length) {
      for (let i = leftCell.length; i < rightCell.length; i++) {
        // fakeCell.key = await rightCell[i].key;
        let fakeCell = {
          // key: rightCell[i].key,
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        await leftCell.push(fakeCell);
      }
      OGeditor.blocks = leftCell;
    } else {
      for (let i = rightCell.length; i < leftCell.length; i++) {
        // fakeCell.key = leftCell[i].key;
        let fakeCell = {
          // key: leftCell[i].key,
          key: Math.random().toString(20).substr(2, 5),
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
          done: false,
          reject: false,
        };
        rightCell.push(fakeCell);
      }
      collabEditor.blocks = rightCell;
    }

    console.log(leftCell);
    console.log(rightCell);

    this.setState({
      OGeditor: OGeditor,
      collabEditor: collabEditor,
      leftCell: leftCell,
      rightCell: rightCell,
    });
  };

  getCollabDocuments = async () => {
    // let userId = this.state.userID
    let userId = sessionStorage.getItem("userId")
    console.log("getCollabDocumentsuserId", userId)
    let doc = this.state.originalDocument;
    let data = await TeamCollaborationServices.getCollabDocuments(
      doc.pendingDocuments,
      userId
    );
    if (data.status === 200) {
      // let mergedDocs = await [].concat.apply([], data.data);
      let mergedDocs = data.data;

      console.log(mergedDocs, "data2__ ___ ____ mergedDocs")

      const onlyDocumentData = mergedDocs[0].onlyDocumentContent

      let transformedContent = onlyDocumentData.replace(/<p>(.*?)<\/p>/g, (match, content) => {
        return `${content.trim()}\n`; // Trim whitespace and add newline
      });
      
      
      console.log(onlyDocumentData,"data2_ _ _ _ _ mergedDocs__Onlydoccontent")
        const textWithoutHTML = transformedContent.replace(/<[^>]*>/g, '');

        const finalText = textWithoutHTML.replace(/&nbsp;/g, ' ');
        
        const formattedText = {
          blocks: [
            {
              key: "9ukr0",
              text: finalText,
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {}
            }
          ],
          entityMap: {}
        };

        const jsonText = JSON.stringify(formattedText);

        mergedDocs[0].onlyDocumentContent = jsonText;
      
      this.setState(
        {
          mergeDocuments: mergedDocs,
          compareID: mergedDocs[0]._id,
          compareDP: mergedDocs[0].editor.display_picture,
          compareUserID: mergedDocs[0].editor._id,
          compareName: mergedDocs[0].editor.username,
          compareRAW: mergedDocs[0].onlyDocumentContent,
          loading: false,
        },
        () => this.convertToHtml(mergedDocs[0].onlyDocumentContent, false)
      );
    } else {
      console.log(data.message);
    }
  };

  convertToHtml = (data, original) => {
    console.log(data, "data2___________________ function", original)
    const storedState = JSON.parse(data);
    const contentState = convertFromRaw(storedState);
    let editorContentHtml = stateToHTML(contentState, option_);
    let htmlContent = ReactHtmlParser(editorContentHtml);
    if (original === true) {
      this.setState({ htmlContent });
    } else {
      this.setState({ comapreContent: htmlContent });
    }
  };

  compareData = async (document) => {
    this.setState(
      {
        changeCount: 0,
        merging: false,
        compareID: document._id,
        compareDP: document.editor.display_picture,
        compareUserID: document.editor._id,
        compareName: document.editor.username,
        compareRAW: document.onlyDocumentContent,
      },
      async () => {
        console.log(document.onlyDocumentContent, "data2 in comparedata")
        await this.convertToHtml(document.onlyDocumentContent, false);
        await this.cellDetails();
      }
    );
  };

  onAccept = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].text = text;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
      isAccepted: "accepted"
    });
  };

  onReject = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].reject = true;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
      isAccepted: "rejected"
    });
  };

  onBoth = (key, text) => {
    const { OGeditor, changeCount } = this.state;
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        OGeditor.blocks[i].text += text;
        OGeditor.blocks[i].reject = true;
        OGeditor.blocks[i].done = true;
      }
    }

    this.setState({
      OGeditor,
      leftCell: OGeditor.blocks,
      changeCount: changeCount + 1,
      merging: true,
      isAccepted: "accepted"
    });
  };

  undoChange = async (key, text) => {
    const { OGeditor, originalDocument, changeCount } = this.state;
    let og = await JSON.parse(originalDocument.onlyDocumentContent);
    for (let i = 0; i < OGeditor.blocks.length; i++) {
      if (OGeditor.blocks[i].key === key) {
        if (i > og.blocks.length - 1) {
          OGeditor.blocks[i].text = "";
        } else {
          OGeditor.blocks[i].text = og.blocks[i].text;
        }
        OGeditor.blocks[i].reject = false;
        OGeditor.blocks[i].done = false;
      }
    }

    this.setState(
      {
        OGeditor,
        leftCell: OGeditor.blocks,
        changeCount: changeCount - 1,
        merging: true,
      },
      () => {
        if (changeCount - 1 === 0) {
          this.setState({ merging: false });
        }
      }
    );
  };

  resetChanges = async () => {
    this.setState({ changeCount: 0 });
    let document = {
      _id: this.state.compareID,
      editor: {
        _id: this.state.compareUserID,
        display_picture: this.state.compareDP,
        username: this.state.compareName,
      },
      onlyDocumentContent: this.state.compareRAW,
    };
    await this.compareData(document);
  };

  removeCollaborater = async (doc) => {
    swal({
      title: "Remove Collaborater",
      text: "Are you sure ? You want to Remove Collaborater for this Document",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (removeCollab) => {
      if (removeCollab) {
        // let userId = this.state.userID
        let userId = sessionStorage.getItem("userId")
        let data = await TeamCollaborationServices.removeCollaborater(doc._id, userId);
        if (data.status === 200) {
          toast.success(data.message);
          setTimeout(() => {
            this.props.history.push({ pathname: "/mydocuments" });
          }, 1000);
        } else {
          toast(data.message);
        }
      } else {
        // Nothing
      }
    });
  };

  convertTextToHtml = (text) => {
    // Split the text into paragraphs based on line breaks
    const paragraphs = text.split('\n');

    // Map each paragraph to wrap it with <p> tags and handle line breaks
    const htmlContent = paragraphs.map((paragraph) => {
        // Replace consecutive line breaks with <br> tags within the paragraph
        const paragraphWithBreaks = paragraph.replace(/\n{2,}/g, '<br><br>');
        // Wrap the paragraph with <p> tags
        return `<p>${paragraphWithBreaks}</p>`;
    });

    // Join the paragraphs into a single HTML string
    return htmlContent.join('');
};

  mergeDocument = async () => {
    let { originalDocument, compareID, compareUserID, OGeditor, userID } = this.state;

    for (let i = 0; i < OGeditor.blocks.length; i++) {
      OGeditor.blocks[i].done = false;
      OGeditor.blocks[i].reject = false;
    }
    let userId = sessionStorage.getItem("userId")
    console.log("OGeditor", OGeditor.blocks[0].text);

    const formattedDocumentContent = JSON.stringify(OGeditor.blocks[0].text);
    const parsedContent = JSON.parse(formattedDocumentContent);
    const correctOnlyDocumentContent = parsedContent.replace(/<br\s*\/?>/gi, '\n');
    const finalContent = this.convertTextToHtml(correctOnlyDocumentContent)
    console.log("merge accepted data: ", formattedDocumentContent)
    console.log("merge accepted data2: ",  finalContent)
    let doc = {
      originalID: originalDocument._id,
      draftID: compareID,
      draftUserId: compareUserID,
      formattedDocumentContent: formattedDocumentContent,
      onlyDocumentContent: finalContent,
      // userId: userID
      userId: userId,
      isAccepted: this.state.isAccepted
    };



    swal({
      title: "Merge Document",
      text: "Are you sure ? You want to Merge this document",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (mergeDoc) => {
      if (mergeDoc) {
        let data = await TeamCollaborationServices.mergeDocument(doc);
        if (data.status === 200) {
          toast.success(data.message);
          const data1={
            userId:sessionStorage.getItem("userId"),
            organizationId:sessionStorage.getItem("organizationId"),
            actionType: "Collaboration",
            collaborationStatus : doc.isAccepted,
            collaborator : doc.draftUserId
          }
          const addLogs = await addUserLogs(data1)
          if (addLogs.status !== 200) {
              toast.error(addLogs.message)
          }
          setTimeout(() => {
            this.props.history.push({ pathname: "/mydocuments" });
          }, 1000);
        } else {
          toast(data.message);
        }
      } else {
        // Nothing
      }
    });
  };


  reactTour = async () => {
    let { leftCell, rightCell } = this.state;
    if (leftCell.length !== rightCell.length) {
      this.setState({ tourChange: true });
    } else {
      for (let i = 0; i < leftCell.length; i++) {
        if (leftCell[i].text !== rightCell[i].text) {
          this.setState({ tourChange: true });
          break;
        }
      }
    }
    this.setState({ reactTour: true });
  }

  render() {
    let {
      username,
      display_picture,
      originalDocument,
      mergeDocuments,
      compareDP,
      compareName,
      leftCell,
      rightCell,
      steps1,
      steps2,
      tourChange,
      colorIndex,
      userProfileColor
    } = this.state;

    function getRandomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
    console.log("compareName",compareName)

    return (
      <div className="pt-2 pb-2">
        <Loader loading={this.state.loading} />
        <div>
          <ToastContainer />
          <ReactTour
            steps={tourChange ? steps2 : steps1}
            isOpen={this.state.reactTour}
            closeTour={() => this.setState({ reactTour: false })}
          />
          <BreadCrumb
            l1="Dashboard"
            l2="Documents"
            l3="Team Collaboration"
            dashboardlink="/dashboard"
            documentLink="/mydocuments"
          />
          <div className="row">
            <div className="col-sm-6">
              <h3 className="whiteText">Team Collaboration
                <i
                  className="fa fa-question-circle mt-2 ml-3"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="Need Help ?"
                  style={{
                    cursor: "pointer",
                    color: "dodgerblue",
                    fontSize: "15px"
                  }}
                  onClick={this.reactTour}
                ></i>
              </h3>
            </div>
            <div className="col-sm-6">
              <AvatarGroup
                style={{ float: "right" }}
                className="point reactour-1"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                {mergeDocuments.map((docs) => (
                  <BasicAvatar name={docs.editor.username} />
                //   <Avatar
                //   style={{
                //     backgroundColor: getRandomColor(),
                //     color: "white"
                //   }}
                // >
                //   <h5
                //     style={{ marginTop: "4px" }}
                //     data-toggle="tooltip"
                //     title={compareName}
                //   >
                //     {compareName.charAt(0).toUpperCase()}

                //   </h5>
                // </Avatar>
                ))}
                {mergeDocuments.length > 3 ? (
                  <Tooltip title="Show more collaborators">
                    <Avatar>+{mergeDocuments.length - 3}</Avatar>
                  </Tooltip>
                ) : (
                  ""
                )}
              </AvatarGroup>
            </div>
          </div>
        </div>
        <React.Fragment>
          <table
            class="table"
            border="0"
            style={{ minHeight: 100, width: "100%", marginBottom: "3%" }}
          >
            <tr>
              <th style={{ border: 0 }}>
                <div className="row" style={{ display: 'flex' }}>
                  <div>
                  <BasicAvatar name={username} />
                    {/* <Avatar
                      style={{
                        backgroundColor: getRandomColor(),
                        color: "white"
                      }}
                    >
                      <h5
                        style={{ marginTop: "4px" }}
                        data-toggle="tooltip"
                        title={username}
                      >
                        {username.charAt(0).toUpperCase()}
                      </h5>
                    </Avatar> */}
                  </div>
                  <div className="pl-3" style={{ marginTop: "10px" }}>
                    <span className="whiteText"> {username} </span>
                  </div>
                </div>
              </th>
              <th style={{ border: 0, textAlign: "center" }}>
                {/* <h3>{originalDocument.documentName}</h3> */}
              </th>
              <th style={{ border: 0 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <BasicAvatar name={compareName} />
                    {/* <Avatar
                      style={{
                        backgroundColor: getRandomColor(),
                        color: "white"
                      }}
                    >
                      <h5
                        style={{ marginTop: "4px" }}
                        data-toggle="tooltip"
                        title={compareName}
                      >
                        {compareName.charAt(0).toUpperCase()}
                      </h5>
                    </Avatar> */}
                  </div>
                  <div className="pl-3" style={{ marginTop: "10px" }}>
                    <span className="whiteText">{compareName}</span>
                  </div>
                </div>
              </th>
            </tr>

            <tbody>
              {/* <tr>
                <td style={{ border: "1px solid #f0f0f7" }}></td>
                <td style={{ border: "1px solid #f0f0f7", textAlign: "center" }}>
                  <h3>Document : {originalDocument.documentName}</h3>
                </td>
                <td style={{ border: "1px solid #f0f0f7" }}></td>
              </tr> */}
              <tr>
                <td
                  className="changeBggray docName"
                  style={{
                    border: "0px solid #f0f0f7",
                    // backgroundColor: "#fff",
                    width: "40%",
                  }}
                >
                  {" "}
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <h3> {originalDocument.documentName}</h3>
                  </div>
                </td>
                {/* <td style={{ border: "1px solid #f0f0f7" }}></td> */}
                <td style={{ border: "1px solid transparent" }}></td>
                <td
                  className=" ml-2 changeBggray docName"
                  style={{
                    border: "0px solid #f0f0f7",
                    // backgroundColor: "#fff",
                    width: "40%",
                  }}
                >
                  <div
                    className="container"
                    style={{
                      marginTop: "3%",
                      textAlign: "justify",
                    }}
                  >
                    <h3>{originalDocument.documentName}</h3>
                  </div>
                </td>
              </tr>
              {/* <h3 style={{ textAlign: "center" }}>{originalDocument.documentName}</h3> */}

              {/* ***** Collaborator ***** */}
              {leftCell.map((editor, index) => (
                <tr>
                  <td
                    className="reactour-2 changeBggray whiteText"
                    style={{
                      border: "0px solid #E0E1E3",
                      width: "40%",
                      // backgroundColor: "#fff",
                    }}
                  >
                    <div
                      className="container"
                      style={{
                        marginTop: "3%",
                        textAlign: "justify",
                      }}
                    >
                      {/* <h3>{originalDocument.documentName}</h3> */}

                      {/* <text>{htmlContent}</text> */}

                      <text key={leftCell[index].key}>
                        {leftCell[index] && (
                          <Diff
                            inputA={leftCell[index].text}
                            inputB={rightCell[index].text}
                            type="words"
                            color={true}
                            reject={leftCell[index].reject || false}
                          />
                        )}
                      </text>
                    </div>
                  </td>

                  <td style={{ border: "1px solid transparent" }} key={editor.key}>
                    {leftCell[index].done ? (
                      <Card style={{ width: "300px" }} color="#CDE4FA">
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() =>
                              this.undoChange(
                                leftCell[index].key,
                                rightCell[index].text
                              )
                            }
                          >
                            UNDO
                          </Button>
                        </CardActions>
                      </Card>
                    ) : (
                      <>
                        {leftCell[index].text !== rightCell[index].text && (
                          <Card
                            className="reactour-4"
                            style={{ width: "300px" }}
                            color="#CDE4FA"
                          >
                            {/* <CardContent>
                      <Typography variant="body2" component="p">
                        Team Collaboration
                      </Typography>
                    </CardContent> */}
                            <CardActions>
                              <div className="row">
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-5 acceptColor"
                                    size="small"
                                    // color="primary"
                                    // style={{ color: "green" }}
                                    onClick={() =>
                                      this.onAccept(
                                        leftCell[index].key,
                                        rightCell[index].text
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                </div>
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-6 rejectColor"
                                    size="small"
                                    // style={{ color: "red" }}
                                    onClick={() =>
                                      this.onReject(
                                        leftCell[index].key,
                                        leftCell[index].text
                                      )
                                    }
                                  >
                                    Reject
                                  </Button>
                                </div>
                                <div className="col-sm-4">
                                  <Button
                                    className="reactour-7 bothColor"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      this.onBoth(
                                        leftCell[index].key,
                                        rightCell[index].text
                                      )
                                    }
                                  >
                                    BOTH
                                  </Button>
                                </div>
                              </div>
                            </CardActions>
                          </Card>
                        )}
                      </>
                    )}
                  </td>
                  <td
                    className="reactour-3 changeBggray whiteText"
                    style={{
                      border: "0px solid #E0E1E3",
                      width: "40%",
                      // backgroundColor: "#fff",
                    }}
                  >
                    <div
                      className="container"
                      style={{
                        marginTop: "3%",
                        textAlign: "justify",
                      }}
                    >
                      {/* <h3> {originalDocument.documentName} </h3> */}
                      {/* <text>{comapreContent}</text> */}

                      <text key={rightCell[index].key}>
                        {rightCell[index] && (
                          <Diff
                            inputA={leftCell[index].text}
                            inputB={rightCell[index].text}
                            type="chars"
                            color={false}
                            right={true}
                          />
                        )}
                      </text>
                    </div>
                  </td>
                </tr>
              ))}
              {/* ***** Collaborator ***** */}
            </tbody>
          </table>
          <br />
          <br />

          <div className="row">
            {/* <div className="col-md-10"></div> */}
            <div className="col-md-12 text-center">
              <button
                type="button"
                className={tourChange ? "btn btn-primary reactour-8" : "btn btn-primary reactour-4"}
                onClick={this.resetChanges}
                style={{
                  marginRight: "5%",
                }}
              >
                Reset Changes
              </button>
              <button
                type="button"
                className={tourChange ? "btn themeBtn reactour-9" : "btn themeBtn reactour-5"}
                onClick={this.mergeDocument}
                disabled={!this.state.merging}
              >
                Complete Merging
              </button>
            </div>
          </div>
        </React.Fragment>

        {/* <!-- Modal --> */}
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Collaborators
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {mergeDocuments.map((doc) => (
                  <div className="row mt-1 point" data-dismiss="modal">
                    <div
                      className="col-sm-2"
                      onClick={() => this.compareData(doc)}
                    >
                      <BasicAvatar name={doc.editor.username} />
                      {/* <Avatar
                        style={{
                          backgroundColor: getRandomColor(),
                          color: "white"
                        }}
                      >
                        <h5
                          style={{ marginTop: "4px" }}
                          data-toggle="tooltip"
                          title={doc.editor.username}
                        >
                          {doc.editor.username.charAt(0).toUpperCase()}
                        </h5>
                      </Avatar> */}
                    </div>
                    <div
                      className="col-sm-6"
                      onClick={() => this.compareData(doc)}
                    >
                      <small>
                        <strong>{doc.editor.username}</strong>
                      </small>
                    </div>
                    <div className="col-sm-4 ml-auto">
                      <i
                        class="fa fa-trash"
                        style={{ color: "red", fontSize: "20px" }}
                        aria-hidden="true"
                        onClick={() => this.removeCollaborater(doc)}
                      ></i>
                    </div>
                  </div>
                ))}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(TeamCollaboration));